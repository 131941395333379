import axios from "axios";
import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import Printheader from '../assets/printheader.png';
import Printfooter from '../assets/printfooter.png';

export default class Viewtreatment extends Component{

    constructor(props){
        super(props);
        this.state = {
            newvalarrpd :[],
            fromdate:"",
            todate:"",
            today : new Date(),
            datearrpd : [],
            findvtdata :[],
            // monthname : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            monthname : ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            findpid:[],
            findappdetails:[],
            rows:[],
        }

        this.fetchviewtreatdata();

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleChangerows = idx => e => {
        console.log("qq")
        const { name, value } = e.target;
        console.log(name + " = " + value)
        const rows = [...this.state.rows];
        let crow = rows[idx]
        crow[name] = value;
        rows[idx] = crow
        this.setState({ rows });
    };

    fetchviewtreatdata = () =>{
        const datavt = {
            fetchviewtreatdata : "yes",
            puid : this.props.match.params.puid,
            treatid : this.props.match.params.treatid,
            appid : this.props.match.params.appid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/therapy.php",datavt).then((res)=>{
            console.log(res.data)

            // if(res.data.findtherypy != false){
            //     this.setState({ newvalarrpd : JSON.parse(res.data.findtherypy['therapydata']) })
            // }
            this.setState({findappdetails:res.data.findappdetails})
            this.setState({findpid:res.data.findpid})
            this.setState({findvtdata:res.data.findvtdata})

            if(res.data.findtherypy === false){
                let rows = [];
                for(let i = 0; i < res.data.findvtdata['totaldays']; i++ ){
                    rows.push({ day : i+1, date : "", status : "", note : ""});
                }
                this.setState({rows:rows});
            } else {
                this.setState({rows:JSON.parse(res.data.findtherypy['therapydata'])})
            }

            // this.setState({ fromdate : res.data.findvtdata['fromdate']})
            // this.setState({ todate : res.data.findvtdata['todate']})

            // var fromdate = new Date(res.data.findvtdata['fromdate'])
            // var todate = new Date(res.data.findvtdata['todate'])
            // // console.log(new Date(res.data.findvtdata['todate']));

            // var datearr = [];
            // while (fromdate <= todate) {
            //     datearr.push(new Date(fromdate));
            //     fromdate.setDate(fromdate.getDate() + 1);
            // }
            // // console.log(datearr)
            // this.setState({ datearrpd : datearr })
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    handleTaskChange = (event,index,date) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
        let newvalarr = this.state.newvalarrpd;
        let newval = { date : date, status : event.target.value }
        if(event.target.value != "")
        {
            if(newvalarr.some(e => e.date == newval['date'])){         // if date is equal
                if(newvalarr.some(e => e.status != newval['status'])){                            // if status is equal
                    newvalarr.splice(newvalarr.findIndex(({ date }) => date === newval['date']), 1)
                    newvalarr.push(newval);
                }
            }
            else{
                newvalarr.push(newval);
            }
        } else {
            newvalarr.splice(newvalarr.findIndex(({ date }) => date === newval['date']), 1)
        }
        this.setState({newvalarrpd:newvalarr})

    }

    // checkselectboxpd = (name,date,index) =>{
    //     for(let j=0;j<this.state.newvalarrpd.length;j++){
    //         var newdatenew = new Date(this.state.newvalarrpd[j]['date'])
    //         var m = newdatenew.getMonth() + 1;
    //         var newdate = newdatenew.getFullYear()+"-"+ m +"-"+newdatenew.getDate();
    //         var status = "";
    //         // console.log(new Date(date))
    //         // console.log(new Date(newdate))
    //         // console.log(new Date(date) > new Date(newdate))
    //         if(date == newdate){
    //             status = this.state.newvalarrpd[j]['status'];
    //             break; 
    //         }
    //         // console.log(status + "  " + date + "  " + name)
    //     }
    //     return (<>
    //         <select className="form-control" value={status} name={name} onChange={(e)=>this.handleTaskChange(e,index,date)}>
    //             <option value="">Select</option>
    //             <option value="progress">Progress</option>
    //             <option value="completed">Completed</option>
    //             <option value="not_complete">Not Complete</option>
    //         </select>
    //     </>)
    // }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 100px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 70%;
                                            height: 100px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 1)
    }

    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px"}}>
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                </div>
                <hr style={{margin:"1.5rem 0",borderTop:"3px solid #616161",opacity:"1"}} />
                    <div id="printablediv">
                        <div className="row">
                            <div className="col-sm-6">
                                <p><b>Patient Name : </b>{this.state.findpid['patient_name']}</p>
                            </div>
                            <div className="col-sm-6">
                                <p><b>Mobile Number : </b>{this.state.findpid['mobile_number']}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <p><b>Appointment Date : </b>{this.state.findappdetails['schedule_date']}</p>
                            </div>
                            <div className="col-sm-6">
                                <p><b>Time : </b>{this.state.findappdetails['start_time']} - {this.state.findappdetails['end_time']}</p>
                            </div>
                        </div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.rows.map((item,index)=>{
                                        return(
                                            <>
                                                <tr>
                                                    <td>{item.day}</td>
                                                    <td><input type={"date"} value={item.date} className="form-control" name="date" onChange={this.handleChangerows(index)} /></td>
                                                    <td>
                                                        <select className="form-control" value={item.status} name="status" onChange={this.handleChangerows(index)}>
                                                            <option value="">Select</option>
                                                            <option value="progress">Progress</option>
                                                            <option value="completed">Completed</option>
                                                            <option value="not_complete">Not Complete</option>
                                                        </select>
                                                    </td>
                                                    <td><input type={"text"} value={item.note} className="form-control" name="note" onChange={this.handleChangerows(index)} /></td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                                {/* {
                                    this.state.datearrpd.map((item, index) =>{
                                        var name = this.state.monthname[item.getMonth()]+"_"+item.getDate()+"_"+item.getFullYear();
                                        var date = item.getFullYear()+"-"+(item.getMonth()+1)+"-"+item.getDate();
                                        return(
                                            <>
                                                <tr>
                                                    <td><p className="m-0">{item.getDate()}-{this.state.monthname[item.getMonth()]}-{item.getFullYear()}</p></td>
                                                    <td>{this.checkselectboxpd(name,date,index)}</td>
                                                </tr> */}
                                                        
                                                {/* <div className="row mt-2">
                                                    <div className="col-sm-3"></div>
                                                    <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                                        <p className="m-0">{item.getDate()}-{this.state.monthname[item.getMonth()]}-{item.getFullYear()}</p>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        {
                                                            this.checkselectboxpd(name,date,index)
                                                        }
                                                    </div>
                                                    <div className="col-sm-3"></div>
                                                </div> */}
                                            {/* </>
                                        )
                                    })
                                } */}
                            </tbody>
                        </table>

                        {
                            (localStorage.getItem("roleslugarr").includes("Update_Therapy")) ? (<>
                                <div className="row">
                                    <div className="col-sm-12 d-flex justify-content-center mt-3">
                                        <button type="submit" className="btn btn-primary btn-sm" >Submit</button>
                                    </div>
                                </div>
                            </>) : (<></>)
                        }
                    </div>
                </div>
            </>
        )
    }
}