import axios from "axios";
import React from "react";
import coreconceptlogo from './assets/Core-Concept-Logo.png';
import swal from 'sweetalert';

export default class Forgot_password extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            otppart : false,
            otp : "",
        }

        this.handleEmail = this.handleEmail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        if(this.state.npswd == this.state.rnpwsd){
            if(this.state.sendotp == this.state.otp){
                if(this.state.npswd == ""){
                    swal("Error", "Please Enter Password", "error").then((swalres)=>{
                    });
                } else {
                    axios.post('https://clinic.dnyanasha.com/ws/login.php',{ submitnewpass : "yes", npswd : this.state.npswd, email : this.state.email, user : this.state.user}).then((res)=>{
                        console.log(res.data)
                        if(res.data.updatepass == true){
                            swal("Updated", "Password Updates successfully", "success").then((swalres)=>{
                            });
                            this.props.history.push( '/');
                        }
                    })
                }
            } else {
                swal("Error", "OTP Not Match", "error").then((swalres)=>{
                });
            }
        } else {
            swal("Error", "OLD and NEW Password Doesn't Match", "error").then((swalres)=>{
            });
        }
    }

    handleChange = (event) => {
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleEmail = (event) =>{
        event.preventDefault();
        if(this.state.email == "" || this.state.email == undefined || this.state.email == null){
            swal("Error", "Please Enter Email", "error").then((swalres)=>{
            });
        } else {
            axios.post('https://clinic.dnyanasha.com/ws/login.php',{ fpemailotp : "yes", email : this.state.email }).then((res)=>{
                console.log(res.data)
                if(res.data.response == "User Not Found"){
                    swal(res.data.response, "User is Not Registered", "error").then((swalres)=>{
                    });
                } else {
                    this.setState({otppart:true})
                    this.setState({response:res.data.response})
                    this.setState({sendotp:res.data.sendotp})
                    this.setState({user:res.data.user})
                }
            })
        }
    }

    render() {
        return (
            <>
                <div className="loginbody">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center w-100">
                            <div className="col-md-6">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <div className="text-center mb-3 bg-white p-2" style={{borderRadius: "20px"}}>
                                                    <a href="#"> <img src={coreconceptlogo} style={{width:"50%"}} alt="" /></a>
                                                </div>
                                                <h4 className="text-center mb-4  text-white">Forgot Password</h4>
                                                
                                                {
                                                    (this.state.otppart) ? (<>
                                                        <h4 className="text-center mb-4  text-white">{this.state.response}</h4>

                                                        <div className="form-group mt-2">
                                                            <label className="mb-1  text-white"><strong>Enter OTP</strong></label>
                                                            <input type="text" name="otp" className="form-control form-controllogin" value={this.state.otp} onChange={this.handleChange} placeholder="OTP" required />
                                                        </div>
                                                        <div className="form-group mt-2">
                                                            <label className="mb-1  text-white"><strong>New Password</strong></label>
                                                            <input type="text" name="npswd" className="form-control form-controllogin" value={this.state.npswd} onChange={this.handleChange} placeholder="New Password" required />
                                                        </div>
                                                        <div className="form-group mt-2">
                                                            <label className="mb-1  text-white"><strong>Re-Enter Password</strong></label>
                                                            <input type="text" name="rnpwsd" className="form-control form-controllogin" value={this.state.rnpwsd} onChange={this.handleChange} placeholder="Re-Enter Password" required />
                                                        </div>

                                                        <button className="btn btnlogin bg-white btn-block mt-4" onClick={this.handleSubmit}>Submit</button>
                                                    </>) : (<>
                                                        <div className="form-group">
                                                            <label className="mb-1  text-white"><strong>Email</strong></label>
                                                            <input type="email" name="email" className="form-control form-controllogin" onChange={this.handleChange} placeholder="example@gmail.com" required />
                                                        </div>
                                                        <button className="btn btnlogin bg-white btn-block mt-3" onClick={this.handleEmail}>Submit</button>
                                                    </>)
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}