import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert";
import Printheader from './assets/printheader.png';
import Printfooter from './assets/printfooter.png';

export default class Viewtreatmentmainnew extends Component {
    constructor(props){
        super(props);
        this.state = {
            findvtdatam:[],
            datearr:[],
            newvalarr : [],
            findtherypy:[],
            findpid : [],
            findappdetails : [],
            // values : [],
            // newValues : [...values],
            monthname : ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            rows : [],
        }

        this.fetchtreatmian();

        this.handleChangerows = this.handleChangerows.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    fetchtreatmian = () =>{
        const datavtm = {
            fetchtreatmiannew : "yes",
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/therapy.php",datavtm).then((res)=>{
            console.log(res.data)
            this.setState({findpid:res.data.findpid})
            this.setState({findappdetails:res.data.findappdetails})

            const highestTotalDays = res.data.findvtdatam.reduce((maxTotalDays, item) => {
                return item.totaldays > maxTotalDays ? item.totaldays : maxTotalDays;
            }, 0);
            console.log(highestTotalDays)

            var treatment = [];
            if(res.data.findvtdatam !== false){
                for(let j=0;j<res.data.findvtdatam.length;j++){
                    treatment.push({"treatname":res.data.findvtdatam[j]['procedure_name'],"treatstatus":false})
                }
            }
            
            let rows = [];
            for(let i = 0; i < highestTotalDays; i++ ){
                rows.push({ day : i+1, date : "", status : "", treatment : treatment, note : ""});
            }
            this.setState({rows:rows});

            // if(res.data.findtherypy === false){
            //     let rows = [];
            //     for(let i = 0; i < res.data.findvtdatam['totaldays']; i++ ){
            //         rows.push({ day : i+1, date : "", status : "", note : ""});
            //     }
            //     this.setState({rows:rows});
            // } else {
            //     this.setState({rows:JSON.parse(res.data.findtherypy['therapydata'])})
            // }
        })
    }

    handleChangerows = idx => e => {
        console.log("qq")
        const { name, value } = e.target;
        console.log(name + " = " + value)
        const rows = [...this.state.rows];
        let crow = rows[idx]
        crow[name] = value;
        rows[idx] = crow
        this.setState({ rows });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .header, .header-space, .footer, .footer-space {
                                            height: 100px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                        }
                                        .header img{
                                            width: 100%;
                                            height: 100px;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                        }
                                        .footer img {
                                            width: 100%;
                                            height: 100px;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 1)
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const savetherpy = {
            savetherpy: "yes",
            therapydata: this.state.rows,
            puid : this.props.match.params.puid,
            treatment_id : this.props.match.params.treatid,
        }
        console.log(savetherpy);
        axios.post("https://clinic.dnyanasha.com/ws/therapy.php", savetherpy).then((res) => {
            console.log(res.data);
            if(res.data.savetherapy > 0){
                swal("Therapy Saved", "Patient Therapy Saved Successfully..!", "success").then((swalres)=>{
                });
                this.fetchtreatmian();
            } else if(res.data.updatetp == true){
                swal("Therapy Updated", "Patient Therapy Updated Successfully..!", "success").then((swalres)=>{
                });
                this.fetchtreatmian();
            }
        });
    }

    render(){
        return(
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                </div>
                <hr style={{margin:"1.5rem 0",borderTop:"3px solid #616161",opacity:"1"}} />
                <div id="printablediv">
                    <div className="row">
                        <div className="col-sm-6">
                            <p><b>Patient Name : </b>{this.state.findpid['patient_name']}</p>
                        </div>
                        <div className="col-sm-6">
                            <p><b>Mobile Number : </b>{this.state.findpid['mobile_number']}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <p><b>Appointment Date : </b>{this.state.findappdetails['schedule_date']}</p>
                        </div>
                        <div className="col-sm-6">
                            <p><b>Time : </b>{this.state.findappdetails['start_time']} - {this.state.findappdetails['end_time']}</p>
                        </div>
                    </div>


                    <form onSubmit={this.handleSubmit}>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Date</th>
                                    <th>Treatment</th>
                                    <th>Status</th>
                                    <th>Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.rows.map((item,index)=>{
                                        return(
                                            <>
                                                <tr>
                                                    <td>{item.day}</td>
                                                    <td><input type={"date"} value={item.date} className="form-control" name="date" onChange={this.handleChangerows(index)} /></td>
                                                    <td style={{fontSize:"15px"}}>
                                                        {item.treatment.map((resss,indexs)=>{return(<>
                                                            <input type="checkbox" id={resss.treatname+index+"id"} checked={(resss.treatstatus) ? true : false } /> <label htmlFor={resss.treatname+index+"id"}>{resss.treatname}</label> <br />
                                                        </>)})}
                                                    </td>
                                                    <td>
                                                        <select className="form-control" value={item.status} name="status" onChange={this.handleChangerows(index)}>
                                                            <option value="">Select</option>
                                                            <option value="progress">Progress</option>
                                                            <option value="completed">Completed</option>
                                                            <option value="not_complete">Not Complete</option>
                                                        </select>
                                                    </td>
                                                    <td><input type={"text"} value={item.note} className="form-control" name="note" onChange={this.handleChangerows(index)} /></td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </form>



                </div>
            </>
        )
    }
}