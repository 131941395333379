import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import coreconceptlogo from '../assets/Core-Concept-Logo.png';
import axios from "axios";
import Printheader from '../assets/printheader.png';
import Printfooter from '../assets/printfooter.png';

//form images import
import cercivaltext from '../assets/orthoneuro/cercivaltext.png'
import ganglion from '../assets/orthoneuro/ganglion.jpg'
import libmantest from '../assets/orthoneuro/libmantest.jpg'
import jawsubluxcation from '../assets/orthoneuro/jawsubluxcation.jpg'
import cervicalmotionab from '../assets/orthoneuro/cervicalmotionab.jpg'
import cervicalmotioncd from '../assets/orthoneuro/cervicalmotioncd.jpg'
import muscletesting from '../assets/orthoneuro/muscletesting.jpg'
import cervicalcomptest from '../assets/orthoneuro/cervicalcomptest.jpg'
import cervicalcomptestcont from '../assets/orthoneuro/cervicalcomptestcont.jpg'
import solohalltest from '../assets/orthoneuro/solohalltest.jpg'
import cervicaldistraction from '../assets/orthoneuro/cervicaldistraction.jpg'
import maxforaminatest from '../assets/orthoneuro/maxforaminatest.jpg'
import odonahuemaneuver from '../assets/orthoneuro/odonahuemaneuver.jpg'
import valsalvatest from '../assets/orthoneuro/valsalvatest.jpg'
import adsontest from '../assets/orthoneuro/adsontest.jpg'
import allenstest from '../assets/orthoneuro/allenstest.jpg'
import wrighttest from '../assets/orthoneuro/wrighttest.jpg'
import flexionextension from '../assets/orthoneuro/flexionextension.jpg'
import rotationabduction from '../assets/orthoneuro/rotationabduction.jpg'
import shoulderatbtest from '../assets/orthoneuro/shoulderatbtest.jpg'
import codmanarm from '../assets/orthoneuro/codmanarm.jpg'
import supraapinatoustest from '../assets/orthoneuro/supraapinatoustest.jpg'
import apleyscartchtest from '../assets/orthoneuro/apleyscartchtest.jpg'
import coracaoidbuttonsign from '../assets/orthoneuro/coracaoidbuttonsign.jpg'
import dawburntest from '../assets/orthoneuro/dawburntest.jpg'
import apprehensiontest from '../assets/orthoneuro/apprehensiontest.jpg'
import yergasontest from '../assets/orthoneuro/yergasontest.jpg'
import cardicmusucldiffer from '../assets/orthoneuro/cardicmusucldiffer.jpg'
import relatedareaelbowrom from '../assets/orthoneuro/relatedareaelbowrom.jpg'
import cozentenniselbowtest from '../assets/orthoneuro/cozentenniselbowtest.jpg'
import ligamentousstabilitytest from '../assets/orthoneuro/ligamentousstabilitytest.jpg'
import circumference from '../assets/orthoneuro/circumference.jpg'
import tricepsreflex from '../assets/orthoneuro/tricepsreflex.jpg'
import bicepsreflex from '../assets/orthoneuro/bicepsreflex.jpg'
import brachioradialisreflex from '../assets/orthoneuro/brachioradialisreflex.jpg'
import dermatomedistribution from '../assets/orthoneuro/dermatomedistribution.jpg'
import dyanometergriptest from '../assets/orthoneuro/dyanometergriptest.jpg'
import mediannervetest from '../assets/orthoneuro/mediannervetest.jpg'
import tinelsign from '../assets/orthoneuro/tinelsign.jpg'
import phalenstest from '../assets/orthoneuro/phalenstest.jpg'
import radilnervetest from '../assets/orthoneuro/radilnervetest.jpg'
import ulnarnervetest from '../assets/orthoneuro/ulnarnervetest.jpg'
import wristcolonus from '../assets/orthoneuro/wristcolonus.jpg'
import swal from "sweetalert";

export default class OrthoNeuroFrom extends Component{
    constructor(props){
        super(props);
        this.state = {
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            pname:"",
            nextbutons : false,

            texamblack : false,
            fexamblue : false,
            sexamred : false,
        }

        this.fetchorthoneuradata()
        // this.determineInputColor();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
    }

    determineInputColor = () => {
        const { fexamblue, sexamred, texamblack } = this.state;
        // return fexamblue ? 'orthoinputblue' : sexamred ? 'orthoinputred' : texamblack ? 'orthoinputblack' : '';
        return texamblack ? 'orthoinputblack' : sexamred ? 'orthoinputred' : fexamblue ? 'orthoinputblue' : '';
    }

    handleCheckboxChange = (checkboxName, value) => {
        this.setState({ [checkboxName]: value });
    };

    handleSubmit = (event) =>{
        event.preventDefault();
        console.log(event.target.length);
        const dataon = { saveortoneuro : "yes", puid: this.props.match.params.puid, appid: this.props.match.params.appid, textcolor : this.determineInputColor() }
        const tabledataon = { saveorthoneurotbaleforma : "yes", puid: this.props.match.params.puid, appid: this.props.match.params.appid, }
        for (let i = 0; i < event.target.length; i++) {
            if((event.target[i].name).includes("table_") === true){
                // console.log(event.target[i].name);
                tabledataon[event.target[i].name] = event.target[i].checked
            } else {
                // console.log(event.target[i].name);
                if(event.target[i].name === "fexamblue" || event.target[i].name === "sexamred" || event.target[i].name === "texamblack"){} else {
                    dataon[event.target[i].name] = event.target[i].value
                }
            }
        }
        console.log(tabledataon);
        this.savetabeldata(tabledataon);
        axios.post("https://clinic.dnyanasha.com/ws/consultation.php",dataon).then((res)=>{
            console.log(res.data)
            if(res.data.saveorthoneuro > 0){
                // swal("Added Successfully", "Ortho & Neuro form Successfully Added..!", "success").then((swalres)=>{
                //     this.fetchorthoneuradata();
                // });
                this.setState({nextbutons:true})
            }
            if(res.data.updateon === true){
                // swal("Updated Successfully", "Patient History & Observation Successfully Updated..!", "success").then((swalres)=>{
                //     this.fetchorthoneuradata();
                // });
                this.setState({nextbutons:true})
            }
        })
    }

    savetabeldata = (tabledataon) =>{
        axios.post("https://clinic.dnyanasha.com/ws/consultation.php",tabledataon).then((res)=>{
            console.log(res.data);
        })
    }

    fetchorthoneuradata = () =>{
        const dataon = {
            fetchorthoneuradata : "yes",
            puid: this.props.match.params.puid,
            appid: this.props.match.params.appid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/consultation.php",dataon).then((res)=>{
            console.log(res.data)
            this.setState({findpid:res.data.findpid})
            this.setState({ pname : res.data.findpid['patient_name'] })

            if(res.data.findtableformadata !== false){
                var tabledata = JSON.parse(res.data.findtableformadata['tabledata']);
                Object.entries(tabledata).map(([key,value])=>{
                    // console.log(key+" = "+value)
                    this.setState({ [key] : value })
                })
            }

            for (let j = 0; j < res.data.findorthoneurodata.length; j++) {
                // console.log(res.data.findorthoneurodata[j]['meta_key'])
                // console.log(res.data.findorthoneurodata[j]['meta_value'])
                this.setState({ [res.data.findorthoneurodata[j]['meta_key']] : res.data.findorthoneurodata[j]['meta_value'] })
                // this.setState({ textcolor : res.data.findorthoneurodata[j]['textcolor']})
                if(res.data.findorthoneurodata[j]['textcolor'] === "orthoinputblack"){
                    this.setState({ texamblack : true })
                }
                if(res.data.findorthoneurodata[j]['textcolor'] === "orthoinputred"){
                    this.setState({ sexamred : true })
                }
                if(res.data.findorthoneurodata[j]['textcolor'] === "orthoinputblue"){
                    this.setState({ fexamblue : true })
                }
            }
            this.determineInputColor();
        })

        

    }

    goBack = () => {
        this.props.history.goBack();
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        // .header, .header-space, .footer, .footer-space {
        document.body.innerHTML = `
                                    <style>
                                        .header, .footer {
                                            height: 100px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                        }
                                        .header img{
                                            width: 100%;
                                            height: 100px;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                        }
                                        .footer img {
                                            width: 100%;
                                            height: 100px;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #d2d2d2;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
                                        // <div class="footer"><img src='${Printfooter}' /> </div>
                                        // <div class="header"><img src='${Printheader}' /></div>
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 10)
    }

    orthoneuroformb = () => {
        // window.open("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroform/"+this.props.match.params.appid, '','width=600,left=200,top=200');
        // this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroformb/formb");///"+this.props.match.params.appid);
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroformb/"+this.props.match.params.appid);
    }

    presecription = () =>{
        // this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/prescription");
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/viewaddprescription/"+this.props.match.params.appid);
    }

    opentreatment = () =>{
        // this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/treatmentplans");
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/addtreatment/"+this.props.match.params.appid);
    }

    handleTableChange = (e) =>{
        this.setState({[e.target.name]:e.target.checked})
    }

    render() {
        const { fexamblue, sexamred, texamblack } = this.state;
        const inputColor = this.determineInputColor();
        return(
                <>
                    <Headerpd puid={this.props.match.params.puid} />
                    <Sidebarpd puid={this.props.match.params.puid} />
                    <div style={{ marginLeft: "220px", padding: "5px" }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                            <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                        </div>
                        <hr />
                        <form onSubmit={this.handleSubmit}>
                            <div id="printablediv">
                                <div className="d-flex justify-content-center"><img src={coreconceptlogo} style={{width: "400px",height:"120px"}} /></div>
                                <hr style={{opacity: "1",borderTop: "2.5px solid",marginBottom:"1px"}} />
                                <h4 className="text-center">ORTHOPEDIC/NEUROLOGICAL EXAMINATION</h4>

                                <div className="row" style={{fontSize:"12px"}}>
                                    <div style={{width:"75%"}}><p className="mb-1">Name : <b>{this.state.pname}</b></p></div>
                                    <div style={{width:"25%"}}><p className="mb-1">Date : <b>{new Date().getDate()} {this.state.monthname[new Date().getMonth()]} {new Date().getFullYear()}</b></p></div>
                                    <div style={{width:"33%",display:"flex"}}>Height : <input type={"number"} min={1} name="height" value={this.state.height} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="form-control h-75 mx-1" style={{width:"70%"}} /></div>
                                    <div style={{width:"33%",display:"flex"}}>Weight : <input type={"number"} min={1} name="weight" value={this.state.weight} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="form-control h-75 mx-1" style={{width:"70%"}} /></div>
                                    <div style={{width:"33%",display:"flex"}}>Blood Pressure Pulse : <input type={"text"} name="bloodpressure" value={this.state.bloodpressure} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="form-control h-75 mx-1" style={{width:"70%"}} /></div>
                                </div>
                                <hr style={{opacity: "1"}} />

                                <div className="row" style={{fontSize:"12px",fontWeight:"bold"}}>
                                    <div style={{ width:"17%" }}>Doctors Use : </div>
                                    <div style={{ width:"16%" }}>IE</div>
                                    <div style={{ width:"16%" }}>RE</div>
                                    <div style={{ width:"16%" }}>PI</div>
                                    <div style={{ width:"16%" }}>IME</div>
                                    <div style={{ width:"16%" }}>WC</div>
                                </div>

                                <div className="row" style={{fontSize:"12px",fontWeight:"bold"}}>
                                    <div style={{ width:"17%" }}></div>
                                    <div style={{ width:"27%" }} className="d-flex" >
                                        <input type={"checkbox"} className="form-check-input mx-1" id="fexamblueid" name="fexamblue" checked={fexamblue} onChange={(e) => this.handleCheckboxChange(e.target.name, e.target.checked)} /> <label htmlFor="fexamblueid">1st Exam-Blue</label>
                                    </div>
                                    <div style={{ width:"27%" }} className="d-flex" >
                                        <input type={"checkbox"} className="form-check-input mx-1" id="sexamredid" name="sexamred" checked={sexamred} onChange={(e) => this.handleCheckboxChange(e.target.name, e.target.checked)} /> <label htmlFor="sexamredid">2nd Exam-Red</label>
                                    </div>
                                    <div style={{ width:"27%" }} className="d-flex" >
                                        <input type={"checkbox"} className="form-check-input mx-1" id="texamblackid" name="texamblack" checked={texamblack} onChange={(e) => this.handleCheckboxChange(e.target.name, e.target.checked)} /> <label htmlFor="texamblackid">3rd Exam-Black</label>
                                    </div>
                                </div>
                                <hr style={{opacity: "1",marginBottom:"0px"}} />

                                <div className="row m-0 w-100" style={{borderBottom:"2px solid #000",paddingBottom:"1px"}}>
                                    <div className="p-0" style={{ width:"49.5%" }}>
                                        <div className="row m-0 mt-2 w-100" style={{fontSize:"12px",fontWeight:"bold" }}>
                                            <div style={{ width:"50%" }}>Antalgic Posture</div>
                                            <div style={{ width:"25%",display:"flex" }} >LA <input type={"text"} name="apla" value={this.state.apla} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="form-control h-75 mx-2" /> </div> 
                                            <div style={{ width:"25%",display:"flex" }} >RA <input type={"text"} name="apra" value={this.state.apra} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="form-control h-75 mx-2" /> </div>
                                        </div>
                                        
                                        <div className="row m-0" style={{height:"92.5%"}}>
                                            <div style={{width:"4%",padding:"0",display:"flex",alignItems:"center",background:"#000"}}>
                                                <img src={cercivaltext} style={{width:"100%"}} />
                                            </div>
                                            <div style={{width:"96%",padding:"0",border: "1px solid #000",borderRight:"0"}}>
                                                <p style={{fontSize:"12px",margin:"0",textAlign:"center"}}><b>1. PALPATION/MUSCLE SPASM/EDEMA/PERCUSSION:</b></p>
                                                <p style={{fontSize:"12px",margin:"0",textAlign:"center",lineHeight:"1"}}>SPINAL EXAMINATION AND ANALYSIS </p>

                                                <table style={{fontSize:"10.4px",marginTop:"2px",borderTop:"1.5px solid"}} className="table orthotable">
                                                    <tr style={{textAlign:"center"}}>
                                                        <td colSpan={2}>Spinous <br/>Percussion</td>
                                                        <td colSpan={3}>Tenderness </td>
                                                        <td colSpan={3}>Para-Vartebral <br/>Muscle Spsam </td>
                                                        <td colSpan={3}>Edema </td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>Level</td><td></td><td>Level</td><td>Left</td><td>Right</td><td>Level</td><td>Left</td><td>Right</td><td>Level</td><td>Left</td><td>Right</td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>OC</td><td><input type={"checkbox"} name="table_spoc" checked={(this.state.table_spoc === true) ? true : false} value={this.state.table_spoc} onChange={this.handleTableChange} /></td>
                                                        <td>OC</td><td><input type={"checkbox"} name="table_tdloc" checked={(this.state.table_tdloc === true) ? true : false} value={this.state.table_tdloc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdroc" checked={(this.state.table_tdroc === true) ? true : false} value={this.state.table_tdroc} onChange={this.handleTableChange} /></td>
                                                        <td>OC</td><td><input type={"checkbox"} name="table_pvmsloc" checked={(this.state.table_pvmsloc === true) ? true : false} value={this.state.table_pvmsloc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsroc" checked={(this.state.table_pvmsroc === true) ? true : false} value={this.state.table_pvmsroc} onChange={this.handleTableChange} /></td>
                                                        <td>OC</td><td><input type={"checkbox"} name="table_edloc" checked={(this.state.table_edloc === true) ? true : false} value={this.state.table_edloc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edroc" checked={(this.state.table_edroc === true) ? true : false} value={this.state.table_edroc} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>AT</td><td><input type={"checkbox"} name="table_spat" checked={(this.state.table_spat === true) ? true : false} value={this.state.table_spat} onChange={this.handleTableChange} /></td>
                                                        <td>AT</td><td><input type={"checkbox"} name="table_tdlat" checked={(this.state.table_tdlat === true) ? true : false} value={this.state.table_tdlat} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrat" checked={(this.state.table_tdrat === true) ? true : false} value={this.state.table_tdrat} onChange={this.handleTableChange} /></td>
                                                        <td>AT</td><td><input type={"checkbox"} name="table_pvmslat" checked={(this.state.table_pvmslat === true) ? true : false} value={this.state.table_pvmslat} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrat" checked={(this.state.table_pvmsrat === true) ? true : false} value={this.state.table_pvmsrat} onChange={this.handleTableChange} /></td>
                                                        <td>AT</td><td><input type={"checkbox"} name="table_edlat" checked={(this.state.table_edlat === true) ? true : false} value={this.state.table_edlat} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrat" checked={(this.state.table_edrat === true) ? true : false} value={this.state.table_edrat} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>AX</td><td><input type={"checkbox"} name="table_spax" checked={(this.state.table_spax === true) ? true : false} value={this.state.table_spax} onChange={this.handleTableChange} /></td>
                                                        <td>AX</td><td><input type={"checkbox"} name="table_tdlax" checked={(this.state.table_tdlax === true) ? true : false} value={this.state.table_tdlax} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrax" checked={(this.state.table_tdrax === true) ? true : false} value={this.state.table_tdrax} onChange={this.handleTableChange} /></td>
                                                        <td>AX</td><td><input type={"checkbox"} name="table_pvmslax" checked={(this.state.table_pvmslax === true) ? true : false} value={this.state.table_pvmslax} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrax" checked={(this.state.table_pvmsrax === true) ? true : false} value={this.state.table_pvmsrax} onChange={this.handleTableChange} /></td>
                                                        <td>AX</td><td><input type={"checkbox"} name="table_edlax" checked={(this.state.table_edlax === true) ? true : false} value={this.state.table_edlax} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrax" checked={(this.state.table_edrax === true) ? true : false} value={this.state.table_edrax} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>3C</td><td><input type={"checkbox"} name="table_sp3c" checked={(this.state.table_sp3c === true) ? true : false} value={this.state.table_sp3c} onChange={this.handleTableChange} /></td>
                                                        <td>3C</td><td><input type={"checkbox"} name="table_tdl3c" checked={(this.state.table_tdl3c === true) ? true : false} value={this.state.table_tdl3c} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr3c" checked={(this.state.table_tdr3c === true) ? true : false} value={this.state.table_tdr3c} onChange={this.handleTableChange} /></td>
                                                        <td>3C</td><td><input type={"checkbox"} name="table_pvmsl3c" checked={(this.state.table_pvmsl3c === true) ? true : false} value={this.state.table_pvmsl3c} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr3c" checked={(this.state.table_pvmsr3c === true) ? true : false} value={this.state.table_pvmsr3c} onChange={this.handleTableChange} /></td>
                                                        <td>3C</td><td><input type={"checkbox"} name="table_edl3c" checked={(this.state.table_edl3c === true) ? true : false} value={this.state.table_edl3c} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr3c" checked={(this.state.table_edr3c === true) ? true : false} value={this.state.table_edr3c} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>4C</td><td><input type={"checkbox"} name="table_sp4C" checked={(this.state.table_sp4C === true) ? true : false} value={this.state.table_sp4C} onChange={this.handleTableChange} /></td>
                                                        <td>4C</td><td><input type={"checkbox"} name="table_tdl4C" checked={(this.state.table_tdl4C === true) ? true : false} value={this.state.table_tdl4C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr4C" checked={(this.state.table_tdr4C === true) ? true : false} value={this.state.table_tdr4C} onChange={this.handleTableChange} /></td>
                                                        <td>4C</td><td><input type={"checkbox"} name="table_pvmsl4C" checked={(this.state.table_pvmsl4C === true) ? true : false} value={this.state.table_pvmsl4C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr4C" checked={(this.state.table_pvmsr4C === true) ? true : false} value={this.state.table_pvmsr4C} onChange={this.handleTableChange} /></td>
                                                        <td>4C</td><td><input type={"checkbox"} name="table_edl4C" checked={(this.state.table_edl4C === true) ? true : false} value={this.state.table_edl4C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr4C" checked={(this.state.table_edr4C === true) ? true : false} value={this.state.table_edr4C} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>5C</td><td><input type={"checkbox"} name="table_sp5C" checked={(this.state.table_sp5C === true) ? true : false} value={this.state.table_sp5C} onChange={this.handleTableChange} /></td>
                                                        <td>5C</td><td><input type={"checkbox"} name="table_tdl5C" checked={(this.state.table_tdl5C === true) ? true : false} value={this.state.table_tdl5C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr5C" checked={(this.state.table_tdr5C === true) ? true : false} value={this.state.table_tdr5C} onChange={this.handleTableChange} /></td>
                                                        <td>5C</td><td><input type={"checkbox"} name="table_pvmsl5C" checked={(this.state.table_pvmsl5C === true) ? true : false} value={this.state.table_pvmsl5C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr5C" checked={(this.state.table_pvmsr5C === true) ? true : false} value={this.state.table_pvmsr5C} onChange={this.handleTableChange} /></td>
                                                        <td>5C</td><td><input type={"checkbox"} name="table_edl5C" checked={(this.state.table_edl5C === true) ? true : false} value={this.state.table_edl5C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr5C" checked={(this.state.table_edr5C === true) ? true : false} value={this.state.table_edr5C} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>6C</td><td><input type={"checkbox"} name="table_sp6C" checked={(this.state.table_sp6C === true) ? true : false} value={this.state.table_sp6C} onChange={this.handleTableChange} /></td>
                                                        <td>6C</td><td><input type={"checkbox"} name="table_tdl6C" checked={(this.state.table_tdl6C === true) ? true : false} value={this.state.table_tdl6C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr6C" checked={(this.state.table_tdr6C === true) ? true : false} value={this.state.table_tdr6C} onChange={this.handleTableChange} /></td>
                                                        <td>6C</td><td><input type={"checkbox"} name="table_pvmsl6C" checked={(this.state.table_pvmsl6C === true) ? true : false} value={this.state.table_pvmsl6C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr6C" checked={(this.state.table_pvmsr6C === true) ? true : false} value={this.state.table_pvmsr6C} onChange={this.handleTableChange} /></td>
                                                        <td>6C</td><td><input type={"checkbox"} name="table_edl6C" checked={(this.state.table_edl6C === true) ? true : false} value={this.state.table_edl6C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr6C" checked={(this.state.table_edr6C === true) ? true : false} value={this.state.table_edr6C} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>7C</td><td><input type={"checkbox"} name="table_sp7C" checked={(this.state.table_sp7C === true) ? true : false} value={this.state.table_sp7C} onChange={this.handleTableChange} /></td>
                                                        <td>7C</td><td><input type={"checkbox"} name="table_tdl7C" checked={(this.state.table_tdl7C === true) ? true : false} value={this.state.table_tdl7C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr7C" checked={(this.state.table_tdr7C === true) ? true : false} value={this.state.table_tdr7C} onChange={this.handleTableChange} /></td>
                                                        <td>7C</td><td><input type={"checkbox"} name="table_pvmsl7C" checked={(this.state.table_pvmsl7C === true) ? true : false} value={this.state.table_pvmsl7C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr7C" checked={(this.state.table_pvmsr7C === true) ? true : false} value={this.state.table_pvmsr7C} onChange={this.handleTableChange} /></td>
                                                        <td>7C</td><td><input type={"checkbox"} name="table_edl7C" checked={(this.state.table_edl7C === true) ? true : false} value={this.state.table_edl7C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr7C" checked={(this.state.table_edr7C === true) ? true : false} value={this.state.table_edr7C} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>1T</td><td><input type={"checkbox"} name="table_sp1T" checked={(this.state.table_sp1T === true) ? true : false} value={this.state.table_sp1T} onChange={this.handleTableChange} /></td>
                                                        <td>1T</td><td><input type={"checkbox"} name="table_tdl1T" checked={(this.state.table_tdl1T === true) ? true : false} value={this.state.table_tdl1T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr1T" checked={(this.state.table_tdr1T === true) ? true : false} value={this.state.table_tdr1T} onChange={this.handleTableChange} /></td>
                                                        <td>1T</td><td><input type={"checkbox"} name="table_pvmsl1T" checked={(this.state.table_pvmsl1T === true) ? true : false} value={this.state.table_pvmsl1T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr1T" checked={(this.state.table_pvmsr1T === true) ? true : false} value={this.state.table_pvmsr1T} onChange={this.handleTableChange} /></td>
                                                        <td>1T</td><td><input type={"checkbox"} name="table_edl1T" checked={(this.state.table_edl1T === true) ? true : false} value={this.state.table_edl1T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr1T" checked={(this.state.table_edr1T === true) ? true : false} value={this.state.table_edr1T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>2T</td><td><input type={"checkbox"} name="table_sp2T" checked={(this.state.table_sp2T === true) ? true : false} value={this.state.table_sp2T} onChange={this.handleTableChange} /></td>
                                                        <td>2T</td><td><input type={"checkbox"} name="table_tdl2T" checked={(this.state.table_tdl2T === true) ? true : false} value={this.state.table_tdl2T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr2T" checked={(this.state.table_tdr2T === true) ? true : false} value={this.state.table_tdr2T} onChange={this.handleTableChange} /></td>
                                                        <td>2T</td><td><input type={"checkbox"} name="table_pvmsl2T" checked={(this.state.table_pvmsl2T === true) ? true : false} value={this.state.table_pvmsl2T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr2T" checked={(this.state.table_pvmsr2T === true) ? true : false} value={this.state.table_pvmsr2T} onChange={this.handleTableChange} /></td>
                                                        <td>2T</td><td><input type={"checkbox"} name="table_edl2T" checked={(this.state.table_edl2T === true) ? true : false} value={this.state.table_edl2T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr2T" checked={(this.state.table_edr2T === true) ? true : false} value={this.state.table_edr2T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>3T</td><td><input type={"checkbox"} name="table_sp3T" checked={(this.state.table_sp3T === true) ? true : false} value={this.state.table_sp3T} onChange={this.handleTableChange} /></td>
                                                        <td>3T</td><td><input type={"checkbox"} name="table_tdl3T" checked={(this.state.table_tdl3T === true) ? true : false} value={this.state.table_tdl3T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr3T" checked={(this.state.table_tdr3T === true) ? true : false} value={this.state.table_tdr3T} onChange={this.handleTableChange} /></td>
                                                        <td>3T</td><td><input type={"checkbox"} name="table_pvmsl3T" checked={(this.state.table_pvmsl3T === true) ? true : false} value={this.state.table_pvmsl3T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr3T" checked={(this.state.table_pvmsr3T === true) ? true : false} value={this.state.table_pvmsr3T} onChange={this.handleTableChange} /></td>
                                                        <td>3T</td><td><input type={"checkbox"} name="table_edl3T" checked={(this.state.table_edl3T === true) ? true : false} value={this.state.table_edl3T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr3T" checked={(this.state.table_edr3T === true) ? true : false} value={this.state.table_edr3T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>4T</td><td><input type={"checkbox"} name="table_sp4T" checked={(this.state.table_sp4T === true) ? true : false} value={this.state.table_sp4T} onChange={this.handleTableChange} /></td>
                                                        <td>4T</td><td><input type={"checkbox"} name="table_tdl4T" checked={(this.state.table_tdl4T === true) ? true : false} value={this.state.table_tdl4T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr4T" checked={(this.state.table_tdr4T === true) ? true : false} value={this.state.table_tdr4T} onChange={this.handleTableChange} /></td>
                                                        <td>4T</td><td><input type={"checkbox"} name="table_pvmsl4T" checked={(this.state.table_pvmsl4T === true) ? true : false} value={this.state.table_pvmsl4T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr4T" checked={(this.state.table_pvmsr4T === true) ? true : false} value={this.state.table_pvmsr4T} onChange={this.handleTableChange} /></td>
                                                        <td>4T</td><td><input type={"checkbox"} name="table_edl4T" checked={(this.state.table_edl4T === true) ? true : false} value={this.state.table_edl4T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr4T" checked={(this.state.table_edr4T === true) ? true : false} value={this.state.table_edr4T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>5T</td><td><input type={"checkbox"} name="table_sp5T" checked={(this.state.table_sp5T === true) ? true : false} value={this.state.table_sp5T} onChange={this.handleTableChange} /></td>
                                                        <td>5T</td><td><input type={"checkbox"} name="table_tdl5T" checked={(this.state.table_tdl5T === true) ? true : false} value={this.state.table_tdl5T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr5T" checked={(this.state.table_tdr5T === true) ? true : false} value={this.state.table_tdr5T} onChange={this.handleTableChange} /></td>
                                                        <td>5T</td><td><input type={"checkbox"} name="table_pvmsl5T" checked={(this.state.table_pvmsl5T === true) ? true : false} value={this.state.table_pvmsl5T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr5T" checked={(this.state.table_pvmsr5T === true) ? true : false} value={this.state.table_pvmsr5T} onChange={this.handleTableChange} /></td>
                                                        <td>5T</td><td><input type={"checkbox"} name="table_edl5T" checked={(this.state.table_edl5T === true) ? true : false} value={this.state.table_edl5T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr5T" checked={(this.state.table_edr5T === true) ? true : false} value={this.state.table_edr5T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>6T</td><td><input type={"checkbox"} name="table_sp6T" checked={(this.state.table_sp6T === true) ? true : false} value={this.state.table_sp6T} onChange={this.handleTableChange} /></td>
                                                        <td>6T</td><td><input type={"checkbox"} name="table_tdl6T" checked={(this.state.table_tdl6T === true) ? true : false} value={this.state.table_tdl6T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr6T" checked={(this.state.table_tdr6T === true) ? true : false} value={this.state.table_tdr6T} onChange={this.handleTableChange} /></td>
                                                        <td>6T</td><td><input type={"checkbox"} name="table_pvmsl6T" checked={(this.state.table_pvmsl6T === true) ? true : false} value={this.state.table_pvmsl6T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr6T" checked={(this.state.table_pvmsr6T === true) ? true : false} value={this.state.table_pvmsr6T} onChange={this.handleTableChange} /></td>
                                                        <td>6T</td><td><input type={"checkbox"} name="table_edl6T" checked={(this.state.table_edl6T === true) ? true : false} value={this.state.table_edl6T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr6T" checked={(this.state.table_edr6T === true) ? true : false} value={this.state.table_edr6T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>7T</td><td><input type={"checkbox"} name="table_sp7T" checked={(this.state.table_sp7T === true) ? true : false} value={this.state.table_sp7T} onChange={this.handleTableChange} /></td>
                                                        <td>7T</td><td><input type={"checkbox"} name="table_tdl7T" checked={(this.state.table_tdl7T === true) ? true : false} value={this.state.table_tdl7T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr7T" checked={(this.state.table_tdr7T === true) ? true : false} value={this.state.table_tdr7T} onChange={this.handleTableChange} /></td>
                                                        <td>7T</td><td><input type={"checkbox"} name="table_pvmsl7T" checked={(this.state.table_pvmsl7T === true) ? true : false} value={this.state.table_pvmsl7T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr7T" checked={(this.state.table_pvmsr7T === true) ? true : false} value={this.state.table_pvmsr7T} onChange={this.handleTableChange} /></td>
                                                        <td>7T</td><td><input type={"checkbox"} name="table_edl7T" checked={(this.state.table_edl7T === true) ? true : false} value={this.state.table_edl7T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr7T" checked={(this.state.table_edr7T === true) ? true : false} value={this.state.table_edr7T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>8T</td><td><input type={"checkbox"} name="table_sp8T" checked={(this.state.table_sp8T === true) ? true : false} value={this.state.table_sp8T} onChange={this.handleTableChange} /></td>
                                                        <td>8T</td><td><input type={"checkbox"} name="table_tdl8T" checked={(this.state.table_tdl8T === true) ? true : false} value={this.state.table_tdl8T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr8T" checked={(this.state.table_tdr8T === true) ? true : false} value={this.state.table_tdr8T} onChange={this.handleTableChange} /></td>
                                                        <td>8T</td><td><input type={"checkbox"} name="table_pvmsl8T" checked={(this.state.table_pvmsl8T === true) ? true : false} value={this.state.table_pvmsl8T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr8T" checked={(this.state.table_pvmsr8T === true) ? true : false} value={this.state.table_pvmsr8T} onChange={this.handleTableChange} /></td>
                                                        <td>8T</td><td><input type={"checkbox"} name="table_edl8T" checked={(this.state.table_edl8T === true) ? true : false} value={this.state.table_edl8T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr8T" checked={(this.state.table_edr8T === true) ? true : false} value={this.state.table_edr8T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>9T</td><td><input type={"checkbox"} name="table_sp9T" checked={(this.state.table_sp9T === true) ? true : false} value={this.state.table_sp9T} onChange={this.handleTableChange} /></td>
                                                        <td>9T</td><td><input type={"checkbox"} name="table_tdl9T" checked={(this.state.table_tdl9T === true) ? true : false} value={this.state.table_tdl9T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr9T" checked={(this.state.table_tdr9T === true) ? true : false} value={this.state.table_tdr9T} onChange={this.handleTableChange} /></td>
                                                        <td>9T</td><td><input type={"checkbox"} name="table_pvmsl9T" checked={(this.state.table_pvmsl9T === true) ? true : false} value={this.state.table_pvmsl9T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr9T" checked={(this.state.table_pvmsr9T === true) ? true : false} value={this.state.table_pvmsr9T} onChange={this.handleTableChange} /></td>
                                                        <td>9T</td><td><input type={"checkbox"} name="table_edl9T" checked={(this.state.table_edl9T === true) ? true : false} value={this.state.table_edl9T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr9T" checked={(this.state.table_edr9T === true) ? true : false} value={this.state.table_edr9T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>10T</td><td><input type={"checkbox"} name="table_sp10T" checked={(this.state.table_sp10T === true) ? true : false} value={this.state.table_sp10T} onChange={this.handleTableChange} /></td>
                                                        <td>10T</td><td><input type={"checkbox"} name="table_tdl10T" checked={(this.state.table_tdl10T === true) ? true : false} value={this.state.table_tdl10T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr10T" checked={(this.state.table_tdr10T === true) ? true : false} value={this.state.table_tdr10T} onChange={this.handleTableChange} /></td>
                                                        <td>10T</td><td><input type={"checkbox"} name="table_pvmsl10T" checked={(this.state.table_pvmsl10T === true) ? true : false} value={this.state.table_pvmsl10T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr10T" checked={(this.state.table_pvmsr10T === true) ? true : false} value={this.state.table_pvmsr10T} onChange={this.handleTableChange} /></td>
                                                        <td>10T</td><td><input type={"checkbox"} name="table_edl10T" checked={(this.state.table_edl10T === true) ? true : false} value={this.state.table_edl10T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr10T" checked={(this.state.table_edr10T === true) ? true : false} value={this.state.table_edr10T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>11T</td><td><input type={"checkbox"} name="table_sp11T" checked={(this.state.table_sp11T === true) ? true : false} value={this.state.table_sp11T} onChange={this.handleTableChange} /></td>
                                                        <td>11T</td><td><input type={"checkbox"} name="table_tdl11T" checked={(this.state.table_tdl11T === true) ? true : false} value={this.state.table_tdl11T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr11T" checked={(this.state.table_tdr11T === true) ? true : false} value={this.state.table_tdr11T} onChange={this.handleTableChange} /></td>
                                                        <td>11T</td><td><input type={"checkbox"} name="table_pvmsl11T" checked={(this.state.table_pvmsl11T === true) ? true : false} value={this.state.table_pvmsl11T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr11T" checked={(this.state.table_pvmsr11T === true) ? true : false} value={this.state.table_pvmsr11T} onChange={this.handleTableChange} /></td>
                                                        <td>11T</td><td><input type={"checkbox"} name="table_edl11T" checked={(this.state.table_edl11T === true) ? true : false} value={this.state.table_edl11T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr11T" checked={(this.state.table_edr11T === true) ? true : false} value={this.state.table_edr11T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>12T</td><td><input type={"checkbox"} name="table_sp12T" checked={(this.state.table_sp12T === true) ? true : false} value={this.state.table_sp12T} onChange={this.handleTableChange} /></td>
                                                        <td>12T</td><td><input type={"checkbox"} name="table_tdl12T" checked={(this.state.table_tdl12T === true) ? true : false} value={this.state.table_tdl12T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr12T" checked={(this.state.table_tdr12T === true) ? true : false} value={this.state.table_tdr12T} onChange={this.handleTableChange} /></td>
                                                        <td>12T</td><td><input type={"checkbox"} name="table_pvmsl12T" checked={(this.state.table_pvmsl12T === true) ? true : false} value={this.state.table_pvmsl12T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr12T" checked={(this.state.table_pvmsr12T === true) ? true : false} value={this.state.table_pvmsr12T} onChange={this.handleTableChange} /></td>
                                                        <td>12T</td><td><input type={"checkbox"} name="table_edl12T" checked={(this.state.table_edl12T === true) ? true : false} value={this.state.table_edl12T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr12T" checked={(this.state.table_edr12T === true) ? true : false} value={this.state.table_edr12T} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>1L</td><td><input type={"checkbox"} name="table_sp1L" checked={(this.state.table_sp1L === true) ? true : false} value={this.state.table_sp1L} onChange={this.handleTableChange} /></td>
                                                        <td>1L</td><td><input type={"checkbox"} name="table_tdl1L" checked={(this.state.table_tdl1L === true) ? true : false} value={this.state.table_tdl1L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr1L" checked={(this.state.table_tdr1L === true) ? true : false} value={this.state.table_tdr1L} onChange={this.handleTableChange} /></td>
                                                        <td>1L</td><td><input type={"checkbox"} name="table_pvmsl1L" checked={(this.state.table_pvmsl1L === true) ? true : false} value={this.state.table_pvmsl1L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr1L" checked={(this.state.table_pvmsr1L === true) ? true : false} value={this.state.table_pvmsr1L} onChange={this.handleTableChange} /></td>
                                                        <td>1L</td><td><input type={"checkbox"} name="table_edl1L" checked={(this.state.table_edl1L === true) ? true : false} value={this.state.table_edl1L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr1L" checked={(this.state.table_edr1L === true) ? true : false} value={this.state.table_edr1L} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>2L</td><td><input type={"checkbox"} name="table_sp2L" checked={(this.state.table_sp2L === true) ? true : false} value={this.state.table_sp2L} onChange={this.handleTableChange} /></td>
                                                        <td>2L</td><td><input type={"checkbox"} name="table_tdl2L" checked={(this.state.table_tdl2L === true) ? true : false} value={this.state.table_tdl2L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr2L" checked={(this.state.table_tdr2L === true) ? true : false} value={this.state.table_tdr2L} onChange={this.handleTableChange} /></td>
                                                        <td>2L</td><td><input type={"checkbox"} name="table_pvmsl2L" checked={(this.state.table_pvmsl2L === true) ? true : false} value={this.state.table_pvmsl2L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr2L" checked={(this.state.table_pvmsr2L === true) ? true : false} value={this.state.table_pvmsr2L} onChange={this.handleTableChange} /></td>
                                                        <td>2L</td><td><input type={"checkbox"} name="table_edl2L" checked={(this.state.table_edl2L === true) ? true : false} value={this.state.table_edl2L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr2L" checked={(this.state.table_edr2L === true) ? true : false} value={this.state.table_edr2L} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>3L</td><td><input type={"checkbox"} name="table_sp3L" checked={(this.state.table_sp3L === true) ? true : false} value={this.state.table_sp3L} onChange={this.handleTableChange} /></td>
                                                        <td>3L</td><td><input type={"checkbox"} name="table_tdl3L" checked={(this.state.table_tdl3L === true) ? true : false} value={this.state.table_tdl3L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr3L" checked={(this.state.table_tdr3L === true) ? true : false} value={this.state.table_tdr3L} onChange={this.handleTableChange} /></td>
                                                        <td>3L</td><td><input type={"checkbox"} name="table_pvmsl3L" checked={(this.state.table_pvmsl3L === true) ? true : false} value={this.state.table_pvmsl3L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr3L" checked={(this.state.table_pvmsr3L === true) ? true : false} value={this.state.table_pvmsr3L} onChange={this.handleTableChange} /></td>
                                                        <td>3L</td><td><input type={"checkbox"} name="table_edl3L" checked={(this.state.table_edl3L === true) ? true : false} value={this.state.table_edl3L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr3L" checked={(this.state.table_edr3L === true) ? true : false} value={this.state.table_edr3L} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>4L</td><td><input type={"checkbox"} name="table_sp4L" checked={(this.state.table_sp4L === true) ? true : false} value={this.state.table_sp4L} onChange={this.handleTableChange} /></td>
                                                        <td>4L</td><td><input type={"checkbox"} name="table_tdl4L" checked={(this.state.table_tdl4L === true) ? true : false} value={this.state.table_tdl4L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr4L" checked={(this.state.table_tdr4L === true) ? true : false} value={this.state.table_tdr4L} onChange={this.handleTableChange} /></td>
                                                        <td>4L</td><td><input type={"checkbox"} name="table_pvmsl4L" checked={(this.state.table_pvmsl4L === true) ? true : false} value={this.state.table_pvmsl4L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr4L" checked={(this.state.table_pvmsr4L === true) ? true : false} value={this.state.table_pvmsr4L} onChange={this.handleTableChange} /></td>
                                                        <td>4L</td><td><input type={"checkbox"} name="table_edl4L" checked={(this.state.table_edl4L === true) ? true : false} value={this.state.table_edl4L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr4L" checked={(this.state.table_edr4L === true) ? true : false} value={this.state.table_edr4L} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>5L</td><td><input type={"checkbox"} name="table_sp5L" checked={(this.state.table_sp5L === true) ? true : false} value={this.state.table_sp5L} onChange={this.handleTableChange} /></td>
                                                        <td>5L</td><td><input type={"checkbox"} name="table_tdl5L" checked={(this.state.table_tdl5L === true) ? true : false} value={this.state.table_tdl5L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr5L" checked={(this.state.table_tdr5L === true) ? true : false} value={this.state.table_tdr5L} onChange={this.handleTableChange} /></td>
                                                        <td>5L</td><td><input type={"checkbox"} name="table_pvmsl5L" checked={(this.state.table_pvmsl5L === true) ? true : false} value={this.state.table_pvmsl5L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr5L" checked={(this.state.table_pvmsr5L === true) ? true : false} value={this.state.table_pvmsr5L} onChange={this.handleTableChange} /></td>
                                                        <td>5L</td><td><input type={"checkbox"} name="table_edl5L" checked={(this.state.table_edl5L === true) ? true : false} value={this.state.table_edl5L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr5L" checked={(this.state.table_edr5L === true) ? true : false} value={this.state.table_edr5L} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>Sac</td><td><input type={"checkbox"} name="table_spSac" checked={(this.state.table_spSac === true) ? true : false} value={this.state.table_spSac} onChange={this.handleTableChange} /></td>
                                                        <td>Sac</td><td><input type={"checkbox"} name="table_tdlSac" checked={(this.state.table_tdlSac === true) ? true : false} value={this.state.table_tdlSac} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrSac" checked={(this.state.table_tdrSac === true) ? true : false} value={this.state.table_tdrSac} onChange={this.handleTableChange} /></td>
                                                        <td>Sac</td><td><input type={"checkbox"} name="table_pvmslSac" checked={(this.state.table_pvmslSac === true) ? true : false} value={this.state.table_pvmslSac} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrSac" checked={(this.state.table_pvmsrSac === true) ? true : false} value={this.state.table_pvmsrSac} onChange={this.handleTableChange} /></td>
                                                        <td>Sac</td><td><input type={"checkbox"} name="table_edlSac" checked={(this.state.table_edlSac === true) ? true : false} value={this.state.table_edlSac} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrSac" checked={(this.state.table_edrSac === true) ? true : false} value={this.state.table_edrSac} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>R II</td><td><input type={"checkbox"} name="table_spRII" checked={(this.state.table_spRII === true) ? true : false} value={this.state.table_spRII} onChange={this.handleTableChange} /></td>
                                                        <td>R II</td><td><input type={"checkbox"} name="table_tdlRII" checked={(this.state.table_tdlRII === true) ? true : false} value={this.state.table_tdlRII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrRII" checked={(this.state.table_tdrRII === true) ? true : false} value={this.state.table_tdrRII} onChange={this.handleTableChange} /></td>
                                                        <td>R II</td><td><input type={"checkbox"} name="table_pvmslRII" checked={(this.state.table_pvmslRII === true) ? true : false} value={this.state.table_pvmslRII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrRII" checked={(this.state.table_pvmsrRII === true) ? true : false} value={this.state.table_pvmsrRII} onChange={this.handleTableChange} /></td>
                                                        <td>R II</td><td><input type={"checkbox"} name="table_edlRII" checked={(this.state.table_edlRII === true) ? true : false} value={this.state.table_edlRII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrRII" checked={(this.state.table_edrRII === true) ? true : false} value={this.state.table_edrRII} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>LII</td><td><input type={"checkbox"} name="table_spLII" checked={(this.state.table_spLII === true) ? true : false} value={this.state.table_spLII} onChange={this.handleTableChange} /></td>
                                                        <td>LII</td><td><input type={"checkbox"} name="table_tdlLII" checked={(this.state.table_tdlLII === true) ? true : false} value={this.state.table_tdlLII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrLII" checked={(this.state.table_tdrLII === true) ? true : false} value={this.state.table_tdrLII} onChange={this.handleTableChange} /></td>
                                                        <td>LII</td><td><input type={"checkbox"} name="table_pvmslLII" checked={(this.state.table_pvmslLII === true) ? true : false} value={this.state.table_pvmslLII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrLII" checked={(this.state.table_pvmsrLII === true) ? true : false} value={this.state.table_pvmsrLII} onChange={this.handleTableChange} /></td>
                                                        <td>LII</td><td><input type={"checkbox"} name="table_edlLII" checked={(this.state.table_edlLII === true) ? true : false} value={this.state.table_edlLII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrLII" checked={(this.state.table_edrLII === true) ? true : false} value={this.state.table_edrLII} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                    <tr style={{textAlign:"center"}}>
                                                        <td style={{width:"35px"}}>Coc</td><td><input type={"checkbox"} name="table_spCoc" checked={(this.state.table_spCoc === true) ? true : false} value={this.state.table_spCoc} onChange={this.handleTableChange} /></td>
                                                        <td>Coc</td><td><input type={"checkbox"} name="table_tdlCoc" checked={(this.state.table_tdlCoc === true) ? true : false} value={this.state.table_tdlCoc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrCoc" checked={(this.state.table_tdrCoc === true) ? true : false} value={this.state.table_tdrCoc} onChange={this.handleTableChange} /></td>
                                                        <td>Coc</td><td><input type={"checkbox"} name="table_pvmslCoc" checked={(this.state.table_pvmslCoc === true) ? true : false} value={this.state.table_pvmslCoc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrCoc" checked={(this.state.table_pvmsrCoc === true) ? true : false} value={this.state.table_pvmsrCoc} onChange={this.handleTableChange} /></td>
                                                        <td>Coc</td><td><input type={"checkbox"} name="table_edlCoc" checked={(this.state.table_edlCoc === true) ? true : false} value={this.state.table_edlCoc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrCoc" checked={(this.state.table_edrCoc === true) ? true : false} value={this.state.table_edrCoc} onChange={this.handleTableChange} /></td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>


                                    </div>
                                    <div className="p-0" style={{ width:"1%",borderLeft:"1px solid #000" }}></div>
                                    <div className="p-0" style={{ width:"49.5%",fontSize:"10px",fontWeight:"bold" }} >
                                        <div className="d-flex justify-content-center mt-2"><img src={ganglion} style={{width:"150px"}} /></div>
                                        <p className="m-0" >1A. GANGLION PALPATION:</p>
                                        <div className="row m-0 mt-1">
                                            <div style={{ padding:"0",width:"10%"}}>Pos.</div>
                                            <div style={{ padding:"0",width:"20%",display:"flex"}}><p className="m-0 d-flex"><input type={"text"} name="ganglonal" value={this.state.ganglonal} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75" /> / <input type={"text"} name="ganglonar" value={this.state.ganglonar} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75"  /></p></div>
                                            <div style={{ padding:"0",width:"70%",letterSpacing:"-0.3px",lineHeight:"1"}}>A. Superior Occipital Nerve :<span className="fw-normal"> test: one inch lateral to EOP. rationale: ropey feeling of nerve = inflammation </span></div>
                                        </div>
                                        <div className="row m-0 mt-1">
                                            <div style={{ padding:"0",width:"10%"}}>Pos.</div>
                                            <div style={{ padding:"0",width:"20%",display:"flex"}}><p className="m-0 d-flex"><input type={"text"} name="ganglonbl" value={this.state.ganglonbl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75" /> / <input type={"text"} name="ganglonbr" value={this.state.ganglonbr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75"  /></p></div>
                                            <div style={{ padding:"0",width:"70%",letterSpacing:"-0.3px",lineHeight:"1"}}>B. Superior Cervical Ganglion :<span className="fw-normal"> test: opposite transverse process of atlas. rationale: tenderness with pressure = irritation. </span></div>
                                        </div>
                                        <div className="row m-0 mt-1">
                                            <div style={{ padding:"0",width:"10%"}}>Pos.</div>
                                            <div style={{ padding:"0",width:"20%",display:"flex"}}><p className="m-0 d-flex"><input type={"text"} name="gangloncl" value={this.state.gangloncl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75" /> / <input type={"text"} name="gangloncr" value={this.state.gangloncr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75"  /></p></div>
                                            <div style={{ padding:"0",width:"70%",letterSpacing:"-0.3px",lineHeight:"1"}}>C. Middle Cervical Ganglion :<span className="fw-normal"> test: anterolateral to C5 vertebral body. rationale: tenderness upon slight pressure = irritation. </span></div>
                                        </div>
                                        <div className="row m-0 mt-1">
                                            <div style={{ padding:"0",width:"10%"}}>Pos.</div>
                                            <div style={{ padding:"0",width:"20%",display:"flex"}}><p className="m-0 d-flex"><input type={"text"} name="ganglondl" value={this.state.ganglondl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75" /> / <input type={"text"} name="ganglondr" value={this.state.ganglondr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75"  /></p></div>
                                            <div style={{ padding:"0",width:"70%",letterSpacing:"-0.3px",lineHeight:"1"}}>D. Inferior Cervical Ganglion :<span className="fw-normal"> test: anterolateral to C7 vertebral body. rationale: tenderness with pressure = irritation. </span></div>
                                        </div>
                                        <div className="row m-0 mt-1">
                                            <div style={{ padding:"0",width:"10%"}}>Pos.</div>
                                            <div style={{ padding:"0",width:"20%",display:"flex"}}><p className="m-0 d-flex"><input type={"text"} name="ganglonel" value={this.state.ganglonel} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75" /> / <input type={"text"} name="gangloner" value={this.state.gangloner} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinputred p-0 h-50 w-75"  /></p></div>
                                            <div style={{ padding:"0",width:"70%",letterSpacing:"-0.3px",lineHeight:"1"}}>E. Brachial Plexus :<span className="fw-normal"> test: immediately posterior to the clavicles. rationale: tenderness with pressure = irritation. to nerves.</span></div>
                                        </div>
                                        <hr style={{opacity: "1",margin:"0px",color:"#000",marginLeft:"-8px",marginTop:"5px"}} />
                                        <div style={{color:"#000"}}>
                                            <p className="m-0 mt-1">2. CRANIAL NERVE EXAM</p>

                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>I</span> OLFACTORY</span><span style={{float: "right",background: "white"}}>Smell</span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>II</span> OPTIC</span><span style={{float: "right",background: "white"}}>Visual Field</span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>III</span> OCULOMOTOR</span><span style={{float: "right",background: "white"}}>Eye Movement </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>IV</span> TROCHLEAR</span><span style={{float: "right",background: "white"}}>Eye Movement  </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>V</span> TRIGEMINAL</span><span style={{float: "right",background: "white"}}>Wink  </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>VI</span> ABDUCENS</span><span style={{float: "right",background: "white"}}>Eye Movement  </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>VII</span> FACIAL</span><span style={{float: "right",background: "white"}}>Smile  </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>VIII</span> ACOUSTIC</span><span style={{float: "right",background: "white"}}>Tuning Fork  </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>IX</span> GLOSSOPHARYNGEAL</span><span style={{float: "right",background: "white"}}>Gag Reflex/Uvula Raising  </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>X</span> VAGUS</span><span style={{float: "right",background: "white"}}>Swallow  </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>XI</span> SPINALACCESSORY</span><span style={{float: "right",background: "white"}}>Shrug Shoulders  </span>
                                            </div>
                                            <div style={{fontWeight:"normal",background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAABCAIAAACDoBMqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABJJREFUGFdj+I8EGBiQuP//AwBO2xfpJMYFgQAAAABJRU5ErkJggg==) repeat-x left bottom"}}>
                                                <span style={{background: "white",padding:"2px"}}> <span style={{width:"35px",display:"inline-block",fontWeight:"bold"}}>XII</span> HYPOGLOSSAL</span><span style={{float: "right",background: "white"}}>Tongue in Cheek  </span>
                                            </div>

                                            {/* <div className="d-flex"><span style={{width:"10%"}}>I</span> <span style={{fontWeight:"normal"}}>OLFACTORY </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Smell</span></div>
                                            
                                            <div className="d-flex"><span style={{width:"10%"}}>II</span> <span style={{fontWeight:"normal"}}>OPTIC </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Visual Field </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>III</span> <span style={{fontWeight:"normal"}}>OCULOMOTOR </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Eye Movement </span></div>
                                            
                                            <div className="d-flex"><span style={{width:"10%"}}>IV</span> <span style={{fontWeight:"normal"}}>TROCHLEAR </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Eye Movement </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>V</span> <span style={{fontWeight:"normal"}}>TRIGEMINAL </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Wink </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>VI</span> <span style={{fontWeight:"normal"}}>ABDUCENS </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Eye Movement </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>VII</span> <span style={{fontWeight:"normal"}}>FACIAL </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Smile </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>VIII</span> <span style={{fontWeight:"normal"}}>ACOUSTIC </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Tuning Fork </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>IX</span> <span style={{fontWeight:"normal"}}>GLOSSOPHARYNGEAL </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Gag Reflex/Uvula Raising </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>X</span> <span style={{fontWeight:"normal"}}>VAGUS </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Swallow </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>XI</span> <span style={{fontWeight:"normal"}}>SPINALACCESSORY </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Shrug Shoulders </span></div>
                                            <div className="d-flex"><span style={{width:"10%"}}>XII</span> <span style={{fontWeight:"normal"}}>HYPOGLOSSAL </span><span style={{borderBottom:"1px dotted #000",width:"75%"}}></span><span style={{fontWeight:"normal"}}>Tongue in Cheek </span></div> */}
                                        </div>

                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"22%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                        <p className="m-0" style={{}}><b>3. LIBMAN'S TEST:</b></p>
                                        <div className="d-flex justify-content-center"><img src={libmantest} style={{width:"120px"}} /></div>
                                        <p className="m-0 d-flex">POS. <input type={"text"} name="libmantestl" value={this.state.libmantestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} /* className="p-0 w-25 orthoinputred" */ className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="libmantestr" value={this.state.libmantestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                        <p className="m-0">test: bilateral pressure on mastoids. <span className="fst-italic">rationale: pain = hypersensitivity</span></p>
                                    </div>
                                    <div className="pt-1" style={{width:"22%",padding:"0 5px",borderRight:"2px solid #000",color:"#000",fontSize:"12px"}}>
                                        <p className="m-0" style={{fontSize:"12px"}}><b>4. JAW SUBLUXATION TEST:</b></p>
                                        <div className="d-flex justify-content-center"><img src={jawsubluxcation} style={{width:"155px"}} /></div>
                                        <p className="m-0 d-flex">POS. <input type={"text"} name="jawsubluxtestl" value={this.state.jawsubluxtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="jawsubluxtestr" value={this.state.jawsubluxtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                        <p className="m-0">test: open and close mouth. <span className="fst-italic">rationale: chin deviates away from side of subluxation </span></p>
                                    </div>
                                    <div className="pt-1" style={{width:"28%",padding:"0 5px",fontSize:"11px"}}>
                                        <p className="m-0" style={{fontSize:"12px"}}><b>5. CERVICAL RANGE OF MOTION: </b></p>
                                        <div className="d-flex justify-content-center"><img src={cervicalmotionab} style={{width:"200px"}} /></div>
                                        <div className="d-flex">
                                            <div style={{width:"50%",lineHeight:"1"}}>A. FLEXION <br/>MEAS.<input type={"text"} name="flexmeasa" value={this.state.flexmeasa} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 60°_____{/*<input type={"text"} name="flexnorma" value={this.state.flexnorma} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="p-0 w-25 orthoinput" />*/} <br/>LIMIT<input type={"text"} name="flexlimita" value={this.state.flexlimita} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />  <br/>PAIN<input type={"text"} name="flexpaina" value={this.state.flexpaina} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> </div>
                                            <div style={{width:"50%",lineHeight:"1"}}>B. EXTENSION <br/>MEAS.<input type={"text"} name="extenmeasa" value={this.state.extenmeasa} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 30°____{/*<input type={"text"} name="extennorma" value={this.state.extennorma} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="p-0 w-25 orthoinput" />*/} <br/>LIMIT<input type={"text"} name="extenlimita" value={this.state.extenlimita} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />  <br/>PAIN<input type={"text"} name="extenpaina" value={this.state.extenpaina} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></div>
                                        </div>
                                        <p className="m-0" style={{lineHeight:"1"}}>rationale: pain opposite side of motion = muscle stretch. Pain with motion = compression. </p>
                                    </div>
                                    <div className="pt-1" style={{width:"28%",padding:"0 1px",fontSize:"11px"}}>
                                        <div className="d-flex justify-content-center"><img src={cervicalmotioncd} style={{width:"197px"}} /></div>
                                        <div className="d-flex">
                                            <div style={{width:"50%",lineHeight:"1"}}>C. LATERAL FLX. <br/>MEAS.<input type={"text"} name="flexmeasb" value={this.state.flexmeasb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 40°_____{/*<input type={"text"} name="flexnormb" value={this.state.flexnormb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="p-0 w-25 orthoinput" />*/} <br/>LIMIT<input type={"text"} name="flexlimitb" value={this.state.flexlimitb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />  <br/>PAIN<input type={"text"} name="flexpainb" value={this.state.flexpainb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> </div>
                                            <div style={{width:"50%",lineHeight:"1"}}>D. ROTATION <br/>MEAS.<input type={"text"} name="extenmeasb" value={this.state.extenmeasb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 60°_____{/*<input type={"text"} name="extennormb" value={this.state.extennormb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="p-0 w-25 orthoinput" />*/} <br/>LIMIT<input type={"text"} name="extenlimitb" value={this.state.extenlimitb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />  <br/>PAIN<input type={"text"} name="extenpainb" value={this.state.extenpainb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></div>
                                        </div>
                                        <p className="m-0 mb-1" style={{lineHeight:"1"}}><b>6. BARRE-LEIOU SIGN:</b> AKA: BUCKLING VERTEBRAL ARTERY SYNDROME. <br/>test: rotate patient's head causing compression of vertebral artery. rationale: positive sign = syncope.</p>
                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"55%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"12px"}}>
                                        <p className="m-0" style={{}}><b>7. MUSCLE TESTING: </b></p>
                                        <div className="d-flex justify-content-center"><img src={muscletesting} style={{width:"400px"}} /></div>
                                        <div className="d-flex" style={{lineHeight:"1"}}>
                                            <p className="text-center fw-bold m-0">NECK <br/>FLEXORS <br/> <span className="fw-normal">POS. <input type={"text"} name="musneckflexl" value={this.state.musneckflexl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="musneckflexr" value={this.state.musneckflexr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinput p-0 w-25" /></span></p>
                                            <p className="text-center fw-bold m-0">NECK <br/>EXTENSORS <br/> <span className="fw-normal">POS. <input type={"text"} name="musneckextel" value={this.state.musneckextel} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="musneckexter" value={this.state.musneckexter} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinput p-0 w-25" /></span></p>
                                            <p className="text-center fw-bold m-0">LATERAL <br/>NECK FLEXORS <br/> <span className="fw-normal">POS. <input type={"text"} name="musnecklatflexl" value={this.state.musnecklatflexl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="musnecklatflexr" value={this.state.musnecklatflexr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="orthoinput p-0 w-25" /></span></p>
                                        </div>
                                    </div>
                                    <div className="pt-1" style={{width:"45%",padding:"0 5px",fontSize:"12px"}}>
                                        <p className="m-0"><b>8. CERVICAL COMPRESSION TESTS: </b></p>
                                        <div className="d-flex justify-content-center"><img src={cervicalcomptest} style={{width:"320px"}} /></div>
                                        <div className="d-flex mb-1" style={{lineHeight:"1"}}>
                                            <p className="m-0"><b>CERVICAL COMPRESSION</b> <br/>PAIN. <input type={"text"} name="cervicompl" value={this.state.cervicompl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="cervicompr" value={this.state.cervicompr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                                <br/><span className="fst-italic">rationale: screening test to localise pain.  </span>
                                            </p>
                                            <p className="m-0"><b>FORAMINA COMPRESSION</b> <br/>PAIN. <input type={"text"} name="formacompl" value={this.state.formacompl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="formacompr" value={this.state.formacompr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                                <br/><span className="fst-italic">rationale: closes IVF. localised = facet radiating = nerve root. </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1 pb-1" style={{width:"55%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0"><b>CERVICAL COMPRESSION TESTS (Cont.): </b></p>
                                        <div className="d-flex justify-content-center"><img src={cervicalcomptestcont} style={{width:"400px"}} /></div>
                                        <div className="d-flex" style={{lineHeight:"1"}}>
                                            <p className="m-0"><b>HYPERFLEXION</b> <br/>PAIN. <input type={"text"} name="hyperflexionl" value={this.state.hyperflexionl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="hyperflexionr" value={this.state.hyperflexionr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                                <span className="fst-italic">rationale: forceful stretch opposite motion, compression side of motion. </span>
                                            </p>
                                            <p className="m-0"><b>HYPEREXTENSION</b> <br/>PAIN. <input type={"text"} name="hyperextensionl" value={this.state.hyperextensionl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="hyperextensionr" value={this.state.hyperextensionr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                                <span className="fst-italic">rationale: see hyeprflexion compression. </span>
                                            </p>
                                            <p className="m-0"><b>SHOULDER DEPRESSION TEST:</b> <br/>PAIN. <input type={"text"} name="shoulderdeprtestl" value={this.state.shoulderdeprtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="shoulderdeprtestr" value={this.state.shoulderdeprtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                                <span className="fst-italic">rationale: compresses nerves / vessels into arms. </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="pt-1" style={{width:"22.5%",borderRight:"3px solid #000",padding:"0 5px",fontSize:"12px"}}>
                                        <p className="m-0"><b>9. SOTO - HALL TEST: </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={solohalltest} style={{width:"145px"}} /></div>
                                        <p className="m-0 mt-2" style={{lineHeight:"1"}}>PAIN. <input type={"text"} name="solohalltestl" value={this.state.solohalltestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="solohalltestr" value={this.state.solohalltestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            test: place hand on chest, flex onto chest.<br/><span className="fst-italic">rationale: pain = possible vertebral fracture.</span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"22.5%",padding:"0 5px",fontSize:"12px"}}>
                                        <p className="m-0"><b>10. CERVICAL DISTRACTION: </b></p>
                                        <div className="d-flex justify-content-center"><img src={cervicaldistraction} style={{width:"145px"}} /></div>
                                        <p className="m-0 mt-2" style={{lineHeight:"1"}}>PAIN. <input type={"text"} name="cervicaldistl" value={this.state.cervicaldistl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="cervicaldistr" value={this.state.cervicaldistr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                        test: lift head firmly.<br/><span className="fst-italic">rationale: pain decreases = candidate for cervical traction. </span>
                                        </p>
                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0"><b>11. MAXIMUM FORAMINA ENCROACHMENT TEST: </b></p>
                                        <div className="d-flex justify-content-center"><img src={maxforaminatest} style={{width:"125px"}} /></div>
                                        <p className="m-0 mt-4" style={{lineHeight:"14px"}}>PAIN. <input type={"text"} name="maxiformaencrol" value={this.state.maxiformaencrol} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="maxiformaencror" value={this.state.maxiformaencror} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: the chin-shoulder test with extension of the neck.<br/><span className="fst-italic">rationale: pain of side of motion = never root/facet involvement. pain opposite = muscular strain. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b>12. O'DONAHUE MANEUVER: </b></p>
                                        <div className="d-flex justify-content-center"><img src={odonahuemaneuver} style={{width:"125px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>PAIN. <input type={"text"} name="odonamaneul" value={this.state.odonamaneul} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="odonamaneur" value={this.state.odonamaneur} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/><span className="fst-italic">rationale: Isometric = Strain Passive = Sprain. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0"><b> 13. VALSALVA'S TEST: </b></p>
                                        <div className="d-flex justify-content-center"><img src={valsalvatest} style={{width:"100px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>PAIN. <input type={"text"} name="valsalvastestl" value={this.state.valsalvastestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="valsalvastestr" value={this.state.valsalvastestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: cross arms, head down deep breath, hold, bear down.<br/><span className="fst-italic"> rationale: pain at site of lesion. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"22.5%",borderRight:"3px solid #000",padding:"0 5px",fontSize:"11px"}}>
                                        <p className="m-0"><b> 14. ADSON'S TEST:</b></p>
                                        <div className="d-flex justify-content-center"><img src={adsontest} style={{width:"110px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="adsonstestl" value={this.state.adsonstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="adsonstestr" value={this.state.adsonstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: take pulse, turn head extend head, deep breath, hold, <br/><span className="fst-italic">rationale: pulse ceases or diminishes = compression of the artery by cervical rib or Sclenus Antics Synd. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"22.5%",padding:"0 5px",fontSize:"11px"}}>
                                        <p className="m-0"><b> 15. ALLEN'S TEST: </b></p>
                                        <div className="d-flex justify-content-center"><img src={allenstest} style={{width:"135px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="allenstestl" value={this.state.allenstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="allenstestr" value={this.state.allenstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: raise arm, make fist, compress radial and ulnar arteries, lower arm, release one artery, repeat for other artery.<br/><span className="fst-italic"> rationale: no flushing = vascular occlusion.</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 16. WRIGHT'S TEST: </b></p>
                                        <div className="d-flex justify-content-center"><img src={wrighttest} style={{width:"130px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="wrightstestl" value={this.state.wrightstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="wrightstestr" value={this.state.wrightstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: take pulse, hyperabduct arm, retake pulse.<br/><span className="fst-italic"> rationale: diminished pulse = compression of the axillary artery by the pectoralis minor.</span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"59.2%",color:"#f13239",borderRight:"3px solid #000",padding:"0 5px",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 17. RELATED AREA-SHOULDER: RANGE OF MOTION</b></p>
                                        <div className="d-flex justify-content-around"><img src={flexionextension} style={{width:"135px"}} /><img src={rotationabduction} style={{width:"135px"}} /></div>
                                        <div className="d-flex">
                                            <div style={{width:"50%",display:"flex",}}>
                                                <p style={{width:"50%",margin:"0"}}>FLEXION <br/>MEAS.<input type={"text"} name="flexmeas17a" value={this.state.flexmeas17a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 90°_____{/*<input type={"text"} name="flexnorm17a" value={this.state.flexnorm17a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> */}<br/>LIMIT<input type={"text"} name="flexlimti17a" value={this.state.flexlimti17a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                <p style={{width:"50%",margin:"0"}}>EXTENSION <br/>MEAS.<input type={"text"} name="extenmeas17a" value={this.state.extenmeas17a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 90°_____{/*<input type={"text"} name="extenorm17a" value={this.state.extenorm17a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/} <br/>LIMIT<input type={"text"} name="extenlimit17a" value={this.state.extenlimit17a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            </div>
                                            <div style={{width:"50%",display:"flex",}}>
                                                <p style={{width:"50%",margin:"0"}}>ROTATION  <br/>INT. MEAS.<input type={"text"} name="rotationmeas17a" value={this.state.rotationmeas17a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 55°_____{/*<input type={"text"} name="rotationnorm5517a" value={this.state.rotationnorm5517a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> */}  <br/>LIMIT<input type={"text"} name="rotationlimit217a" value={this.state.rotationlimit217a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /><br/>EXT. MEAS.<input type={"text"} name="extrotationmeas217a" value={this.state.extrotationmeas217a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /><br/>NORM.40°-45°_____{/*<input type={"text"} name="extrotationnorm217a" value={this.state.extrotationnorm217a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>LIMIT<input type={"text"} name="extrotationlimit2217a" value={this.state.extrotationlimit2217a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/}</p>
                                                <p style={{width:"50%",margin:"0"}}>AD/ABDUCTION  <br/>AB. MEAS.<input type={"text"} name="abumeas17a" value={this.state.abumeas17a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /><br/>NORM. 180°_____{/*<input type={"text"} name="abunorm5517a" value={this.state.abunorm5517a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> */} <br/>LIMIT<input type={"text"} name="abulimit217a" value={this.state.abulimit217a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /><br/>AD. MEAS.<input type={"text"} name="abuadmeas217a" value={this.state.abuadmeas217a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /><br/>NORM. 45°_____{/*<input type={"text"} name="abuadnorm217a" value={this.state.abuadnorm217a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /><br/>LIMIT<input type={"text"} name="abuadlimit2217a" value={this.state.abuadlimit2217a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-1" style={{width:"22.47%",color:"#f13239",padding:"0 5px",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b>18. SHOULDER ABDUCTION TENDONITIS-BURSITIS TEST:</b></p>
                                        <div className="d-flex justify-content-center"><img src={shoulderatbtest} style={{width:"130px"}} /></div>
                                        <p className="m-0">POS. <input type={"text"} name="shoulderabdutendburtestl" value={this.state.shoulderabdutendburtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="shoulderabdutendburtestr" value={this.state.shoulderabdutendburtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: test on abduction. <br/><span className="fst-italic"> rationale: pain from 0-75° = tendonitis, pain from 75-180° = bursitis. </span>
                                        </p>
                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 19. CODMAN ARM DROP TEST:  </b></p>
                                        <div className="d-flex justify-content-center"><img src={codmanarm} style={{width:"130px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="codmanarmdroptestl" value={this.state.codmanarmdroptestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="codmanarmdroptestr" value={this.state.codmanarmdroptestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: extend arm, abduct 90°, strike arm downwards. <br/><span className="fst-italic">rationale: inability to hold in place = rotator cuff tear. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 20. SUPRA- SPINATOUS IS TENDONIT TEST:  </b></p>
                                        <div className="d-flex justify-content-center"><img src={supraapinatoustest} style={{width:"135px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="supraspintendtestl" value={this.state.supraspintendtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="supraspintendtestr" value={this.state.supraspintendtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: abduct arm against resistance. <br/><span className="fst-italic"> rationale: pain over head of supraspinatous = tendonitis  </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 21. APLEY SCRATCH TEST: </b></p>
                                        <div className="d-flex justify-content-center"><img src={apleyscartchtest} style={{width:"120px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="apleyscratchtestl" value={this.state.apleyscratchtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="apleyscratchtestr" value={this.state.apleyscratchtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: place hand behind head for external rotation, reach for wallet for internal rot. <br/><span className="fst-italic"> rationale: pain = tendonitis. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 22. CORACOID PUSH BUTTON SIGN: </b></p>
                                        <div className="d-flex justify-content-center"><img src={coracaoidbuttonsign} style={{width:"130px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="coracpushbutsignl" value={this.state.coracpushbutsignl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="coracpushbutsignr" value={this.state.coracpushbutsignr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: apply pressure to subacromial bursae. <br/><span className="fst-italic"> rationale: pain = bursitis. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"22.47%",color:"#f13239",padding:"0 5px",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 23. DAWBURN'S TEST: </b></p>
                                        <div className="d-flex justify-content-center"><img src={dawburntest} style={{width:"130px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="dawburntestl" value={this.state.dawburntestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="dawburntestr" value={this.state.dawburntestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: apply firm pressure on coracoid push button, raise arm 90°, deltoid covers push button.<br/><span className="fst-italic"> rationale: decrease pain = bursitis. </span>
                                        </p>
                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 24. APPREHENSION TEST:  </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={apprehensiontest} style={{width:"130px"}} /></div>
                                        <p className="m-0 mt-5" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="apprehensiontestl" value={this.state.apprehensiontestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="apprehensiontestr" value={this.state.apprehensiontestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: abduct and externally rotate arm.<br/><span className="fst-italic">rationale: pain = chronic shoulder dislocation.</span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b>  25• YERGASON'S TEST:  </b></p>
                                        <div className="d-flex justify-content-center mt-4"><img src={yergasontest} style={{width:"125px"}} /></div>
                                        <p className="m-0 mt-4" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="yergasonstestl" value={this.state.yergasonstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="yergasonstestr" value={this.state.yergasonstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: flex elbow, resist external rotation with downward pressure on elbow. <br/><span className="fst-italic">rationale: pain = instability of biceps tendon.  </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 26. CARDIAC MUSCULOSKELET DIFFERENTIATION:  </b></p>
                                        <div className="d-flex justify-content-center"><img src={cardicmusucldiffer} style={{width:"105px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="cardicmusculdiffl" value={this.state.cardicmusculdiffl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="cardicmusculdiffr" value={this.state.cardicmusculdiffr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: examine if pain in thorax exists.  <br/><span className="fst-italic">rationale: if trigger point increases pain, or move. decrease neuromusculoskeletal involvement.  </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"42.2%",padding:"0 5px",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0"><b> 27. RELATED AREAS-ELBOW: <br/>RANGE OF MOTION:   </b></p>
                                        <div className="d-flex justify-content-center"><img src={relatedareaelbowrom} style={{width:"300px"}} /></div>
                                        <div className="d-flex">
                                            <p className="m-0" style={{width:"50%"}}>FLEX MEAS.<input type={"text"} name="flexmeas27a" value={this.state.flexmeas27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 135°_____{/*<input type={"text"} name="flexnorm27a" value={this.state.flexnorm27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/} <br/>LIMIT<input type={"text"} name="flexlimti27a" value={this.state.flexlimti27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>SUP. MEAS.<input type={"text"} name="flexsupmeas27a" value={this.state.flexsupmeas27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/> NORM. 90°_____{/*<input type={"text"} name="flexsupnorm27a" value={this.state.flexsupnorm27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>LIMIT<input type={"text"} name="flexsuplimit27a" value={this.state.flexsuplimit27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/}</p>
                                            <p className="m-0" style={{width:"50%"}}>EXT. MEAS.<input type={"text"} name="extmeas27a" value={this.state.extmeas27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 0-5°_____{/*<input type={"text"} name="extnorm27a" value={this.state.extnorm27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/} <br/>LIMIT<input type={"text"} name="extlimti27a" value={this.state.extlimti27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>PRON MEAS.<input type={"text"} name="extpronmeas27a" value={this.state.extpronmeas27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 90°_____{/*<input type={"text"} name="extpronnorm27a" value={this.state.extpronnorm27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>LIMIT<input type={"text"} name="extpronlimit27a" value={this.state.extpronlimit27a} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/}</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 28. COZEN'S TENNIS ELBOW TEST:  </b></p>
                                        <div className="d-flex justify-content-center mt-5"><img src={cozentenniselbowtest} style={{width:"120px"}} /></div>
                                        <p className="m-0 mt-5" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="cozenteniselbowtestl" value={this.state.cozenteniselbowtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="cozenteniselbowtestr" value={this.state.cozenteniselbowtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: stabilize forearm make fist and extend wrist, force fist into flexion against resistance.<br/><span className="fst-italic"> rationale: pain at lateral colateral ligament. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px",color:"#000"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b>  29. LIGAMENTOUS STABILITY TEST:   </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={ligamentousstabilitytest} style={{width:"140px"}} /></div>
                                        <p className="m-0 mt-3" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="ligamentousstabtestl" value={this.state.ligamentousstabtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="ligamentousstabtestr" value={this.state.ligamentousstabtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: stabilize elbow, hold wrist, force forearm med/lat.<br/><span className="fst-italic">rationale: positive for gapping on medial or lateral side. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b>30. CIRCUMFERENCE: </b></p>
                                        <div className="d-flex justify-content-center mt-4"><img src={circumference} style={{width:"90px"}} /></div>
                                        <p className="m-0 mt-3" style={{lineHeight:"14px"}}>UPPER <input type={"text"} name="circumferenceul" value={this.state.circumferenceul} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="circumferenceur" value={this.state.circumferenceur} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/><br/>LOWER <input type={"text"} name="circumferencell" value={this.state.circumferencell} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="circumferencelr" value={this.state.circumferencelr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/><span className="fst-italic">rationale: nerve, muscle or vascular damage producing muscle atrophy. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 31. TRICEPS REFLEX: </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={tricepsreflex} style={{width:"135px"}} /></div>
                                        <p className="m-0 mt-4" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="tricepsreflexl" value={this.state.tricepsreflexl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="tricepsreflexr" value={this.state.tricepsreflexr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: tap triceps tendon.<br/><span className="fst-italic">rationale: increased or decreased response = C7 involvement. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"22.47%",padding:"0 5px",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 32. BICEPS REFLEX:  </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={bicepsreflex} style={{width:"125px"}} /></div>
                                        <p className="m-0 mt-4" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="bicepsreflexl" value={this.state.bicepsreflexl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="bicepsreflexr" value={this.state.bicepsreflexr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: tap crease of elbow.<br/><span className="fst-italic"> rationale: increased or decreased response = C5 involvement. </span>
                                        </p>
                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b>33. BRACHIORADIALIS REFLEX: </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={brachioradialisreflex} style={{width:"125px"}} /></div>
                                        <p className="m-0 mt-2" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="brachiordalisreflexl" value={this.state.brachiordalisreflexl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="brachiordalisreflexr" value={this.state.brachiordalisreflexr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: supinate forearm, 90°, percuss radial side proximal to wrist. <br/><span className="fst-italic"> rationale: increased or decreased response = C6 involvement. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 34. DERMATOME DISTRIBUTION: </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={dermatomedistribution} style={{width:"90px"}} /></div>
                                        <p className="m-0 mt-3" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="dermatomedistributionl" value={this.state.dermatomedistributionl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="dermatomedistributionr" value={this.state.dermatomedistributionr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/><span className="fst-italic">rationale: nerve root compression. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 35. DYNAMOMETER GRIP TEST: </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={dyanometergriptest} style={{width:"125px"}} /></div>
                                        <p className="m-0 d-flex justify-content-around fw-bold mt-1">L &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; R</p>
                                        <p className="m-0" style={{lineHeight:"14px"}}>
                                            POS. <input type={"text"} name="dynamometergriptestl1" value={this.state.dynamometergriptestl1} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="dynamometergriptestr1" value={this.state.dynamometergriptestr1} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                        <br/>POS. <input type={"text"} name="dynamometergriptestl2" value={this.state.dynamometergriptestl2} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="dynamometergriptestr2" value={this.state.dynamometergriptestr2} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                        <br/>POS. <input type={"text"} name="dynamometergriptestl3" value={this.state.dynamometergriptestl3} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="dynamometergriptestr3" value={this.state.dynamometergriptestr3} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: average three tries each hand. <br/><span className="fst-italic">rationale: primary hand is 10% stronger.</span>
                                        </p>
                                        <p className="m-0 text-center fw-bold">**MALINGERING TEST** </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 36. MEDIAN NERVE TEST:  </b></p>
                                        <div className="d-flex justify-content-center mt-5"><img src={mediannervetest} style={{width:"125px"}} /></div>
                                        <p className="m-0 mt-5" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="mediannervetestl" value={this.state.mediannervetestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="mediannervetestr" value={this.state.mediannervetestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: opposition of thumb and ring finger. <br/><span className="fst-italic"> rationale: weakness = median nerve involvement. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"22.47%",padding:"0 5px",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 37. TINEL SIGN: </b></p>
                                        <div className="d-flex justify-content-center mt-5"><img src={tinelsign} style={{width:"125px"}} /></div>
                                        <p className="m-0" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="tinelsignl" value={this.state.tinelsignl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="tinelsignr" value={this.state.tinelsignr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: tap palmar surface of wrist.<br/><span className="fst-italic">rationale: pain into all but little finger = Carpal, Tunnel Syndrome. </span>
                                        </p>
                                    </div>
                                </div>

                                <div className="row m-0" style={{borderBottom:"3px solid #000"}}>
                                    <div className="pt-1" style={{width:"18.33%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b>38. PHALEN'S TEST:</b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={phalenstest} style={{width:"110px"}} /></div>
                                        <p className="m-0 mt-2" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="phalenstestl" value={this.state.phalenstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="phalenstestr" value={this.state.phalenstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: hyperflex wrists hold for 60 seconds<br/><span className="fst-italic">  rationale: paresthesia in fingers = Carpal Tunnel Syndrome. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 39. RADIAL NERVE TEST: </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={radilnervetest} style={{width:"125px"}} /></div>
                                        <p className="m-0 mt-2" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="radialnervetestl" value={this.state.radialnervetestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="radialnervetestr" value={this.state.radialnervetestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: make fist, extend wrist against resistance.<br/><span className="fst-italic"> rationale: inability = radial nerve involvement. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 40. ULNAR NERVE TEST:  </b></p>
                                        <div className="d-flex justify-content-center mt-3"><img src={ulnarnervetest} style={{width:"115px"}} /></div>
                                        <p className="m-0 mt-2" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="ulnarnervetestl" value={this.state.ulnarnervetestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="ulnarnervetestr" value={this.state.ulnarnervetestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: hands palmar side down, spread finger.<br/><span className="fst-italic">rationale: unequal separation of middle and ring finger = ulnar nerve involvement. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"19.73%",padding:"0 5px",borderRight:"3px solid #000",fontSize:"11px"}}>
                                        <p className="m-0" style={{lineHeight:"14px"}}><b> 41. WRIST COLONUS: </b></p>
                                        <div className="d-flex justify-content-center mt-4"><img src={wristcolonus} style={{width:"130px"}} /></div>
                                        <p className="m-0 mt-4" style={{lineHeight:"14px"}}>POS. <input type={"text"} name="wristcolonusl" value={this.state.wristcolonusl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="wristcolonusr" value={this.state.wristcolonusr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            <br/>test: forceful extension of wrist.  <br/><span className="fst-italic">rationale: sustained fibrillations = upper motor neuron lesion. </span>
                                        </p>
                                    </div>
                                    <div className="pt-1" style={{width:"22.47%",padding:"0 5px",fontSize:"11px"}}>

                                    </div>
                                </div>
                                
                            </div>

                            {
                                (this.state.nextbutons) ? (
                                    <div className="row mt-3">
                                        <div className="col-sm-12 d-flex justify-content-center">
                                            <button className="btn btn-primary btn-sm" onClick={this.presecription}>Prescription</button>
                                            <button className="btn btn-primary btn-sm mx-3" onClick={this.opentreatment}>Treatment Plan</button>
                                            <button className="btn btn-primary btn-sm" onClick={this.orthoneuroformb}>LUMBAR SPINE & UPPERLIMB EXAMINATION</button>
                                        </div>
                                    </div>
                                ) : (<><button className="btn btn-sm btn-primary mt-3" type="submit">Submit</button></>)
                            }
                        </form>

                    </div>
                </>
        )
    }
}