import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useHistory } from 'react-router-dom';
// import corelogo from '../assets/Core-Concept-Logo.png';
// import corelogoshort from '../assets/Core-Concept-Logo short.png';
// import corelogoshortdown from '../assets/Core-Concept-Logo short down.png';
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import Checklogin from './checklogin';
import axios from 'axios';
import swal from 'sweetalert';

import cliniclogo from '../assets/cmaas.png';

// import demoimg from '../assets/Core-Concept-Logo short old.png'

function Header() {

    const history = useHistory();

    const logout = () => {
        localStorage.clear();
        history.push(  '/');
    }

    const changepassword = () =>{
        setModalIsOpencp(true)
    }
 
    const [count, setcount] = useState(0);
    const [oldpswd, setoldpswd] = useState("");
    const [newpswd, setnewpswd] = useState("");
    const [repswd, setrepswd] = useState("");
    const [notifications, setnotifications] = useState([]);

    const [modalIsOpencp , setModalIsOpencp] = useState(false);
    const [modalIsOpen , setModalIsOpen] = useState(false);
    const [customStyles , setCustomStyles] = useState({
                                                        content: {
                                                        top: '50%',
                                                        left: '50%',
                                                        right: 'auto',
                                                        bottom: 'auto',
                                                        marginRight: '-50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: '45%',
                                                        padding: '0',
                                                        border:'none'
                                                        },
                                                    });

    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const closeModalcp = () => {
        setModalIsOpencp(false);
    }

    useEffect(() => {
        const datanoti ={
            datanoti : "yes",
            user : localStorage.getItem("user"),
            user_id : localStorage.getItem("user_id"),
        }
        axios.post('https://clinic.dnyanasha.com/ws/notification.php',datanoti).then((res)=>{
            console.log(res.data);
            if(count == 0){
                setcount(1);
                setnotifications(res.data.findnoti)
            }
        })
    });

    const handleChange = (event) => {
        var name = event.target.name;
        if(name === "oldpswd" ){
            setoldpswd(event.target.value)
        }
        if(name === "newpswd" ){
            setnewpswd(event.target.value)
        }
        if(name === "repswd" ){
            setrepswd(event.target.value)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const cpdata = {
            changepassword : "yes",
            user : localStorage.getItem("user"),
            user_id : localStorage.getItem("user_id"),
            oldpswd : oldpswd,
            newpswd : newpswd,
            repswd : repswd,
        }
        axios.post("https://clinic.dnyanasha.com/ws/login.php",cpdata).then((res)=>{
            console.log(res.data);
            swal(res.data.se, res.data.response, res.data.se.toLowerCase()).then((swalres)=>{
                if(res.data.se === "Success"){closeModalcp()}
            });
        })
    }

    return(
        <> 
        <Checklogin />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Department Modal"
                ariaHideApp={false}
            >
                <div className='card'>
                    <div className='card-header'>
                        <h5>Notifications</h5>
                    </div>
                    <div className='card-body'>
                        <div style={{height:"420px",overflow:"scroll"}}>
                            {
                                notifications.map((item,index)=>{
                                    return(
                                        <>
                                            <div key={index}>
                                                <h5>{item.noti_heading}</h5>
                                                <p>{item.noti_content}</p>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                
            </Modal>

            <Modal
                isOpen={modalIsOpencp}
                onRequestClose={closeModalcp}
                style={customStyles}
                contentLabel="Password change Modal"
                ariaHideApp={false}
            >
                <div className='card'>
                    <div className='card-header'>
                        <h5>Change Password</h5>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-sm-12 mt-2'>
                                    <div className="form-group">
                                        <label className="form-check-label">Old Password</label>
                                        {/* <input type="text" className="form-control" name="oldpswd" placeholder="Old Password" onChange={(e)=>{setoldpswd(e.target.value)}}  required /> */}
                                        <input type="text" className="form-control" name="oldpswd" placeholder="Old Password" onChange={handleChange}  required />
                                    </div>
                                </div>
                                <div className='col-sm-12 mt-2'>
                                    <div className="form-group">
                                        <label className="form-check-label">New Password</label>
                                        <input type="text" className="form-control" name="newpswd" placeholder="New Password" onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='col-sm-12 mt-2'>
                                    <div className="form-group">
                                        <label className="form-check-label">Re-Enter Password</label>
                                        <input type="text" className="form-control" name="repswd" placeholder="Re-Enter Password" onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='col-sm-12 mt-2'>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
            </Modal>

            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Container style={{marginLeft:"53px",marginRight:"60px",maxWidth:"100%"}}>
                    <Navbar.Brand href="#home" className='w-100'>{/*d-flex justify-content-center*/}
                        {/* <img src={corelogoshort} style={{width:"270px"}} /><img src={corelogoshortdown} style={{width:"270px"}} /> */}
                        <img src={cliniclogo} style={{width:"155px"}} />
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                    <Navbar.Collapse id="responsive-navbar-nav" style={{display:"flex",flexBasis:"auto"}}>
                        <Nav className="me-auto">
                        </Nav>

                        <Nav style={{flexDirection:"row",alignItems:"center"}}>
                        {/* <img src={demoimg} /> */}
                        <NavDropdown className='me-3' title = {
                                                <div className='float-lg-start' >
                                                    Welcome {localStorage.getItem("name")}
                                                </div>
                                            }  id="collasible-nav-dropdown" >
                                <NavDropdown.Item onClick={() => changepassword()}>Change Password</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
                                {/* <NavDropdown.Item href="#action/3.2">
                                    Another action
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item> */}
                            </NavDropdown>
                                <box-icon type='solid' name='bell' color="#707071" style={{cursor:"pointer"}} onClick={openModal} ></box-icon>
                            {/* <Nav.Link href="#deets">Doctor Login</Nav.Link> */}
                        </Nav>

                        {/* <Nav className="me-auto">
                        <Nav.Link href="#features">Features</Nav.Link>
                        <Nav.Link href="#pricing">Pricing</Nav.Link>
                        <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                            Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                            Separated link
                            </NavDropdown.Item>
                        </NavDropdown>
                        </Nav>
                        <Nav>
                        <Nav.Link href="#deets">More deets</Nav.Link>
                        <Nav.Link eventKey={2} href="#memes">
                            Dank memes
                        </Nav.Link>
                        </Nav> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header;