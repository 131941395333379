import axios from "axios";
import React from "react";
import Select from 'react-select'
import swal from 'sweetalert';

export default class Add_appointment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deptlist : [],
            options : [],
            findpatient : [],
            findpatientdetails : [],
            type : "inclinic",
            category : "",
            finddoctor : [],
            datemesg : "",
            doctor_id : "",
            fromtoarr : [],
            apptime : "",
            fortime : "",
            fromad : "no",
            showsubmitapp : true,
            consulttype : "T",
        }

        this.handlechangepid = this.handlechangepid.bind(this);
        this.handlechangepidforp = this.handlechangepidforp.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this); 

        this.fetchappdata();
        if(localStorage.getItem("user") === "Patient"){this.handlechangepidforp()}

        // console.log(this.props)
        if (this.props.location.state && this.props.location.state.fromad) {
            if(this.props.location.state.fromad === "yes"){
                this.setState({patientid:this.props.location.state.patientid})
                this.setState({fromad:this.props.location.state.fromad})
                this.handlechangepidforfromad()
            }
        }

        // console.log(new Date().getFullYear() + "-" + (new Date().getMonth()+1) +"-"+new Date().getDate())
        // this.setState({schdate:new Date().getFullYear() + "-" + (new Date().getMonth()+1) +"-"+new Date().getDate()})
        // this.setState({schdate:"2023-06-01"})
        // this.setState({availableslot:dateStr.split("T")[1].split("+")[0]})
    }

    handleSubmit = (event) =>{
        this.setState({showsubmitapp:false})
        event.preventDefault();
        const appoitmentdata = {
            add_appoinment : 'yes',
            user : localStorage.getItem('user'),
            type : this.state.type,
            pid : this.state.pid,
            department : this.state.department,
            category : this.state.category,
            schdate : this.state.schdate,
            apptime : this.state.apptime,
            fortime : this.state.fortime,
            notice : this.state.notice,
            fees : this.state.fees,
            doctor_id : this.state.doctor_id,
            pprocedure : this.state.pprocedure,
            availableslot : this.state.availableslot,
        }
        // console.log(appoitmentdata)
        axios.post('https://clinic.dnyanasha.com/ws/appointment.php',appoitmentdata).then((res)=>{
            if(res.data.saveappoitment > 0)
            {
                if (this.props.location.state && this.props.location.state.fromad) {
                    if(this.props.location.state.fromad === "yes"){
                        if(this.state.department === '1'){
                            this.props.history.push( "/addphysotherapydeatilform/"+res.data.saveappoitment)
                        }
                        if(this.state.department === '2'){
                            this.props.history.push( "/adddietdeatilform/"+res.data.saveappoitment)
                        }
                    }
                } else {
                    swal({title:"Appointment Added", text:"Appointment Added Successfully..!", icon:"success",timer:1000}).then((swalres)=>{
                        if(localStorage.getItem("user") !== "Doctor" ){
                            this.props.history.goBack();
                        } else {
                            if(this.state.department === '1'){
                                this.props.history.push( "/addphysotherapydeatilform/"+res.data.saveappoitment)
                            }
                            if(this.state.department === '2'){
                                this.props.history.push( "/adddietdeatilform/"+res.data.saveappoitment)
                            }
                        }
                    });
                }
            }
        })
    }

    handleChange = (event) =>{
        let name = event.target.name;
        if( name == "department")
        {
            this.setState({[name]: event.target.value});
            axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{fetchdocdetails:'yes',deptid:event.target.value}).then((res)=>{
                this.setState({finddoctor:res.data.finddoctor})
            })
        } else {
            if(name === "schdate"){
                this.setState({[name]: event.target.value});
                // console.log(new Date(new Date(event.target.value).setHours(0,0,0,0)).getDay())
                // console.log(new Date().setHours(0,0,0,0))
                // console.log(new Date(event.target.value).getTime() < new Date().getTime())
                var selectdate = new Date(event.target.value).setHours(0,0,0,0);
                var todaydate = new Date().setHours(0,0,0,0);
                if(selectdate > todaydate){
                    // this.setState({datemesg : "greater" })
                    if(this.state.doctor_id == ""){this.setState({datemesg : "Kindly Select Doctor...!" });this.setState({ fromtoarr : [] })} else{
                        axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{checkdocsch:"yes",doctor_id:this.state.doctor_id,selectdate:event.target.value}).then((res)=>{
                            console.log(res.data);
                            this.setState({ datemesg : res.data.status })
                            this.setState({ fromtoarr : res.data.fromtoarr })
                        })
                    }
                } else if(selectdate === todaydate){
                    // console.log("equal")
                    // this.setState({datemesg : "equal" })
                    if(this.state.doctor_id == ""){this.setState({datemesg : "Kindly Select Doctor...!" })} else{
                        axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{checkdocsch:"yes",doctor_id:this.state.doctor_id,selectdate:event.target.value}).then((res)=>{
                            console.log(res.data);
                            this.setState({ datemesg : res.data.status })
                            this.setState({ fromtoarr : res.data.fromtoarr })
                        })
                    }
                } else if(selectdate < todaydate) {
                    // console.log("less")
                    this.setState({datemesg : "Can't Enter Previous date...!" })
                    this.setState({ fromtoarr : [] })
                } else {
                    // console.log("enter")
                    this.setState({datemesg : "Kindly Enter the date...!" })
                    this.setState({ fromtoarr : [] })
                }
            } else {
                this.setState({[name]: event.target.value});
            }
        }
    }

    handlechangepidforfromad = () =>{
        const data = {
            fetchpatientdetail : 'yes',
            pid : this.props.location.state.patientid
        }
        axios.post("https://clinic.dnyanasha.com/ws/appointment.php",data).then((res)=>{
            this.setState({findpatientdetails:res.data.findpatientdetails})
            this.setState({pid:res.data.findpatientdetails['patient_id']})
        })
    }

    handlechangepidforp = () =>{
        const data = {
            fetchpatientdetail : 'yes',
            pid : localStorage.getItem("user_id")
        }
        axios.post("https://clinic.dnyanasha.com/ws/appointment.php",data).then((res)=>{
            this.setState({findpatientdetails:res.data.findpatientdetails})
            this.setState({pid:res.data.findpatientdetails['patient_id']})
        })
    }

    handlechangepid = (event) =>{
        this.setState({pid:event['value']})
        const data = {
            fetchpatientdetail : 'yes',
            pid : event['value']
        }
        axios.post("https://clinic.dnyanasha.com/ws/appointment.php",data).then((res)=>{
            this.setState({findpatientdetails:res.data.findpatientdetails})
        })
    }

    fetchappdata = () => {
        const appdata = {
            fetchdept : "yes",
        }
        axios.post("https://clinic.dnyanasha.com/ws/appointment.php",appdata).then((res)=>{
            this.setState({deptlist:res.data.finddept})
            this.setState({ findpatient:res.data.findpatient})
            
            this.setState({schdate:new Date().getFullYear() + "-" + String(new Date().getMonth()+1).padStart(2,'0') + "-" + String(new Date().getDate()).padStart(2,'0')});
            this.setState({availableslot:new Date().getHours()+":"+new Date().getMinutes()})

            var patientarray = [];
            for(var i=0;i<res.data.findpatient.length;i++){
                patientarray.push({value:res.data.findpatient[i]['patient_id'],label:res.data.findpatient[i]['patient_name']})
            }
            this.setState({options:patientarray})
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    addpatientform = () => {
        this.props.history.push("/add_patient");
    }

    render() {
        return (
            <>
                <div>
                    <button className="btn btn-primary backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                </div>
                <div className="card p-3">
                    <div className="row">
                        <div className="col-sm-12 cardheadper">
                            <h6>Add Appointment</h6>
                        </div>

                        <div className="col-sm-12">
                            <div className="core-container">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p style={{margin:"0"}}>Select Type <span style={{color:"red"}}>*</span></p>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group d-flex align-items-center" style={{padding:"0"}}>
                                                <input className="form-check-input" style={{margin:"0"}} type="radio" name="type" id="inclinic" value="inclinic" onChange={this.handleChange} checked={this.state.type == "inclinic"} required/>
                                                <label className="form-check-label" htmlFor="inclinic">In-Clinic Appointment</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group d-flex align-items-center" style={{padding:"0"}}>
                                                <input className="form-check-input" style={{margin:"0"}} type="radio" name="type" id="videocons" value="videocons" onChange={this.handleChange} checked={this.state.type == "videocons"} required/>
                                                <label className="form-check-label" htmlFor="videocons">Video Consultation</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className=" row">
                                        <div className='col-sm-6 form-group'>
                                            <label className="form-check-label">Patient <span style={{color:"red"}}>*</span></label>
                                            <div className="input-group">
                                                {
                                                    (localStorage.getItem("user") === "Patient") ? (
                                                                <select className="form-control" name="pname" value={localStorage.getItem("user_id")} onChange={()=>{}} disabled>
                                                                    <option>Select Patient</option>
                                                                    {
                                                                        this.state.findpatient.map((item,index)=>{
                                                                            return(<option key={index} value={item.patient_id}>{item.patient_name}</option>)
                                                                        })
                                                                    }
                                                                </select>
                                                            ) : (<>
                                                                    {
                                                                        (this.props.location.state && this.props.location.state.fromad) ? (
                                                                        (this.props.location.state.fromad == "yes") ? (
                                                                            <>
                                                                                <select className="form-control" name="pname" value={this.props.location.state.patientid} onChange={()=>{}} disabled>
                                                                                    <option>Select Patient</option>
                                                                                    {
                                                                                        this.state.findpatient.map((item,index)=>{
                                                                                            return(<option key={index} value={item.patient_id}>{item.patient_name}</option>)
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </>
                                                                        ) : (<>
                                                                                <Select className="form-control p-0" options={this.state.options} name="pname" onChange={this.handlechangepid} required />
                                                                                <div className="input-group-append"><span className="input-group-text" onClick={this.addpatientform} style={{borderRadius:"0 0.375rem 0.375rem 0",cursor:"pointer"}}><box-icon name='plus'></box-icon></span></div>
                                                                            </>)
                                                                        ) : (<>
                                                                            <Select className="form-control p-0" options={this.state.options} name="pname" onChange={this.handlechangepid} required />
                                                                            <div className="input-group-append"><span className="input-group-text" onClick={this.addpatientform} style={{borderRadius:"0 0.375rem 0.375rem 0",cursor:"pointer"}}><box-icon name='plus'></box-icon></span></div>
                                                                        </>)
                                                                    }
                                                                </>)
                                                }
                                            </div>
                                        </div>
                                        <div className='col-sm-6 form-group'>
                                            <label className="form-check-label">Patient ID <span style={{color:"red"}}>*</span></label>
                                            <input type="text" className="form-control" placeholder="Patient ID" value={this.state.findpatientdetails['unique_pid']} disabled />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <div className='col-sm-6'>
                                            <label className="form-check-label">Mobile Number <span style={{color:"red"}}>*</span> </label>
                                            <input type="text" className="form-control" placeholder="Enter Mobile Number" value={this.state.findpatientdetails['mobile_number']} disabled />
                                        </div>
                                        <div className='col-sm-6'>
                                            <label className="form-check-label">Email ID </label>
                                            <input type="text" className="form-control" placeholder="Email ID" value={this.state.findpatientdetails['email']} disabled />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <div className='col-sm-6'>
                                            <label className="form-check-label">Department <span style={{color:"red"}}>*</span></label>
                                            <select className="form-select" name="department" onChange={this.handleChange} required>
                                                <option value="">Select Department</option>
                                                {
                                                    this.state.deptlist.map((res,index)=>{
                                                        return(
                                                            <option key={index} value={res.dept_id}>{res.dept_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                        {/* <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="form-check-label">Doctor</label>
                                                <input type="text" name="doctor" className="form-control" placeholder="Dcotor" disabled />
                                            </div>
                                        </div> */}

                                        <div className='col-sm-6'>
                                            <label className="form-check-label">Doctor</label>
                                            {/* <input type="text" name="doctor" className="form-control" placeholder="Doctor" value={this.state.finddoctor['doctor_name']} disabled />
                                            <input type="text" name="doctor_id" className="form-control" style={{display:"none"}} value={this.state.finddoctor['doctor_id']} onChange={this.handleChange} disabled /> */}
                                            <select className="form-select" name="doctor_id" onChange={this.handleChange}>
                                                <option>Select Doctor</option>
                                                {
                                                    this.state.finddoctor.map((item,index)=>{
                                                        return(
                                                            <option key={index} value={item.doctor_id}>{item.doctor_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {/* <div className='col-sm-6'>
                                            <label className="form-check-label">Category</label>
                                            <select className="form-select" name="category"onChange={this.handleChange} disabled>
                                                <option value="">Select Category</option>
                                            </select>
                                        </div> */}
                                    </div>
                                    
                                    <div className="form-group row">
                                        <div className='col-sm-6'>
                                            <label className="form-check-label">Schedule On <span style={{color:"red"}}>*</span></label>
                                            <input type="date" className="form-control" placeholder="Schedule Date" name="schdate" value={this.state.schdate}  onChange={this.handleChange} required />
                                            <p className="text-danger fw-bold form-control-sm m-0">{this.state.datemesg}</p>
                                        </div>

                                        <div className='col-sm-6'>
                                            <label className="form-check-label">At <span style={{color:"red"}}>*</span></label>
                                            <input type="time" className="form-control" placeholder="Time" name="availableslot" value={this.state.availableslot} onChange={this.handleChange} required />
                                            {/* <label className="form-check-label">Available Slot <span style={{color:"red"}}>*</span></label> */}
                                            {/* <select className="form-select" name="availableslot" onChange={this.handleChange} required>
                                                <option value="">Select Slot</option>
                                                {
                                                    this.state.fromtoarr.map((res,index)=>{
                                                        return(
                                                            <option key={index} value={res}>{res}</option>
                                                        )
                                                    })
                                                }
                                            </select> */}
                                        </div>



                                        {/* <div className='col-sm-3'>
                                            <label className="form-check-label">At <span style={{color:"red"}}>*</span></label>
                                            <input type="time" className="form-control" placeholder="Time" name="apptime" onChange={this.handleChange} required />
                                        </div> */}
                                        <div className='col-sm-6'>
                                            <label className="form-check-label">For <span style={{color:"red"}}>*</span></label>
                                            <select className="form-select" name="fortime" onChange={this.handleChange} required>
                                                <option value="">Select</option>
                                                <option value="15">15 min</option>
                                                <option value="30">30 min</option>
                                                <option value="45">45 min</option>
                                                <option value="60">1 Hours</option>
                                                <option value="75">1:15 Hours</option>
                                                <option value="90">1:30 Hours</option>
                                                <option value="105">1:45 Hours</option>
                                                <option value="120">2 Hours</option>
                                                <option value="135">2:15 Hours</option>
                                                <option value="150">2:30 Hours</option>
                                                <option value="165">2:45 Hours</option>
                                                <option value="180">3 Hours</option>
                                                <option value="195">3:15 Hours</option>
                                                <option value="210">3:30 Hours</option>
                                                <option value="225">3:45 Hours</option>
                                                <option value="240">4 Hours</option>
                                                <option value="255">4:15 Hours</option>
                                                <option value="270">4:30 Hours</option>
                                                <option value="285">4:45 Hours</option>
                                            </select>
                                        </div>

                                        <div className='col-sm-6'>
                                            <label className="form-check-label">Consultation Type  <span style={{color:"red"}}>*</span></label>
                                            <select className="form-select" name="consulttype" value={this.state.consulttype} onChange={this.handleChange} required>
                                                <option value={"T"}>Treatment</option>
                                                <option value={"C"}>Consultation</option>
                                                <option value={"TC"}>Treatment & Consultation</option>
                                            </select>
                                        </div>


                                    </div>
                                    
                                    <div className="form-group row d-none">
                                        {
                                            (this.state.type == "inclinic") ? 
                                            (<>
                                                <div className='col-sm-6'>
                                                    <label className="form-check-label">Planned Procedure</label>
                                                    <textarea className="form-control" id="pprocedure" name="pprocedure" onChange={this.handleChange} rows="3"></textarea>
                                                </div>
                                            </>) : (<>
                                                <div className='col-sm-6'>
                                                    <label className="form-check-label">Fees <span style={{color:"red"}}>*</span></label>
                                                    <input type="number" className="form-control" name="fees" placeholder="Fees" onChange={this.handleChange} required />
                                                </div>
                                            </>)
                                        }

                                        <div className='col-sm-6'>
                                            <label className="form-check-label">Notes</label>
                                            <textarea className="form-control" id="notice" name="notice" onChange={this.handleChange} rows="3"></textarea>
                                        </div>
                                    </div>
                                    
                                    <hr></hr>
                                    <div className='text-end'>
                                        {/* <button className="btn btn-light m-2"> Cancel</button> */}
                                        {
                                            (this.state.showsubmitapp) ? (
                                                <button className="btn btn-warning">Submit</button>
                                            ) : (<></>)
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}