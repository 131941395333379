import axios from "axios";
import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import DataTable from 'react-data-table-component'
import Printheader from '../assets/printheader.png';
import Printfooter from '../assets/printfooter.png';
import SignatureCanvas from 'react-signature-canvas';
import Modal from 'react-modal';
import swal from "sweetalert";

export default class Dietplans extends Component{

    constructor(props){
        super(props);
        this.state = {
            findpatientappoint : [],
        }

        this.fetchdatadpdp()
    }

    fetchdatadpdp = () =>{
        const datatp = {
            fetchdpdata : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',datatp).then((res)=>{
            console.log(res.data);
            this.setState({findalldiettreat:res.data.findalldiettreat})
            this.setState({findpatientappoint:res.data.findpatientappoint})
        })
    }

    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px",background:"#e7e7e7"}}>

                <div className="row">
                    <div className="col-sm-10">
                        <h5>Diet Plans</h5>
                    </div>
                    <div className="col-sm-2">
                        {/* <button className="btn btn-primary" onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/adddietplanew') }} >+ Add</button> */}
                    </div>
                </div>
                <hr />

                <div className="row">
                    <div className="col-sm-12">
                        {
                            this.state.findpatientappoint.map((item,index)=>{
                                return(
                                    <div key={index}>
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row" style={{fontSize:"11px"}}>
                                                    <div className="col-sm-3"><b>Appointment Date</b></div>
                                                    <div className="col-sm-3"><b>Appointment Time</b></div>
                                                    <div className="col-sm-3"><b>Add Dite</b></div>
                                                    <div className="col-sm-3"><b>View Results</b></div>
                                                </div>
                                            </div>
                                            <div className="card-body p-1">
                                                <div className="row" style={{fontSize:"13px"}}>
                                                    <div className="col-sm-3 m-auto">{item.schedule_date}</div>
                                                    <div className="col-sm-3 m-auto">{item.start_time} - {item.end_time}</div>
                                                    <div className="col-sm-3 m-auto"><button className="btn btn-primary btn-sm" onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/adddietplanew/'+item.appointment_id) }}>Add</button></div>
                                                    <div className="col-sm-3 m-auto"><button className="btn btn-primary btn-sm" onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/resultdietplan/'+item.appointment_id) }}>View</button></div>
                                                </div>
                                            </div>
                                            <div className="card-footer"></div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {/* {
                            this.state.findalldiettreat.map((item,index)=>{
                                return(
                                    <>
                                        <p key={index} className="m-0">{item.dtdate}</p>
                                        <div className="card" key={index}>
                                            <div className="card-header">
                                                <div className="row" style={{fontSize:"11px"}}>
                                                    <div className="col-sm-2"><b>DIET NAME</b></div>
                                                    <div className="col-sm-4"><b>FROM - TO DATE</b></div>
                                                    <div className="col-sm-2"><b>PRICE</b></div>
                                                    <div className="col-sm-2"><b>DISCOUNT</b></div>
                                                    <div className="col-sm-1"><b>TOTAL</b></div>
                                                    <div className="col-sm-1"></div>
                                                </div>
                                            </div>
                                            <div className="card-body p-1">
                                                <div className="row" key={index}>
                                                    <div className="col-sm-2" style={{fontSize:"13px"}}>{item.dietname}</div>
                                                    <div className="col-sm-4" style={{fontSize:"13px"}}>{item.fromdate} - {item.todate}</div>
                                                    <div className="col-sm-2" style={{fontSize:"13px"}}>{item.dietprice}</div>
                                                    <div className="col-sm-2" style={{fontSize:"13px"}}>{item.discount }{ (item.dis_type == "PERCENT") ? (<> %</>) : (<> INR</>) }</div>
                                                    <div className="col-sm-1" style={{fontSize:"13px"}}>{ (item.dis_type == "PERCENT") ? (item.dietprice - (item.dietprice * (item.discount/100))) : (item.dietprice - item.discount) }</div>
                                                    <div className="col-sm-1"></div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="row" style={{fontSize:"13px"}}>
                                                    <div className="col-sm-3 d-flex align-items-center">From Date : {item.fromdate}</div>
                                                    <div className="col-sm-3 d-flex align-items-center">To Date : {item.todate}</div>
                                                    <div className="col-sm-3 d-flex justify-content-end"><button style={{fontSize:"13px"}} onClick={()=>this.viewdiet(item.diettreatment)} className="btn btn-primary btn-sm">View</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        } */}
                    </div>
                </div>

                </div>
            </>
        )
    }
}