import axios from "axios";
import React from "react";
import DataTable from 'react-data-table-component'
import swal from "sweetalert";
import Printheader from './assets/printheaderpre.png';
import Printfooter from './assets/printfooter.png';

export default class ViewPrecriptionMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            findallviewppresc : [],
            findpdetails : [],
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            findappdetails : [],
        }

        this.fetchviewprec();
    }

    fetchviewprec = () =>{
        const data = {
            fetchviewprec : 'yes',
            appid : this.props.match.params.appid,
            user : localStorage.getItem("user"),
            user_id : localStorage.getItem("user_id"),
        }
        axios.post('https://clinic.dnyanasha.com/ws/prescription.php',data).then((res)=>{
            console.log(res.data)
            this.setState({findallviewppresc:res.data.findallviewppresc})
            this.setState({findpdetails:res.data.findpdetails})
            this.setState({findappdetails:res.data.findappdetails})
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    deleteprescription = (prescription_id) =>{
        // console.log(prescription_id);
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if(willDelete){
                axios.post("https://clinic.dnyanasha.com/ws/prescription.php",{deleteprescription:"yes",prescription_id:prescription_id}).then((res)=>{
                    if(res.data.deleteprescription === true){this.fetchviewprec()}
                })
            } else {}
        })
        
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        div {
                                            font-size:15px !important;
                                        }
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 120px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 100%;
                                            height: 120px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        .content {
                                            width:100%;
                                        }
                                        </style>
                                        <table style="width:100%;">
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        `;
                                        setTimeout(() => {
                                            window.print();
                                            document.body.innerHTML = originalContents;
                                            window.location.reload();
                                        }, 500)
                                        // <div class="watermark"><p>Coreconcept</p></div>
    }

    render(){
        return(
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    <button className="btn btn-primary btn-sm my-2 mx-3" onClick={this.printform} >Print</button>
                </div>

                <hr style={{margin:"1.5rem 0",borderTop:"3px solid #616161",opacity:"1"}} />
                <div id="printablediv">
                <hr className="m-0" />
                <div className="row">
                    <div className="col-sm-12">
                        <div class="d-flex justify-content-around w-100">
                            <p class="m-0"><b>{this.state.findpdetails['patient_name']}</b></p>
                            <p class="m-0">{ new Date().getFullYear() - new Date(this.state.findpdetails['dob']).getFullYear() } Yrs / { (this.state.findpdetails['gender'] == 'm') ? ("Male") : ("Female")  }</p>
                            <p class="m-0">{ new Date().getDate() + " " + this.state.monthname[new Date().getMonth()] + " " + new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
                <hr className="m-0" />
                <div className="row">
                    <div className="col-sm-12">
                        {/* <div className="row" style={{fontSize:"11px",borderBottom:"1px solid #c9c9c9"}}>
                            <div className="col-sm-3">DRUG NAME</div>
                            <div className="col-sm-2">STRENGTH</div>
                            <div className="col-sm-2">DURATION</div>
                            <div className="col-sm-4"><div className="row"><div className="col-sm-4">MORNING</div><div className="col-sm-4">NOON</div><div className="col-sm-4">NIGHT</div></div></div>
                            <div className="col-sm-1"></div>
                        </div> */}

                            {
                                this.state.findallviewppresc.map((item,index)=>{
                                    return(
                                        <>
                                                <div className="row" key={index} style={{fontSize:"14px"}}>
                                                    <div className="d-flex justify-content-around">
                                                        <p className="m-0 mt-2 w-100 text-start">{item.drug_type} {item.drug_name}</p>
                                                        <p className="m-0 mt-2 w-100">{item.duration} In {(item.morning === "1") ? ("Morning") : (<></>)} {(item.noon === "1") ? (", Afternoon") : (<></>)} {(item.night === "1") ? (", Night") : (<></>)}</p>
                                                        <p className="m-0 mt-2 w-100 text-end">{item.duration} Per {item.duration_type}</p>
                                                        {/* <p className="m-0 mt-2 w-100 text-end hideonprint"><box-icon name='trash' style={{fill:"red",cursor:"pointer"}} onClick={()=>this.deleteprescription(item.prescription_id)} ></box-icon></p> */}
                                                    </div>
                                                    {(item.pinstruction !== "") ? (<p className="m-0"><b>Instruction : </b>{item.pinstruction}</p>) : (<></>)}
                                                </div>
                                            {/* <div className="row" key={index}>
                                                <div className="col-sm-3" style={{fontSize:"14px"}}><b>{item.drug_name} {item.drug_type}</b><br /><b>Notes : </b> {item.instruction}</div>
                                                <div className="col-sm-2" style={{fontSize:"14px"}}>{item.strength_number} {item.strength}</div>
                                                <div className="col-sm-2" style={{fontSize:"14px"}}>{item.duration} {item.duration_type}</div>
                                                <div className="col-sm-4">
                                                    <div className="row">
                                                        <div className="col-sm-4" style={{fontSize:"14px"}}>{item.morning}</div>
                                                        <div className="col-sm-4" style={{fontSize:"14px"}}>{item.noon}</div>
                                                        <div className="col-sm-4" style={{fontSize:"14px"}}>{item.night}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 d-flex p-0" style={{fontSize:"14px"}}>
                                                            {(item.after_food == "1") ? (<><p>After Food</p></>) : (<></>) }
                                                            {(item.before_food == "1") ? (<><p>Before Food</p></>) : (<></>) }
                                                        </div>
                                                        <div className="col-sm-6"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-1"></div>
                                            </div> */}
                                        </>
                                    )
                                })
                            }
                    </div>
                </div>
                </div>
            </>
        )
    }
}