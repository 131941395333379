import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";

import Printheader from '../assets/printheaderdiet.png';
import Printfooter from '../assets/printfooter.png';
import axios from "axios";
import swal from "sweetalert";

export default class Resultdietplan extends Component{

    constructor(props){
        super(props);
        this.state = {
            anthropometric : [],
            datearrdpd : [],
            dietresult : [],
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
        }

        this.fetchresultdatadata();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    fetchresultdatadata = () => {
        const dataresult = {
            fetchresult : "yes",
            appid : this.props.match.params.appid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/dietmain.php',dataresult).then((res)=>{
            console.log(res.data);
            this.setState({ pname : res.data.findpdetails['patient_name']})

            this.setState({ anthropometric : [JSON.parse(res.data.finddietplanallocate.mesurments)] })
            this.setState({ mesurments : JSON.parse(res.data.finddietplanallocate.mesurments) })

            this.setState({ dietresult : res.data.dietresult })
            for(let i=0;i<=res.data.dietresult.length;i++){
                var namedate = (res.data.dietresult.date).replace(/-/g,"_");
                console.log(res.data.dietresult[i])
                if(res.data.dietresult[i]['result'] === null){}else{
                    // console.log('weight' in res.data.dietresult[i]['result'])
                    // if('weight' in res.data.dietresult[i]['result']) { console.log(res.data.dietresult[i]['result']['weight']) }
                    if('weight' in res.data.dietresult[i]['result']) { this.setState({ [namedate+"_weight"] : res.data.dietresult[i]['result']['weight'] }) }
                }
            }

            // var fromdate = new Date(res.data.finddietplanallocate['fromdate'])
            // var todate = new Date(res.data.finddietplanallocate['todate'])
            // var gapdays = Number(res.data.finddietplanallocate['results_days']);

            // var datearrd = [];
            // // var i=0;
            // while (fromdate <= todate) {
            //     datearrd.push(new Date(fromdate));
            //     // datearrd[fromdate.toISOString().substr(0, 10)+"_date"] = new Date(fromdate);
            //     // console.log(fromdate.toISOString().substr(0, 10))
            //     // var resultdatearr = (res.data.finddietresult[i] === undefined) ? "" : res.data.finddietresult[i]['plan_date']
            //     // if(fromdate.toISOString().substr(0, 10) === resultdatearr){
            //     //     var plan_data = JSON.parse(res.data.finddietresult[i]['plan_data'])
            //     //     console.log(resultdatearr)
            //     //     datearrd[plan_data] = plan_data
            //     // }

            //     fromdate.setDate(fromdate.getDate() + gapdays);
            //     // i++;
            // }
            // console.log(datearrd)
            // this.setState({ datearrdpd : datearrd })
            // // var mesurearr = JSON.parse(res.data.finddietplanallocate.mesurments);
            // // var mesurevalue = '';
            // // console.log(mesurearr);
            // // for(let i=0;i<res.data.finddietresult.length;i++){
            // //     mesurevalue = JSON.parse(res.data.finddietresult[i]['plan_data'])
            // //     console.log(mesurevalue);
            // // }

            // // this.setState({ finddietresult : JSON.parse(res.data.finddietresult) })

        })
    }

    handleSubmit = (e) =>{
        e.preventDefault();
        console.log(e)
        const dataresult = { appid : this.props.match.params.appid, }
        for(let i=0;i<e.target.length;i++){ dataresult[e.target[i].name] = e.target[i].value; }
        axios.post('https://clinic.dnyanasha.com/ws/dietmain.php',dataresult).then((res)=>{
            console.log(res.data);
            if(res.data.savedietresult > 0){
                swal("Diet Measurement Added", "Diet Measurement Added Successfully..!", "success").then((swalres)=>{
                    this.fetchdietplandata();
                });
            }
            if(res.data.updatedietresult === true){
                swal("Diet Measurement Updated", "Diet Measurement Updated Successfully..!", "success").then((swalres)=>{
                    this.fetchdietplandata();
                });
            }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 100px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 70%;
                                            height: 100px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">${printContents}</div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 1)
    }

    handleChange = (e) => {

    }

    viewresult = (mesurments,namedate,result) =>{
        console.log(result)
        console.log(namedate)
        var vresweight,vresheight,vresupperarm,vreschest,vreswaist,vreslowerabdomen,vreships,vresthigh,vrescalf,vresbmi,vresibwt;
        vresweight = (this.state.vresweight === undefined) ? result['weight'] : "" ;//namedate+"vresweight";console.log(namedate+"vresweight"); //if(result === null ){}else{ this.setState({ [namedate+"vresweight"] : result['vresweight'] }) }//vresweight = result['weight']; }
        // vresheight = namedate+vresheight;console.log(namedate+"vresheight"); if(result === null ){}else{ this.setState({ [namedate+vresheight] : result['vresheight'] }) }//vresheight = result['height']; }
        // vresupperarm = namedate+vresupperarm;console.log(namedate+"vresupperarm"); if(result === null ){}else{ this.setState({ [namedate+vresupperarm] : result['vresupperarm'] }) }//vresupperarm = result['upperarm']; }
        // vreschest = namedate+vreschest;console.log(namedate+"vreschest"); if(result === null ){}else{ this.setState({ [namedate+vreschest] : result['vreschest'] }) }//vreschest = result['chest']; }
        // vreswaist = namedate+vreswaist;console.log(namedate+"vreswaist"); if(result === null ){}else{ this.setState({ [namedate+vreswaist] : result['vreswaist'] }) }//vreswaist = result['waist']; }
        // vreslowerabdomen = namedate+vreslowerabdomen;console.log(namedate+"vreslowerabdomen"); if(result === null ){}else{ this.setState({ [namedate+vreslowerabdomen] : result['vreslowerabdomen'] }) }//vreslowerabdomen = result['lowerabdomen']; }
        // vreships = namedate+vreships;console.log(namedate+"vreships"); if(result === null ){}else{ this.setState({ [namedate+vreships] : result['vreships'] }) }//vreships = result['hips']; }
        // vresthigh = namedate+vresthigh;console.log(namedate+"vresthigh"); if(result === null ){}else{ this.setState({ [namedate+vresthigh] : result['vresthigh'] }) }//vresthigh = result['thigh']; }
        // vrescalf = namedate+vrescalf;console.log(namedate+"vrescalf"); if(result === null ){}else{ this.setState({ [namedate+vrescalf] : result['vrescalf'] }) }//vrescalf = result['calf']; }
        // vresbmi = namedate+vresbmi;console.log(namedate+"vresbmi"); if(result === null ){}else{ this.setState({ [namedate+vresbmi] : result['vresbmi'] }) }//vresbmi = result['bmi']; }
        // vresibwt = namedate+vresibwt;console.log(namedate+"vresibwt"); if(result === null ){}else{ this.setState({ [namedate+vresibwt] : result['vresibwt'] }) }//vresibwt = result['ibwt']; }
        return(<>
            {(mesurments.weight) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Weight : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50"  name={"weight"} value={vresweight} onChange={(e)=> {this.handleChange(e)}}  /> </p></div>
                </div>
            : "" }
            {(mesurments.height) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Height : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"height"} value={this.state.vresheight} /> </p></div>
                </div>
            : "" }
            {(mesurments.upperarm) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Upper Arm : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"upperarm"} value={this.state.vresupperarm} /> </p></div>
                </div>
            : "" }
            {(mesurments.chest) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Chest : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"chest"} value={this.state.vreschest} /> </p></div>
                </div>
            : "" }
            {(mesurments.waist) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Waist : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"waist"} value={this.state.vreswaist} /> </p></div>
                </div>
            : "" }
            {(mesurments.lowerabdomen) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Lower Abdomen : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"lowerabdomen"} value={this.state.vreslowerabdomen} /> </p></div>
                </div>
            : "" }
            {(mesurments.hips) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Hips : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"hips"} value={this.state.vreships} /> </p></div>
                </div>
            : "" }
            {(mesurments.thigh) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Thigh : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"thigh"} value={this.state.vresthigh} /> </p></div>
                </div>
            : "" }
            {(mesurments.calf) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Calf : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"calf"} value={this.state.vrescalf} /> </p></div>
                </div>
            : "" }
            {(mesurments.bmi) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>BMI : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"bmi"} value={this.state.vresbmi} /> </p></div>
                </div>
            : "" }
            {(mesurments.ibwt) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>IBW : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"ibwt"} value={this.state.vresibwt} /> </p></div>
                </div>
            : "" }
            </>
        )
    }

    render(){
        const mesurments = this.state.mesurments;
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px"}}>
                    
                    <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                        <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                    </div>

                    <hr />

                    <div id="printablediv">
                        <h3 className="text-center text-decoration-underline">All Measurements</h3>

                        <div className="row">
                            <div className="col-sm-6">
                                <p className="d-flex align-items-center">Patient Name : <b className="ms-2"> { this.state.pname }</b></p>
                            </div>
                            <div className="col-sm-6">
                                <p className="d-flex align-items-center">Date : <b className="ms-2"> { new Date().getDate()+"-"+(new Date().getMonth()+1)+"-"+new Date().getFullYear() }</b></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Measurements</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.dietresult.map((item,index)=>{
                                                var date = item.date;
                                                var namedate = (item.date).replace(/-/g,"_");
                                                // var nameweight = (item.date).replace(/-/g,"_")+"_weight";
                                                return(
                                                    <tr key={index}>
                                                        <td>
                                                            <p className="m-0">{new Date(item.date).getDate()} {this.state.monthname[new Date(item.date).getMonth()]} {new Date(item.date).getFullYear()}</p>
                                                        </td>
                                                        <td>
                                                            <form onSubmit={this.handleSubmit}>
                                                                {this.viewresult(mesurments,namedate,item.result)}
                                                                {/* {(mesurments.weight) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Weight : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" value={nameweight} name={nameweight} onChange={(e)=>this.setState({[e.target.name]:e.target.value})}  /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.height) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Height : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_height"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.upperarm) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Upper Arm : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_upperarm"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.chest) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Chest : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_chest"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.waist) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Waist : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_waist"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.lowerabdomen) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Lower Abdomen : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_lowerabdomen"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.hips) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Hips : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_hips"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.thigh) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Thigh : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_thigh"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.calf) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Calf : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_calf"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.bmi) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>BMI : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_bmi"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.ibwt) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>IBW : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_ibwt"} /> </p></div>
                                                                    </div>
                                                                : "" } */}
                                                                <input type={"hidden"} value={date} name="mesauredate" onChange={(e)=>this.setState({[e.target.name]:e.target.value})}/>
                                                                <button className="btn btn-sm btn-primary hideonprint" name="savedietresult" value={"yes"} type="submit">Submit</button>
                                                            </form>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {/* {
                                            this.state.datearrdpd.map((items, indexs) =>{
                                                return(
                                                    <tr key={indexs}>
                                                        <td>
                                                            <p className="m-0">{items.getDate()} {this.state.monthname[items.getMonth()]} {items.getFullYear()}</p>
                                                        </td>
                                                        <td>
                                                        {
                                                            this.state.anthropometric.map((item,index)=>{
                                                                // var date = items.getFullYear()+"-"+(items.getMonth()+1)+"-"+items.getDate();
                                                                var date = items.toISOString().substr(0, 10)
                                                                var wname = this.state.monthname[items.getMonth()]+"_"+items.getDate()+"_"+items.getFullYear();
                                                                return(
                                                                    <>
                                                                        <form onSubmit={this.handleSubmit} key={indexs}>
                                                                            {(item.weight) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Weight : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="weight" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.height) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Height : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="height" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.upperarm) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Upper Arm : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="upperarm" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.chest) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Chest : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="chest" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.waist) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Waist : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="waist" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.lowerabdomen) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Lower Abdomen : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="lowerabdomen" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.hips) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Hips : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="hips" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.thigh) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Thigh : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="thigh" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.calf) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>Calf : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="calf" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.bmi) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>BMI : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="bmi" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            {(item.ibwt) ? 
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"></div>
                                                                                    <div className="col-sm-3"><b>IBW : </b></div>
                                                                                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="ibwt" /> </p></div>
                                                                                </div>
                                                                            : "" }
                                                                            <input type={"hidden"} value={date} name="mesauredate" onChange={(e)=>this.setState({[e.target.name]:e.target.value})}/>
                                                                            <button className="btn btn-sm btn-primary" name="savedietresult" value={"yes"} type="submit">Submit</button>
                                                                        </form>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        } */}
                                    </tbody>
                                </table>
                                {/* {
                                    this.state.anthropometric.map((item,index)=>{
                                        return(
                                            <>
                                                {(item.weight) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Weight : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.height) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Height : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.upperarm) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Upper Arm : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.chest) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Chest : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.waist) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Waist : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.lowerabdomen) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Lower Abdomen : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.hips) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Hips : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.thigh) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Thigh : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.calf) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>Calf : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.bmi) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>BMI : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                                {(item.ibwt) ? 
                                                    <div className="row">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-3"><b>IBW : </b></div>
                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" /> </p></div>
                                                    </div>
                                                : "" }
                                            </>
                                        )
                                    })
                                } */}
                            </div>
                        </div>

                    </div>

                </div>
            </>
        )
    }
}