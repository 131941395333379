import React from "react";
import DataTable from 'react-data-table-component';
import axios from "axios";

import usericon from './assets/usericon.png';

export default class Patient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns : [
                {
                  name:"Image",
                  cell:(row)=>{ {
                      if(row.profile_picture === ""){
                          if(row.pro_pic_data === ""){
                              var imgurl = usericon;
                          } else {
                              var imgurl = row.pro_pic_data;
                          }
                      } else {
                          var imgurl = "https://clinic.dnyanasha.com/ws/"+row.profile_picture;
                      }
                    } 
                      return(<> <img src={imgurl} style={{height:"40px",width:"40px",opacity:"0.5"}} /> </>) },
                    width:"100px"
                },
                {
                  name:"Patient ID",
                  selector:(row)=>row.unique_pid,
                  width:"120px"
                },
                {
                  name:"Patient Name",
                  selector:(row)=>row.patient_name,
                },
                {
                    name:"Mobile Number",
                    selector:(row)=>row.mobile_number,
                },
                {
                    name:"Gender",
                    selector:(row)=>{if(row.gender == "m"){ return("Male") } if(row.gender == "f"){ return("Female") } }
                },
                {
                    name:"Options",
                    // cell:(row)=><><button className="btn btn-primary" onClick={()=>this.props.history.push({ pathname: '/patient_dashboard',state:{pdata : row}})}>View</button></>
                    cell:(row)=><><button className="btn btn-primary" onClick={()=>{/*this.props.history.push({ pathname: '/patient_dashboard/'+row.unique_pid+'/profile' })*/window.open('https://clinic.dnyanasha.com/patient_dashboard/'+row.unique_pid+'/profile', '_blank')}}>View</button></>
                },
            ],
            search : [],
            patientlist : [],
            results : [],
        }

        this.fetchdata();
        this.setSearch();
    }

    openpatientdetails = (row) =>{
        window.open('/patient_dashboard/'+row.unique_pid+'/appointment', '_blank')
    }

    fetchdata = () => {
        const datapatient = {
            fetch_patient : 'yes',
        }
        axios.post('https://clinic.dnyanasha.com/ws/patient.php',datapatient).then((res)=>{
            console.log(res.data)
            this.setState({ patientlist : res.data.findpatient})
            this.setState({ results: res.data.findpatient})
        })
    }

    setSearch = (svalue) => {
        if(svalue == "" || svalue == undefined || svalue == null ){
            this.setState({search:svalue})
            this.setState({results:this.state.patientlist})
        }
        else {
            if(!isNaN(svalue)){
                var result = this.state.patientlist.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.mobile_number.toLowerCase().match(this.state.search);
                });
            } else if(svalue.charAt(0) == "p"){
                var result = this.state.patientlist.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.unique_pid.toLowerCase().match(this.state.search);
                });
            } else {
                var result = this.state.patientlist.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.patient_name.toLowerCase().match(this.state.search);
                });
            }
            this.setState({results:result})
        }
    }
    
    render(){
        return(
            <>
                <div className="card p-3">
                    <div className="row">
                        <div className="col-sm-12 cardheadper">
                            <h6>All Patient</h6>
                            <button className="btn btn-primary text-white addrolebtn" onClick={() => this.props.history.push(  '/add_patient/fromad')}>Add Patient</button>
                        </div>
                    </div>
                </div>

                <div className="card p-3 mt-4">
                    <div className="row">
                        <div className="col-sm-12">
                            <DataTable
                                columns={this.state.columns} 
                                data={this.state.results} 
                                style={{textAlign:"center"}}
                                // title="All Doctors"
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight='450px'
                                highlightOnHover
                                subHeader
                                onRowClicked={this.openpatientdetails}
                                subHeaderComponent={
                                    <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                        <div style={{fontSize:"22px"}}>All Patient</div>
                                        <input 
                                            type="text"
                                            placeholder='search here'
                                            className='w-25 form-control'
                                            value={this.state.search}
                                            onChange={(e)=>this.setSearch(e.target.value)}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}