import axios from 'axios';
import React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
// import corelogo from '../assets/Core-Concept-Logo.png';
import corelogoshorticon from '../assets/Core-Concept-Logo short old.png';

import cliniclogo from '../assets/cmaas.png';
// import Checklogin from './checklogin';

// const Sidebar1 = () => {
function Sidebar() {

  const history = useHistory();

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };
  let menuItems = [
    {
      module_name: <img src={cliniclogo} style={{width:'155px'}} />,
      iconname: "menu",
    },

    // {
    //   module_name: "Dashboard",
    //   iconname: "home",
    //   type: "solid",
    //   module_link: "dashboard"
    // },
    // {
    //   module_name: "Doctor",
    //   iconname: "user-plus",
    //   type: "solid",
    //   module_link: "doctor"
    // },
    // {
    //   module_name: "Patient",
    //   iconname: "user",
    //   type: "solid",
    //   module_link: "patient"
    // },
    // {
    //   module_name: "Appoiment",
    //   iconname: "spreadsheet",
    //   type: "solid",
    // },
    // {
    //   module_name: "Invoice",
    //   iconname: "star",
    //   type: "solid",
    // },
    // {
    //   module_name: "Permissions",
    //   iconname: "key",
    //   type: "solid",
    //   module_link: "permissions"
    // },
    // {
    //   module_name: "Settings",
    //   iconname: "cog",
    //   type: "solid",
    // },
    // {
    //   module_name: "Log Out",
    //   iconname: "log-out",
    //   color: "red",
    //   rotate: "180",
    // },

  ];
  const [hovered, setHovered] = useState(null);
  const [active, setActive] = useState(1);
  // const [active, setActive] = useState(localStorage.getItem('activesidebar'));
  const [animate, setAnimate] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const changeSmall = useMediaQuery("(max-height: 550px)");

  const [sidebarmenu, setSidebarmenu] = useState(menuItems);
  let delay = 1;


  useEffect(() => { if(window.innerWidth < 850){setExpanded(false);}
    setAnimate(true);
    let timer = setTimeout(() => setAnimate(false), delay * 1000);
    // console.log(sidebarmenu);
    fetchdata();

    return () => {
      clearTimeout(timer);
    };
  }, [active, delay]);
  
  const fetchdata = () => {
    // console.log("chalra")
    var menuside = sidebarmenu;
    const data = {
      fetchsidebar : 'yes',
      userid : localStorage.getItem("user_id"),
      user : localStorage.getItem("user"),
    }
    axios.post("https://clinic.dnyanasha.com/ws/module.php",data).then((res)=>{
      // console.log(menuside.length);
      // console.log(res.data);
      // console.log(res.data.findmodules.length)
      if(menuside.length < 2 )
      {
        // console.log(res.data.findmodules.module_name)
        var newarr = menuside.concat(res.data.findmodules)
        setSidebarmenu(newarr);
      }
      // menuside.push(res.data.findmodules);
    })
  }
  
  return ( <>
  {/* <Checklogin /> */}
    <div className={`sidebar ${expanded && "expanded"}`} onMouseOver={()=>setExpanded(true)} onMouseLeave={()=>setExpanded(false)} >
      {/* {menuItems.map((item, index) => { */}
      {sidebarmenu.map((item, index) => {
        if(item.module_name === "Communication" && localStorage.getItem("user") === "Admin"){}else{
        let middle = false;
        if (!(index === 0 || index === menuItems.length - 1)) {
          middle = true;
        }

        return (
          <div
            className={`boxicon-container ${
              expanded && "expanded-boxicon-container"
            }`}
            onMouseEnter={() => {
              if (middle) {
                setHovered(index);
              }
            }}
            onMouseLeave={() => {
              if (middle) {
                setHovered(null);
              }
            }}
            onClick={() => {
              setExpanded(false);
              if (middle) {
                setActive(index);
                // localStorage.setItem('activesidebar',index);
                // setActive(localStorage.getItem('activesidebar'));
                // console.log(active);

                // history.push(  '/'+item.module_link);
                history.push(  '/'+item.module_link);

              }
              if (index === 0) {
                setExpanded(!expanded);
              }
            }}
            key={index}
          >
            {(item.iconname === "menu") ? (
              (expanded) ? (<></>) : (
              // <img src={corelogoshorticon} style={{width:'60px'}} />
              <></>
              )
            ) : (
            <box-icon
              class={`${middle && "boxicon"} 
                      ${!middle && "first-and-last-trash-fix"}
                      ${active === index && "active"}
                      `}
              size={changeSmall ? "sm" : "md"}
              name={item.iconname}
              // type={item.type}
              type = "solid"
              color={
                hovered === index || active === index ? "black" : item.color
              }
              animation={active === index && animate ? "tada" : ""}
              rotate={item.rotate}
            ></box-icon>
            )}
            {
              (expanded === true) ? (
                <p
                  className={`description 
                  ${expanded && "show-description"}
                  ${active === index && "active-description"}`}
                >
                  {item.module_name}
                </p>
              ) : (<></>)
            }


          </div>
        );
      }
      })}
    </div>
    </>);
};

export default Sidebar;

// ReactDOM.render(
//   <div className="container">
//       <Sidebar />
//     <p className="text">THIS IS MY SIDEBAR!</p>
//   </div>,
//   document.getElementById('root')
// );
