import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert";
import Printheader from './assets/printheader.png';
import Printfooter from './assets/printfooter.png';

export default class Viewdietmain extends Component {
    constructor(props){
        super(props);
        this.state = {
            finddites:[],
            datearrd:[],
            dietvalarr:[],
            pname : [],
            dietresult : [],
            monthname : ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
        }

        this.fetchdietmain();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    fetchdietmain = () =>{
        const datavtm = {
            fetchdietmain : "yes",
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/dietmain.php",datavtm).then((res)=>{
            console.log(res.data)
            this.state = { finddites : res.data.finddites }
            this.setState({ pname : res.data.findpid['patient_name']})
            this.setState({ dietresult : res.data.dietresult })
            this.setState({ mesurments : JSON.parse(res.data.finddietplanallocate.mesurments) })

            if(res.data.findgivendiet != false){
                this.setState({ dietvalarr : JSON.parse(res.data.findgivendiet['ditedata']) })
            }
            
            
            // this.setState({finddites : res.data.finddites})
            // var monthname = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            // console.log(JSON.parse(res.data.findgivendiet['ditedata']))
            // for(let j=0;j<JSON.parse(res.data.findgivendiet['ditedata']).length;j++){
            //     console.log(JSON.parse(res.data.findgivendiet['ditedata'])[j])
            //     console.log(JSON.parse(res.data.findgivendiet['ditedata'])[j]['weight'])
            //     var newdate = new Date(JSON.parse(res.data.findgivendiet['ditedata'])[j]['date'])
            //     var wname = monthname[newdate.getMonth()]+"_"+newdate.getDate()+"_"+newdate.getFullYear()+"_w";
            //     this.setState({ wname : JSON.parse(res.data.findgivendiet['ditedata'])[j]['weight'] })
            //     wname = ""
            //     console.log(wname)
            // }

            this.setState({ fromdate : res.data.finddites['fromdate']})
            this.setState({ todate : res.data.finddites['todate']})

            var fromdate = new Date(res.data.finddites['fromdate'])
            var todate = new Date(res.data.finddites['todate'])
            // var fromdate = new Date("2023-01-01")
            // var todate = new Date("2023-01-31")

            var datearrd = [];
            while (fromdate <= todate) {
                datearrd.push(new Date(fromdate));
                fromdate.setDate(fromdate.getDate() + 6);
            }
            // console.log(datearrd)
            this.setState({ datearrd : datearrd })

        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    // handleChange = (event,index,date) =>{
    //     let name = event.target.name;
    //     this.setState({[name]: event.target.value});

    //     console.log(name);
    //     let dietvalarr = this.state.dietvalarr;
    //     let newval;
    //     if(name.includes("_w")){
    //         if(dietvalarr.some(e => e.date == date)){
    //             let ii = dietvalarr.findIndex(({ date }) => date === dietvalarr['date']);
    //             console.log(ii)
    //             if('weight' in dietvalarr[ii]){
    //                 dietvalarr[ii]['weight'] = event.target.value;
    //             } else{
    //                 newval = { date : date , weight : event.target.value }
    //                 dietvalarr.push(newval);
    //             }
    //             // console.log(dietvalarr[dietvalarr.findIndex(({ date }) => date === date)]['weight'])
    //             // dietvalarr.splice(dietvalarr.findIndex(({ date }) => date === date), 1)
    //         }else{
    //             newval = { date : date, weight : event.target.value }
    //             dietvalarr.push(newval);
    //         }
    //     }
    //     if(name.includes("_n")){
    //         newval = { date : date, note : event.target.value }
    //         dietvalarr.push(newval);
    //     }
    //     this.setState({dietvalarr:dietvalarr})
    //     console.log(dietvalarr)
    // }

    handleChange = (event,index,date) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
        let ditevalarr = this.state.dietvalarr;
        console.log(ditevalarr)
        let newval = { date : date, weight : event.target.value }
        if(event.target.value != "")
        {
            if(ditevalarr.some(e => e.date == newval['date'])){         // if date is equal
                if(ditevalarr.some(e => e.status != newval['weight'])){                            // if status is equal
                    ditevalarr.splice(ditevalarr.findIndex(({ date }) => date === newval['date']), 1)
                    ditevalarr.push(newval);
                }
            }
            else{
                ditevalarr.push(newval);
            }
        } else {
            ditevalarr.splice(ditevalarr.findIndex(({ date }) => date === newval['date']), 1)
        }
        this.setState({dietvalarr:ditevalarr})
    }

    // handleSubmit = (event) =>{
    //     event.preventDefault();
    //     const datadpt = {
    //         savedpt : "yes",
    //         dietdata : JSON.stringify(this.state.dietvalarr),
    //         puid : this.props.match.params.puid,
    //         dietid : this.props.match.params.dietid,
    //     }
    //     console.log(datadpt)
    //     axios.post("https://clinic.dnyanasha.com/ws/dietmain.php",datadpt).then((res)=>{
    //         console.log(res.data)
    //         if(res.data.savedtp > 0){
    //             swal("Diet Detail Saved", "Patient Diet Detail Saved Successfully..!", "success").then((swalres)=>{
    //             });
    //             this.fetchtreatmian();
    //         } else if(res.data.updatedtp == true){
    //             swal("Diet Detail Updated", "Patient Diet Detail Updated Successfully..!", "success").then((swalres)=>{
    //             });
    //             this.fetchtreatmian();
    //         }
    //     })
    // }

    handleSubmit = (e) =>{
        e.preventDefault();
        console.log(e)
        const dataresult = { appid : this.props.match.params.appid, }
        for(let i=0;i<e.target.length;i++){ dataresult[e.target[i].name] = e.target[i].value; }
        axios.post('https://clinic.dnyanasha.com/ws/dietmain.php',dataresult).then((res)=>{
            console.log(res.data);
            if(res.data.savedietresult > 0){
                swal("Diet Measurement Added", "Diet Measurement Added Successfully..!", "success").then((swalres)=>{
                    this.fetchdietplandata();
                });
            }
            if(res.data.updatedietresult === true){
                swal("Diet Measurement Updated", "Diet Measurement Updated Successfully..!", "success").then((swalres)=>{
                    this.fetchdietplandata();
                });
            }
        })
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .header, .header-space, .footer, .footer-space {
                                            height: 100px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                        }
                                        .header img{
                                            width: 100%;
                                            height: 100px;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                        }
                                        .footer img {
                                            width: 100%;
                                            height: 100px;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">${printContents}</div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 1)
    }

    checkweightinput = (wname,date,index) =>{
        for(let j=0;j<this.state.dietvalarr.length;j++){
            var newdatenew = new Date(this.state.dietvalarr[j]['date'])
            var newdate = newdatenew.getFullYear()+"-"+(newdatenew.getMonth()+1)+"-"+newdatenew.getDate();
            var weight = "";
            if(date == newdate){
                weight = this.state.dietvalarr[j]['weight'];
                break; 
            }
        }
        return(
            <>
                <div className="col-sm-12 p-0 d-flex align-items-center justify-content-between">
                    Weight : <input type="number" name={wname} value={weight} onChange={(e)=>this.handleChange(e,index,date)} min={"1"} className="form-control w-75 ms-sm-3 p-1" />
                </div>
            </>
        )
    }

    viewresult = (mesurments,namedate,result) =>{
        console.log(result)
        var vresweight,vresheight,vresupperarm,vreschest,vreswaist,vreslowerabdomen,vreships,vresthigh,vrescalf,vresbmi,vresibwt;
        if(result === null ){}else{ vresweight = result['weight']; }
        if(result === null ){}else{ vresheight = result['height']; }
        if(result === null ){}else{ vresupperarm = result['upperarm']; }
        if(result === null ){}else{ vreschest = result['chest']; }
        if(result === null ){}else{ vreswaist = result['waist']; }
        if(result === null ){}else{ vreslowerabdomen = result['lowerabdomen']; }
        if(result === null ){}else{ vreships = result['hips']; }
        if(result === null ){}else{ vresthigh = result['thigh']; }
        if(result === null ){}else{ vrescalf = result['calf']; }
        if(result === null ){}else{ vresbmi = result['bmi']; }
        if(result === null ){}else{ vresibwt = result['ibwt']; }
        return(<>
            {(mesurments.weight) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Weight : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50"  name={"weight"} value={vresweight} onChange={(e)=>this.setState({[e.target.name]:e.target.value})}  /> </p></div>
                </div>
            : "" }
            {(mesurments.height) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Height : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"height"} value={vresheight} /> </p></div>
                </div>
            : "" }
            {(mesurments.upperarm) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Upper Arm : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"upperarm"} value={vresupperarm} /> </p></div>
                </div>
            : "" }
            {(mesurments.chest) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Chest : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"chest"} value={vreschest} /> </p></div>
                </div>
            : "" }
            {(mesurments.waist) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Waist : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"waist"} value={vreswaist} /> </p></div>
                </div>
            : "" }
            {(mesurments.lowerabdomen) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Lower Abdomen : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"lowerabdomen"} value={vreslowerabdomen} /> </p></div>
                </div>
            : "" }
            {(mesurments.hips) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Hips : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"hips"} value={vreships} /> </p></div>
                </div>
            : "" }
            {(mesurments.thigh) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Thigh : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"thigh"} value={vresthigh} /> </p></div>
                </div>
            : "" }
            {(mesurments.calf) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>Calf : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"calf"} value={vrescalf} /> </p></div>
                </div>
            : "" }
            {(mesurments.bmi) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>BMI : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"bmi"} value={vresbmi} /> </p></div>
                </div>
            : "" }
            {(mesurments.ibwt) ? 
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3"><b>IBW : </b></div>
                    <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={"ibwt"} value={vresibwt} /> </p></div>
                </div>
            : "" }
            </>
        )
    }

    render(){
        const mesurments = this.state.mesurments;
        return(
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                </div>
                <hr style={{margin:"1.5rem 0",borderTop:"3px solid #616161",opacity:"1"}} />
                <div id="printablediv">
                    <h3 className="text-center text-decoration-underline">All Measurements</h3>

                    <div className="row">
                        <div className="col-sm-6">
                            <p className="d-flex align-items-center">Patient Name : <b className="ms-2"> { this.state.pname }</b></p>
                        </div>
                        <div className="col-sm-6">
                            <p className="d-flex align-items-center">Date : <b className="ms-2"> { new Date().getDate()+"-"+(new Date().getMonth()+1)+"-"+new Date().getFullYear() }</b></p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Measurements</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.dietresult.map((item,index)=>{
                                            var date = item.date;
                                            var namedate = (item.date).replace(/-/g,"_");
                                            return(
                                                <tr key={index}>
                                                    <td>
                                                        <p className="m-0">{new Date(item.date).getDate()} {this.state.monthname[new Date(item.date).getMonth()]} {new Date(item.date).getFullYear()}</p>
                                                    </td>
                                                    <td>
                                                        <form onSubmit={this.handleSubmit}>
                                                            {this.viewresult(mesurments,namedate,item.result)}
                                                        {/* {(mesurments.weight) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Weight : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name="weight" onChange={(e)=>this.setState({[e.target.name]:e.target.value})}  /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.height) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Height : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_height"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.upperarm) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Upper Arm : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_upperarm"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.chest) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Chest : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_chest"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.waist) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Waist : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_waist"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.lowerabdomen) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Lower Abdomen : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_lowerabdomen"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.hips) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Hips : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_hips"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.thigh) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Thigh : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_thigh"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.calf) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>Calf : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_calf"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.bmi) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>BMI : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_bmi"} /> </p></div>
                                                                    </div>
                                                                : "" }
                                                                {(mesurments.ibwt) ? 
                                                                    <div className="row">
                                                                        <div className="col-sm-1"></div>
                                                                        <div className="col-sm-3"><b>IBW : </b></div>
                                                                        <div className="col-sm-6"><p><input type={"text"} className="form-control w-50" name={namedate+"_ibwt"} /> </p></div>
                                                                    </div>
                                                                : "" } */}
                                                                <input type={"hidden"} value={date} name="mesauredate" onChange={(e)=>this.setState({[e.target.name]:e.target.value})}/>
                                                                <button className="btn btn-sm btn-primary" name="savedietresult" value={"yes"} type="submit">Submit</button>
                                                        </form>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* <b>Note : </b> Kindly Enter Weight at every 7th day.
                    <form onSubmit={this.handleSubmit} className="mt-4"> 
                    {
                        this.state.datearrd.map((item, index) =>{
                            var date = item.getFullYear()+"-"+(item.getMonth()+1)+"-"+item.getDate();
                            var wname = this.state.monthname[item.getMonth()]+"_"+item.getDate()+"_"+item.getFullYear()+"_w";
                            if(index === 0){ var bordertb = "1px solid #000000"; } else { var bordertb = ''; }
                            return(
                                <>
                                    <div className="row" key={index}>
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-2 d-flex align-items-center justify-content-center pb-2" style={{borderBottom:" 1px solid #000000",borderLeft:"1px solid #000000",borderTop:bordertb}}>
                                            <p className="m-0">{item.getDate()}-{this.state.monthname[item.getMonth()]}-{item.getFullYear()}</p>
                                        </div>
                                        <div className="col-sm-4 pb-2" style={{borderBottom:" 1px solid #000000",borderRight:"1px solid #000000",borderTop:bordertb}}>
                                            <div className="row m-0 mt-2">
                                                {
                                                    this.checkweightinput(wname,date,index)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-3"></div>
                                    </div>
                                </>
                            )
                        })
                    }

                    {
                        (localStorage.getItem("user") === "Patient") ? (<>
                            <div className="row">
                                <div className="col-sm-12 d-flex justify-content-center mt-3">
                                    <button type="submit" className="btn btn-primary btn-sm" >Submit</button>
                                </div>
                            </div>
                        </>) : (<></>)
                    }
                    </form> */}
                </div>
            </>
        )
    }
}