import React from "react";
import axios from "axios";
import swal from "sweetalert";

export default class CommunicationMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            findallcommain : [],
            commfilemain : null,
            commessage : "",
            fileurlhost : "https://clinic.dnyanasha.com/ws/uploads/communicationfiles/",
        }

        this.fetchcommdatamain();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        if(this.state.commessage === "" ){
            swal("Error", "Please Write Some Message...!", "error").then((swalres)=>{
            });
        } else {
            var formdata = new FormData;
            formdata.append("addcommmessagemain",'yes');
            formdata.append("commessage",this.state.commessage);
            formdata.append("commfile",this.state.commfilemain);
            formdata.append("luserid",localStorage.getItem("user_id"));
            formdata.append("luser",localStorage.getItem("user"));

            console.log(formdata);
            axios.post('https://clinic.dnyanasha.com/ws/communication.php',formdata).then((res)=>{
                console.log(res.data)
                if(res.data.savecommunicationmain > 0)
                {
                    this.setState({commessage:""})
                    this.fetchcommdatamain();
                }
            })
        }
    }

    fetchcommdatamain = () =>{
        const cdata = {
            fetchcommdatamain : 'yes',
            user : localStorage.getItem("user"),
            userid : localStorage.getItem("user_id"),
        }
        axios.post('https://clinic.dnyanasha.com/ws/communication.php',cdata).then((res)=>{
            console.log(res.data);
            this.setState({ findallcommain : res.data.findallcommunication })
        })
    }

    handleFileUpload = (event) => {
        this.setState({commfilemain:event.target.files[0]})
    };

    render(){
        return(
            <>
                <div className="d-flex flex-column justify-content-between position-absolute " style={{width: "-webkit-fill-available",height:"80%"}}>
                    <div className="row p-1 m-0" style={{backgroundColor:"#e0e0e0"}}>
                        <div className="col-sm-12 d-flex align-items-center">
                            {
                                (localStorage.getItem("user") == "Patient") ? (<>
                                    <box-icon type='solid' style={{fill:"#797979"}} size='20' name='user-circle'></box-icon>
                                    &nbsp;&nbsp;&nbsp;<p className="m-0">Doctor and Staff</p>
                                </>) : (<>
                                    <img src={"https://clinic.dnyanasha.com/ws/"+this.state.findpdetail.profile_picture} style={{height:"50px",width:"50px",borderRadius:"50%"}} />
                                    &nbsp;&nbsp;&nbsp;<p className="m-0">{this.state.findpdetail.patient_name}</p>
                                </>)
                            }
                        </div>
                    </div>

                    <div className="row h-75 w-100 m-0">
                        <div className="col-sm-12 h-100 w-100 overflow-auto" id="trails">
                            {
                                this.state.findallcommain.map((item,index)=>{
                                    return(
                                        <>
                                            {
                                                (localStorage.getItem("user_id") === item.luserid) ? (
                                                    <div className="mt-3 mb-2 d-flex flex-column align-items-end" >
                                                        <p className="m-0 p-1 px-3" style={{borderRadius:"5px",width:"fit-content",background:"#efefef"}}>{item.commessage}</p>
                                                        {(item.commfile == "") ? (<></>) : (<><p className="m-0 small"><b>Attached File : </b><a href={this.state.fileurlhost+((item.commfile).split(".")[0]+"_"+item.communication_id+"."+(item.commfile).split(".")[1])} target={"_blank"}>{item.commfile}</a></p></>)}
                                                        <p className="m-0" style={{fontSize: "10px",color:"#3c3c3c"}}>{item.created_at}</p>
                                                    </div>
                                                ) : (
                                                    <div className="mt-3 mb-2">
                                                        <p className="m-0 small"><b>{item.luser}</b></p>
                                                        <p className="m-0 p-1 px-3" style={{borderRadius:"5px",width:"fit-content",background:"#efefef"}}>{item.commessage}</p>
                                                        {(item.commfile == "") ? (<></>) : (<><p className="m-0 small"><b>Attached File : </b><a href={this.state.fileurlhost+((item.commfile).split(".")[0]+"_"+item.communication_id+"."+(item.commfile).split(".")[1])} target={"_blank"}>{item.commfile}</a></p></>)}
                                                        <p className="m-0" style={{fontSize: "10px",color:"#3c3c3c"}}>{item.created_at}</p>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="row p-2 m-0 mb-2" style={{backgroundColor:"#e0e0e0"}}>
                        <div className="col-sm-1 d-flex justify-content-center align-items-center p-0"><box-icon name='paperclip' style={{transform:"rotate(45deg)",fill:"#797979"}} onClick={()=>{this.refs.uploadfile.click()}} ></box-icon><input type="file" ref="uploadfile" onChange={this.handleFileUpload} hidden/></div>
                        <div className="col-sm-10 p-0"><input type="text" className="form-control" placeholder="Enter Message" name="commessage" value={this.state.commessage} onChange={this.handleChange} /></div>
                        <div className="col-sm-1 p-0"><button className="btn btn-primary" onClick={this.handleSubmit} >Send</button></div>
                    </div>
                </div>
            </>
        )
    }
}