import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import coreconceptlogo from './assets/Core-Concept-Logo.png';
import cliniclogo from './assets/cmaas.png';
import swal from 'sweetalert';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            passwordshow : false,
        }

        if(localStorage.getItem("user_id") != null ){
            window.location.href = "/dashboard";
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    loginfun = () =>{
        this.props.history.push(  '/dashboard');
    }

    handleChange = (event) => {
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            logincheck : 'yes',
            email : this.state.email,
            password : this.state.password
        }
        // console.log(data);
        axios.post('https://clinic.dnyanasha.com/ws/login.php',data).then((res)=>{
        // axios.post('http://192.168.1.154:5000/Emp_Login',{Email_ID:"manisha@gmail.com",Password:"Test@123"}).then((res)=>{
            console.log(res.data);
            if(res.data.response == "Successfully Login")
            {
                swal({title:res.data.response, text:"Successfully Login", icon:"success",timer:1000}).then((swalres)=>{
                    // console.log(swalres);
                    console.log(res.data.findres);
                    if(res.data.user == "Doctor")
                    {
                        localStorage.setItem("loggedin",true);
                        localStorage.setItem("user_id",res.data.findres.doctor_id);
                        localStorage.setItem("name",res.data.findres.doctor_name);
                        localStorage.setItem("email",res.data.findres.doc_email);
                        localStorage.setItem("user",res.data.user);
                        
                        localStorage.setItem("roleidarr",JSON.stringify(res.data.roleidarr));
                        localStorage.setItem("roleslugarr",JSON.stringify(res.data.roleslugarr));
                        
                        this.props.history.push(  '/dashboard')
                    }
                    if(res.data.user == "Staff")
                    {
                        localStorage.setItem("loggedin",true);
                        localStorage.setItem("user_id",res.data.findres.staff_id);
                        localStorage.setItem("name",res.data.findres.staff_name);
                        localStorage.setItem("email",res.data.findres.staff_email);
                        localStorage.setItem("user",res.data.user);

                        localStorage.setItem("roleidarr",JSON.stringify(res.data.roleidarr));
                        localStorage.setItem("roleslugarr",JSON.stringify(res.data.roleslugarr));
                        this.props.history.push( '/dashboard')
                    }
                    if(res.data.user == "Patient")
                    {
                        localStorage.setItem("loggedin",true);
                        localStorage.setItem("user_id",res.data.findres.patient_id);
                        localStorage.setItem("name",res.data.findres.patient_name);
                        localStorage.setItem("email",res.data.findres.email);
                        localStorage.setItem("user",res.data.user);
                        
                        localStorage.setItem("roleidarr",JSON.stringify(res.data.roleidarr));
                        localStorage.setItem("roleslugarr",JSON.stringify(res.data.roleslugarr));
                        
                        this.props.history.push( '/dashboard')
                    }
                    if(res.data.user == "Admin")
                    {
                        localStorage.setItem("user",res.data.user);
                        localStorage.setItem("name","Admin");
                        localStorage.setItem("user_id","0");
                        localStorage.setItem("email","admin@admin.com");

                        localStorage.setItem("roleidarr",JSON.stringify(res.data.roleidarr));
                        localStorage.setItem("roleslugarr",JSON.stringify(res.data.roleslugarr));
                        this.props.history.push( '/dashboard')
                    }
                    
                });
            }
            if(res.data.response === "Invalid Password")
            {
                swal(res.data.response, "Enter Correct Password", "error").then((swalres)=>{
                    // console.log(swalres);
                });
            }
            if(res.data.response === "Access Denied")
            {
                swal(res.data.response, "Contact to Admin", "error").then((swalres)=>{
                    // console.log(swalres);
                });
            }
            if(res.data.response === "User Not Found")
            {
                swal(res.data.response, "User is Not Registered", "error").then((swalres)=>{
                    // console.log(swalres);
                });
            }
            if(res.data.response === "Invalid email format or Enter Mobile No")
            {
                swal("Invalid",res.data.response, "error").then((swalres)=>{
                    // console.log(swalres);
                });
            }
        })
    }

    render(){
        return(
            <>
            <div className="loginbody">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center w-100">
                        <div className="col-md-6">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form">
                                            <div className="text-center mb-3 bg-white p-2" style={{borderRadius: "20px"}}>
                                                <a href="#"> <img src={cliniclogo} style={{width:"40%"}} alt="" /></a>
                                            </div>
                                            <h4 className="text-center mb-4  text-white"> Login</h4>
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="form-group">
                                                    <label className="mb-1  text-white"><strong>Email</strong></label>
                                                    <input type="text" name="email" className="form-control form-controllogin" style={{borderRadius:"1.25rem"}} onChange={this.handleChange} placeholder="example@gmail.com" required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="mb-1  text-white"><strong>Password</strong></label>
                                                    <div className="input-group">
                                                        <input type={this.state.passwordshow ? "text" : "password"} name="password" className="form-control form-controllogin" onChange={this.handleChange} placeholder="password" style={{borderRadius:"1.25rem 0 0 1.25rem"}} required />
                                                        <div className="input-group-append d-flex align-items-center" style={{background: "#e8f0ff",height: "54px",marginTop:"1px",padding: "10px",borderRadius:"0 1.25rem 1.25rem 0"}}><box-icon style={{cursor:"pointer"}} name={this.state.passwordshow ? 'hide' : 'show'} onClick={()=>this.setState({passwordshow:!this.state.passwordshow})} ></box-icon></div>
                                                    </div>
                                                </div>
                                                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                    <div className="form-group">
                                                    <div className="custom-control custom-checkbox ml-1 text-white">
                                                            {/* <input type="checkbox" className="custom-control-input" id="basic_checkbox_1" />
                                                            <label className="custom-control-label" htmlFor="basic_checkbox_1">Remember my preference</label> */}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <a className="text-white" href="/forgotpassword">Forgot Password?</a>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <input type="submit" name="login" className="btn btnlogin bg-white btn-block" value="Sign Me In" />
                                                    {/* <input type="submit" name="login" className="btn btnlogin bg-white btn-block" value="Sign Me In" onClick={() => this.loginfun()} /> */}
                                                </div>
                                            </form>
                                                <div className="form-row d-flex justify-content-between mt-4">
                                                    <div className="form-group">
                                                        {/* <a href="customer_login.php" className="text-white">Switch to Customer Login</a> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <a href="/add_patient" className="text-white">Register Patient</a>
                                                        {/* <a onClick={() => this.props.history.push(  '/add_patient')} className="text-white">Register Patient</a> */}
                                                    </div>
                                                </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
export default withRouter(Login);