import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import coreconceptlogo from '../assets/Core-Concept-Logo.png';
import axios from "axios";
import swal from "sweetalert";
import lumbrosacraltext from '../assets/orthoneurob/lumbrosacraltext.png'


import libantest from '../assets/orthoneurob/libantest.jpg'
import odonahuemaneuvaer from '../assets/orthoneurob/odonahuemaneuvaer.jpg'
import tripodtest from '../assets/orthoneurob/tripodtest.jpg'
import patellarreflex from '../assets/orthoneurob/patellarreflex.jpg'
import minersign from '../assets/orthoneurob/minersign.jpg'
import chestexpansiontest from '../assets/orthoneurob/chestexpansiontest.jpg'
import osteoplistline from '../assets/orthoneurob/osteoplistline.jpg'
import kempstest from '../assets/orthoneurob/kempstest.jpg'
import trendelenburgtest from '../assets/orthoneurob/trendelenburgtest.jpg'
import palpascinerve from '../assets/orthoneurob/palpascinerve.jpg'
import piriformismuspal from '../assets/orthoneurob/piriformismuspal.jpg'
import dorsolrangofmot from '../assets/orthoneurob/dorsolrangofmot.jpg'
import cerebfunctest from '../assets/orthoneurob/cerebfunctest.jpg'
import toewalktest from '../assets/orthoneurob/toewalktest.jpg'
import heelwalktest from '../assets/orthoneurob/heelwalktest.jpg'
import gastromusctest from '../assets/orthoneurob/gastromusctest.jpg'
import forcedleglowertest from '../assets/orthoneurob/forcedleglowertest.jpg'
import laseguetest from '../assets/orthoneurob/laseguetest.jpg'
import braggardtest from '../assets/orthoneurob/braggardtest.jpg'
import fajerstantest from '../assets/orthoneurob/fajerstantest.jpg'
import homanstest from '../assets/orthoneurob/homanstest.jpg'
import hooversign from '../assets/orthoneurob/hooversign.jpg'
import favereoatricktest from '../assets/orthoneurob/favereoatricktest.jpg'
import laquerrestest from '../assets/orthoneurob/laquerrestest.jpg'
import muscletesting from '../assets/orthoneurob/muscletesting.jpg'
import circumfancet from '../assets/orthoneurob/circumfancet.jpg'
import dermatomedistri from '../assets/orthoneurob/dermatomedistri.jpg'
import clonustests from '../assets/orthoneurob/clonustests.jpg'
import pathologyrefelexs from '../assets/orthoneurob/pathologyrefelexs.jpg'
import patreflexcont1 from '../assets/orthoneurob/patreflexcont1.jpg'
import patreflexcont2 from '../assets/orthoneurob/patreflexcont2.jpg'
import vibrationperanemia from '../assets/orthoneurob/vibrationperanemia.jpg'
import bigtoetest from '../assets/orthoneurob/bigtoetest.jpg'
import sotohalltest from '../assets/orthoneurob/sotohalltest.jpg'
import linderstest from '../assets/orthoneurob/linderstest.jpg'
import thomastest from '../assets/orthoneurob/thomastest.jpg'
import gaenslenstest from '../assets/orthoneurob/gaenslenstest.jpg'
import oberstest from '../assets/orthoneurob/oberstest.jpg'
import pulses39 from '../assets/orthoneurob/pulses39.jpg'
import elytest from '../assets/orthoneurob/elytest.jpg'
import yeomansfemstrechtest from '../assets/orthoneurob/yeomansfemstrechtest.jpg'
import achillesreflex from '../assets/orthoneurob/achillesreflex.jpg'
import muscletesting43 from '../assets/orthoneurob/muscletesting43.jpg'
import muscletestcont from '../assets/orthoneurob/muscletestcont.jpg'
import relatedareakneerom from '../assets/orthoneurob/relatedareakneerom.jpg'
import mcmurraytest from '../assets/orthoneurob/mcmurraytest.jpg'
import drawersign from '../assets/orthoneurob/drawersign.jpg'
import collateralligamenttest from '../assets/orthoneurob/collateralligamenttest.jpg'
import osgoodschaltlertest from '../assets/orthoneurob/osgoodschaltlertest.jpg'
import compression from '../assets/orthoneurob/compression.jpg'
import distraction49 from '../assets/orthoneurob/distraction49.jpg'


export default class OrthoNeuroFromB extends Component{
    constructor(props){
        super(props);
        this.state = {
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            pname:"",
            nextbutons : false,

            texamblack : false,
            fexamblue : false,
            sexamred : false,
        }

        this.fetchorthoneuradataformb()

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    determineInputColor = () => {
        const { fexamblue, sexamred, texamblack } = this.state;
        // return fexamblue ? 'orthoinputblue' : sexamred ? 'orthoinputred' : texamblack ? 'orthoinputblack' : '';
        return texamblack ? 'orthoinputblack' : sexamred ? 'orthoinputred' : fexamblue ? 'orthoinputblue' : '';
    }

    handleCheckboxChange = (checkboxName, value) => {
        this.setState({ [checkboxName]: value });
    };

    fetchorthoneuradataformb = () =>{
        const dataon = {
            fetchorthoneuradataformb : "yes",
            puid: this.props.match.params.puid,
            appid: this.props.match.params.appid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/consultation.php",dataon).then((res)=>{
            console.log(res.data)
            this.setState({findpid:res.data.findpid})
            this.setState({ pname : res.data.findpid['patient_name'] })

            if(res.data.findtableformbdata['tabledata'] !== ""){
                var tabledatab = JSON.parse(res.data.findtableformbdata['tabledata']);
                Object.entries(tabledatab).map(([key,value])=>{
                    // console.log(key+" = "+value)
                    this.setState({ [key] : value })
                })
            }

            for (let j = 0; j < res.data.findorthoneurodataformb.length; j++) {
                // console.log(res.data.findorthoneurodata[j]['meta_key'])
                // console.log(res.data.findorthoneurodata[j]['meta_value'])
                this.setState({ [res.data.findorthoneurodataformb[j]['meta_key']] : res.data.findorthoneurodataformb[j]['meta_value'] })

                if(res.data.findorthoneurodataformb[j]['textcolor'] === "orthoinputblack"){
                    this.setState({ texamblack : true })
                }
                if(res.data.findorthoneurodataformb[j]['textcolor'] === "orthoinputred"){
                    this.setState({ sexamred : true })
                }
                if(res.data.findorthoneurodataformb[j]['textcolor'] === "orthoinputblue"){
                    this.setState({ fexamblue : true })
                }
            }
            this.determineInputColor();
        })
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const dataon = { saveortoneuroformb : "yes", puid: this.props.match.params.puid, appid: this.props.match.params.appid, textcolor : this.determineInputColor() }
        const tabledataonb = { saveorthoneurotbaleformb : "yes", puid: this.props.match.params.puid, appid: this.props.match.params.appid, }
        for (let i = 0; i < event.target.length; i++) {
            // console.log(event.target[i].name);
            if((event.target[i].name).includes("table_") === true){
                // console.log(event.target[i].name);
                tabledataonb[event.target[i].name] = event.target[i].checked
            }
            else{
                if (event.target[i].type === "checkbox") {
                    dataon[event.target[i].name] = event.target[i].checked
                } else{
                    dataon[event.target[i].name] = event.target[i].value
                }
            }
        }
        this.savetabeldatab(tabledataonb);
        axios.post("https://clinic.dnyanasha.com/ws/consultation.php",dataon).then((res)=>{
            console.log(res.data)
            if(res.data.saveorthoneuroformb > 0){
                // swal("Added Successfully", "Ortho & Neuro form Successfully Added..!", "success").then((swalres)=>{
                //     this.fetchorthoneuradataformb();
                // });
                this.setState({nextbutons:true})
            }
            if(res.data.updateonformb === true){
                // swal("Updated Successfully", "Patient History & Observation Successfully Updated..!", "success").then((swalres)=>{
                //     this.fetchorthoneuradataformb();
                // });
                this.setState({nextbutons:true})
            }
        })
    }

    savetabeldatab = (tabledataonb) =>{
        axios.post("https://clinic.dnyanasha.com/ws/consultation.php",tabledataonb).then((res)=>{
            console.log(res.data);
        })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            console.log(event.target.name+" = "+event.target.value + " = " + event.target.checked);
            if (event.target.checked === true) {
                this.setState({ [name]: "1" })
            } else {
                this.setState({ [name]: "" })
            }
        } else {
            this.setState({ [name]: value })
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        // .header, .header-space, .footer, .footer-space {
        document.body.innerHTML = `
                                    <style>
                                        .header, .footer {
                                            height: 100px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                        }
                                        .header img{
                                            width: 100%;
                                            height: 100px;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                        }
                                        .footer img {
                                            width: 100%;
                                            height: 100px;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #d2d2d2;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
                                        // <div class="footer"><img src='${Printfooter}' /> </div>
                                        // <div class="header"><img src='${Printheader}' /></div>
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 10)
    }

    orthoneuroforma = () => {
        // window.open("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroform/"+this.props.match.params.appid, '','width=600,left=200,top=200');
        // this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroform/forma");///"+this.props.match.params.appid);
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroform/"+this.props.match.params.appid);
    }

    presecription = () =>{
        // this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/prescription");
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/viewaddprescription/"+this.props.match.params.appid);
    }
    
    opentreatment = () =>{
        // this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/treatmentplans");
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/addtreatment/"+this.props.match.params.appid);
    }

    handleTableChange = (e) =>{
        this.setState({[e.target.name]:e.target.checked})
    }

    render() {
        const { fexamblue, sexamred, texamblack } = this.state;
        const inputColor = this.determineInputColor();
        return(
                <>
                    <Headerpd puid={this.props.match.params.puid} />
                    <Sidebarpd puid={this.props.match.params.puid} />
                    <div style={{ marginLeft: "220px", padding: "5px" }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                            <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                        </div>
                        <hr />
                        <form onSubmit={this.handleSubmit}>
                            <div id="printablediv">
                                <div className="d-flex justify-content-center"><img src={coreconceptlogo} style={{width: "400px",height:"120px"}} /></div>
                                    <hr style={{opacity: "1",borderTop: "2.5px solid",marginBottom:"1px"}} />
                                    <h4 className="text-center mt-2">ORTHOPEDIC/NEUROLOGICAL ASSESSMENT <br/> (LUMBROSACRAL)</h4>
                                    <p className="text-end m-0">No.: <input type={"text"} style={{border:0}} /> </p>

                                    <div className="row" style={{fontSize:"12px"}}>
                                        <div style={{width:"75%"}}><p className="mb-1">Name : <b>{this.state.pname}</b></p></div>
                                        <div style={{width:"25%"}}><p className="mb-1">Date : <b>{new Date().getDate()} {this.state.monthname[new Date().getMonth()]} {new Date().getFullYear()}</b></p></div>
                                        <div style={{width:"33%",display:"flex"}}>Height : <input type={"number"} min={1} name="height" value={this.state.height} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="form-control h-75 mx-1" style={{width:"70%"}} /></div>
                                        <div style={{width:"33%",display:"flex"}}>Weight : <input type={"number"} min={1} name="weight" value={this.state.weight} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="form-control h-75 mx-1" style={{width:"70%"}} /></div>
                                        <div style={{width:"33%",display:"flex"}}>Blood Pressure Pulse : <input type={"text"} name="bloodpressure" value={this.state.bloodpressure} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className="form-control h-75 mx-1" style={{width:"70%"}} /></div>
                                    </div>
                                    <hr style={{opacity: "1"}} />

                                    <div className="row" style={{fontSize:"12px",fontWeight:"bold"}}>
                                        <div style={{ width:"17%" }}>Doctors Use : </div>
                                        <div style={{ width:"16%" }}>IE</div>
                                        <div style={{ width:"16%" }}>RE</div>
                                        <div style={{ width:"16%" }}>PI</div>
                                        <div style={{ width:"16%" }}>IME</div>
                                        <div style={{ width:"16%" }}>WC</div>
                                    </div>

                                    <div className="row" style={{fontSize:"12px",fontWeight:"bold"}}>
                                        <div style={{ width:"17%" }}></div>
                                        <div style={{ width:"27%" }}>
                                            <input type={"checkbox"} className="form-check-input mx-1" id="fexamblueid" name="fexamblue" checked={fexamblue} onChange={(e) => this.handleCheckboxChange(e.target.name, e.target.checked)} /> <label htmlFor="fexamblueid">1st Exam-Blue</label>
                                        </div>
                                        <div style={{ width:"27%" }}>
                                            <input type={"checkbox"} className="form-check-input mx-1" id="sexamredid" name="sexamred" checked={sexamred} onChange={(e) => this.handleCheckboxChange(e.target.name, e.target.checked)} /> <label htmlFor="sexamredid">2nd Exam-Red</label>
                                        </div>
                                        <div style={{ width:"27%" }}>
                                            <input type={"checkbox"} className="form-check-input mx-1" id="texamblackid" name="texamblack" checked={texamblack} onChange={(e) => this.handleCheckboxChange(e.target.name, e.target.checked)} /> <label htmlFor="texamblackid">3rd Exam-Black</label>
                                        </div>
                                    </div>

                                    {/* <div style={{fontSize:"12px",fontWeight:"bold"}} className="mt-3">
                                        <p className="m-0">Chief Complaints : </p>
                                        <textarea className="w-100 p-0" rows={4} name="chiefcomplaints" value={this.state.chiefcomplaints} onChange={this.handleChange}></textarea>

                                        <p className="m-0">History of Condition (Onset Course & Treatment Prior Occurance) : </p>
                                        <textarea className="w-100 p-0" rows={4} name="historyofconoctpo" value={this.state.historyofconoctpo} onChange={this.handleChange}></textarea>
                                    </div> */}

                                    {/* <div style={{fontSize:"12px"}} className="mt-3">
                                        <p className="m-0"><b>Diurnal Variation : </b></p>
                                        <div className="d-flex justify-content-around">
                                            <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Morning === "1") ? true : false} id="Morningid" name="Morning" value={"Morning"} /> <label htmlFor="Morningid">Morning</label></span>
                                            <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Daytime === "1") ? true : false} id="Daytimeid" name="Daytime" value={"Daytime"} /> <label htmlFor="Daytimeid">Daytime</label></span>
                                            <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Evening === "1") ? true : false} id="Eveningid" name="Evening" value={"Evening"} /> <label htmlFor="Eveningid">Evening</label></span>
                                            <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Night === "1") ? true : false} id="Nightid" name="Night" value={"Night"} /> <label htmlFor="Nightid">Night</label></span>
                                            <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Constant === "1") ? true : false} id="Constantid" name="Constant" value={"Constant"} /> <label htmlFor="Constantid">Constant</label></span>
                                            <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Intermittent === "1") ? true : false} id="Intermittentid" name="Intermittent" value={"Intermittent"} /> <label htmlFor="Intermittentid">Intermittent</label></span>
                                        </div>
                                    </div> */}

                                    {/* <div style={{fontSize:"12px"}} className="mt-3">
                                        <p className="m-0 mt-2"><b>Medical History : </b> <input type={"text"} name="medicalhistory" value={this.state.medicalhistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"85%"}} /> </p>
                                        <p className="m-0 mt-2"><b>Past History : </b> <input type={"text"} name="pasthistory" value={this.state.pasthistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"87%"}} /> </p>
                                        <p className="m-0 mt-2"><b>Any Previous Same Episode : </b> <input type={"text"} name="anyprevioussameepis" value={this.state.anyprevioussameepis} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"79%"}} /> </p>
                                        <p className="m-0 mt-2"><b>Surgical History : </b> <input type={"text"} name="surgicalhistory" value={this.state.surgicalhistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"85%"}} /> </p>
                                        <p className="m-0 mt-2"><b>Treatment / Therapy History : </b> <input type={"text"} name="treattherapyhistory" value={this.state.treattherapyhistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"78%"}} /> </p>
                                        <p className="m-0 mt-2"><b>Family History : </b> <input type={"text"} name="familyhistory" value={this.state.familyhistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"86%"}} /> </p>
                                    </div>

                                    <div style={{fontSize:"12px"}} className="mt-4">
                                        <p><b>Select a number from 0 to 10 to describe your intensity / severity of pain : </b></p>
                                        <div>
                                            <div className="d-flex justify-content-between" style={{width:"90%"}}><p>No Pain</p><p>Unbearable Pain</p></div>
                                            <input type={"range"} min={0} max={10} step={1}  value={this.state.descintensityofpain} name="descintensityofpain" style={{width:"90%"}} />
                                            <div className="d-flex justify-content-between" style={{width:"90%"}}><p>0</p><p>|</p><p>|</p><p>|</p><p>|</p><p>5</p><p>|</p><p>|</p><p>|</p><p>|</p><p>10</p></div>
                                        </div>
                                    </div> */}

                                    {/* <div style={{fontSize:"12px"}} className="mt-4 d-flex">
                                        <div style={{width:"50%"}}>
                                            <p><b>Type of Pain : </b></p>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Aching === "1") ? true : false} id="Achingid" name="Aching" value={"Aching"} /><label htmlFor="Achingid">Aching</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Heavy === "1") ? true : false} id="Heavyid" name="Heavy" value={"Heavy"} /><label htmlFor="Heavyid">Heavy</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Burning === "1") ? true : false} id="Burningid" name="Burning" value={"Burning"} /><label htmlFor="Burningid">Burning</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Itching === "1") ? true : false} id="Itchingid" name="Itching" value={"Itching"} /><label htmlFor="Itchingid">Itching</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cramping === "1") ? true : false} id="Crampingid" name="Cramping" value={"Cramping"} /><label htmlFor="Crampingid">Cramping</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sharp === "1") ? true : false} id="Sharpid" name="Sharp" value={"Sharp"} /><label htmlFor="Sharpid">Sharp</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Deep === "1") ? true : false} id="Deepid" name="Deep" value={"Deep"} /><label htmlFor="Deepid">Deep</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Shooting === "1") ? true : false} id="Shootingid" name="Shooting" value={"Shooting"} /><label htmlFor="Shootingid">Shooting</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Dull === "1") ? true : false} id="Dullid" name="Dull" value={"Dull"} /><label htmlFor="Dullid">Dull</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Throbbing === "1") ? true : false} id="Throbbingid" name="Throbbing" value={"Throbbing"} /><label htmlFor="Throbbingid">Throbbing</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Tingling === "1") ? true : false} id="Tinglingid" name="Tingling" value={"Tingling"} /><label htmlFor="Tinglingid">Tingling</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Getting_Longer === "1") ? true : false} id="Getting_Longerid" name="Getting_Longer" value={"Getting_Longer"} /><label htmlFor="Getting_Longerid">Getting Longer</label></p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Getting_Shorter === "1") ? true : false} id="Getting_Shorterid" name="Getting_Shorter" value={"Getting_Shorter"} /><label htmlFor="Getting_Shorterid">Getting Shorter</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Morning_Stiffness === "1") ? true : false} id="Morning_Stiffnessid" name="Morning_Stiffness" value={"Morning_Stiffness"} /><label htmlFor="Morning_Stiffnessid">Morning Stiffness</label></p>
                                            </div>
                                        </div>
                                        <div style={{width:"50%"}}>
                                            <p><b>Aggravating Factors </b>(What makes your pain worse) : </p>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Activity === "1") ? true : false} id="Activityid" name="Activity" value={"Activity"} /><label htmlFor="Activityid">Activity</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Squatting === "1") ? true : false} id="Squattingid" name="Squatting" value={"Squatting"} /><label htmlFor="Squattingid">Squatting</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Traveling === "1") ? true : false} id="Travelingid" name="Traveling" value={"Traveling"} /><label htmlFor="Travelingid">Traveling</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bending === "1") ? true : false} id="Bendingid" name="Bending" value={"Bending"} /><label htmlFor="Bendingid">Bending</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lifting === "1") ? true : false} id="Liftingid" name="Lifting" value={"Lifting"} /><label htmlFor="Liftingid">Lifting</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Rest === "1") ? true : false} id="Restid" name="Rest" value={"Rest"} /><label htmlFor="Restid">Rest</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Prolonged_Sitting === "1") ? true : false} id="Prolonged_Sittingid" name="Prolonged_Sitting" value={"Prolonged_Sitting"} /><label htmlFor="Prolonged_Sittingid">Prolonged Sitting</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Motion === "1") ? true : false} id="Motionid" name="Motion" value={"Motion"} /><label htmlFor="Motionid">Motion</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Working === "1") ? true : false} id="Workingid" name="Working" value={"Working"} /><label htmlFor="Workingid">Working</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Walking === "1") ? true : false} id="Walkingid" name="Walking" value={"Walking"} /><label htmlFor="Walkingid">Walking</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Climbing_Stairs === "1") ? true : false} id="Climbing_Stairsid" name="Climbing_Stairs" value={"Climbing_Stairs"} /><label htmlFor="Climbing_Stairsid">Climbing Stairs</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bowel_Movements === "1") ? true : false} id="Bowel_Movementsid" name="Bowel_Movements" value={"Bowel_Movements"} /><label htmlFor="Bowel_Movementsid">Bowel Movements</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Exercising === "1") ? true : false} id="Exercisingid" name="Exercising" value={"Exercising"} /><label htmlFor="Exercisingid">Exercising</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lying_Down === "1") ? true : false} id="Lying_Downid" name="Lying_Down" value={"Lying_Down"} /><label htmlFor="Lying_Downid">Lying Down</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Twisting === "1") ? true : false} id="Twistingid" name="Twisting" value={"Twisting"} /><label htmlFor="Twistingid">Twisting</label></p>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div style={{fontSize:"12px"}} className="mt-4 d-flex">
                                        <div style={{width:"50%"}}>
                                            <p><b>Relieing Factors </b>(What makes your pain better) : </p>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Rest === "1") ? true : false } id="Restid" name="Rest" value={"Rest"} /><label htmlFor="Restid">Rest</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Changing_Position === "1") ? true : false } id="Changing_Positionid" name="Changing_Position" value={"Changing_Position"} /><label htmlFor="Changing_Positionid">Changing Position</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Activity === "1") ? true : false } id="Activityid" name="Activity" value={"Activity"} /><label htmlFor="Activityid">Activity</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bending === "1") ? true : false } id="Bendingid" name="Bending" value={"Bending"} /><label htmlFor="Bendingid">Bending</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Exercises === "1") ? true : false } id="Exercisesid" name="Exercises" value={"Exercises"} /><label htmlFor="Exercisesid">Exercises</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Ice_Packs === "1") ? true : false } id="Ice_Packsid" name="Ice_Packs" value={"Ice_Packs"} /><label htmlFor="Ice_Packsid">Ice Packs</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Medication === "1") ? true : false } id="Medicationid" name="Medication" value={"Medication"} /><label htmlFor="Medicationid">Medication</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Hot_Packs === "1") ? true : false } id="Hot_Packsid" name="Hot_Packs" value={"Hot_Packs"} /><label htmlFor="Hot_Packsid">Hot Packs</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sitting_Standing === "1") ? true : false } id="Sitting_Standingid" name="Sitting_Standing" value={"Sitting_Standing"} /><label htmlFor="Sitting_Standingid">Sitting/Standing</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Using_Brace === "1") ? true : false } id="Using_Braceid" name="Using_Brace" value={"Using_Brace"} /><label htmlFor="Using_Braceid">Using Brace</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lying_Down === "1") ? true : false } id="Lying_Downid" name="Lying_Down" value={"Lying_Down"} /><label htmlFor="Lying_Downid">Lying Down</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Alcohol === "1") ? true : false } id="Alcoholid" name="Alcohol" value={"Alcohol"} /><label htmlFor="Alcoholid">Alcohol</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Stretching === "1") ? true : false } id="Stretchingid" name="Stretching" value={"Stretching"} /><label htmlFor="Stretchingid">Stretching</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Relaxation_Therapy === "1") ? true : false } id="Relaxation_Therapyid" name="Relaxation_Therapy" value={"Relaxation_Therapy"} /><label htmlFor="Relaxation_Therapyid">Relaxation Therapy</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Others === "1") ? true : false } id="Othersid" name="Others" value={"Others"} /><label htmlFor="Othersid">Others</label></p>
                                            </div>
                                        </div>
                                        <div style={{width:"50%"}}>
                                            <p><b>Job Profile </b>(Characteristics and Brief Description) : </p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><textarea name="workmech" value={this.state.workmech} onChange={this.handleChange}></textarea></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100">Work : Mechanical Stresses</p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><textarea name="leisuremech" value={this.state.leisuremech} onChange={this.handleChange}></textarea></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100">Leisure : Mechanical Stresses</p>
                                        </div>
                                    </div> */}

                                    {/* <div style={{fontSize:"12px"}} className="mt-4 d-flex">
                                        <div style={{width:"50%"}}>
                                            <p><b>Personal History : </b></p>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Sleep : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.sleep_Normal === "1") ? true : false} id="sleep_Normalid" name="sleep_Normal" value={"sleep_Normal"} /><label htmlFor="sleep_Normalid">Normal</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Disturbed === "1") ? true : false} id="Disturbedid" name="Disturbed" value={"Disturbed"} /><label htmlFor="Disturbedid">Disturbed</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Appetite : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Appetite_Normal === "1") ? true : false} id="Appetite_Normalid" name="Appetite_Normal" value={"Appetite_Normal"} /><label htmlFor="Appetite_Normalid">Normal</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Appetite_Reduced === "1") ? true : false} id="Appetite_Reducedid" name="Appetite_Reduced" value={"Appetite_Reduced"} /><label htmlFor="Appetite_Reducedid">Reduced</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Bowel : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bowel_Normal === "1") ? true : false} id="Bowel_Normalid" name="Bowel_Normal" value={"Bowel_Normal"} /><label htmlFor="Bowel_Normalid">Normal</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bowel_Disturbed === "1") ? true : false} id="Bowel_Disturbedid" name="Bowel_Disturbed" value={"Bowel_Disturbed"} /><label htmlFor="Bowel_Disturbedid">Disturbed</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Bladder : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bladder_Normal === "1") ? true : false} id="Bladder_Normalid" name="Bladder_Normal" value={"Bladder_Normal"} /><label htmlFor="Bladder_Normalid">Normal</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bladder_Disturbed === "1") ? true : false} id="Bladder_Disturbedid" name="Bladder_Disturbed" value={"Bladder_Disturbed"} /><label htmlFor="Bladder_Disturbedid">Disturbed</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Sleeping Position : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.sleepp_Prone === "1") ? true : false} id="sleepp_Proneid" name="sleepp_Prone" value={"sleepp_Prone"} /><label htmlFor="sleepp_Proneid">Prone</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.sleepp_Supine === "1") ? true : false} id="sleepp_Supineid" name="sleepp_Supine" value={"sleepp_Supine"} /><label htmlFor="sleepp_Supineid">Supine</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.sleepp_Side_lying === "1") ? true : false} id="sleepp_Side_lyingid" name="sleepp_Side_lying" value={"sleepp_Side_lying"} /><label htmlFor="sleepp_Side_lyingid">Side lying R/L</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Surface : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Surface_Firm === "1") ? true : false} id="Surface_Firmid" name="Surface_Firm" value={"Surface_Firm"} /><label htmlFor="Surface_Firmid">Firm</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Surface_Soft === "1") ? true : false} id="Surface_Softid" name="Surface_Soft" value={"Surface_Soft"} /><label htmlFor="Surface_Softid">Soft</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Surface_Stag === "1") ? true : false} id="Surface_Stagid" name="Surface_Stag" value={"Surface_Stag"} /><label htmlFor="Surface_Stagid">Stag</label></p>
                                            </div>
                                        </div>
                                        <div style={{width:"50%"}} className="d-flex">
                                            <div style={{width:"50%"}}>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Personal Habits : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Smoking === "1") ? true : false } id="Smokingid" name="Smoking" value={"Smoking"} /><label htmlFor="Smokingid">Smoking</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.phAlcohol === "1") ? true : false } id="phAlcoholid" name="phAlcohol" value={"phAlcohol"} /><label htmlFor="phAlcoholid">Alcohol</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Tobacco_Chewing === "1") ? true : false } id="Tobacco_Chewingid" name="Tobacco_Chewing" value={"Tobacco_Chewing"} /><label htmlFor="Tobacco_Chewingid">Tobacco Chewing/Use</label></p>
                                            </div>
                                            <div style={{width:"50%"}}>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Vechile You Drive : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Four_Wheeler === "1") ? true : false } id="Four_Wheelerid" name="Four_Wheeler" value={"Four_Wheeler"} /><label htmlFor="Four_Wheelerid">Four Wheeler</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Two_Wheeler === "1") ? true : false } id="Two_Wheelerid" name="Two_Wheeler" value={"Two_Wheeler"} /><label htmlFor="Two_Wheelerid">Two Wheeler</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.vydTraveling === "1") ? true : false } id="vydTravelingid" name="vydTraveling" value={"vydTraveling"} /><label htmlFor="vydTravelingid">Traveling</label></p>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div style={{fontSize:"12px"}} className="mt-4">
                                        <p className="m-0 mt-2"><b>Psycho - Social History : </b> <input type={"text"} name="psychosochis" value={this.state.psychosochis} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"80%"}} /> </p>
                                        <p className="m-0 mt-2"><b>Drugs & Chemicals : </b> <input type={"text"} name="drugsachecm" value={this.state.drugsachecm} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"82%"}} /> </p>
                                        <p className="m-0 mt-2"><b>Home Hobby Recreational or Occupational Factors : </b> <input type={"text"} name="homehubbyrecoccfac" value={this.state.homehubbyrecoccfac} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"65%"}} /> </p>
                                        <p className="m-0 mt-2"><b>Secondary Complaints : </b> <input type={"text"} name="secondcompl" value={this.state.secondcompl} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"80%"}} /> </p>
                                    </div> */}

                                    {/* <p className="m-0 mt-4"><b>POSTURE </b></p>
                                    <div style={{fontSize:"12px"}} className="d-flex">
                                        <div style={{width:"50%"}}>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Sitting : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sitting_Good === "1") ? true : false} id="Sitting_Goodid" name="Sitting_Good" value={"Sitting_Good"} /><label htmlFor="Sitting_Goodid">Good</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sitting_Fair === "1") ? true : false} id="Sitting_Fairid" name="Sitting_Fair" value={"Sitting_Fair"} /><label htmlFor="Sitting_Fairid">Fair</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sitting_Poor === "1") ? true : false} id="Sitting_Poorid" name="Sitting_Poor" value={"Sitting_Poor"} /><label htmlFor="Sitting_Poorid">Poor</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Lordosis (Cervical) : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cervical_Red === "1") ? true : false} id="Cervical_Redid" name="Cervical_Red" value={"Cervical_Red"} /><label htmlFor="Cervical_Redid">Red</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cervical_Acc === "1") ? true : false} id="Cervical_Accid" name="Cervical_Acc" value={"Cervical_Acc"} /><label htmlFor="Cervical_Accid">Acc</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cervical_Normal === "1") ? true : false} id="Cervical_Normalid" name="Cervical_Normal" value={"Cervical_Normal"} /><label htmlFor="Cervical_Normalid">Normal</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Lateral Shift : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Laterals_Right === "1") ? true : false} id="Laterals_Rightid" name="Laterals_Right" value={"Laterals_Right"} /><label htmlFor="Laterals_Rightid">Right</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Laterals_Left === "1") ? true : false} id="Laterals_Leftid" name="Laterals_Left" value={"Laterals_Left"} /><label htmlFor="Laterals_Leftid">Left</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Laterals_Nill === "1") ? true : false} id="Laterals_Nillid" name="Laterals_Nill" value={"Laterals_Nill"} /><label htmlFor="Laterals_Nillid">Nill</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Correction of Posture : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.cop_Better === "1") ? true : false} id="cop_Betterid" name="cop_Better" value={"cop_Better"} /><label htmlFor="cop_Betterid">Better</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.cop_Worse === "1") ? true : false} id="cop_Worseid" name="cop_Worse" value={"cop_Worse"} /><label htmlFor="cop_Worseid">Worse</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.cop_no_effect === "1") ? true : false} id="cop_no_effectid" name="cop_no_effect" value={"cop_no_effect"} /><label htmlFor="cop_no_effectid">No Effect</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Revelant : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} id="revelant_Yesid" name="revelant_Yes" value={"revelant_Yes"} /><label htmlFor="revelant_Yesid">Yes</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} id="revelant_Noid" name="revelant_No" value={"revelant_No"} /><label htmlFor="revelant_Noid">No</label></p>
                                            </div>
                                        </div>
                                        <div style={{width:"50%"}}>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Standing : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Standing_Good === "1") ? true : false } id="Standing_Goodid" name="Standing_Good" value={"Standing_Good"} /><label htmlFor="Standing_Goodid">Good</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Standing_Fair === "1") ? true : false } id="Standing_Fairid" name="Standing_Fair" value={"Standing_Fair"} /><label htmlFor="Standing_Fairid">Fair</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Standing_Poor === "1") ? true : false } id="Standing_Poorid" name="Standing_Poor" value={"Standing_Poor"} /><label htmlFor="Standing_Poorid">Poor</label></p>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Lordosis (Lumbar) : </b></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lumbar_Red === "1") ? true : false } id="Lumbar_Redid" name="Lumbar_Red" value={"Lumbar_Red"} /><label htmlFor="Lumbar_Redid">Red</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lumbar_Acc === "1") ? true : false } id="Lumbar_Accid" name="Lumbar_Acc" value={"Lumbar_Acc"} /><label htmlFor="Lumbar_Accid">Acc</label></p>
                                                <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lumbar_Normal === "1") ? true : false } id="Lumbar_Normalid" name="Lumbar_Normal" value={"Lumbar_Normal"} /><label htmlFor="Lumbar_Normalid">Normal</label></p>
                                            </div>
                                        </div>
                                    </div> */}
                                    

                                    <div className="p-0 mt-4 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="p-0 " style={{ width:"48%" }}>
                                            <div className="row m-0" style={{height:"92.5%"}}>
                                                    <div style={{width:"4%",padding:"0",display:"flex",alignItems:"center",background:"#000"}}>
                                                        <img src={lumbrosacraltext} style={{width:"100%"}} />
                                                    </div>
                                                    <div style={{width:"96%",padding:"0",paddingBottom:"5px",border: "1px solid #000"}}>
                                                        <p style={{fontSize:"12px",margin:"0",textAlign:"center"}}><b>1. PALPATION/MUSCLE SPASM/EDEMA/PERCUSSION:</b></p>
                                                        <p style={{fontSize:"12px",margin:"0",textAlign:"center",lineHeight:"1"}}>SPINAL EXAMINATION AND ANALYSIS </p>

                                                        <table style={{fontSize:"10.4px",marginTop:"2px",borderTop:"1.5px solid"}} className="table orthotable">
                                                            <tr style={{textAlign:"center"}}>
                                                                <td colSpan={2}>Spinous <br/>Percussion</td>
                                                                <td colSpan={3}>Tenderness </td>
                                                                <td colSpan={3}>Para-Vartebral <br/>Muscle Spsam </td>
                                                                <td colSpan={3}>Edema </td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>Level</td><td></td><td>Level</td><td>Left</td><td>Right</td><td>Level</td><td>Left</td><td>Right</td><td>Level</td><td>Left</td><td>Right</td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>OC</td><td><input type={"checkbox"} name="table_spoc" checked={(this.state.table_spoc === true) ? true : false} value={this.state.table_spoc} onChange={this.handleTableChange} /></td>
                                                                <td>OC</td><td><input type={"checkbox"} name="table_tdloc" checked={(this.state.table_tdloc === true) ? true : false} value={this.state.table_tdloc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdroc" checked={(this.state.table_tdroc === true) ? true : false} value={this.state.table_tdroc} onChange={this.handleTableChange} /></td>
                                                                <td>OC</td><td><input type={"checkbox"} name="table_pvmsloc" checked={(this.state.table_pvmsloc === true) ? true : false} value={this.state.table_pvmsloc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsroc" checked={(this.state.table_pvmsroc === true) ? true : false} value={this.state.table_pvmsroc} onChange={this.handleTableChange} /></td>
                                                                <td>OC</td><td><input type={"checkbox"} name="table_edloc" checked={(this.state.table_edloc === true) ? true : false} value={this.state.table_edloc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edroc" checked={(this.state.table_edroc === true) ? true : false} value={this.state.table_edroc} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>AT</td><td><input type={"checkbox"} name="table_spat" checked={(this.state.table_spat === true) ? true : false} value={this.state.table_spat} onChange={this.handleTableChange} /></td>
                                                                <td>AT</td><td><input type={"checkbox"} name="table_tdlat" checked={(this.state.table_tdlat === true) ? true : false} value={this.state.table_tdlat} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrat" checked={(this.state.table_tdrat === true) ? true : false} value={this.state.table_tdrat} onChange={this.handleTableChange} /></td>
                                                                <td>AT</td><td><input type={"checkbox"} name="table_pvmslat" checked={(this.state.table_pvmslat === true) ? true : false} value={this.state.table_pvmslat} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrat" checked={(this.state.table_pvmsrat === true) ? true : false} value={this.state.table_pvmsrat} onChange={this.handleTableChange} /></td>
                                                                <td>AT</td><td><input type={"checkbox"} name="table_edlat" checked={(this.state.table_edlat === true) ? true : false} value={this.state.table_edlat} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrat" checked={(this.state.table_edrat === true) ? true : false} value={this.state.table_edrat} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>AX</td><td><input type={"checkbox"} name="table_spax" checked={(this.state.table_spax === true) ? true : false} value={this.state.table_spax} onChange={this.handleTableChange} /></td>
                                                                <td>AX</td><td><input type={"checkbox"} name="table_tdlax" checked={(this.state.table_tdlax === true) ? true : false} value={this.state.table_tdlax} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrax" checked={(this.state.table_tdrax === true) ? true : false} value={this.state.table_tdrax} onChange={this.handleTableChange} /></td>
                                                                <td>AX</td><td><input type={"checkbox"} name="table_pvmslax" checked={(this.state.table_pvmslax === true) ? true : false} value={this.state.table_pvmslax} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrax" checked={(this.state.table_pvmsrax === true) ? true : false} value={this.state.table_pvmsrax} onChange={this.handleTableChange} /></td>
                                                                <td>AX</td><td><input type={"checkbox"} name="table_edlax" checked={(this.state.table_edlax === true) ? true : false} value={this.state.table_edlax} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrax" checked={(this.state.table_edrax === true) ? true : false} value={this.state.table_edrax} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>3C</td><td><input type={"checkbox"} name="table_sp3c" checked={(this.state.table_sp3c === true) ? true : false} value={this.state.table_sp3c} onChange={this.handleTableChange} /></td>
                                                                <td>3C</td><td><input type={"checkbox"} name="table_tdl3c" checked={(this.state.table_tdl3c === true) ? true : false} value={this.state.table_tdl3c} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr3c" checked={(this.state.table_tdr3c === true) ? true : false} value={this.state.table_tdr3c} onChange={this.handleTableChange} /></td>
                                                                <td>3C</td><td><input type={"checkbox"} name="table_pvmsl3c" checked={(this.state.table_pvmsl3c === true) ? true : false} value={this.state.table_pvmsl3c} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr3c" checked={(this.state.table_pvmsr3c === true) ? true : false} value={this.state.table_pvmsr3c} onChange={this.handleTableChange} /></td>
                                                                <td>3C</td><td><input type={"checkbox"} name="table_edl3c" checked={(this.state.table_edl3c === true) ? true : false} value={this.state.table_edl3c} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr3c" checked={(this.state.table_edr3c === true) ? true : false} value={this.state.table_edr3c} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>4C</td><td><input type={"checkbox"} name="table_sp4C" checked={(this.state.table_sp4C === true) ? true : false} value={this.state.table_sp4C} onChange={this.handleTableChange} /></td>
                                                                <td>4C</td><td><input type={"checkbox"} name="table_tdl4C" checked={(this.state.table_tdl4C === true) ? true : false} value={this.state.table_tdl4C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr4C" checked={(this.state.table_tdr4C === true) ? true : false} value={this.state.table_tdr4C} onChange={this.handleTableChange} /></td>
                                                                <td>4C</td><td><input type={"checkbox"} name="table_pvmsl4C" checked={(this.state.table_pvmsl4C === true) ? true : false} value={this.state.table_pvmsl4C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr4C" checked={(this.state.table_pvmsr4C === true) ? true : false} value={this.state.table_pvmsr4C} onChange={this.handleTableChange} /></td>
                                                                <td>4C</td><td><input type={"checkbox"} name="table_edl4C" checked={(this.state.table_edl4C === true) ? true : false} value={this.state.table_edl4C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr4C" checked={(this.state.table_edr4C === true) ? true : false} value={this.state.table_edr4C} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>5C</td><td><input type={"checkbox"} name="table_sp5C" checked={(this.state.table_sp5C === true) ? true : false} value={this.state.table_sp5C} onChange={this.handleTableChange} /></td>
                                                                <td>5C</td><td><input type={"checkbox"} name="table_tdl5C" checked={(this.state.table_tdl5C === true) ? true : false} value={this.state.table_tdl5C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr5C" checked={(this.state.table_tdr5C === true) ? true : false} value={this.state.table_tdr5C} onChange={this.handleTableChange} /></td>
                                                                <td>5C</td><td><input type={"checkbox"} name="table_pvmsl5C" checked={(this.state.table_pvmsl5C === true) ? true : false} value={this.state.table_pvmsl5C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr5C" checked={(this.state.table_pvmsr5C === true) ? true : false} value={this.state.table_pvmsr5C} onChange={this.handleTableChange} /></td>
                                                                <td>5C</td><td><input type={"checkbox"} name="table_edl5C" checked={(this.state.table_edl5C === true) ? true : false} value={this.state.table_edl5C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr5C" checked={(this.state.table_edr5C === true) ? true : false} value={this.state.table_edr5C} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>6C</td><td><input type={"checkbox"} name="table_sp6C" checked={(this.state.table_sp6C === true) ? true : false} value={this.state.table_sp6C} onChange={this.handleTableChange} /></td>
                                                                <td>6C</td><td><input type={"checkbox"} name="table_tdl6C" checked={(this.state.table_tdl6C === true) ? true : false} value={this.state.table_tdl6C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr6C" checked={(this.state.table_tdr6C === true) ? true : false} value={this.state.table_tdr6C} onChange={this.handleTableChange} /></td>
                                                                <td>6C</td><td><input type={"checkbox"} name="table_pvmsl6C" checked={(this.state.table_pvmsl6C === true) ? true : false} value={this.state.table_pvmsl6C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr6C" checked={(this.state.table_pvmsr6C === true) ? true : false} value={this.state.table_pvmsr6C} onChange={this.handleTableChange} /></td>
                                                                <td>6C</td><td><input type={"checkbox"} name="table_edl6C" checked={(this.state.table_edl6C === true) ? true : false} value={this.state.table_edl6C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr6C" checked={(this.state.table_edr6C === true) ? true : false} value={this.state.table_edr6C} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>7C</td><td><input type={"checkbox"} name="table_sp7C" checked={(this.state.table_sp7C === true) ? true : false} value={this.state.table_sp7C} onChange={this.handleTableChange} /></td>
                                                                <td>7C</td><td><input type={"checkbox"} name="table_tdl7C" checked={(this.state.table_tdl7C === true) ? true : false} value={this.state.table_tdl7C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr7C" checked={(this.state.table_tdr7C === true) ? true : false} value={this.state.table_tdr7C} onChange={this.handleTableChange} /></td>
                                                                <td>7C</td><td><input type={"checkbox"} name="table_pvmsl7C" checked={(this.state.table_pvmsl7C === true) ? true : false} value={this.state.table_pvmsl7C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr7C" checked={(this.state.table_pvmsr7C === true) ? true : false} value={this.state.table_pvmsr7C} onChange={this.handleTableChange} /></td>
                                                                <td>7C</td><td><input type={"checkbox"} name="table_edl7C" checked={(this.state.table_edl7C === true) ? true : false} value={this.state.table_edl7C} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr7C" checked={(this.state.table_edr7C === true) ? true : false} value={this.state.table_edr7C} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>1T</td><td><input type={"checkbox"} name="table_sp1T" checked={(this.state.table_sp1T === true) ? true : false} value={this.state.table_sp1T} onChange={this.handleTableChange} /></td>
                                                                <td>1T</td><td><input type={"checkbox"} name="table_tdl1T" checked={(this.state.table_tdl1T === true) ? true : false} value={this.state.table_tdl1T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr1T" checked={(this.state.table_tdr1T === true) ? true : false} value={this.state.table_tdr1T} onChange={this.handleTableChange} /></td>
                                                                <td>1T</td><td><input type={"checkbox"} name="table_pvmsl1T" checked={(this.state.table_pvmsl1T === true) ? true : false} value={this.state.table_pvmsl1T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr1T" checked={(this.state.table_pvmsr1T === true) ? true : false} value={this.state.table_pvmsr1T} onChange={this.handleTableChange} /></td>
                                                                <td>1T</td><td><input type={"checkbox"} name="table_edl1T" checked={(this.state.table_edl1T === true) ? true : false} value={this.state.table_edl1T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr1T" checked={(this.state.table_edr1T === true) ? true : false} value={this.state.table_edr1T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>2T</td><td><input type={"checkbox"} name="table_sp2T" checked={(this.state.table_sp2T === true) ? true : false} value={this.state.table_sp2T} onChange={this.handleTableChange} /></td>
                                                                <td>2T</td><td><input type={"checkbox"} name="table_tdl2T" checked={(this.state.table_tdl2T === true) ? true : false} value={this.state.table_tdl2T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr2T" checked={(this.state.table_tdr2T === true) ? true : false} value={this.state.table_tdr2T} onChange={this.handleTableChange} /></td>
                                                                <td>2T</td><td><input type={"checkbox"} name="table_pvmsl2T" checked={(this.state.table_pvmsl2T === true) ? true : false} value={this.state.table_pvmsl2T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr2T" checked={(this.state.table_pvmsr2T === true) ? true : false} value={this.state.table_pvmsr2T} onChange={this.handleTableChange} /></td>
                                                                <td>2T</td><td><input type={"checkbox"} name="table_edl2T" checked={(this.state.table_edl2T === true) ? true : false} value={this.state.table_edl2T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr2T" checked={(this.state.table_edr2T === true) ? true : false} value={this.state.table_edr2T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>3T</td><td><input type={"checkbox"} name="table_sp3T" checked={(this.state.table_sp3T === true) ? true : false} value={this.state.table_sp3T} onChange={this.handleTableChange} /></td>
                                                                <td>3T</td><td><input type={"checkbox"} name="table_tdl3T" checked={(this.state.table_tdl3T === true) ? true : false} value={this.state.table_tdl3T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr3T" checked={(this.state.table_tdr3T === true) ? true : false} value={this.state.table_tdr3T} onChange={this.handleTableChange} /></td>
                                                                <td>3T</td><td><input type={"checkbox"} name="table_pvmsl3T" checked={(this.state.table_pvmsl3T === true) ? true : false} value={this.state.table_pvmsl3T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr3T" checked={(this.state.table_pvmsr3T === true) ? true : false} value={this.state.table_pvmsr3T} onChange={this.handleTableChange} /></td>
                                                                <td>3T</td><td><input type={"checkbox"} name="table_edl3T" checked={(this.state.table_edl3T === true) ? true : false} value={this.state.table_edl3T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr3T" checked={(this.state.table_edr3T === true) ? true : false} value={this.state.table_edr3T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>4T</td><td><input type={"checkbox"} name="table_sp4T" checked={(this.state.table_sp4T === true) ? true : false} value={this.state.table_sp4T} onChange={this.handleTableChange} /></td>
                                                                <td>4T</td><td><input type={"checkbox"} name="table_tdl4T" checked={(this.state.table_tdl4T === true) ? true : false} value={this.state.table_tdl4T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr4T" checked={(this.state.table_tdr4T === true) ? true : false} value={this.state.table_tdr4T} onChange={this.handleTableChange} /></td>
                                                                <td>4T</td><td><input type={"checkbox"} name="table_pvmsl4T" checked={(this.state.table_pvmsl4T === true) ? true : false} value={this.state.table_pvmsl4T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr4T" checked={(this.state.table_pvmsr4T === true) ? true : false} value={this.state.table_pvmsr4T} onChange={this.handleTableChange} /></td>
                                                                <td>4T</td><td><input type={"checkbox"} name="table_edl4T" checked={(this.state.table_edl4T === true) ? true : false} value={this.state.table_edl4T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr4T" checked={(this.state.table_edr4T === true) ? true : false} value={this.state.table_edr4T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>5T</td><td><input type={"checkbox"} name="table_sp5T" checked={(this.state.table_sp5T === true) ? true : false} value={this.state.table_sp5T} onChange={this.handleTableChange} /></td>
                                                                <td>5T</td><td><input type={"checkbox"} name="table_tdl5T" checked={(this.state.table_tdl5T === true) ? true : false} value={this.state.table_tdl5T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr5T" checked={(this.state.table_tdr5T === true) ? true : false} value={this.state.table_tdr5T} onChange={this.handleTableChange} /></td>
                                                                <td>5T</td><td><input type={"checkbox"} name="table_pvmsl5T" checked={(this.state.table_pvmsl5T === true) ? true : false} value={this.state.table_pvmsl5T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr5T" checked={(this.state.table_pvmsr5T === true) ? true : false} value={this.state.table_pvmsr5T} onChange={this.handleTableChange} /></td>
                                                                <td>5T</td><td><input type={"checkbox"} name="table_edl5T" checked={(this.state.table_edl5T === true) ? true : false} value={this.state.table_edl5T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr5T" checked={(this.state.table_edr5T === true) ? true : false} value={this.state.table_edr5T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>6T</td><td><input type={"checkbox"} name="table_sp6T" checked={(this.state.table_sp6T === true) ? true : false} value={this.state.table_sp6T} onChange={this.handleTableChange} /></td>
                                                                <td>6T</td><td><input type={"checkbox"} name="table_tdl6T" checked={(this.state.table_tdl6T === true) ? true : false} value={this.state.table_tdl6T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr6T" checked={(this.state.table_tdr6T === true) ? true : false} value={this.state.table_tdr6T} onChange={this.handleTableChange} /></td>
                                                                <td>6T</td><td><input type={"checkbox"} name="table_pvmsl6T" checked={(this.state.table_pvmsl6T === true) ? true : false} value={this.state.table_pvmsl6T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr6T" checked={(this.state.table_pvmsr6T === true) ? true : false} value={this.state.table_pvmsr6T} onChange={this.handleTableChange} /></td>
                                                                <td>6T</td><td><input type={"checkbox"} name="table_edl6T" checked={(this.state.table_edl6T === true) ? true : false} value={this.state.table_edl6T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr6T" checked={(this.state.table_edr6T === true) ? true : false} value={this.state.table_edr6T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>7T</td><td><input type={"checkbox"} name="table_sp7T" checked={(this.state.table_sp7T === true) ? true : false} value={this.state.table_sp7T} onChange={this.handleTableChange} /></td>
                                                                <td>7T</td><td><input type={"checkbox"} name="table_tdl7T" checked={(this.state.table_tdl7T === true) ? true : false} value={this.state.table_tdl7T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr7T" checked={(this.state.table_tdr7T === true) ? true : false} value={this.state.table_tdr7T} onChange={this.handleTableChange} /></td>
                                                                <td>7T</td><td><input type={"checkbox"} name="table_pvmsl7T" checked={(this.state.table_pvmsl7T === true) ? true : false} value={this.state.table_pvmsl7T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr7T" checked={(this.state.table_pvmsr7T === true) ? true : false} value={this.state.table_pvmsr7T} onChange={this.handleTableChange} /></td>
                                                                <td>7T</td><td><input type={"checkbox"} name="table_edl7T" checked={(this.state.table_edl7T === true) ? true : false} value={this.state.table_edl7T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr7T" checked={(this.state.table_edr7T === true) ? true : false} value={this.state.table_edr7T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>8T</td><td><input type={"checkbox"} name="table_sp8T" checked={(this.state.table_sp8T === true) ? true : false} value={this.state.table_sp8T} onChange={this.handleTableChange} /></td>
                                                                <td>8T</td><td><input type={"checkbox"} name="table_tdl8T" checked={(this.state.table_tdl8T === true) ? true : false} value={this.state.table_tdl8T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr8T" checked={(this.state.table_tdr8T === true) ? true : false} value={this.state.table_tdr8T} onChange={this.handleTableChange} /></td>
                                                                <td>8T</td><td><input type={"checkbox"} name="table_pvmsl8T" checked={(this.state.table_pvmsl8T === true) ? true : false} value={this.state.table_pvmsl8T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr8T" checked={(this.state.table_pvmsr8T === true) ? true : false} value={this.state.table_pvmsr8T} onChange={this.handleTableChange} /></td>
                                                                <td>8T</td><td><input type={"checkbox"} name="table_edl8T" checked={(this.state.table_edl8T === true) ? true : false} value={this.state.table_edl8T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr8T" checked={(this.state.table_edr8T === true) ? true : false} value={this.state.table_edr8T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>9T</td><td><input type={"checkbox"} name="table_sp9T" checked={(this.state.table_sp9T === true) ? true : false} value={this.state.table_sp9T} onChange={this.handleTableChange} /></td>
                                                                <td>9T</td><td><input type={"checkbox"} name="table_tdl9T" checked={(this.state.table_tdl9T === true) ? true : false} value={this.state.table_tdl9T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr9T" checked={(this.state.table_tdr9T === true) ? true : false} value={this.state.table_tdr9T} onChange={this.handleTableChange} /></td>
                                                                <td>9T</td><td><input type={"checkbox"} name="table_pvmsl9T" checked={(this.state.table_pvmsl9T === true) ? true : false} value={this.state.table_pvmsl9T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr9T" checked={(this.state.table_pvmsr9T === true) ? true : false} value={this.state.table_pvmsr9T} onChange={this.handleTableChange} /></td>
                                                                <td>9T</td><td><input type={"checkbox"} name="table_edl9T" checked={(this.state.table_edl9T === true) ? true : false} value={this.state.table_edl9T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr9T" checked={(this.state.table_edr9T === true) ? true : false} value={this.state.table_edr9T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>10T</td><td><input type={"checkbox"} name="table_sp10T" checked={(this.state.table_sp10T === true) ? true : false} value={this.state.table_sp10T} onChange={this.handleTableChange} /></td>
                                                                <td>10T</td><td><input type={"checkbox"} name="table_tdl10T" checked={(this.state.table_tdl10T === true) ? true : false} value={this.state.table_tdl10T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr10T" checked={(this.state.table_tdr10T === true) ? true : false} value={this.state.table_tdr10T} onChange={this.handleTableChange} /></td>
                                                                <td>10T</td><td><input type={"checkbox"} name="table_pvmsl10T" checked={(this.state.table_pvmsl10T === true) ? true : false} value={this.state.table_pvmsl10T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr10T" checked={(this.state.table_pvmsr10T === true) ? true : false} value={this.state.table_pvmsr10T} onChange={this.handleTableChange} /></td>
                                                                <td>10T</td><td><input type={"checkbox"} name="table_edl10T" checked={(this.state.table_edl10T === true) ? true : false} value={this.state.table_edl10T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr10T" checked={(this.state.table_edr10T === true) ? true : false} value={this.state.table_edr10T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>11T</td><td><input type={"checkbox"} name="table_sp11T" checked={(this.state.table_sp11T === true) ? true : false} value={this.state.table_sp11T} onChange={this.handleTableChange} /></td>
                                                                <td>11T</td><td><input type={"checkbox"} name="table_tdl11T" checked={(this.state.table_tdl11T === true) ? true : false} value={this.state.table_tdl11T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr11T" checked={(this.state.table_tdr11T === true) ? true : false} value={this.state.table_tdr11T} onChange={this.handleTableChange} /></td>
                                                                <td>11T</td><td><input type={"checkbox"} name="table_pvmsl11T" checked={(this.state.table_pvmsl11T === true) ? true : false} value={this.state.table_pvmsl11T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr11T" checked={(this.state.table_pvmsr11T === true) ? true : false} value={this.state.table_pvmsr11T} onChange={this.handleTableChange} /></td>
                                                                <td>11T</td><td><input type={"checkbox"} name="table_edl11T" checked={(this.state.table_edl11T === true) ? true : false} value={this.state.table_edl11T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr11T" checked={(this.state.table_edr11T === true) ? true : false} value={this.state.table_edr11T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>12T</td><td><input type={"checkbox"} name="table_sp12T" checked={(this.state.table_sp12T === true) ? true : false} value={this.state.table_sp12T} onChange={this.handleTableChange} /></td>
                                                                <td>12T</td><td><input type={"checkbox"} name="table_tdl12T" checked={(this.state.table_tdl12T === true) ? true : false} value={this.state.table_tdl12T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr12T" checked={(this.state.table_tdr12T === true) ? true : false} value={this.state.table_tdr12T} onChange={this.handleTableChange} /></td>
                                                                <td>12T</td><td><input type={"checkbox"} name="table_pvmsl12T" checked={(this.state.table_pvmsl12T === true) ? true : false} value={this.state.table_pvmsl12T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr12T" checked={(this.state.table_pvmsr12T === true) ? true : false} value={this.state.table_pvmsr12T} onChange={this.handleTableChange} /></td>
                                                                <td>12T</td><td><input type={"checkbox"} name="table_edl12T" checked={(this.state.table_edl12T === true) ? true : false} value={this.state.table_edl12T} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr12T" checked={(this.state.table_edr12T === true) ? true : false} value={this.state.table_edr12T} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>1L</td><td><input type={"checkbox"} name="table_sp1L" checked={(this.state.table_sp1L === true) ? true : false} value={this.state.table_sp1L} onChange={this.handleTableChange} /></td>
                                                                <td>1L</td><td><input type={"checkbox"} name="table_tdl1L" checked={(this.state.table_tdl1L === true) ? true : false} value={this.state.table_tdl1L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr1L" checked={(this.state.table_tdr1L === true) ? true : false} value={this.state.table_tdr1L} onChange={this.handleTableChange} /></td>
                                                                <td>1L</td><td><input type={"checkbox"} name="table_pvmsl1L" checked={(this.state.table_pvmsl1L === true) ? true : false} value={this.state.table_pvmsl1L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr1L" checked={(this.state.table_pvmsr1L === true) ? true : false} value={this.state.table_pvmsr1L} onChange={this.handleTableChange} /></td>
                                                                <td>1L</td><td><input type={"checkbox"} name="table_edl1L" checked={(this.state.table_edl1L === true) ? true : false} value={this.state.table_edl1L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr1L" checked={(this.state.table_edr1L === true) ? true : false} value={this.state.table_edr1L} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>2L</td><td><input type={"checkbox"} name="table_sp2L" checked={(this.state.table_sp2L === true) ? true : false} value={this.state.table_sp2L} onChange={this.handleTableChange} /></td>
                                                                <td>2L</td><td><input type={"checkbox"} name="table_tdl2L" checked={(this.state.table_tdl2L === true) ? true : false} value={this.state.table_tdl2L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr2L" checked={(this.state.table_tdr2L === true) ? true : false} value={this.state.table_tdr2L} onChange={this.handleTableChange} /></td>
                                                                <td>2L</td><td><input type={"checkbox"} name="table_pvmsl2L" checked={(this.state.table_pvmsl2L === true) ? true : false} value={this.state.table_pvmsl2L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr2L" checked={(this.state.table_pvmsr2L === true) ? true : false} value={this.state.table_pvmsr2L} onChange={this.handleTableChange} /></td>
                                                                <td>2L</td><td><input type={"checkbox"} name="table_edl2L" checked={(this.state.table_edl2L === true) ? true : false} value={this.state.table_edl2L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr2L" checked={(this.state.table_edr2L === true) ? true : false} value={this.state.table_edr2L} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>3L</td><td><input type={"checkbox"} name="table_sp3L" checked={(this.state.table_sp3L === true) ? true : false} value={this.state.table_sp3L} onChange={this.handleTableChange} /></td>
                                                                <td>3L</td><td><input type={"checkbox"} name="table_tdl3L" checked={(this.state.table_tdl3L === true) ? true : false} value={this.state.table_tdl3L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr3L" checked={(this.state.table_tdr3L === true) ? true : false} value={this.state.table_tdr3L} onChange={this.handleTableChange} /></td>
                                                                <td>3L</td><td><input type={"checkbox"} name="table_pvmsl3L" checked={(this.state.table_pvmsl3L === true) ? true : false} value={this.state.table_pvmsl3L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr3L" checked={(this.state.table_pvmsr3L === true) ? true : false} value={this.state.table_pvmsr3L} onChange={this.handleTableChange} /></td>
                                                                <td>3L</td><td><input type={"checkbox"} name="table_edl3L" checked={(this.state.table_edl3L === true) ? true : false} value={this.state.table_edl3L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr3L" checked={(this.state.table_edr3L === true) ? true : false} value={this.state.table_edr3L} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>4L</td><td><input type={"checkbox"} name="table_sp4L" checked={(this.state.table_sp4L === true) ? true : false} value={this.state.table_sp4L} onChange={this.handleTableChange} /></td>
                                                                <td>4L</td><td><input type={"checkbox"} name="table_tdl4L" checked={(this.state.table_tdl4L === true) ? true : false} value={this.state.table_tdl4L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr4L" checked={(this.state.table_tdr4L === true) ? true : false} value={this.state.table_tdr4L} onChange={this.handleTableChange} /></td>
                                                                <td>4L</td><td><input type={"checkbox"} name="table_pvmsl4L" checked={(this.state.table_pvmsl4L === true) ? true : false} value={this.state.table_pvmsl4L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr4L" checked={(this.state.table_pvmsr4L === true) ? true : false} value={this.state.table_pvmsr4L} onChange={this.handleTableChange} /></td>
                                                                <td>4L</td><td><input type={"checkbox"} name="table_edl4L" checked={(this.state.table_edl4L === true) ? true : false} value={this.state.table_edl4L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr4L" checked={(this.state.table_edr4L === true) ? true : false} value={this.state.table_edr4L} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>5L</td><td><input type={"checkbox"} name="table_sp5L" checked={(this.state.table_sp5L === true) ? true : false} value={this.state.table_sp5L} onChange={this.handleTableChange} /></td>
                                                                <td>5L</td><td><input type={"checkbox"} name="table_tdl5L" checked={(this.state.table_tdl5L === true) ? true : false} value={this.state.table_tdl5L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdr5L" checked={(this.state.table_tdr5L === true) ? true : false} value={this.state.table_tdr5L} onChange={this.handleTableChange} /></td>
                                                                <td>5L</td><td><input type={"checkbox"} name="table_pvmsl5L" checked={(this.state.table_pvmsl5L === true) ? true : false} value={this.state.table_pvmsl5L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsr5L" checked={(this.state.table_pvmsr5L === true) ? true : false} value={this.state.table_pvmsr5L} onChange={this.handleTableChange} /></td>
                                                                <td>5L</td><td><input type={"checkbox"} name="table_edl5L" checked={(this.state.table_edl5L === true) ? true : false} value={this.state.table_edl5L} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edr5L" checked={(this.state.table_edr5L === true) ? true : false} value={this.state.table_edr5L} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>Sac</td><td><input type={"checkbox"} name="table_spSac" checked={(this.state.table_spSac === true) ? true : false} value={this.state.table_spSac} onChange={this.handleTableChange} /></td>
                                                                <td>Sac</td><td><input type={"checkbox"} name="table_tdlSac" checked={(this.state.table_tdlSac === true) ? true : false} value={this.state.table_tdlSac} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrSac" checked={(this.state.table_tdrSac === true) ? true : false} value={this.state.table_tdrSac} onChange={this.handleTableChange} /></td>
                                                                <td>Sac</td><td><input type={"checkbox"} name="table_pvmslSac" checked={(this.state.table_pvmslSac === true) ? true : false} value={this.state.table_pvmslSac} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrSac" checked={(this.state.table_pvmsrSac === true) ? true : false} value={this.state.table_pvmsrSac} onChange={this.handleTableChange} /></td>
                                                                <td>Sac</td><td><input type={"checkbox"} name="table_edlSac" checked={(this.state.table_edlSac === true) ? true : false} value={this.state.table_edlSac} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrSac" checked={(this.state.table_edrSac === true) ? true : false} value={this.state.table_edrSac} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>R II</td><td><input type={"checkbox"} name="table_spRII" checked={(this.state.table_spRII === true) ? true : false} value={this.state.table_spRII} onChange={this.handleTableChange} /></td>
                                                                <td>R II</td><td><input type={"checkbox"} name="table_tdlRII" checked={(this.state.table_tdlRII === true) ? true : false} value={this.state.table_tdlRII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrRII" checked={(this.state.table_tdrRII === true) ? true : false} value={this.state.table_tdrRII} onChange={this.handleTableChange} /></td>
                                                                <td>R II</td><td><input type={"checkbox"} name="table_pvmslRII" checked={(this.state.table_pvmslRII === true) ? true : false} value={this.state.table_pvmslRII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrRII" checked={(this.state.table_pvmsrRII === true) ? true : false} value={this.state.table_pvmsrRII} onChange={this.handleTableChange} /></td>
                                                                <td>R II</td><td><input type={"checkbox"} name="table_edlRII" checked={(this.state.table_edlRII === true) ? true : false} value={this.state.table_edlRII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrRII" checked={(this.state.table_edrRII === true) ? true : false} value={this.state.table_edrRII} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>LII</td><td><input type={"checkbox"} name="table_spLII" checked={(this.state.table_spLII === true) ? true : false} value={this.state.table_spLII} onChange={this.handleTableChange} /></td>
                                                                <td>LII</td><td><input type={"checkbox"} name="table_tdlLII" checked={(this.state.table_tdlLII === true) ? true : false} value={this.state.table_tdlLII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrLII" checked={(this.state.table_tdrLII === true) ? true : false} value={this.state.table_tdrLII} onChange={this.handleTableChange} /></td>
                                                                <td>LII</td><td><input type={"checkbox"} name="table_pvmslLII" checked={(this.state.table_pvmslLII === true) ? true : false} value={this.state.table_pvmslLII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrLII" checked={(this.state.table_pvmsrLII === true) ? true : false} value={this.state.table_pvmsrLII} onChange={this.handleTableChange} /></td>
                                                                <td>LII</td><td><input type={"checkbox"} name="table_edlLII" checked={(this.state.table_edlLII === true) ? true : false} value={this.state.table_edlLII} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrLII" checked={(this.state.table_edrLII === true) ? true : false} value={this.state.table_edrLII} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{width:"35px"}}>Coc</td><td><input type={"checkbox"} name="table_spCoc" checked={(this.state.table_spCoc === true) ? true : false} value={this.state.table_spCoc} onChange={this.handleTableChange} /></td>
                                                                <td>Coc</td><td><input type={"checkbox"} name="table_tdlCoc" checked={(this.state.table_tdlCoc === true) ? true : false} value={this.state.table_tdlCoc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_tdrCoc" checked={(this.state.table_tdrCoc === true) ? true : false} value={this.state.table_tdrCoc} onChange={this.handleTableChange} /></td>
                                                                <td>Coc</td><td><input type={"checkbox"} name="table_pvmslCoc" checked={(this.state.table_pvmslCoc === true) ? true : false} value={this.state.table_pvmslCoc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_pvmsrCoc" checked={(this.state.table_pvmsrCoc === true) ? true : false} value={this.state.table_pvmsrCoc} onChange={this.handleTableChange} /></td>
                                                                <td>Coc</td><td><input type={"checkbox"} name="table_edlCoc" checked={(this.state.table_edlCoc === true) ? true : false} value={this.state.table_edlCoc} onChange={this.handleTableChange} /></td><td><input type={"checkbox"} name="table_edrCoc" checked={(this.state.table_edrCoc === true) ? true : false} value={this.state.table_edrCoc} onChange={this.handleTableChange} /></td>
                                                            </tr>
                                                        </table>

                                                    </div>
                                            </div>
                                        </div>

                                        <div style={{ width:"52%" }} className="d-flex">
                                            <div className="pt-1" style={{width:"33%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                                <p className="m-0" style={{}}><b>2. LIBMAN TEST:</b></p>
                                                <div className="d-flex justify-content-center"><img src={libantest} style={{width:"100%"}} /></div>
                                                <p className="m-0 d-flex">POS. <input type={"text"} name="libmantestl" value={this.state.libmantestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="libmantestr" value={this.state.libmantestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                <p className="m-0">test: bilateral pressure on mastoids. <span className="fst-italic">rationale: pain = hypersensitivity</span></p>
                                            </div>
                                            <div className="pt-1" style={{width:"33%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                                <p className="m-0" style={{}}><b>3. O'DONAHUE MANEUVER :</b></p>
                                                <div className="d-flex justify-content-center"><img src={odonahuemaneuvaer} style={{width:"100%"}} /></div>
                                                <p className="m-0 d-flex">POS. <input type={"text"} name="odonahuemanl" value={this.state.odonahuemanl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="odonahuemanr" value={this.state.odonahuemanr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                <p className="m-0"><span className="fst-italic">rationale: isometric = strain <br/>passive = sprain.</span></p>
                                            </div>
                                            <div className="pt-1" style={{width:"33%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                                <p className="m-0" style={{}}><b>4. TRIPOD TEST:</b></p>
                                                <div className="d-flex justify-content-center"><img src={tripodtest} style={{width:"100%"}} /></div>
                                                <p className="m-0 d-flex">POS. <input type={"text"} name="tripodtestl" value={this.state.tripodtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="tripodtestr" value={this.state.tripodtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                <p className="m-0">test: patient seated, extend one leg. <span className="fst-italic"> rationale: positive for sciatic pain. <br/> ** MALINGERING TEST** <br/>AKA: Sitting Lasegue. </span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>5. PATELLAR REFLEX :</b></p>
                                            <div className="d-flex justify-content-center"><img src={patellarreflex} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="patellarreflexl" value={this.state.patellarreflexl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="patellarreflexr" value={this.state.patellarreflexr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: cross leg, strike patellar teriodn. <span className="fst-italic"> rationale: increased or decreased response = L4 nerve. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"45%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <div className="row m-0 mt-2 w-100" style={{fontSize:"12px",fontWeight:"bold" }}>
                                                <div style={{ width:"50%" }}>Antalgic Posture</div>
                                                <div style={{ width:"25%",display:"flex" }} >LA <input type={"text"} name="apla" value={this.state.apla} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> </div> 
                                                <div style={{ width:"25%",display:"flex" }} >RA <input type={"text"} name="apra" value={this.state.apra} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> </div>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="d-flex">
                                                <div style={{width:"48%"}}>
                                                    <p className="m-0" style={{}}><b>6. MINER'S SIGN :</b></p>
                                                    <div className="d-flex justify-content-center"><img src={minersign} style={{width:"100%"}} /></div>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="minersignl" value={this.state.minersignl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="minersignr" value={this.state.minersignr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: observe patient rising from sitting position. <span className="fst-italic"> rationale: weight to good leg = US involve. </span></p>
                                                </div>
                                                <div style={{width:"48%"}}>
                                                    <p className="m-0" style={{}}><b>7. CHEST EXPANSION TEST :</b></p>
                                                    <div className="d-flex justify-content-center"><img src={chestexpansiontest} style={{width:"100%"}} /></div>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="chestexpntestl" value={this.state.chestexpntestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="chestexpntestr" value={this.state.chestexpntestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: measure the circumference of the chest at the nipple line from the point of maximum exhalation to maximum inhalation. <span className="fst-italic"> rationale: difference of less than 1.1/2" = possible Ankylosing Spondylitis. </span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-1" style={{width:"17%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>8. OSTEOPOROSIS SPONDLYO-LISTHESIS LINE :</b></p>
                                            <div className="d-flex justify-content-center"><img src={osteoplistline} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="osteoplistlinel" value={this.state.osteoplistlinel} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: examine back for indentation crossing L5 area <span className="fst-italic">rationale: skin will pucker when L5 moves anterior. </span></p>
                                            <p className="m-0 d-flex">POS.  <input type={"text"} name="osteoplistliner" value={this.state.osteoplistliner} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: examine ant. for line crossing umbilicus.<span className="fst-italic"> rationale: positive = osteoporosis. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"17%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>9. KEMP'S TEST:</b></p>
                                            <div className="d-flex justify-content-center"><img src={kempstest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="kempstestl" value={this.state.kempstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="kempstestr" value={this.state.kempstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: bend patient obliquely backwards.  <span className="fst-italic">rationale: pain = facet syndrome, fracture or disc involvement (lat. disc protrusion = radiating pain on side of test medial disc protrusion = radiating pain opposite side of test). </span></p>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>10. TREDELEN BURG TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={trendelenburgtest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="trendelenburgtestl" value={this.state.trendelenburgtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="trendelenburgtestr" value={this.state.trendelenburgtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: stand on one leg, flex thigh and calf. <span className="fst-italic"> rationale: failure of crest to rise = weak gluteus med. opposite side. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>11. PALPATION OF THE SCIATIC NERVE :</b></p>
                                            <div className="d-flex justify-content-center"><img src={palpascinerve} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="palpascinervel" value={this.state.palpascinervel} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="palpascinerver" value={this.state.palpascinerver} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: flex hip, palpate midpoint of ischial tuberosity & gr. trochanter.<span className="fst-italic">  rationale: pain = pressure of disc herniation, space occupying lesion.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>12. PIRIFORMIS MUSCLE PALPATION :</b></p>
                                            <div className="d-flex justify-content-center"><img src={piriformismuspal} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="piriformismuspall" value={this.state.piriformismuspall} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="piriformismuspalr" value={this.state.piriformismuspalr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: palpate midway between the greater trochanter and sacrum.  <span className="fst-italic"> rationale: contraction may irritate the sciatic nerve.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"40%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>13. DORSOLUMBAR RANGE OF MOTION :</b></p>
                                            <div className="d-flex justify-content-center"><img src={dorsolrangofmot} style={{width:"100%"}} /></div>
                                            
                                            <div className="d-flex">
                                                <div><div className="d-flex">
                                                    <div style={{width:"50%",lineHeight:"1"}}>A. FLEXION <br/>MEAS.<input type={"text"} name="flexmeasa" value={this.state.flexmeasa} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 60°_____{/*<input type={"text"} name="flexnorma" value={this.state.flexnorma} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/}  <br/>LIMIT<input type={"text"} name="flexlimita" value={this.state.flexlimita} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />  <br/>PAIN <input type={"text"} name="flexpaina" value={this.state.flexpaina} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> </div>
                                                    <div style={{width:"50%",lineHeight:"1"}}>B. EXTENSION <br/>MEAS.<input type={"text"} name="extenmeasa" value={this.state.extenmeasa} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 30°_____{/*<input type={"text"} name="extennorma" value={this.state.extennorma} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/}  <br/>LIMIT<input type={"text"} name="extenlimita" value={this.state.extenlimita} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />  <br/>PAIN<input type={"text"} name="extenpaina" value={this.state.extenpaina} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></div>
                                                </div>
                                                <p className="m-0" style={{lineHeight:"1"}}><b>DORSOLUMBAR FLEXION:</b> rationale: limitation may be due to a disc involvement.  </p></div>
                                                <div><div className="d-flex">
                                                    <div style={{width:"50%",lineHeight:"1"}}>A. FLEXION <br/>MEAS.<input type={"text"} name="flexmeasb" value={this.state.flexmeasb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 60°_____{/*<input type={"text"} name="flexnormb" value={this.state.flexnormb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/}  <br/>LIMIT<input type={"text"} name="flexlimitb" value={this.state.flexlimitb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />  <br/>PAIN <input type={"text"} name="flexpainb" value={this.state.flexpainb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> </div>
                                                    <div style={{width:"50%",lineHeight:"1"}}>B. EXTENSION <br/>MEAS.<input type={"text"} name="extenmeasb" value={this.state.extenmeasb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 30°_____{/*<input type={"text"} name="extennormb" value={this.state.extennormb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/}  <br/>LIMIT<input type={"text"} name="extenlimitb" value={this.state.extenlimitb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />  <br/>PAIN<input type={"text"} name="extenpainb" value={this.state.extenpainb} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></div>
                                                </div>
                                                <p className="m-0" style={{lineHeight:"1"}}><b>DORSOLUMBAR EXTENSION:</b> rationale: limit due to facet or spodylo.  </p></div>
                                            </div>
                                            {/* <p className="m-0 d-flex">POS. <input type={"text"} name="dorsolrangofmotl" value={this.state.dorsolrangofmotl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="dorsolrangofmotr" value={this.state.dorsolrangofmotr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p> */}
                                            {/* <p className="m-0">test: cross leg, strike patellar teriodn. <span className="fst-italic"> rationale: increased or decreased response = L4 nerve. </span></p> */}
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>14. CEREBELLAR FUNCTION TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={cerebfunctest} style={{width:"100%"}} /></div>
                                            <p className="m-0">1. Finger to Finger Test:</p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="cerebfunctest1l" value={this.state.cerebfunctest1l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="cerebfunctest1r" value={this.state.cerebfunctest1r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">2. Finger to Nose Test:</p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="cerebfunctest2l" value={this.state.cerebfunctest2l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="cerebfunctest2r" value={this.state.cerebfunctest2r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">3. Heel to Shin Test:</p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="cerebfunctest3l" value={this.state.cerebfunctest3l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="cerebfunctest3r" value={this.state.cerebfunctest3r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">4. Rhomberg Test:</p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="cerebfunctest4l" value={this.state.cerebfunctest4l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="cerebfunctest4r" value={this.state.cerebfunctest4r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0"><span className="fst-italic"> rationale: inability = cerebellar dysfunction. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>15. TOE WALK TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={toewalktest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="toewalktestl" value={this.state.toewalktestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="toewalktestr" value={this.state.toewalktestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: observe patient walking on toes. <span className="fst-italic"> rationale: inability = L5/S1 nerve roots.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>16. HEAL WALK TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={heelwalktest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="heelwalktestl" value={this.state.heelwalktestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="heelwalktestr" value={this.state.heelwalktestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">est: observe patient walking on heels.  <span className="fst-italic"> rationale: inability = L4/L5 nerve roots.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>17. GASTROCNEMIUS MUSCLE TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={gastromusctest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="gastromusctestl" value={this.state.gastromusctestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="gastromusctestr" value={this.state.gastromusctestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: toe walk test. <span className="fst-italic">  rationale: inability due to weakness possibly by S1/S2 involvement.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>18. FORCED LEG LOWERING TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={forcedleglowertest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="forcedleglowertestl" value={this.state.forcedleglowertestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="forcedleglowertestr" value={this.state.forcedleglowertestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: raise extended leg to 30°, apply downward pressure against resistance.  <span className="fst-italic"> rationale: inability = muscle weakness.  </span></p>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>19. LASEGUE'S TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={laseguetest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="laseguetestl" value={this.state.laseguetestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="laseguetestr" value={this.state.laseguetestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: straight leg raise with knee locked.<span className="fst-italic">  rationale: positive = sciatica or tight hamstrings.   </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>20. BRAGGARD'S TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={braggardtest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="braggardtestl" value={this.state.braggardtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="braggardtestr" value={this.state.braggardtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: dorsiflex foot 1" below point of pain for Laseque's test.  <span className="fst-italic"> rationale: positive for sciatica. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>21. FAJERSZTAJN'S TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={fajerstantest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="fajerstantestl" value={this.state.fajerstantestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="fajerstantestr" value={this.state.fajerstantestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: well leg straight leg raising test.  <span className="fst-italic">rationale: pain in sick leg positive for space occupying lesion.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>22. HOMAN'S TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={homanstest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="homanstestl" value={this.state.homanstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="homanstestr" value={this.state.homanstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: extended leg, raise 30° off table dorsiflex foot. <span className="fst-italic">  rationale: calf pain = thrombophlebitis.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>23. HOOVER'S SIGN :</b></p>
                                            <div className="d-flex justify-content-center"><img src={hooversign} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="hooversignl" value={this.state.hooversignl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="hooversignr" value={this.state.hooversignr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: stabilize calcaneus of good leg, have patient raise bad leg. <span className="fst-italic">rationale: no downward pressure with good leg = malingering. <br/> ** MALINGERING TEST**   </span></p>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>24. FABERE-PATRICK TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={favereoatricktest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="favereoatricktestl" value={this.state.favereoatricktestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="favereoatricktestr" value={this.state.favereoatricktestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: "sign of four".<span className="fst-italic">  rationale: positive for hip pathology. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>25. LAQUERRES TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={laquerrestest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="laquerrestestl" value={this.state.laquerrestestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="laquerrestestr" value={this.state.laquerrestestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: Fabere-Patrick test, force femur into acetabulum. <span className="fst-italic"> rationale: pain = hip pathology.   </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"60%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>26. MUSCLE TESTING :</b></p>
                                            <div className="d-flex justify-content-center"><img src={muscletesting} style={{width:"100%"}} /></div>
                                            <div className="d-flex">
                                                <div>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="lmuscletestingl1" value={this.state.muscletestingl1} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="muscletestingr1" value={this.state.muscletestingr1} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: flex thigh, internally rotate leg, force leg laterally against resistance. <span className="fst-italic"> rationale: weakness = S1/S2 involvement.  </span></p>
                                                </div>
                                                <div>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="lmuscletestingl2" value={this.state.muscletestingl2} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="muscletestingr2" value={this.state.muscletestingr2} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: flex leg and calf aprox 90°, stabilize ankle, extend leg against resistance. <span className="fst-italic">  rationale: weakness = L2/L3 involvement.    </span></p>
                                                </div>
                                                <div>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="lmuscletestingl3" value={this.state.muscletestingl3} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="muscletestingr3" value={this.state.muscletestingr3} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: extend leg 45°, evert foot, force foot down and out against resistance. <span className="fst-italic">    </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>27. CIRCUMFERENCE :</b></p>
                                            <div className="d-flex justify-content-center"><img src={circumfancet} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">THIGH. <input type={"text"} name="thighcircumfancetl" value={this.state.thighcircumfancetl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="thighcircumfancetr" value={this.state.thighcircumfancetr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0 d-flex">CALF. <input type={"text"} name="calfcircumfancetl" value={this.state.calfcircumfancetl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="calfcircumfancetr" value={this.state.calfcircumfancetr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0"><span className="fst-italic">rationale: nerve, muscle or vascular damage producing atrophy. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>28. DERMATOME DISTRIBUTION :</b></p>
                                            <div className="d-flex justify-content-center"><img src={dermatomedistri} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="dermatomedistril" value={this.state.dermatomedistril} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="dermatomedistrir" value={this.state.dermatomedistrir} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0"><span className="fst-italic"> rationale: nerve roots compression.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"40%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>29. CLONUS TESTS :</b></p>
                                            <div className="d-flex justify-content-center"><img src={clonustests} style={{width:"100%"}} /></div>
                                            <div className="d-flex">
                                                <div>
                                                    <p className="m-0"><b>ANKLE</b></p>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="clonustests1l" value={this.state.clonustests1l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="clonustests1r" value={this.state.clonustests1r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: forceful dorsiflexion of foot <span className="fst-italic">  rationale: sustained fibrillations = upper motor neuron lesion.  </span></p>
                                                </div>
                                                <div>
                                                    <p className="m-0"><b>PATELLAR</b></p>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="clonustests2l" value={this.state.clonustests2l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="clonustests2r" value={this.state.clonustests2r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: extend leg, force patella towards feet. <span className="fst-italic"> rationale: sustained fibrillations = upper motor neuron lesion. </span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>30. PATHOLOGY REFLEXs:</b></p>
                                            <div className="d-flex justify-content-center"><img src={pathologyrefelexs} style={{width:"100%"}} /></div>
                                            <p className="m-0"><b>OPPENHEIM</b></p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="pathologyrefelexsl" value={this.state.pathologyrefelexsl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="pathologyrefelexsr" value={this.state.pathologyrefelexsr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: run finger along crest of tibia. <span className="fst-italic">rationale: extension of other four = upper motor neuron lesion.  </span></p>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>31. PATHOLOGY REFLEXS (Cont.):</b></p>
                                            <div className="d-flex justify-content-center"><img src={patreflexcont1} style={{width:"100%"}} /></div>
                                            <p className="m-0"><b>CHADDOCK'S TEST:</b></p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="patreflexcont1l" value={this.state.patreflexcont1l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="patreflexcont1r" value={this.state.patreflexcont1r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: stroke lateral malelous.  <span className="fst-italic">rationale: extension of great toe with flexion of other four = upper motor neuron lesion. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b></b></p>
                                            <div className="d-flex justify-content-center"><img src={patreflexcont2} style={{width:"100%"}} /></div>
                                            <p className="m-0"><b>BABINSKI'S TSET:</b></p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="patreflexcont2l" value={this.state.patreflexcont2l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="patreflexcont2r" value={this.state.patreflexcont2r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: sharp point, stroke heel to ball then medial ward.  <span className="fst-italic">rationale: extension of great toe with fanning of other four = upper motor neuron lesion.   </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>32. VIBRATION FOR PERNICIOUS ANEMIA:</b></p>
                                            <div className="d-flex justify-content-center"><img src={vibrationperanemia} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="vibrationperanemial" value={this.state.vibrationperanemial} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="vibrationperanemiar" value={this.state.vibrationperanemiar} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: place tuning fork over toes. <span className="fst-italic">rationale: loss of vibratory sense possible for pernicious anemia. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>33. BIG TOE TEST:</b></p>
                                            <div className="d-flex justify-content-center"><img src={bigtoetest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="bigtoetestl" value={this.state.bigtoetestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="bigtoetestr" value={this.state.bigtoetestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: hold toe, patient dorsiflexes against resistance. <span className="fst-italic">rationale: weaknees = L5 nerve root involvement.<br/> AKA: SICARDS TEST  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>34. SOTO-HALL TEST:</b></p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="sotohalltestul" value={this.state.sotohalltestul} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="sotohalltestur" value={this.state.sotohalltestur} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: place hand on chest, flex onto chest. <span className="fst-italic"> rationale: pain = possible vertebral fracture.  </span></p>
                                            <div className="d-flex justify-content-center"><img src={sotohalltest} style={{width:"100%"}} /></div>
                                            <p className="m-0"><b>LHERMITTE'S SIGN</b></p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="sotohalltestull" value={this.state.sotohalltestull} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="sotohalltestulr" value={this.state.sotohalltestulr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: Soto-Hall test producing elec-like shocks down arms or legs.  <span className="fst-italic">rationale: positive for multiple sclerosis, compression or cervical disorders. </span></p>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>35. LINDER"S TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={linderstest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="linderstestl" value={this.state.linderstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="linderstestr" value={this.state.linderstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: flex head and thoracic spine.  <span className="fst-italic">rationale: pain in lumbar spine or down leg = sciatica.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>36. THOMAS TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={thomastest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="thomastestl" value={this.state.thomastestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="thomastestr" value={this.state.thomastestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: flex thigh onto abdomen.   <span className="fst-italic">rationale: opposite leg lifts off table = tight hip flexors. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>37. GAENSLEN'S TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={gaenslenstest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="gaenslenstestl" value={this.state.gaenslenstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="gaenslenstestr" value={this.state.gaenslenstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: patient to side of table, flex thigh to chest, hyper-extend opposite leg off table. <span className="fst-italic">rationale: pain = sacroiliac path.<br/> **MALINGERING TEST**<br/> (if pain not detected when each leg tested).  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>38. OBER'S TEST :</b></p>
                                            <div className="d-flex justify-content-center"><img src={oberstest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="oberstestl" value={this.state.oberstestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="oberstestr" value={this.state.oberstestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: patient on side, leg up, abduct, flex knee to 90°, release. <span className="fst-italic">rationale: if thigh remains abducted = contraction of the iliotibial tract.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>39. PULSES :</b></p>
                                            <div className="d-flex justify-content-center"><img src={pulses39} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="pulses39l" value={this.state.pulses39l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="pulses39r" value={this.state.pulses39r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0"> <span className="fst-italic">rationale: irregularity in pulse = vascular occlusion, dissecting aortic aneurysm, thrombophlebitis or aortic insufficiency. </span></p>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>40. ELY TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={elytest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="elytestl" value={this.state.elytestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="elytestr" value={this.state.elytestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: flex heel to opposite buttocks, lift thigh off table.   <span className="fst-italic">rationale: pain = sacroiliac lesion. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>41. YEOMAN'S FEMORAL STRETCH TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={yeomansfemstrechtest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="yeomansfemstrechtestl" value={this.state.yeomansfemstrechtestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="yeomansfemstrechtestr" value={this.state.yeomansfemstrechtestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: flex heel to same buttocks. <span className="fst-italic">rationale: pain = L3/L4 IVD. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>42. ACHILLES REFLEX : </b></p>
                                            <div className="d-flex justify-content-center"><img src={achillesreflex} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="achillesreflexl" value={this.state.achillesreflexl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="achillesreflexr" value={this.state.achillesreflexr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: percuss achilles tendon.  <span className="fst-italic">rationale: increased or decreased response = S1 nerve root involvement. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"40%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>43. MUSCLE TESTING : </b></p>
                                            <div className="d-flex justify-content-center"><img src={muscletesting43} style={{width:"100%"}} /></div>
                                            <div className="d-flex">
                                                <div>
                                                    <p className="m-0"><b>GLUTEUS MAXIMUS</b></p>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="muscletesting43l" value={this.state.muscletesting43l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="muscletesting43r" value={this.state.muscletesting43r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: flex leg 90°, extend thigh, force leg into flexion against resistance.  <span className="fst-italic">rationale: Si nerve root involvement. </span></p>
                                                </div>
                                                <div>
                                                    <p className="m-0"><b>HAMSTRINGS</b></p>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="muscletesting43l" value={this.state.muscletesting43l} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="muscletesting43r" value={this.state.muscletesting43r} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: flex leg 90°, extend leg against resistance. <span className="fst-italic">rationale: L5, S1 nerve root involvement.  </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>MUSCLE TEST (Cont.) : </b></p>
                                            <div className="d-flex justify-content-center"><img src={muscletestcont} style={{width:"100%"}} /></div>
                                            <p className="m-0"><b>SOLEUS</b></p>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="muscletestcontl" value={this.state.muscletestcontl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="muscletestcontr" value={this.state.muscletestcontr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: flex leg 90° plantar flex foot, dorsiflex against resistance.  <span className="fst-italic">rationale: Si nerve root involvement. </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"40%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>44. RELATED AREAS-KNEE RANGE OF MOTION : </b></p>
                                            <div className="d-flex justify-content-center"><img src={relatedareakneerom} style={{width:"40%"}} /></div>
                                            <div className="d-flex">
                                                <div style={{width:"100%",display:"flex",}}>
                                                    <div style={{width:"50%",lineHeight:"1"}}>A. FLEXION <br/>MEAS.<input type={"text"} name="flexmeasa44" value={this.state.flexmeasa44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 130°_____{/*<input type={"text"} name="flexnorma44" value={this.state.flexnorma44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/} <br/>LIMIT<input type={"text"} name="flexlimita44" value={this.state.flexlimita44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />   </div>
                                                    <div style={{width:"50%",lineHeight:"1"}}>B. EXTENSION <br/>MEAS.<input type={"text"} name="extenmeasa44" value={this.state.extenmeasa44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 0-5°_____{/*<input type={"text"} name="extennorma44" value={this.state.extennorma44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/} <br/>LIMIT<input type={"text"} name="extenlimita44" value={this.state.extenlimita44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> </div>
                                                    {/* <p style={{width:"50%",margin:"0"}}>FLEXION <br/>MEAS.________ <br/>NORM.__130°___ <br/>LIMIT_________</p>
                                                    <p style={{width:"50%",margin:"0"}}>EXTENSION <br/>MEAS.________ <br/>NORM.__0-5°___ <br/>LIMIT_________</p> */}
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div style={{width:"100%",display:"flex",}}><p style={{width:"50%",margin:"0"}}> <br/>INTERNAL <br/>ROTATION</p></div>
                                                <div style={{width:"100%",display:"flex",}}><p style={{width:"50%",margin:"0"}}> <br/>EXTERNAL <br/>ROTATION</p></div>
                                            </div>
                                            <div className="d-flex">
                                                <div style={{width:"100%",display:"flex",}}>
                                                    <div style={{width:"50%",lineHeight:"1"}}>A. FLEXION <br/>MEAS.<input type={"text"} name="flexmeasb44" value={this.state.flexmeasb44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 130°_____{/*<input type={"text"} name="flexnormb44" value={this.state.flexnormb44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/} <br/>LIMIT<input type={"text"} name="flexlimitb44" value={this.state.flexlimitb44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> </div>
                                                    <div style={{width:"50%",lineHeight:"1"}}>B. EXTENSION <br/>MEAS.<input type={"text"} name="extenmeasb44" value={this.state.extenmeasb44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> <br/>NORM. 0-5°_____{/*<input type={"text"} name="extennormb44" value={this.state.extennormb44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />*/} <br/>LIMIT<input type={"text"} name="extenlimitb44" value={this.state.extenlimitb44} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></div>

                                                    {/* <p style={{width:"50%",margin:"0"}}>FLEXION <br/>MEAS.________ <br/>NORM.__10°___ <br/>LIMIT_________</p>
                                                    <p style={{width:"50%",margin:"0"}}>EXTENSION <br/>MEAS.________ <br/>NORM.__10°___ <br/>LIMIT_________</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>45. McMURRAY'S TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={mcmurraytest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="mcmurraytestl" value={this.state.mcmurraytestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="mcmurraytestr" value={this.state.mcmurraytestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: flex leg, externally rotate, internally rotate as extend. <span className="fst-italic"> rationale: click = medial meniscus tear.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>46. DRAWER SIGN : </b></p>
                                            <div className="d-flex justify-content-center"><img src={drawersign} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="drawersignl" value={this.state.drawersignl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="drawersignr" value={this.state.drawersignr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: flex leg 900, foot on table, grasp behind knee push than pull, repeat for other leg.  <span className="fst-italic">rationale: gapping on pull = torn anterior cruciate lig. gapping on push = torn posterior cruciate ligament.  </span></p>
                                        </div>
                                    </div>

                                    <div className="p-0 d-flex" style={{borderTop:"1px solid #000",borderBottom:"1px solid #000"}}>
                                        <div className="pt-1" style={{width:"40%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>47. COLLATERAL LIGAMENT TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={collateralligamenttest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">
                                                MEDIAL POS. <input type={"text"} name="collateralligamenttestml" value={this.state.collateralligamenttestml} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="collateralligamenttestmr" value={this.state.collateralligamenttestmr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                                LATERAL POS. <input type={"text"} name="collateralligamenttestll" value={this.state.collateralligamenttestll} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="collateralligamenttestlr" value={this.state.collateralligamenttestlr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} />
                                            </p>
                                            <p className="m-0">test: stabilize ankle, contact knee, apply valgus and varus stress  <span className="fst-italic">rationale: gapping on valgus (int.) stress = medial meniscus instability, gapping on varus (ext.) stress = lateral meniscus instability.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"20%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>48. OSGOOD SCHLATTER TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={osgoodschaltlertest} style={{width:"100%"}} /></div>
                                            <p className="m-0 d-flex">POS. <input type={"text"} name="osgoodschaltlertestl" value={this.state.osgoodschaltlertestl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="osgoodschaltlertestr" value={this.state.osgoodschaltlertestr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                            <p className="m-0">test: palpate tibial tubercle.  <span className="fst-italic">rationale: pain or swelling = Osgood Schlatter.  </span></p>
                                        </div>
                                        <div className="pt-1" style={{width:"40%",padding:"0 5px",borderRight:"2px solid #000",fontSize:"12px"}}>
                                            <p className="m-0" style={{}}><b>49. APLEY'S COMPRESSION DISTRACTION TEST : </b></p>
                                            <div className="d-flex justify-content-center"><img src={distraction49} style={{width:"50%"}} /><img src={compression} style={{width:"40%"}} /></div>
                                            <div className="d-flex">
                                                <div>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="distraction49ll" value={this.state.distraction49ll} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="distraction49lr" value={this.state.distraction49lr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">test: flex leg to 90°, stabilize back of thigh (with knee), press down on heel, internally and externally rotate tibia.  <span className="fst-italic">rationale: pain positive on side of torn meniscus. </span></p>
                                                </div>
                                                <div>
                                                    <p className="m-0 d-flex">POS. <input type={"text"} name="distraction49rl" value={this.state.distraction49rl} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /> / <input type={"text"} name="distraction49rr" value={this.state.distraction49rr} onChange={(e)=>this.setState({[e.target.name]:e.target.value})} className={`p-0 w-25 ${inputColor}`} /></p>
                                                    <p className="m-0">Apley's Compression test, lift up on leg. <span className="fst-italic"> rationale: pain = ligamentous damage, no pain = meniscus damage. </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            </div>

                            {
                                (this.state.nextbutons) ? (
                                    <div className="row mt-3">
                                        <div className="col-sm-12 d-flex justify-content-center">
                                            <button className="btn btn-primary btn-sm" onClick={this.presecription}>Prescription</button>
                                            <button className="btn btn-primary btn-sm mx-3" onClick={this.opentreatment}>Treatment Plan</button>
                                            <button className="btn btn-primary btn-sm" onClick={this.orthoneuroforma}>CERVICAL SPINE & UPPERLIMB EXAMINATION</button>
                                        </div>
                                    </div>
                                ) : (<><button className="btn btn-sm btn-primary mt-3" type="submit">Submit</button></>)
                            }

                        </form>
                    </div>
                </>
        )
    }
}