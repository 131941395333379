import axios from "axios";
import DataTable from 'react-data-table-component'
import React, { Component } from "react";
import swal from "sweetalert";
import Modal from 'react-modal';
import Printheader from './assets/printheader.png';
import Printfooter from './assets/printfooter.png';
import Select from 'react-select'

export default class Invoicepuidview extends Component{

    constructor(props){
        super(props);
        this.state = {
            findapp : [],
            findpid : [],
            findallinnvoice : [],
            invoice_number : this.props.match.params.invno,
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            showreceipt : "1",
            showinnvoice : "1",
            findacceptrec : [],
            totalval : 0,
            discountval : 0,
            distype : "PERCENT",
            errmesg : "",
            totalamt : 0,
            consession : 0,
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
            customStyles1 : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '60%',
                  height: '50%',
                },
            },
            checkdetails : false,
            carddetails : false,
            upidetails : false,
            modaladdopen : false,
            options : [],
            modaleditopen : false,
            showconcession : "hideonprint",
            invdate : "",
        }

        this.fetchinnpatapp();
        this.handleChangeDis = this.handleChangeDis.bind(this);

        this.divRef = React.createRef();
    }

    fetchinnpatapp = () =>{
        const datapmia = {
            fetchinnpatapp : "yes",
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
            invno : this.props.match.params.invno,
        }
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",datapmia).then((res)=>{
            console.log(res.data)
            this.setState({ findapp : res.data.findapp })
            this.setState({ findpid : res.data.findpid })
            this.setState({ findallinnvoice : res.data.findallinnvoice })
            this.setState({ invoice_number : res.data.findallinnvoice[0]['invoice_number'] })
            this.setState({ invdate : res.data.findallinnvoice[0]['created_at'] })
            this.setState({totalpaid:res.data.findtotalpaidamt['totalpaid']})
            this.setState({ findacceptrec : res.data.findacceptrec })

            res.data.findallinnvoice.map((item)=>{if(item.discount !== "0"){this.setState({showconcession:""})}})
        })
    }

    openamtmodal = () =>{
        this.setState({ modalIsOpen : true })
    }

    handlemop = (e) =>{
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value === "cash"){
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
        if(e.target.value === "cheque"){
            this.setState({checkdetails : true})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
        if(e.target.value === "card"){
            this.setState({checkdetails : false})
            this.setState({carddetails : true})
            this.setState({upidetails : false})
        }
        if(e.target.value === "upi"){
            this.setState({upidetails : true})
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
        }
        if(e.target.value === ""){
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
        this.setState({ modaladdopen : false })
        this.setState({ modaleditopen : false })
    }

    openaddmodel = () =>{
        this.setState({treatname : 0})
        this.setState({unitcost : 0})
        this.setState({qty : 0})
        this.setState({discountval : 0})
        // this.setState({distype : 0})
        this.setState({totalamt : 0})
        const dataaddmodel = {
            dataaddmodel : "yes"
        }
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",dataaddmodel).then((res)=>{
            console.log(res.data);
            this.setState({ findalltherypy : res.data.findalltherypy })
            var treatarr = [];
            for(var i=0;i<res.data.findalltherypy.length;i++){
                treatarr.push({value:res.data.findalltherypy[i]['procedure_id'],label:res.data.findalltherypy[i]['procedure_name']+" "+res.data.findalltherypy[i]['procedure_price']})
            }
            this.setState({options:treatarr})
            this.setState({ modaladdopen : true })
        })
    }

    handlechangetreatid = (event) =>{
        this.setState({tid:event['value']})
        console.log(event['value'])
        const datatid = {
            fetchtreatdata : "yes",
            tid:event['value']
        }
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",datatid).then((res)=>{
            console.log(res.data)
            this.setState({treatname:res.data.findtreatdetials['procedure_name']})
            this.setState({unitcost:res.data.findtreatdetials['procedure_price']})
            this.setState({qty:1})
            this.chnageamt(res.data.findtreatdetials['procedure_name'],res.data.findtreatdetials['procedure_price'],1,"NUMBER",0);
        })
    }

    submitinnvoice = () => {
        const datainn = {
            saveinnvoice : "yes",
            treatname : this.state.treatname,
            unitcost : this.state.unitcost,
            qty : this.state.qty,
            consession : this.state.consession,
            distype : this.state.distype,
            discountval : this.state.discountval,
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
            invno : this.props.match.params.invno,
        }
        axios.post('https://clinic.dnyanasha.com/ws/invoice.php',datainn).then((res)=>{
            if(res.data.saveinnvoice > 0){
                // swal("Innvoice Added", "Innvoice Successfully Added..!", "success").then((swalres)=>{
                    this.fetchinnpatapp();
                    this.closeModal();
                // });
            }
        })
    }

    openeditmodel = (invoiceid) =>{
        this.setState({treatname : 0})
        this.setState({unitcost : 0})
        this.setState({qty : 0})
        this.setState({discountval : 0})
        // this.setState({distype : 0})
        this.setState({totalamt : 0})
        this.setState({invoiceid : invoiceid})
        const datainedit = {
            datainedit : "yes",
            invoiceid : invoiceid
        }
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",datainedit).then((res)=>{
            console.log(res.data)
            this.setState({treatname:res.data.finndinnvoicedetaisl['treatment_name']})
            this.setState({unitcost:res.data.finndinnvoicedetaisl['unit_cost']})
            this.setState({qty:res.data.finndinnvoicedetaisl['quantity']})
            this.setState({distype:res.data.finndinnvoicedetaisl['discount_type']})
            this.setState({discountval:res.data.finndinnvoicedetaisl['discount']})
            this.chnageamt(res.data.finndinnvoicedetaisl['treatment_name'],res.data.finndinnvoicedetaisl['unit_cost'],res.data.finndinnvoicedetaisl['quantity'],res.data.finndinnvoicedetaisl['discount_type'],res.data.finndinnvoicedetaisl['discount']);
            this.setState({ modaleditopen : true })
        })
    }

    submiteditinnvoice = () =>{
        const dataeditinn = {
            dataeditinn : "yes",
            treatname : this.state.treatname,
            unitcost : this.state.unitcost,
            qty : this.state.qty,
            distype : this.state.distype,
            discountval : this.state.discountval,
            invoiceid : this.state.invoiceid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/invoice.php',dataeditinn).then((res)=>{
            console.log(res.data)
            if(res.data.updateeditinvoice === true){
                // swal("Innvoice Updated", "Innvoice Updated Successfully..!", "success").then((swalres)=>{
                    this.fetchinnpatapp();
                    this.closeModal();
                // });
            }
        })   
    }

    chnagedistype = (e) =>{
        this.setState({distype:e.target.value})
        if(this.state.unitcost === undefined && this.state.qty === undefined ){
            this.setState({errmesg : "Enter Qty and Price."})
        } else {
            var proprice = this.state.unitcost * this.state.qty;
            if(e.target.value == "PERCENT")
            {
                if(this.state.discountval > 100){
                    this.setState({errmesg : "Percent can't be more than 100."})
                } else {
                    let toatlafterdis =  proprice - (proprice * (this.state.discountval / 100));
                    this.setState({totalamt : toatlafterdis})
                }
            }
            if(e.target.value == "NUMBER")
            {
                this.setState({errmesg : ""})
                if(proprice < this.state.discountval){
                    this.setState({errmesg : "Discount can't be more than Price."})
                } else {
                    let toatlafterdis = proprice - this.state.discountval;
                    this.setState({totalamt : toatlafterdis})
                }
            }
        }
    }

    handleChangeDis = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
        if(this.state.unitcost === undefined && this.state.qty === undefined ){
            this.setState({errmesg : "Enter Qty and Price."})
        } else {
            var proprice = this.state.unitcost * this.state.qty;
            if(this.state.distype == "PERCENT")
            {
                if(event.target.value > 100){
                    this.setState({errmesg : "Percent can't be more than 100."})
                } else {
                    this.setState({errmesg : ""})
                    let toatlafterdis =  proprice - (proprice * (event.target.value / 100));
                    this.setState({totalamt : toatlafterdis})
                }
            }
            if(this.state.distype == "NUMBER")
            {
                this.setState({errmesg : ""})
                if(proprice < event.target.value){
                    this.setState({errmesg : "Discount can't be more than Price."})
                } else {
                    let toatlafterdis = proprice - event.target.value;
                    this.setState({totalamt : toatlafterdis})
                }
            }
        }
    }

    chnageamt = (treatname,unitcost,qty,distype,discountval) =>{
        // console.log(treatname+" "+unitcost+" "+qty+" "+distype+" "+discountval)
        if(unitcost === undefined && qty === undefined ){
            this.setState({errmesg : "Enter Qty and Price."})
        } else {
            var proprice = unitcost * qty;
            if(distype == "PERCENT")
            {
                if(discountval > 100){
                    this.setState({errmesg : "Percent can't be more than 100."})
                } else {
                    this.setState({errmesg : ""})
                    let toatlafterdis =  proprice - (proprice * (discountval / 100));
                    this.setState({totalamt : toatlafterdis})
                }
            }
            if(distype == "NUMBER")
            {
                this.setState({errmesg : ""})
                if(proprice < discountval){
                    this.setState({errmesg : "Discount can't be more than Price."})
                } else {
                    let toatlafterdis = proprice - discountval;
                    this.setState({totalamt : toatlafterdis})
                }
            }
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        td, th {
                                            padding : 0 !important;
                                        }
                                        table, tr, td, th {
                                            border : 0;
                                        }
                                        p,td {
                                            font-size:12px;
                                        }
                                        p {
                                            margin: 0;
                                        }
                                        .hideonprint {
                                            display:none !important;
                                        }
                                        .header, .header-space {
                                            height: 100px;
                                        }
                                        .footer, .footer-space {
                                            height: 50px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 70%;
                                            height: 100px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        `;
                    setTimeout(() => {
                        window.print();
                        document.body.innerHTML = originalContents;
                        window.location.reload();
                    }, 500)
                                        // <div class="watermark"><p>Coreconcept</p></div>
    }

    payamoount = () =>{
        const datapay = {
            acceptpayment : "yes",
            amttopay : this.state.amttopay,
            modeopay : this.state.modeopay,
            bankname : this.state.bankname,
            chequeno : this.state.chequeno,
            last4digino : this.state.last4digino,
            transactionno : this.state.transactionno,
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
            invno : this.props.match.params.invno,
        }
        axios.post('https://clinic.dnyanasha.com/ws/invoice.php',datapay).then((res)=>{
            console.log(res.data)
            if(res.data.saverec > 0)
            // swal("Amount Paid", "Amount Paid Successfully..!", "success").then((swalres)=>{
                this.fetchinnpatapp();
                this.closeModal();
            // });
        })
    }

    mailinnvoice = (e) =>{
        console.log(this.divRef);
        console.log(this.divRef.current.innerHTML);
        const datamailinvoice = {
            sendpdftomail : "yes",
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
            findallinnvoice : JSON.stringify(this.state.findallinnvoice),
            findpid : JSON.stringify(this.state.findpid)
            // mailcontent : this.divRef.current.innerHTML
        }
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",datamailinvoice).then((res)=>{
            console.log(res.data);
        })
        // const options = {
        //     filename: 'Innvoice.pdf',
        //     image: { type: 'jpeg', quality: 0.98 },
        //     html2canvas: { scale: 2 },
        //     jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        //     margin: 1,
        //     html2pdf: { width: 792 },
        // };
        // html2pdf().set(options).from(this.divRef.current).toPdf().output('datauri').then(pdfData => {
        //     // console.log('PDF data:', pdfData);
        //     console.log('PDF data:', pdfData.split(';base64,').pop());
        //     var pdfDatafile = pdfData.split(';base64,').pop();
        //     axios.post("https://clinic.dnyanasha.com/ws/invoice.php", {pdfData:pdfDatafile,sendpdftomail:"yes"}, {
                
        //     })
        //     // Do something with the PDF data, such as sending it to an API
        // });
        // // html2pdf().from(this.divRef.current).set(options).outputPdf().then(pdfData => {
        // //     axios.post("https://clinic.dnyanasha.com/ws/invoice.php", {pdfData:pdfData,sendpdftomail:"yes"}, {
        // //         headers: { 'Content-Type': 'application/json' }, 
        // //       }).then(response => {})
        // // })
    }

    deleteinvoice = (invoices_id) =>{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if(willDelete){
                axios.post("https://clinic.dnyanasha.com/ws/invoice.php",{deleteinvoice:"yes",invoices_id:invoices_id}).then((res)=>{
                    if(res.data.deleteinvoice === true){this.fetchinnpatapp()}
                })
            } else {}
        })
    }

    deletereceiptmain = (acceptpay_id) =>{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if(willDelete){
                axios.post("https://clinic.dnyanasha.com/ws/invoice.php",{deletereciept:"yes",acceptpay_id:acceptpay_id}).then((res)=>{
                    if(res.data.deletereciept === true){this.fetchinnpatapp()}
                })
            } else {}
        })
    }

    render(){
        const totalamount = this.state.findallinnvoice.reduce((acc, curr) => acc +((curr.discount != "") ? ( ( (curr.unit_cost * curr.quantity) - ((curr.discount_type == "PERCENT") ? 
        ((curr.unit_cost * curr.quantity) * (curr.discount/100)) : (curr.discount) ) )
        ) : ((curr.unit_cost * curr.quantity))), 0);
        const totaldiscount = this.state.findallinnvoice.reduce((acc,curr)=> acc + Number(curr.discount), 0);
        const totalcost = this.state.findallinnvoice.reduce((acc,curr)=> acc + Number(curr.unit_cost), 0);
        let balance = totalamount;
        return(
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    <div><button className="btn btn-sm btn-primary" onClick={this.mailinnvoice}>Send To Mail</button>
                    <button className="btn btn-primary btn-sm my-2 mx-3" onClick={this.printform} >Print</button></div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                    <p></p>
                    <p>
                        <input type={"checkbox"} id="showreceiptid" name="showreceipt" value={"showreceipt"} checked={(this.state.showreceipt === "1") ? true : false} onChange={(e)=>{if(e.target.checked){this.setState({ [e.target.name] : "1" })}else{this.setState({ [e.target.name] : "" })}}} /> <label htmlFor="showreceiptid"> Receipt </label> <br />
                        <input type={"checkbox"} id="showinnvoiceid" name="showinnvoice" value={"showinnvoice"} checked={(this.state.showinnvoice === "1") ? true : false} onChange={(e)=>{if(e.target.checked){this.setState({ [e.target.name] : "1" })}else{this.setState({ [e.target.name] : "" })}}} /> <label htmlFor="showinnvoiceid"> Invoice </label>
                    </p>
                </div>

                <hr className="m-0" />

                <div id="printablediv" ref={this.divRef}>
                    <div className="row">
                        <div className="" style={{width:"65%"}}>
                            <p>
                                Patient Name : <b>{this.state.findpid['patient_name']} &nbsp; 
                                { (this.state.findpid['gender'] == 'm') ? ("M") : ("F")  }
                                {(this.state.findpid['dob'] === "") ? (<></>) : (
                                    <>/{ new Date().getFullYear() - new Date(this.state.findpid['dob']).getFullYear()} Yrs</>
                                )}</b>
                            </p></div>
                        <div className="" style={{width:"35%"}}><p>Patient ID : <b>{this.state.findpid['unique_pid']}</b></p></div>
                        <div className="" style={{width:"65%"}}><p>Email : <b>{this.state.findpid['email']}</b></p></div>
                        <div className="" style={{width:"35%"}}><p>Mob. No. : <b>{this.state.findpid['mobile_number']}</b></p></div>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex justify-content-between">
                        <h5 className="text-success">Invoices</h5>
                        <div className="d-flex align-items-center">
                            <p className="m-0">Date : <b>{new Date(this.state.invdate).getDate()}  {this.state.monthname[new Date(this.state.invdate).getMonth()]} {new Date(this.state.invdate).getFullYear()}</b></p>
                            <p className="m-0 mx-5">Invoice Number : <b>{this.state.invoice_number}</b></p>
                        </div>
                    </div>
                    <hr className="m-0" />

                    {
                        (this.state.showinnvoice === "1") ? (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Treatment & Products</th> 
                                        <th>Unit Cost</th>
                                        <th>Qty</th>
                                        <th className={this.state.showconcession}>Concession</th>
                                        <th>Total Cost INR</th>
                                        <th className="hideonprint">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.findallinnvoice.map((itme,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td>{itme.treatment_name}</td>
                                                    <td>{itme.unit_cost}</td>
                                                    <td>{itme.quantity}</td>
                                                    <td className={this.state.showconcession}>{itme.discount} { (itme.discount != "") ? ((itme.discount_type == "PERCENT") ? "%" : "INR") : (<></>)}</td>
                                                    <td>{(itme.discount != "") ? ( ( (itme.unit_cost * itme.quantity) - ((itme.discount_type == "PERCENT") ? 
                                                        ((itme.unit_cost * itme.quantity) * (itme.discount/100)) : (itme.discount) ) )
                                                        ) : ((itme.unit_cost * itme.quantity))}</td>
                                                    <td className="d-flex align-items-center hideonprint">
                                                        <button className="btn btn-primary btn-sm" onClick={()=>this.openeditmodel(itme.invoices_id)}>Edit</button>
                                                        <box-icon name='trash' style={{fill:"red",cursor:"pointer"}} onClick={()=>this.deleteinvoice(itme.invoices_id)} ></box-icon>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    <tr className="hideonprint">
                                        <td colSpan={6} ><button className="btn btn-primary btn-sm" onClick={this.openaddmodel}>Add</button></td>
                                    </tr>

                                    {/* <tr className="hideonprint">
                                        <td><input type={"text"} placeholder="Treatment Name" name="treatname" value={this.state.treatname} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}}  /></td>
                                        <td><input type={"number"} placeholder="Unit Cost"  min={1} name="unitcost" value={this.state.unitcost} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({discountval:0})}}/></td>
                                        <td><input type={"number"} placeholder="Qty"  min={1} name="qty" value={this.state.qty} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({totalamt:this.state.unitcost*this.state.qty});this.setState({discountval:0})}}/></td>
                                        <td >

                                            <div className="d-flex">
                                                <input type="number" name="discountval" min="0" value={this.state.discountval} onChange={this.handleChangeDis} className="w-50" />
                                                <select name="distype" onChange={this.chnagedistype} value={this.state.distype}>
                                                    <option value="PERCENT">%</option>
                                                    <option value="NUMBER">INR</option>
                                                </select>
                                            </div>
                                            <p className="text-danger m-0">{this.state.errmesg}</p> */}

                                            {/* <input className="w-50" type={"number"} placeholder="Concession"  min={1} name="consession" value={this.state.consession} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({totalamt:((this.state.qty * this.state.unitcost) - e.target.value)})}}/>INR */}
                                        
                                        {/* </td>
                                        <td>{this.state.totalamt}</td>
                                    </tr> 
                                    <button className="btn btn-primary btn-sm hideonprint" onClick={this.submitinnvoice}>Submit</button> */}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        {/* <th className="text-end">Total Amount</th> */}
                                        <th colSpan={4} className="text-end">Total Amount</th>
                                        {/* <th>{totalcost}</th>
                                        <th></th>
                                        <th>{totaldiscount}</th> */}
                                        <th>{totalamount}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={4} className="text-end">{/*<button className="btn btn-primary btn-sm mx-5 hideonprint" onClick={this.openamtmodal}>Pay Amount</button>*/}Paid Amount</th>
                                        <th>{this.state.totalpaid}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={4} className="text-end">Remaining Amount</th>
                                        <th>{totalamount - this.state.totalpaid}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        ) : (<></>)
                    }

                    {
                        (this.state.showreceipt === "1") ? (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Receipt No.</th>
                                        <th>Mode of Payment</th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                        <th className="hideonprint">Cancel Receipt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.findacceptrec.map((item,index)=>{
                                            var date = new Date(item.payment_date).getDate()+" "+this.state.monthname[new Date(item.payment_date).getMonth()]+" "+new Date(item.payment_date).getFullYear();
                                            const prevAmount = index === 0 ? 0 : this.state.findacceptrec[index - 1].amount;
                                            const prevBalance = index === 0 ? balance : this.state.findacceptrec[index - 1].balance;
                                            const newBalance = prevBalance - item.amount;
                                            item.balance = newBalance;
                                            balance = prevAmount === 0 ? balance - item.amount : prevBalance;
                                            return(
                                                    <tr>
                                                        <td>{date}</td>
                                                        <td>{item.recept_no}</td>
                                                        <td>{item.modeofpay}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{newBalance}</td>
                                                        <td className="hideonprint">
                                                            {
                                                                // (localStorage.getItem("user") === "Admin" || localStorage.getItem("user") === "Doctor") ? (
                                                                    <box-icon name='trash' style={{fill:"red",cursor:"pointer"}} onClick={()=>this.deletereceiptmain(item.acceptpay_id)} ></box-icon>
                                                                // ) : (<></>)
                                                            }
                                                        </td>
                                                    </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        ) : (<></>)
                    }

                    <Modal
                        isOpen={this.state.modaleditopen}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles1}
                        contentLabel="Edit Invoice"
                        ariaHideApp={false}
                    >
                        <h5>Edit Innvoice</h5>
                        <tr>
                            <th>Treatment & Products</th>
                            <th>Unit Cost</th>
                            <th>Qty</th>
                            <th>Concession</th>
                            <th>Total Cost INR</th>
                            <th></th>
                        </tr>
                        <tr className="hideonprint">
                            <td><input type={"text"} placeholder="Treatment Name" name="treatname" value={this.state.treatname} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}}  /></td>
                            <td><input type={"number"} placeholder="Unit Cost"  min={1} name="unitcost" value={this.state.unitcost} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({discountval:0})}}/></td>
                            <td><input type={"number"} placeholder="Qty"  min={1} name="qty" value={this.state.qty} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({totalamt:this.state.unitcost*this.state.qty});this.setState({discountval:0})}}/></td>
                            <td>
                                <div className="d-flex">
                                    <input type="number" name="discountval" min="0" value={this.state.discountval} onChange={this.handleChangeDis} className="w-50" />
                                    <select name="distype" onChange={this.chnagedistype/*(e)=>{}*/} value={this.state.distype}>
                                        <option value="PERCENT">%</option>
                                        <option value="NUMBER">INR</option>
                                    </select>
                                </div>
                                <p className="text-danger m-0">{this.state.errmesg}</p>

                                {/* <input className="w-50" type={"number"} placeholder="Concession"  min={1} name="consession" value={this.state.consession} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({totalamt:((this.state.qty * this.state.unitcost) - e.target.value)})}}/>INR */}
                            
                            </td>
                            <td>{this.state.totalamt}</td>
                            <td><button className="btn btn-primary btn-sm hideonprint" onClick={this.submiteditinnvoice}>Submit</button></td>
                        </tr>
                        
                    </Modal>

                    <Modal
                        isOpen={this.state.modaladdopen}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles1}
                        contentLabel="Add Invoice"
                        ariaHideApp={false}
                    >
                        <h5>Add Innvoice</h5>
                        
                        <tr>
                            <th>Treatment & Products</th>
                            <th>Unit Cost</th>
                            <th>Qty</th>
                            <th>Concession</th>
                            <th>Total Cost INR</th>
                            <th></th>
                        </tr>
                        <tr className="hideonprint">
                            <td>
                                {/* <input type={"text"} placeholder="Treatment Name" name="treatname" value={this.state.treatname} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}}  /> */}
                                <Select className="form-control p-0 selecttid" options={this.state.options} name="treatname" onChange={this.handlechangetreatid} required />
                            </td>
                            <td><input type={"number"} placeholder="Unit Cost"  min={1} name="unitcost" value={this.state.unitcost} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({discountval:0})}}/></td>
                            <td><input type={"number"} placeholder="Qty"  min={1} name="qty" value={this.state.qty} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({totalamt:this.state.unitcost*this.state.qty});this.setState({discountval:0})}}/></td>
                            <td>
                                <div className="d-flex">
                                    <input type="number" name="discountval" min="0" value={this.state.discountval} onChange={this.handleChangeDis} className="w-50" />
                                    <select name="distype" onChange={this.chnagedistype/*(e)=>{}*/} value={this.state.distype}>
                                        <option value="PERCENT">%</option>
                                        <option value="NUMBER">INR</option>
                                    </select>
                                </div>
                                <p className="text-danger m-0">{this.state.errmesg}</p>

                                {/* <input className="w-50" type={"number"} placeholder="Concession"  min={1} name="consession" value={this.state.consession} onChange={(e)=>{this.setState({[e.target.name]:e.target.value});this.setState({totalamt:((this.state.qty * this.state.unitcost) - e.target.value)})}}/>INR */}
                            
                            </td>
                            <td>{this.state.totalamt}</td>
                            <td><button className="btn btn-primary btn-sm hideonprint" onClick={this.submitinnvoice}>Submit</button></td>
                        </tr>

                    </Modal>

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="View Appointment Modal"
                        ariaHideApp={false}
                    >
                        <h5>Pay Amount</h5>
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label">Amount to Pay</label>
                                    <input type={"number"} min={0} className="form-control" name="amttopay" value={this.state.amttopay} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label">Mode of Payment</label>
                                    <select className="form-control" onChange={this.handlemop} name="modeopay" value={this.state.modeopay} >
                                        <option value={""}>Select Mode</option>
                                        <option value={"upi"}>UPI</option>
                                        <option value={"cash"}>Cash</option>
                                        <option value={"cheque"}>Cheque</option>
                                        <option value={"card"}>Card</option>
                                    </select>
                                </div>
                            </div>
                            {
                                (this.state.checkdetails) ? (<>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="form-label">Bank Name</label>
                                        <input type={"text"} className="form-control" name="bankname" value={this.state.bankname} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="form-label">Cheque No.</label>
                                        <input type={"number"} min={1} className="form-control" name="chequeno" value={this.state.chequeno} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                    </div>
                                </div>
                                </>) : (<></>)
                            }
                            {
                                (this.state.carddetails) ? (<>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="form-label">Last 4 Digit</label>
                                        <input type={"text"} className="form-control" name="last4digino" value={this.state.last4digino} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                    </div>
                                </div>
                                </>) : (<></>)
                            }
                            {
                                (this.state.upidetails) ? (<>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="form-label">Transaction No.</label>
                                            <input type={"text"} className="form-control" name="transactionno" value={this.state.transactionno} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                        </div>
                                    </div>
                                </>) : (<></>)
                            }
                        </div>
                        <div className="row">
                            <div className="col-sm-1 mt-3">
                                <button className="btn btn-sm btn-primary" onClick={this.payamoount}>Submit</button>
                            </div>
                        </div>
                    </Modal>

                </div>

            </>
        )
    }
}