import axios from "axios";
import DataTable from 'react-data-table-component'
import React, { Component } from "react";
import swal from "sweetalert";
import Modal from 'react-modal';

import { RotatingLines } from  'react-loader-spinner'

export default class Invoice extends Component{

    constructor(props){
        super(props); 
        this.state = {
            loading : false,
            findpinndata : [],
            // columns : [
            //     {
            //         name:"Invoice Number",
            //         selector:(row)=>row.invoice_number
            //     },
            //     {
            //         name:"Treatment",
            //         selector:(row)=>row.treatment_name
            //     },
            //     {
            //         name:"Unit Cost",
            //         selector:(row)=>row.unit_cost
            //     },
            //     {
            //         name:"Quantity",
            //         selector:(row)=>row.quantity
            //     },
            //     {
            //         name:"Discount",
            //         selector:(row)=>{return(<>{row.discount} {
            //             (row.discount != "") ? 
            //             ((row.discount_type == "PERCENT") ? "%" : "INR") : (<></>)
            //             }</>)}
            //     },
            //     {
            //         name:"Total Amount",
            //         selector:(row)=>{return(<>{ 
            //             (row.discount != "") ?
            //             (
            //                 ( (row.unit_cost * row.quantity) - ((row.discount_type == "PERCENT") ? ((row.unit_cost * row.quantity) * (row.discount/100)) : (row.discount) ) )
            //             ) : ((row.unit_cost * row.quantity))
            //             }</>)}
            //     },
            //     {
            //         name:"Status",
            //         cell:(row)=>{return( (row.status == "U") ? (<><span style={{background:"#dc3545",padding:"5px 10px",borderRadius:"20px"}}>Un-Paid</span></>) : ((row.status == "P") ? (<><span style={{background:"#28a745",padding:"5px 10px",borderRadius:"20px"}}>Paid</span></>) : (<></>) ) )}
            //     },
            //     {
            //         name:"Action",
            //         cell:(row)=>{return(((JSON.parse(localStorage.getItem("roleslugarr"))).includes("Update_Innvoice") || localStorage.getItem("user") == "Admin") ? (<><box-icon style={{cursor:"pointer",marginLeft:"5px"}} name='show' alt="View" title="View" onClick={()=>this.changestatusmain(row)} ></box-icon></>) : (<></>) )}
            //     }
            // ],
            columns : [
                {
                    name:"Patient Name",
                    selector:(row)=>row.patient_name
                },
                {
                    name:"Total Amount",
                    selector:(row)=>row.totalamt
                },
                // {
                //     name:"Discount",
                //     selector:(row)=>row.discount
                // },
                // {
                //     name:"Quantity",
                //     selector:(row)=>row.quantity
                // },
                // {
                //     name:"Discount",
                //     selector:(row)=>{return(<>{row.discount} {
                //         (row.discount != "") ? 
                //         ((row.discount_type == "PERCENT") ? "%" : "INR") : (<></>)
                //         }</>)}
                // },
                // {
                //     name:"Total Amount",
                //     selector:(row)=>{return(<>{ 
                //         (row.discount != "") ?
                //         (
                //             ( (row.totalamt) - ((row.discount_type == "PERCENT") ? ((row.totalamt) * (row.discount/100)) : (row.discount) ) )
                //         ) : ((row.totalamt))
                //         }</>)}
                // },
                {
                    name:"Action",
                    selector:(row)=>{return(
                        <>
                            <button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push(  '/invoice/'+row.unique_pid)}} >View Invoice</button>
                        </>
                    )}
                }
                // {
                //     name:"Status",
                //     cell:(row)=>{return( (row.status == "U") ? (<><span style={{background:"#dc3545",padding:"5px 10px",borderRadius:"20px"}}>Un-Paid</span></>) : ((row.status == "P") ? (<><span style={{background:"#28a745",padding:"5px 10px",borderRadius:"20px"}}>Paid</span></>) : (<></>) ) )}
                // },
                // {
                //     name:"Action",
                //     cell:(row)=>{return(((JSON.parse(localStorage.getItem("roleslugarr"))).includes("Update_Innvoice") || localStorage.getItem("user") == "Admin") ? (<><box-icon style={{cursor:"pointer",marginLeft:"5px"}} name='show' alt="View" title="View" onClick={()=>this.changestatusmain(row)} ></box-icon></>) : (<></>) )}
                // }
            ],
            results : [],
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
        }
        
        // if(localStorage.getItem("user") == "Patient")
        // {
        // }
        this.fetchdatainn();
        this.submitmodal = this.submitmodal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // console.log(['Update_Innvoice', 'Dashboard', 'Patient', 'Invoice'].includes("Update_Innvoice"));
        // console.log((JSON.parse(localStorage.getItem("roleslugarr"))).includes("Update_Innvoice"))

    }

    fetchdatainn = () =>{
        const data={
            fetchdatainnd : "yes",
            user : localStorage.getItem("user"),
            uid : localStorage.getItem("user_id")
        }
        this.setState({loading:true});
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",data).then((res)=>{
            console.log(res.data)
            // this.setState({ results : res.data.findpinndata })
            // this.setState({ findpinndata : res.data.findpinndata })
            this.setState({ results : res.data.findallinnpat })
            this.setState({ findallinnpat : res.data.findallinnpat })
            this.setState({loading:false});
        })
    }

    changestatusmain = (item) =>{
        this.setState({ innid : item.invoices_id })
        this.setState({ pstatus : item.status })
        this.setState({ stp : item.showtp })
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
    }

    handleChange = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name] : value })
    }

    submitmodal = (event) =>{
        event.preventDefault();
        const datainout = {changepstatus:'yes',innid:this.state.innid,status:this.state.pstatus,stp:this.state.stp};
        axios.post('https://clinic.dnyanasha.com/ws/invoice.php',datainout).then((res)=>{
        if(res.data.updaateps == true)
        {
            swal("Payment Status Updated", "Payment Status Updated Successfully..!", "success").then((swalres)=>{
                this.setState({ modalIsOpen : false })
                this.fetchdatainn();
            });
        }    
        })
    }

    setSearch = (svalue) => {
        // console.log("")
        if(svalue == "" || svalue == undefined || svalue == null ){
            this.setState({search:svalue})
            this.setState({results:this.state.findallinnpat})
        }
        else {
            // console.log(svalue)
            if(svalue.includes("inv")){
                var result = this.state.findallinnpat.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.patient_name.toLowerCase().match(this.state.search);
                });
            } else {
                var result = this.state.findallinnpat.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.patient_name.toLowerCase().match(this.state.search);
                });
            }
            this.setState({results:result})
        }
    }

    render(){
        if(this.state.loading === true ){
            return(
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                />
                )
        } else {
        return(
            <>
                <DataTable
                    columns={this.state.columns} 
                    data={this.state.results} 
                    style={{textAlign:"center"}}
                    // title="All Doctors"
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='450px'
                    highlightOnHover
                    subHeader
                    onRowClicked={(row)=>{this.props.history.push(  '/invoice/'+row.unique_pid)}}
                    subHeaderComponent={
                        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                            <div style={{fontSize:"22px"}}>All Invoices</div>
                            <input 
                                type="text"
                                placeholder='search here'
                                className='w-25 form-control'
                                value={this.state.search}
                                onChange={(e)=>this.setSearch(e.target.value)}
                            />
                        </div>
                    }
                />

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={this.state.customStyles}
                    contentLabel="View Appointment Modal"
                    ariaHideApp={false}
                >
                    <h5>Update Payment Status</h5>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="form-check-label">Status</label>
                                <select className="form-control" name="pstatus" value={this.state.pstatus} onChange={this.handleChange}>
                                    <option value="U">Un-Paid</option>
                                    <option value="P">Paid</option>
                                </select>
                            </div>                        
                        </div>

                        <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="form-check-label">Show To Patient</label>
                                    <select className="form-control" name="stp" value={this.state.stp} onChange={this.handleChange}>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </select>
                                </div>
                            </div>

                        <div className="col-sm-2 mt-2" style={{display:"flex",alignItems:"flex-end"}}>
                            <button type="submit" className="btn btn-primary" onClick={this.submitmodal}>Submit</button>
                        </div>
                    </div>
                </Modal>
            </>
        )
        }
    }
}