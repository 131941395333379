import axios from "axios";
import React from "react";
import Modal from 'react-modal';
import swal from 'sweetalert';

export default class Permissions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen : false,
            modalIsOpenModule : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '30%'
                },
              },
            modulelist : [],
            rolelist : [],
            modulerole : [],
            resmodulerarr : [],
            findassignedrole:[],
        }
        Modal.setAppElement();
        this.handleChangeModal = this.handleChangeModal.bind(this);
        this.handleSubmitModal = this.handleSubmitModal.bind(this);
        this.handleSubmitModalModule = this.handleSubmitModalModule.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.fetchdata();
    }

    fetchdata = () =>{
        const data = {
            fetch_role : 'yes',
        }
        console.log(data);
        axios.post('https://clinic.dnyanasha.com/ws/rolepermission.php',data).then((res)=>{
            console.log(res.data);
            this.setState({ rolelist : res.data.findroles });
        })


        const datamodulerole = {
            fetchmodulesrole:'yes'
        }
        axios.post('https://clinic.dnyanasha.com/ws/rolepermission.php',datamodulerole).then((res)=>{
            console.log(res.data)
            this.setState({ modulerole : res.data.findmodules })
        })
    }

    handleChange(event) {
        // console.log(event.target.checked);
        var resmodulerarrres = this.state.resmodulerarr;
        var varname = event.target.name+":"+event.target.value;
        if(event.target.checked === true){
            // console.log(resmodulerarrres.includes(varname));
            if(resmodulerarrres.includes(varname)){}
            else { resmodulerarrres.push(varname) }
        } else {
            if(resmodulerarrres.includes(varname)){
                if(resmodulerarrres.indexOf(varname) > -1){
                    resmodulerarrres.splice(resmodulerarrres.indexOf(varname), 1)
                }
            }
        }
        this.setState({resmodulerarr : resmodulerarrres})
        // console.log(resmodulerarrres)
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.roleselected > 0){
            const modulerole = {
                saveroleaccess : 'yes',
                resmodulerarr : this.state.resmodulerarr,
                selectedrole : this.state.roleselected,
            }
            // console.log(modulerole);
            axios.post('https://clinic.dnyanasha.com/ws/rolepermission.php',modulerole).then((res)=>{
                // console.log(res.data);
                if(res.data.saverolemodule > 0)
                {
                    swal("Access Given", "Access Given Successfully..!", "success").then((swalres)=>{
                        console.log(swalres);
                    });
                }
            })
        } else {
            swal("Error", "Please Select Role", "error").then((res)=>{
                this.nameInput.focus();
            })
        }
    }

    handleChangeModal(event) {
        let name = event.target.name;
        this.setState({[name]: event.target.value});

        if(name === "roleselected")
        {
            var resmodulerarrres = [];
            const datarolecheck = {
                fetchchkrole : "yes",
                roleselected : event.target.value
            }
            axios.post('https://clinic.dnyanasha.com/ws/rolepermission.php',datarolecheck).then((res)=>{
                // console.log(res.data);
                this.setState({findassignedrole:res.data.findassignedrole})
                for(let i=0;i<res.data.findassignedrole.length;i++){
                    // console.log(res.data.findassignedrole[i]);
                    resmodulerarrres.push(res.data.findassignedrole[i]);
                }
                this.setState({resmodulerarr : resmodulerarrres})
            })
        }
    }

    handleSubmitModal(event) {
        event.preventDefault();
        const datamodal = {
            add_role : 'yes',
            role_name : this.state.role_name,
        }
        console.log(datamodal);
        axios.post('https://clinic.dnyanasha.com/ws/rolepermission.php',datamodal).then((res)=>{
            console.log(res.data)
            if(res.data.saverole > 0)
            {
                swal("Role Added", "Role Successfully added..!", "success").then((swalres)=>{
                    console.log(swalres);
                    if(swalres)
                    {
                        this.closeModal();
                        this.fetchdata();
                    }
                });
            }
        })
    }

    handleSubmitModalModule(event) {
        event.preventDefault();
        const datamodalmodule = {
            add_module : 'yes',
            module_name : this.state.module_name,
            module_order : this.state.module_order,
            module_link : this.state.module_link,
            module_icon : this.state.module_icon,
            module_parent : this.state.module_parent,
        }
        console.log(datamodalmodule);
        axios.post('https://clinic.dnyanasha.com/ws/module.php',datamodalmodule).then((res)=>{
            console.log(res.data.savemodule)
            if((res.data.savemodule) > 0)
            {
                swal("Module Added", "Module Successfully added..!", "success").then((swalres)=>{
                    console.log(swalres);
                    if(swalres)
                    {
                        this.closeModal();
                        this.fetchdata();
                    }
                });
            }
        })
    }

    openModal = () => {
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
        this.setState({ modalIsOpenModule : false })
    }

    openModalModule = () => {
        const datamodule = {
            fetch_module : 'yes',
        }
        axios.post('https://clinic.dnyanasha.com/ws/module.php',datamodule).then((res)=>{
            console.log(res.data)
            this.setState({ modulelist : res.data.findmodules })
        })
        this.setState({ modalIsOpenModule : true })
    }
    
    render(){
        return(
            <>
                <div>
                    <div className="card p-3">
                        <div className="row">
                            <div className="col-sm-12 cardheadper">
                                <h6>All Permissions</h6>
                                <div>
                                    {/* <button className="btn btn-sm btn-primary" onClick={this.openModalModule}>Add Module</button> */}
                                    <button className="btn btn-sm btn-primary" style={{marginLeft:"10px"}} onClick={this.openModal}>Add Role</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="Permission Modal"
                        ariaHideApp={false}
                    >
                        <h5>Add Role</h5>
                        <form onSubmit={this.handleSubmitModal}>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Role Name</label>
                                            <input type="text" className="form-control" name="role_name" onChange={this.handleChangeModal} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <button className="btn btn-primary" type="submit" >Submit</button>
                                    <a onClick={this.closeModal} className="btn btn-danger" style={{marginLeft:"10px"}}>close</a>
                                </div>
                            </div>
                        </form>
                    </Modal>

                    {/* <Modal
                        isOpen={this.state.modalIsOpenModule}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="Module Modal"
                        ariaHideApp={false}
                    >
                        <h5>Add Module</h5>
                        <form onSubmit={this.handleSubmitModalModule}>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Module Name</label>
                                            <input type="text" className="form-control" name="module_name" onChange={this.handleChangeModal} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Module Order</label>
                                            <input type="text" className="form-control" name="module_order" onChange={this.handleChangeModal} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Module Link</label>
                                            <input type="text" className="form-control" name="module_link" onChange={this.handleChangeModal} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Module Icon</label>
                                            <input type="text" className="form-control" name="module_icon" onChange={this.handleChangeModal} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Module Parent</label>
                                            <select className="form-control" name="module_parent" onChange={this.handleChangeModal}>
                                                <option value="">Select Module Parent</option>
                                                {
                                                    this.state.modulelist.map((res,index)=>{
                                                        return(
                                                            <option key={index} value={res.module_id}>{res.module_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <button className="btn btn-primary" type="submit" >Submit</button>
                                    <a onClick={this.closeModal} className="btn btn-danger" style={{marginLeft:"10px"}}>close</a>
                                </div>
                            </div>
                        </form>
                    </Modal> */}

                    <div className="card p-3 mt-4">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label>Select Role</label>
                                    <select ref={(input) => { this.nameInput = input; }} className="form-control" name="roleselected" onChange={this.handleChangeModal}>
                                        <option>Select Role</option>
                                        {
                                            this.state.rolelist.map((res,index)=>{
                                                return(
                                                    <option key={index} value={res.role_id} >{res.role_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* { (this.state.findassignedrole.includes(item.module_id)) ? (<>checked</>) : (<></>) } */}

                        <div className="row">
                            <form onSubmit={this.handleSubmit}>
                                <div className="col-sm-12">
                                    {
                                        this.state.modulerole.map((item,index)=>{
                                            let name = item.module_name.split(' ').join('_');
                                            return(
                                                <>
                                                    <div className="form-group" key={index}>
                                                        { (this.state.resmodulerarr.includes(name+":"+item.module_id)) ? (<>
                                                                <input className="mt-3" type="checkbox" name={name} id={item.module_name} value={item.module_id} onChange={this.handleChange} checked/><label style={{marginLeft:"10px"}} htmlFor={item.module_name}>{item.module_name}</label>
                                                            </>) : (<>
                                                                <input className="mt-3" type="checkbox" name={name} id={item.module_name} value={item.module_id} onChange={this.handleChange} /><label style={{marginLeft:"10px"}} htmlFor={item.module_name}>{item.module_name}</label>
                                                            </>)
                                                        }
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                    <center><button className="btn btn-primary" >Submit</button></center>
                            </form>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    }