import React, { Component, useCallback, useState } from "react"
import { useHistory } from "react-router-dom"

function Sidebarpd(props){

    const [isCollapsed,setisCollapsed] = useState(false)

    const history = useHistory();

    // useEffect(() => {

    // });

    // const handleClick = useCallback((e)=>{
    //     const target = e.currentTarget;
    //     const targetId = target.id;
    //     target.classList.toggle('activepd');
    //     // e.currentTarget.classList.add('activepd');
    // },[]);

    const handleClick = (e,link) => {
        // console.log(e.currentTarget.classList)
        history.push(link)
        e.currentTarget.classList.add('activepd');
    }

    // console.log(props)

    return(
            <>
                {/* patient dashboard sidebar      style={{width:"220px",border:"1px solid #dee2e6",borderTopWidth:"0",position:"absolute",height:"75%"}} */}
                <div className={`sidebarinside p-1 ${isCollapsed ? 'collapsedinside' : ''}`}>
                    {/* <p className="m-0 text-end" style={{height:"22px"}}><box-icon type='solid' name='chevron-left' style={{cursor:"pointer"}} onClick={()=>setisCollapsed(!isCollapsed)}></box-icon></p> */}

                    <p className="menumainname" style={{color:"#0088c1",fontSize:"14px"}}><b>Patient</b></p>
                    <div className="d-flex align-items-center" style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}} id="Profile" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/profile')} ><box-icon  type='solid' name='user-detail' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> <span>Profile</span></div>
                    <div className="d-flex align-items-center mt-1" style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}} id="Appointments" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/appointment')} ><box-icon  type='solid' name='calendar' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> <span>Appointments</span> </div>
                    <div className="d-flex align-items-center mt-1" style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}} id="Communication" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/communication')} ><box-icon  type='solid' name='chat' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> <span>Communication</span> </div>

                    <p className="menumainname" style={{color:"#0088c1",fontSize:"14px",marginTop:"12px"}}><b>EMR</b></p>
                    <div className="d-flex align-items-center " style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  id="Consultation" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/labfiles')} ><box-icon   name='receipt' style={{marginRight:"7px",fill:"#747679"}}></box-icon> <span>Lab Files</span> </div>
                    {
                        (localStorage.getItem("user") === "Admin" || localStorage.getItem("user") === "Doctor") ? 
                        <div className="d-flex align-items-center " style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  id="Consultation" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/consultation')} ><box-icon   name='time-five' style={{marginRight:"7px",fill:"#747679"}}></box-icon> <span>Consultation</span> </div>
                        : (<></>)
                    }
                    
                    <div className="d-flex align-items-center mt-1" style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  id="Prescription" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/prescription')} ><box-icon   name='plus-medical' style={{marginRight:"7px",fill:"#747679"}}></box-icon> <span>Prescription</span> </div>
                    <div className="d-flex align-items-center mt-1" style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  id="Plans" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/treatmentplans')} ><box-icon   name='loader-circle' style={{marginRight:"7px",fill:"#747679"}}></box-icon> <span>Protocol (Treatment Plans)</span> </div>
                    <div className="d-flex align-items-center mt-1" style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  id="Plans" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/dietplans')} ><box-icon   name='loader-circle' style={{marginRight:"7px",fill:"#747679"}}></box-icon> <span>Diet Plans</span> </div>
                    {/* <div className="d align-items-center-flex " style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/completeprocedure') } onClick={(e)=>handleClick()}}e,""><box-icon   name='check-circle' style={{marginRight:"7px",fill:"#747679"}}></box-icon> Completed Procedures</div> */}

                    <p className="menumainname" style={{color:"#0088c1",fontSize:"14px",marginTop:"12px"}}><b>Billings</b></p>
                    <div className="d-flex align-items-center " style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  id="Invoice" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/invoicepd')} ><box-icon  type='solid' name='receipt' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> <span>Invoice</span> </div>
                    <div className="d-flex align-items-center mt-1" style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  id="Billings" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/billingpd')} ><box-icon  type='solid' name='receipt' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> <span>Billings</span> </div>
                    <div className="d-flex align-items-center mt-1" style={{marginLeft:"10px",fontSize:"12px",cursor:"pointer"}}  id="Ledger" onClick={(e)=>handleClick(e,'/patient_dashboard/'+props.puid+'/ledgerpd')} ><box-icon  type='solid' name='receipt' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> <span>Ledger</span> </div>
                    {/* <div className="d-flex mt-2" style={{marginLeft:"10px",cursor:"pointer"}}><box-icon  name='rupee' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Payments</div> */}
                </div>
                {/* patient dashboard sidebar */}
            </>
    )
}






// onClick={(e)=>{ e.currentTarget.classList.add('activepd');  history.push(  '/patient_dashboard/'+props.puid+'/profile')}} 
// onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/appointment') }}
// onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/communication') }}
// onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/consultation') }}
// onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/prescription') }}
// onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/treatmentplans') }}
// onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/invoicepd') }}
// onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/billingpd') }}
// onClick={(e)=>{ e.currentTarget.classList.add('activepd'); history.push(  '/patient_dashboard/'+props.puid+'/ledgerpd') }}



export default Sidebarpd;
// export default class Sidebarpd extends Component{
//     constructor(props){
//         super(props);
//         this.state = {}

//         console.log(this.props)

//     }
//     render(){
//         return(
//             <>
//                 {/* patient dashboard sidebar */}
//                 <div className="p-1" style={{width:"220px",border:"1px solid #dee2e6",borderTopWidth:"0",position:"absolute",height:"75%"}}>
                    
//                     <p style={{color:"#0088c1"}}><b>Patient</b></p>
//                     <div className="d-flex" style={{marginLeft:"10px",cursor:"pointer"}} onClick={()=>{this.props.history.push(  '/patient_dashboard/profile/'+this.state.unique_pid)}} ><box-icon  type='solid' name='user-detail' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Profile</div>
//                     <div className="d-flex pt-2" style={{marginLeft:"10px",cursor:"pointer"}} onClick={()=>{ this.props.history.push(  '/patient_dashboard/appointment/'+this.state.unique_pid) }}><box-icon  type='solid' name='calendar' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Appointments</div>
//                     <div className="d-flex pt-2" style={{marginLeft:"10px",cursor:"pointer"}}><box-icon  type='solid' name='chat' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Communication</div>
                    
//                     <p style={{color:"#0088c1",marginTop:"12px"}}><b>EMR</b></p>
//                     <div className="d-flex " style={{marginLeft:"10px",cursor:"pointer"}}><box-icon   name='plus-medical' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Prescription</div>
                    
//                     <p style={{color:"#0088c1",marginTop:"12px"}}><b>Billings</b></p>
//                     <div className="d-flex " style={{marginLeft:"10px",cursor:"pointer"}}><box-icon  type='solid' name='receipt' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Invoice</div>
//                     <div className="d-flex pt-2" style={{marginLeft:"10px",cursor:"pointer"}}><box-icon  name='rupee' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Payments</div>
//                 </div>
//                 {/* patient dashboard sidebar */}
//             </>
//         )
//     }
// }