import React from "react";
import axios from "axios";
import swal from 'sweetalert';
import DataTable from 'react-data-table-component'
import Modal from 'react-modal';

export default class AddTherapy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded : false,
            findqllprocedure : [],
            filteredListt : [],
            searchQuery: '',
            modaleditopen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
        }

        this.fetchallprocedureat();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    } 

    fetchallprocedureat = () =>{
        const datapro = {
            fetchallprocedure : "yes",
            puid : "",
            appid : "",
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',datapro).then((res)=>{
            console.log(res.data);
            this.setState({findqllprocedure:res.data.findqllprocedure})
            this.setState({filteredListt:res.data.findqllprocedure})
            if(this.state.searchQuery !== ""){
                const searchQuery = this.state.searchQuery;
                const filteredListt = res.data.findqllprocedure.filter(item =>
                    item.procedure_name.toLowerCase().includes(searchQuery.toLowerCase())
                );
                this.setState({
                    searchQuery,
                    filteredListt
                });
            }
        })
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const dataprocedure = {
            addprocedure : 'yes',
            proceudrename : this.state.proceudrename,
            procedureprice : this.state.procedureprice,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',dataprocedure).then((res)=>{
            console.log(res.data);
            if(res.data.saveprocedure > 0)
            {
                this.setState({expanded:false})
                this.fetchallprocedureat();
                // swal("Procedure Added", "Procedure Successfully Added..!", "success").then((swalres)=>{
                //     this.setState({expanded:false})
                //     this.fetchallprocedureat();
                // });
            }
        })
    }

    handleChange = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSearchQueryChange = (event) => {
        const searchQuery = event.target.value;
        const filteredListt = this.state.findqllprocedure.filter(item =>
            item.procedure_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        this.setState({
            searchQuery,
            filteredListt
        });
    }

    openedittherapy = (procedure_id) =>{
        this.setState({upprocedure_id:procedure_id})
        const dataeditp = {procedure_id:procedure_id,editprocedure:"yes"}
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',dataeditp).then((res)=>{
            console.log(res.data)
            this.setState({proceudrenameedit:res.data.findprodetails['procedure_name']})
            this.setState({procedurepriceedit:res.data.findprodetails['procedure_price']})
            this.setState({modaleditopen:true})
        })
    }

    closeModal = () => {
        this.setState({ modaleditopen : false })
    }

    updateprocedure = () =>{
        const updatapord = {
            updatapord : "yes",
            upprocedure_id : this.state.upprocedure_id,
            proceudrenameedit : this.state.proceudrenameedit,
            procedurepriceedit : this.state.procedurepriceedit,
        }
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',updatapord).then((res)=>{
            console.log(res.data)
            if(res.data.updateprocudure === true){
                    this.setState({modaleditopen:false})
                    this.fetchallprocedureat();
                // swal("Procedure Updated", "Procedure Successfully Updated..!", "success").then((swalres)=>{
                    //     this.setState({modaleditopen:false})
                    //     this.fetchallprocedureat();
                // });
            }
        })
    }

    deletetherapy = (procedure_id) =>{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if(willDelete) {
                axios.post('https://clinic.dnyanasha.com/ws/settings.php',{deletepro : "yes",procedure_id:procedure_id}).then((res)=>{
                    if(res.data.deletepro){
                        this.fetchallprocedureat();
                        // swal("Procedure Deleted", "Procedure Successfully Deleted..!", "success").then((swalres)=>{
                            // this.fetchallprocedureat();
                        // });
                    }
                })
            }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { filteredListt } = this.state;
        return(
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    <button className="btn btn-primary btn-sm my-2" onClick={()=>{this.setState({expanded : !this.state.expanded})}}>Add Therapy</button>
                </div>
                <hr />
                <div className="card mb-3" style={{ display: this.state.expanded ? 'block' : 'none', transition: "2s" ,transitionTimingFunction: "ease" }}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5>Add Therapy</h5>
                            <div>
                                <a className="btn btn-danger btn-sm" style={{marginLeft:"10px"}} onClick={() => this.setState({expanded : !this.state.expanded}) }>Hide</a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Therapy Name</label>
                                        <input type="text" className=" form-control w-100 h-100" name="proceudrename" placeholder="Name" onChange={this.handleChange} required />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Therapy Price</label>
                                        <input type="number" className=" form-control w-100 h-100" name="procedureprice" placeholder="INR" onChange={this.handleChange} required />
                                    </div>
                                </div>
                                <div className="col-sm-2 mt-3">
                                    <button type="submit" className="btn btn-sm btn-primary w-100 h-100">Save Procedure</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div style={{ padding:"10px" }}>
                            <input type="text" className="form-control" name="drugsearch" placeholder="Search...." value={this.state.searchQuery} onChange={this.handleSearchQueryChange} />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        {
                            filteredListt.map((item,index)=>{
                                return(
                                        <>
                                            <div className="card p-3 mt-2">
                                                <div className="d-flex justify-content-between">
                                                    <p className="m-0">{item.procedure_name}</p>
                                                    <p className="m-0 d-flex">
                                                        ₹{item.procedure_price}
                                                        <box-icon type='solid' name='edit' class="mx-2" style={{cursor:"pointer"}} onClick={()=>this.openedittherapy(item.procedure_id)}></box-icon>
                                                        <box-icon name='trash' style={{fill:"#dc3545",cursor:"pointer"}} onClick={()=>this.deletetherapy(item.procedure_id)} ></box-icon>
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                )
                            })
                        }
                    </div>
                </div>

                <Modal
                        isOpen={this.state.modaleditopen}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="Therapy Edit"
                        ariaHideApp={false}
                    >
                        <h5>Edit Therapy</h5>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Therapy Name</label>
                                        <input type="text" className=" form-control w-100 h-100" name="proceudrenameedit" value={this.state.proceudrenameedit} placeholder="Name" onChange={this.handleChange} required />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Therapy Price</label>
                                        <input type="number" className=" form-control w-100 h-100" name="procedurepriceedit" value={this.state.procedurepriceedit} placeholder="INR" onChange={this.handleChange} required />
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-3">
                                    <button onClick={this.updateprocedure} className="btn btn-sm btn-primary w-100 h-100">Update Procedure</button>
                                </div>
                            </div>
                    </Modal>
            </>
        )
    }
}