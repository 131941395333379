// import logo from './logo.svg';
import './App.css';
// main form import
import Header from './components/header';
import Sidebar from './components/sidebar';
import Login from './login';
import Dashboard from './dashboard';
import Doctor from './doctor';
import Permissions from './permissions';
import Patient from './patient';
import Add_patient from './add_patient';
import Appointment from './appointment';
import Add_appointment from './add_appointment';
import Patient_Dashboard from './patient_dashboard';
import Settings from './settings';
import Invoice from './invoice';
import Treatmentplanst from './treatmentplans';
import Viewtreatmentplans from './viewtreatmentplans';
import PrecriptionMain from './prescription';
import ViewPrecriptionMain from './viewprescription';
import Viewtreatmentmain from './viewtreatmentmain';

import Viewtreatmentmainnew from './viewtreatmentmainnew';

import Viewdietmain from './viewdietmain';
import CommunicationMain from './communicationmain';
import Forgot_password from './forgot_password';
import AddditeDetailsForm from './addditedetailsform';
import AddphysotherapyDetailsForm from './addphysotherapydetailsform';
import Add_patientfromad from './add_patientfromad';
import Invoicepuid from './invoicepuid';
import Invoicepuidview from './invoicepuidview';
import AddTherapy from './addtherapy';
import Report from './report';


//patient dashboard import
import Profile from './patient_dashboard/profile';
import Appointmentpd from './patient_dashboard/appoinmentpd';
import Communication from './patient_dashboard/communication';
import Prescription from './patient_dashboard/prescription';
import Viewaddprescription from './patient_dashboard/viewaddprescription';
import Invoicepd from './patient_dashboard/invoicepd';
import Treatmentplans from './patient_dashboard/traetmentplans';
import Addtreatment from './patient_dashboard/addtreatment';
import Completeprocedure from './patient_dashboard/completeprocedure';
import Consultation from './patient_dashboard/consultation';
import ConsultationView from './patient_dashboard/consultationview';
import Adddietplan from './patient_dashboard/adddietplan';
import Viewtreatment from './patient_dashboard/viewtratment';
import Viewdietpd from './patient_dashboard/viewdietpd';
import Adddietplannew from './patient_dashboard/adddietplannew';
import Resultdietplan from './patient_dashboard/resultdietplan';
import Invoicepdview from './patient_dashboard/innvoiceview';
import OrthoNeuroFrom from './patient_dashboard/orthoneurofrom';
import OrthoNeuroFromB from './patient_dashboard/orthoneurofromb';
import Billingpd from './patient_dashboard/billingpd';
import Ledgerpd from './patient_dashboard/ledgerpd';
import Dietplans from './patient_dashboard/dietplans';
import Labfiles from './patient_dashboard/labfiles';


// import Checklogin from './components/checklogin';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.css';


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';

function App() {

  if(window.location.pathname !== '/'){
    // || window.location.pathname !== '/forgotpassword' || window.location.pathname !== '/add_patient'
    if(localStorage.getItem("user") === null || localStorage.getItem("user_id") === null){
      if(window.location.pathname === '/add_patient' || window.location.pathname === '/forgotpassword'){}else{
        window.location.href = '/';
      }
    }
  }
 
  // const [piddata, setPiddata] = useState([]);

  // useEffect(() => {
  //   // console.log(piddata.length)
  //   if(piddata.length == 0)
  //   {
  //     const data = {
  //       fetchroutespdata : 'yes'
  //     }
  //     axios.post('https://clinic.dnyanasha.com/ws/routes.php',data).then((res)=>{
  //       console.log(res.data)
  //       setPiddata(res.data.findallpid)
  //     })
  //   }
  // });

  // const history = useHistory();
  // console.log(history);
  // console.log((history.location.pathname).replace("/",""));
  // const urlpath = (history.location.pathname).replace("/","");
  // console.log( JSON.parse(localStorage.getItem("roleslugarr")) );
  // const accessarr = JSON.parse(localStorage.getItem("roleslugarr"));
  
  // console.log( accessarr.indexOf(urlpath) > -1 );
  // const [accessvalue , setAccessvalue] = useState(false);
  // if(accessarr.indexOf(urlpath) > -1){
  //     if(accessarr.indexOf(urlpath) == accessvalue){setAccessvalue(true);}
  // } else {
  //     if(accessarr.indexOf(urlpath) == accessvalue){setAccessvalue(false);}
  // }

  // console.log(localStorage.getItem("user"));
  // console.log(window.location.pathname);
  // console.log(process.env.PUBLIC_URL);
  // console.log((localStorage.getItem("user") == null && window.location.pathname == "/add_patient") ? "yes" : "no");

  return (
    <>
    {/* <Checklogin /> */}
      {/* <Router basename='/coreconcept'> */}
      <Router>
        <Switch>
            <Route exact path={ "/"} component={Login} />
            <Route exact path={  "/forgotpassword"} component={Forgot_password} />
          <>
            {
              // window.location.pathname.includes('/admin') ? 
              // (
                <div>
                  {(localStorage.getItem("user") == null && window.location.pathname == "/add_patient") ? (<></>) : (
                      <>
                      {
                        (localStorage.getItem("user") == null && window.location.pathname == "/forgotpassword") ? (<></>) : (
                          <Header />
                        )
                      }
                      </>
                    )
                  }
                  <div style={{display:"flex",height:"100%"}}>
                    {(localStorage.getItem("user") == null && window.location.pathname == "/add_patient") ? (<></>) : (
                        <>
                        {
                          (localStorage.getItem("user") == null && window.location.pathname == "/forgotpassword") ? (<></>) : (
                            <Sidebar />
                          )
                        }
                        </>
                      )
                    }
                    <div style={{padding:"15px 15px 15px 15px",height:"100%",width:"100%",marginLeft:"3.5rem"}}>
                      
                      {/* main routes */}
                      <Route exact path={  "/dashboard"} component={Dashboard} />
                      <Route exact path={  "/doctor"} component={Doctor} />
                      <Route exact path={  "/permissions"} component={Permissions} />
                      <Route exact path={  "/patient"} component={Patient} />
                      <Route exact path={  "/add_patient"} component={Add_patient} />
                      <Route exact path={  "/add_patient/fromad"} component={Add_patientfromad} />
                      <Route exact path={  "/appointment"} component={Appointment} />
                      <Route exact path={  "/adddietdeatilform/:appid"} component={AddditeDetailsForm} />
                      <Route exact path={  "/addphysotherapydeatilform/:appid"} component={AddphysotherapyDetailsForm} />
                      <Route exact path={  "/add_appointment"} component={Add_appointment} />
                      {/* <Route exact path="/patient_dashboard" component={Patient_Dashboard} /> */}
                      <Route exact path={  "/setting"} component={Settings} />
                      <Route exact path={  "/invoice"} component={Invoice} />
                      <Route exact path={  "/invoice/:puid"} component={Invoicepuid} />

                      {/* <Route exact path={  "/invoice/:puid/view/:appid"} component={Invoicepuidview} /> */}
                      <Route exact path={  "/invoice/:puid/view/:invno"} component={Invoicepuidview} />

                      <Route exact path={  "/treatmentplans"} component={Treatmentplanst} />
                      <Route exact path={  "/treatment/:puid/viewtreatmentplans"} component={ Viewtreatmentplans} />
                      <Route exact path={  "/treatment/:puid/viewtreatmentplans/view/:treatid/:appid"} component={ Viewtreatmentmain} />

                      {/* <Route exact path={  "/treatment/:puid/viewtreatmentplans/view/new/:appid"} component={ Viewtreatmentmainnew } /> */}
                      
                      {/* <Route exact path={  "/treatment/:puid/viewdietplans/view/:dietid"} component={ Viewdietmain} /> */}
                      <Route exact path={  "/treatment/:puid/viewdietplans/view/:appid"} component={ Viewdietmain} />
                      <Route exact path={  "/prescription"} component={ PrecriptionMain} />
                      <Route exact path={  "/prescription/viewprescription/:appid"} component={ ViewPrecriptionMain} />
                      <Route exact path={  "/communication"} component={CommunicationMain} />
                      <Route exact path={  "/addtherapy"} component={AddTherapy} />
                      <Route exact path={  "/report"} component={Report} />

                      {/* main routes */}




                      {/* patient dashboard routes */}
                      {/* <Route exact path="/patient_dashboard/profile" component={Profile} /> */}
                      <Route exact path={  "/patient_dashboard/:puid"} component={Patient_Dashboard} />
                      <Route exact path={  "/patient_dashboard/:puid/profile"} component={Profile} />
                      <Route exact path={  "/patient_dashboard/:puid/appointment"} component={Appointmentpd} />
                      <Route exact path={  "/patient_dashboard/:puid/adddietdeatilform/:appid"} component={AddditeDetailsForm} />
                      <Route exact path={  "/patient_dashboard/:puid/addphysotherapydeatilform/:appid"} component={AddphysotherapyDetailsForm} />
                      <Route exact path={  "/patient_dashboard/:puid/communication"} component={Communication} />
                      <Route exact path={  "/patient_dashboard/:puid/prescription"} component={Prescription} />
                      <Route exact path={  "/patient_dashboard/:puid/viewaddprescription/:appid"} component={Viewaddprescription} />
                      {/* <Route exact path="/patient_dashboard/:puid/invoicepd/:appid" component={Invoicepd} /> */}
                      <Route exact path={  "/patient_dashboard/:puid/invoicepd"} component={Invoicepd} />

                      {/* <Route exact path={  "/patient_dashboard/:puid/invoicepd/view/:appid"} component={Invoicepdview} /> */}
                      <Route exact path={  "/patient_dashboard/:puid/invoicepd/view/:invno"} component={Invoicepdview} />

                      <Route exact path={  "/patient_dashboard/:puid/billingpd"} component={Billingpd} />
                      <Route exact path={  "/patient_dashboard/:puid/ledgerpd"} component={Ledgerpd} />
                      <Route exact path={  "/patient_dashboard/:puid/treatmentplans"} component={Treatmentplans} />
                      <Route exact path={  "/patient_dashboard/:puid/dietplans"} component={Dietplans} />
                      <Route exact path={  "/patient_dashboard/:puid/completeprocedure"} component={Completeprocedure} />
                      {/* <Route exact path={  "/patient_dashboard/:puid/addtreatment"} component={Addtreatment} /> */}
                      <Route exact path={  "/patient_dashboard/:puid/addtreatment/:appid"} component={Addtreatment} />

                      <Route exact path={  "/patient_dashboard/:puid/labfiles"} component={Labfiles} />
                      <Route exact path={  "/patient_dashboard/:puid/consultation"} component={Consultation} />

                      <Route exact path={  "/patient_dashboard/:puid/consultation/:appid"} component={ConsultationView} />
                      <Route exact path={  "/patient_dashboard/:puid/consultation/orthoneuroform/:appid"} component={OrthoNeuroFrom} />
                      {/* <Route exact path={  "/patient_dashboard/:puid/consultation/orthoneuroform/forma"} component={OrthoNeuroFrom} /> */}
                      <Route exact path={  "/patient_dashboard/:puid/consultation/orthoneuroformb/:appid"} component={OrthoNeuroFromB} />
                      {/* <Route exact path={  "/patient_dashboard/:puid/consultation/orthoneuroformb/formb"} component={OrthoNeuroFromB} /> */}
                      <Route exact path={  "/patient_dashboard/:puid/adddietplan"} component={Adddietplan} />
                      <Route exact path={  "/patient_dashboard/:puid/adddietplanew/:appid"} component={Adddietplannew} />
                      <Route exact path={  "/patient_dashboard/:puid/resultdietplan/:appid"} component={Resultdietplan} />
                      <Route exact path={  "/patient_dashboard/:puid/treatmentplans/view/:treatid/:appid"} component={Viewtreatment} />
                      <Route exact path={  "/patient_dashboard/:puid/viewdietplans/view/:dietid"} component={ Viewdietpd} />
                      {/* {
                        piddata.map((item,index)=>{
                          // console.log(item)
                          // var purl = item.unique_pid+"/patient_dashboard";
                          var purl = "/patient_dashboard/:puid";
                          console.log(purl)
                        })
                      } */}
                      {/* patient dashboard routes */}


                      {/* <Route exact path="/admin/dashboard" component={Dashboard} /> */}
                      {/* <Route exact path="/admin/doctor" component={Doctor} /> */}
                      {/* <Route exact path="/admin/permissions" component={Permissions} /> */}
                      {/* <Route exact path="/admin/patient" component={Patient} /> */}
                      {/* <Route exact path="/admin/add_patient" component={Add_patient} /> */}
                      {/* <Route exact path="/admin/appointment" component={Appointment} /> */}
                      {/* <Route exact path="/admin/add_appointment" component={Add_appointment} /> */}
                      {/* <Route exact path="/admin/patient_dashboard" component={Patient_Dashboard} /> */}
                      {/* <Route exact path="/admin/setting" component={Settings} /> */}
                    </div>
                  </div>
                </div>
              // ) : (
              //   <>
              //   </>
              // )
            }
          </>
        </Switch>
      </Router>
    </>
  );
}

export default App;
