import React from "react";

import smileEmoji from './assets/smile.png';
import smile1Emoji from './assets/smile1.png';
import confuseEmoji from './assets/confused.png';
import sadEmoji from './assets/sad.png';
import sad1Emoji from './assets/sad1.png';
import disapptEmoji from './assets/dissapointment.png';
import humanBody from './assets/humanbody.png';
import axios from "axios";
import swal from 'sweetalert';

import Webcam from "react-webcam";
import Modal from 'react-modal';

import ImageMarker, { Marker } from 'react-image-marker';

import './patient.css';


export default class Add_patient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              },
            videoConstraints : {
                width: "500",
                height: "500",
                facingMode: "user"
            },
            imageSrc : "",
            markers : [],
            pid:null,
            imgfile : null,
            otherfollwdiseas:false
            // < Array < Marker >> [{top : 10, left : 50,}],
            // markers : Array < Marker > [ { top : 10, left : 50, },]
        }

        // console.log(this.props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleimgChange = this.handleimgChange.bind(this);

        this.fetchpdata();
    }

    fetchpdata = () =>{
        const datap = {
            fetchapdata : 'yes',
        }
        axios.post("https://clinic.dnyanasha.com/ws/patient.php",datap).then((res)=>{
            console.log(res.data);
            this.setState({pid:res.data.pid})
        })
    }

    CustomMarker = (marker) => {
        return (
            <div className="image-marker__marker image-marker__marker--default text-dark" data-testid="marker">{marker['itemNumber']+1}</div>
        );
        // <div style={{border:"1px solid block",height:"15px",width:"15px"}} data-testid="marker"></div>
        // return (
        //     <>
        //         <input type="text" className="form-control p-0" style={{width:"50px"}}  />
        //         <div className="image-marker__marker image-marker__marker--default" data-testid="marker"></div>
        //     </>
        // );
      };

    handleChange = (event) => {
        let name = event.target.name
        this.setState({ [name] : event.target.value });
        
        if(event.target.value === "other" && name === "other" ){
            this.setState({otherfollwdiseas:!this.state.otherfollwdiseas})
        }
    }

    handleimgChange = (event) => {
        // console.log(event.target.files[0]);
        this.setState({imgfile:event.target.files[0]})
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        const datapatient = {
            add_patient : 'yes',
            fullname : this.state.fullname, 
            pmail : this.state.pmail,
            pid : this.state.pid,
            fdate    : this.state.fdate,
            pcity    : this.state.pcity,
            pdistrict: this.state.pdistrict, 
            pphone    : this.state.pphone,
            pdob    : this.state.pdob,
            psex : this.state.psex,
            paddres  : this.state.paddres,
            imageSrc : this.state.imageSrc,
        }
        console.log(datapatient);
        if(this.state.pphone.length === 10){
            axios.post('https://clinic.dnyanasha.com/ws/patient.php',datapatient).then((res)=>{
                // console.log(res.data)
                if(res.data.savepatient > 0)
                {
                    if(this.state.imgfile == null)
                    {
                        swal("Patient Added", "Patient Successfully added..!", "success").then((swalres)=>{
                            if(swalres)
                            {
                                this.props.history.goBack();
                            }
                        });
                    } else {
                        var formdata = new FormData;
                        formdata.append("saveproimg",'yes');
                        formdata.append("imagefile",this.state.imgfile);
                        // formdata.append("imageSrc",this.state.imageSrc);
                        formdata.append("patientid",res.data.savepatient);
                        formdata.append("pid",this.state.pid);
                        axios.post("https://clinic.dnyanasha.com/ws/patient.php",formdata).then((ress)=>{
                            // console.log(ress.data)
                            if(ress.data.updatepropicurl == true)
                            {
                                swal("Patient Added", "Patient Successfully added..!", "success").then((swalres)=>{
                                    if(swalres)
                                    {
                                        this.props.history.goBack();
                                    }
                                });
                            }
                        })
                    }
                } else {
                    swal(res.data.savepatient, res.data.savepatient, "error").then((swalres)=>{});
                }
            })
        } else {
            swal("Error", "Invalid Mobile Number..!", "error").then((swalres)=>{})
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    opencameramodel = () =>{
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;  
    }

    changecamerapos = () =>{
        const currentFacingMode = this.state.videoConstraints.facingMode;
        this.setState({
            videoConstraints: {
              ...this.state.videoConstraints,
              facingMode: currentFacingMode === "user" ? "environment" : "user"
            }
        });
    }

    render() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                </div>
                <div className="card p-3">
                    <div className="row">
                        <div className="col-sm-12 cardheadper">
                            <h6>Add Patient</h6>
                        </div>


                        <div className="col-sm-12">

                            <div className="core-container" id="printablediv">
                                <form onSubmit={this.handleSubmit} style={{paddingRight:"70px",paddingLeft:"70px"}}>
                                        
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <img src={(this.state.imgfile) ? URL.createObjectURL(this.state.imgfile) : (this.state.imageSrc) ? this.state.imageSrc  : null } style={{height:"120px",width:"120px"}} />
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="form-group">
                                                    <label>Profile Picture</label>
                                                    <div className="input-group">
                                                        <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg, image/jpg" onChange={this.handleimgChange} />
                                                        <div className="input-group-append"><span className="input-group-text" onClick={this.opencameramodel} style={{borderRadius:"0 0.375rem 0.375rem 0",cursor:"pointer"}}><box-icon type='solid' name='camera-plus'></box-icon></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-sm-1">
                                                <label>Date/दिनांक</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <input type="date" className="form-control" placeholder="Date"  name="fdate" onChange={this.handleChange}/>
                                            </div>
                                            <div className="col-sm-2" style={{width:"110px"}}>
                                                <label>Patient ID</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" name="patientid" value={this.state.pid} readOnly />
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label className="col-sm-1 col-form-label">Name / नांव</label>
                                            <div className="col-sm-5">
                                                <input type="text" className="form-control" placeholder="Full Name" name="fullname" onChange={this.handleChange} required />
                                            </div>
                                            <label className="col-sm-1 col-form-label">Mobile No. / फोन नं.</label>
                                            <div className="col-sm-5">
                                                <input type="number" maxLength={10} className="form-control" placeholder="Mobile Number" name="pphone" onChange={this.handleChange} required />
                                            </div>
                                        </div> {/*------form-group----- */}

                                        <div className="form-group row">
                                            <label className="col-sm-1 col-form-label">Email / ईमेल</label>
                                            <div className="col-sm-5">
                                                <input type="email" className="form-control" placeholder="Email / ईमेल" name="pmail" onChange={this.handleChange}/>
                                            </div>
                                            <label className="col-sm-1 form-label">Gender / लिंग</label>
                                            <div className="col-sm-5">{/*splin*/ }
                                                <select className="form-select " onChange={this.handleChange} name="psex" required>
                                                    <option value="">select Gender</option>
                                                    <option value="m">Male</option>
                                                    <option value="f">Female</option>
                                                </select>
                                            </div>
                                        </div> {/*------form-group----- */}

                                        <div className="form-group row">
                                            {/* <div className="col-sm-4 col-form-label"> */}
                                                <label className="col-sm-1 form-label">DOB / जन्म दिनांक</label>
                                            {/* </div> */}
                                            <div className="col-sm-3 col-form-label">
                                                <input type="date" className="form-control" placeholder="Date" name="pdob" onChange={this.handleChange}/>
                                            </div>
                                            {/* <label className="col-sm-1 form-label">DOB / जन्म दिनांक</label>
                                            <div className="col-sm-3 col-form-label">
                                                <input type="date" className="form-control" placeholder="Date" name="pdob" onChange={this.handleChange}/>
                                            </div> */}
                                            
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-1 col-form-label">Address / पत्ता</label>
                                            <div className="col-sm-11">
                                                <input type="text" className="form-control" placeholder="Address" name="paddres" onChange={this.handleChange}/>
                                            </div>
                                        </div> {/*------form-group----- */}

                                        <div className="form-group row">
                                            <label className="col-sm-1 col-form-label">City / शहर</label>
                                            <div className="col-sm-5">
                                                <input type="text" className="form-control" placeholder="City" name="pcity" onChange={this.handleChange}/>
                                            </div>
                                            <label className="col-sm-1 col-form-label">District / जिल्हा</label>
                                            <div className="col-sm-5">
                                                <input type="text" className="form-control" placeholder="District" name="pdistrict" onChange={this.handleChange}/>
                                            </div>
                                        </div> {/*------form-group----- */}

                                        


                                        <hr />

                                        <p className="text-center"><b>Disclaimer</b></p>

                                        <p>Our treatments and consultations are based on the information provided by you.
                                             Any misinformation or withholding any information from the practitioner may result into incorrect diagnosis 
                                             and line of treatment which may affect the Patient’s health, 
                                             Core Concept shall not be held liable in such cases. 
                                             The Patient shall indemnify Core Concept in case of any damages,
                                              losses and health issues arising out of misinformation or withholding of such information.</p>

                                        {/* <p className="text-center"><b>Consent Form</b></p>
                                        <p>
                                            <table>
                                                <tr>
                                                    <td className="d-flex" style={{width:"30px"}}>1.</td>
                                                    <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                                                </tr>
                                                <tr>
                                                    <td className="d-flex" style={{width:"30px"}}>2.</td>
                                                    <td>I confirm that the Core Concept team explained the procedure and treatment to me thoroughly to me and how it will help me with my current condition.</td>
                                                </tr>
                                                <tr>
                                                    <td className="d-flex" style={{width:"30px"}}>3.</td>
                                                    <td>I understand the risk and complications if I do not follow the aftercare instructions or follow ups as advised.</td>
                                                </tr>
                                                <tr>
                                                    <td className="d-flex" style={{width:"30px"}}>4.</td>
                                                    <td>I confirm that the information provided by me is true to my knowledge and I am fully responsible for any information that I hide or withhold for the purpose of this treatment and I indemnify Core Concept and its team against any losses and damages arising out of withholding or hiding such information.</td>
                                                </tr>
                                                <tr>
                                                    <td className="d-flex" style={{width:"30px"}}>5.</td>
                                                    <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                                                </tr>
                                            </table> */}
                                            {/* 1. I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.<br />
                                            2. I confirm that the Core Concept team explained the procedure and treatment to me thoroughly to me and how it will help me with my current condition.<br />
                                            3. I understand the risk and complications if I do not follow the aftercare instructions or follow ups as advised.<br />
                                            4. I confirm that the information provided by me is true to my knowledge and I am fully responsible for any information that I hide or withhold for the purpose of this treatment and I indemnify Core Concept and its team against any losses and damages arising out of withholding or hiding such information.<br />
                                            5. I voluntarily give my consent to the doctors, therapists and consultants of Core Concept for performing the treatment and procedure on me. */}
                                        {/* </p> */}

                                        <div>
                                            <button type="submit" className="btn btn-primary mt-2">Submit</button>
                                        </div>

                                </form>
                            </div>

                        </div>


                    </div>
                </div>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={this.state.customStyles}
                    contentLabel="Department Modal"
                    ariaHideApp={false}
                >
                    <Webcam 
                        audio={false}
                        height={"50%"}
                        screenshotFormat="image/jpeg"
                        width={"50%"}
                        videoConstraints={this.state.videoConstraints}
                    >

                        {({ getScreenshot }) => (<>
                            <div className="d-flex">
                            <button className="btn btn-primary btn-sm mt-2"
                                onClick={() => {
                                    const imageSrc = getScreenshot();
                                    this.setState({imageSrc:imageSrc})
                                    console.log(imageSrc);
                                    this.closeModal()
                                }}
                            >
                                Capture photo
                            </button>
                            <button className="btn btn-primary btn-sm mx-2 mt-2" onClick={this.changecamerapos}><box-icon type='solid' style={{fill:"#fff"}} name='analyse'></box-icon></button>
                            </div>
                        </>)}
                    </Webcam>
                </Modal>
            </>
        )
    }
}