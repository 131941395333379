import axios from "axios";
import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import swal from "sweetalert";

export default class Viewdietpd extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            dietvalarrpd : [],
            datearrdpd : [],
            findditespd : [],
            monthname : ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
        }

        this.fetchdietpd();
    }

    goBack = () => {
        this.props.history.goBack();
    }

    fetchdietpd = () =>{
        const datavtm = {
            fetchdietpd : "yes",
            puid : this.props.match.params.puid,
            dietid : this.props.match.params.dietid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/dietmain.php",datavtm).then((res)=>{
            console.log(res.data)
            this.state = { findditespd : res.data.finddites }

            if(res.data.findgivendiet != false){
                this.setState({ dietvalarrpd : JSON.parse(res.data.findgivendiet['ditedata']) })
            }

            this.setState({ fromdate : res.data.finddites['fromdate']})
            this.setState({ todate : res.data.finddites['todate']})

            var fromdate = new Date(res.data.finddites['fromdate'])
            var todate = new Date(res.data.finddites['todate'])

            var datearrd = [];
            while (fromdate <= todate) {
                datearrd.push(new Date(fromdate));
                fromdate.setDate(fromdate.getDate() + 6);
            }
            this.setState({ datearrdpd : datearrd })

        })
    }


    handleChange = (event,index,date) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
        let ditevalarr = this.state.dietvalarrpd;
        let newval = { date : date, weight : event.target.value }
        if(event.target.value != "")
        {
            if(ditevalarr.some(e => e.date == newval['date'])){         // if date is equal
                if(ditevalarr.some(e => e.status != newval['weight'])){                            // if status is equal
                    ditevalarr.splice(ditevalarr.findIndex(({ date }) => date === newval['date']), 1)
                    ditevalarr.push(newval);
                }
            }
            else{
                ditevalarr.push(newval);
            }
        } else {
            ditevalarr.splice(ditevalarr.findIndex(({ date }) => date === newval['date']), 1)
        }
        this.setState({dietvalarrpd:ditevalarr})
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const datadpt = {
            savedptpd : "yes",
            dietdata : JSON.stringify(this.state.dietvalarrpd),
            puid : this.props.match.params.puid,
            dietid : this.props.match.params.dietid,
        }
        console.log(datadpt)
        axios.post("https://clinic.dnyanasha.com/ws/dietmain.php",datadpt).then((res)=>{
            console.log(res.data)
            if(res.data.savedtp > 0){
                swal("Diet Detail Saved", "Patient Diet Detail Saved Successfully..!", "success").then((swalres)=>{
                });
                this.fetchtreatmian();
            } else if(res.data.updatedtp == true){
                swal("Diet Detail Updated", "Patient Diet Detail Updated Successfully..!", "success").then((swalres)=>{
                });
                this.fetchtreatmian();
            }
        })
    }

    checkweightinputpd = (wname,date,index) =>{
        for(let j=0;j<this.state.dietvalarrpd.length;j++){
            var newdatenew = new Date(this.state.dietvalarrpd[j]['date'])
            var newdate = newdatenew.getFullYear()+"-"+(newdatenew.getMonth()+1)+"-"+newdatenew.getDate();
            var weight = "";
            if(date == newdate){
                weight = this.state.dietvalarrpd[j]['weight'];
                break; 
            }
        }
        return(
            <>
                <div className="col-sm-12 p-0 d-flex align-items-center justify-content-between">
                    Weight : <input type="number" name={wname} value={weight} onChange={(e)=>this.handleChange(e,index,date)} min={"1"} className="form-control w-75 ms-sm-3 p-1" />
                </div>
            </>
        )
    }

    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"226px",padding:"5px"}}>
                    <div>
                        <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    </div>
                    <hr style={{margin:"1.5rem 0",borderTop:"3px solid #616161",opacity:"1"}} />
                    <b>Note : </b> Kindly Enter Weight at every 7th day.

                    <form onSubmit={this.handleSubmit} className="mt-4"> 
                    {
                        this.state.datearrdpd.map((item, index) =>{
                            var date = item.getFullYear()+"-"+(item.getMonth()+1)+"-"+item.getDate();
                            var wname = this.state.monthname[item.getMonth()]+"_"+item.getDate()+"_"+item.getFullYear()+"_w";
                            // var nname = this.state.monthname[item.getMonth()]+"_"+item.getDate()+"_"+item.getFullYear()+"_n";
                            if(index === 0){ var bordertb = "1px solid #000000"; } else { var bordertb = ''; }
                            return(
                                <>
                                    <div className="row" key={index}>
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-2 d-flex align-items-center justify-content-center pb-2" style={{borderBottom:" 1px solid #000000",borderLeft:"1px solid #000000",borderTop:bordertb}}>
                                            <p className="m-0">{item.getDate()}-{this.state.monthname[item.getMonth()]}-{item.getFullYear()}</p>
                                        </div>
                                        <div className="col-sm-4 pb-2" style={{borderBottom:" 1px solid #000000",borderRight:"1px solid #000000",borderTop:bordertb}}>
                                            <div className="row m-0 mt-2">
                                                {
                                                    this.checkweightinputpd(wname,date,index)
                                                }
                                                {/* <div className="col-sm-12 p-0 d-flex align-items-center justify-content-between">
                                                    Weight : <input type="number" name={wname} value={this.state.wname} onChange={(e)=>this.handleChange(e,index,date)} min={"1"} className="form-control w-75 ms-sm-3 p-1" />
                                                </div> */}
                                                <div className="col-sm-12 p-0 d-flex align-items-center justify-content-between mt-2">
                                                    {/* Note : <input type="text" name={nname} value={this.state.nname} onChange={(e)=>this.handleChange(e,index,date)} className="form-control w-75 ms-sm-3 p-1" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3"></div>
                                    </div>
                                </>
                            )
                        })
                    }

                    {
                        (localStorage.getItem("user") === "Doctor") ? (<>
                            <div className="row">
                                <div className="col-sm-12 d-flex justify-content-center mt-3">
                                    <button type="submit" className="btn btn-primary btn-sm" >Submit</button>
                                </div>
                            </div>
                        </>) : (<></>)
                    }
                    </form>

                </div>
            </>
        )
    }
}