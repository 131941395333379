import axios from "axios";
import React, { Component } from "react"
import Select from 'react-select'

export default class Headerpd extends Component{

    constructor(props){
        super(props);
        this.state = {
            findallpatient : [],
            unique_pid : null,
            metaarr : [],
            mainscreen : null,
            pdata : null,
            findthispatient : [],
            options:[],
            pid:[],
        }
        // console.log(this.props)

        this.fetchpddata();
        this.handlechangepid = this.handlechangepid.bind(this)
    }

    fetchpddata = () =>{
        const pddata = {
            fetchpddata : 'yes',
            // patientid : this.props.location.state.pdata.patient_id,
            // patientid : this.state.pdata.patient_id,
            patientid : this.props.puid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/patient.php",pddata).then((res)=>{
            console.log(res.data)
            // this.setState({findallpatient:res.data.findallpatient})
            // this.setState({unique_pid:this.props.match.params.puid})
            this.setState({metaarr:res.data.metaarr})
            this.setState({findthispatient:res.data.findthispatient})
            this.setState({mainscreen:"profile"})
            // console.log(res.data.metaarr)

            // var parr = [];
            // for(let i=0;i<res.data.findallpatient.length;i++){
            //     parr.push({value:res.data.findallpatient[i]['unique_pid'],label:res.data.findallpatient[i]['patient_name']})
            // }
            // this.setState({options:parr})
            var pl = [];
            res.data.findallpatient.map((res)=>{
                    // console.log(res.unique_pid+"="+res.patient_name)
                    pl.push({value:res.unique_pid, label : res.patient_name})
            })
            this.setState({ options : pl })
            this.setState({ pid : pl.findIndex(({value}) => value == this.props.puid) })


        })
    }

    handlechangepid = (event) =>{
        // console.log(event['value'])
        this.setState({pid:event['value']})
        // this.props.history.push(  "/")
        // this.props.history.push(  "/patient_dashboard/" + event['value'] + "/profile")
    }


    render(){
        return(
            <>
                {/* patient dashboard header */}
                <div className="row m-auto">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-3">
                            <div className="form-group">

                                {/* <Select className="form-control p-0" options={this.state.options} value={this.state.options[this.state.pid]} name="pname" onChange={this.handlechangepid} /> */}
                                <input type={"text"} style={{fontSize:"12px"}} value={this.state.findthispatient.patient_name} disabled className="form-control mt-1"/>
                                {/* <select className="form-control mt-1" value={this.props.puid} onChange={()=>{}} disabled>
                                    <option>Select Patient</option>
                                    {
                                        this.state.findallpatient.map((res,index)=>{
                                            return(
                                                <option key={index} value={res.unique_pid}>{res.patient_name}</option>
                                            )
                                        })
                                    }
                                </select> */}
                            </div>
                        </div>
                        <div className="col-sm-2 d-flex align-items-center" style={{fontSize:"12px"}}>
                            { (this.state.findthispatient.gender == 'm') ? ("Male") : ("Female")  } ({ new Date().getFullYear() - new Date(this.state.findthispatient.dob).getFullYear() })
                        </div>
                        <div className="col-sm-4 d-flex align-items-center" style={{fontSize:"12px"}}>
                            {  "( ID : " + this.props.puid + " )"  }
                            {/* {  "( ID : " + this.state.unique_pid + " )"  } */}
                        </div>
                    </div>
                {/* patient dashboard header */}
                <hr style={{marginBottom:"0"}} />
            </>
        )
    }
}