import axios from "axios";
import React from "react";
import Profile from "./patient_dashboard/profile";
import Appointmentpd from "./patient_dashboard/appoinmentpd";

export default class Patient_Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            findallpatient : [],
            unique_pid : null,
            metaarr : [],
            // mainscreen : "profile",
            mainscreen : null,
            pdata : null,
            findthispatient : [],
        }

        console.log(this.props);
        // console.log(this.props.match.params.puid);

        // if(this.props.pdata)
        // {
        //     this.setState({ pdata:this.props.pdata })
        // } else {
        //     this.setState({ pdata:this.props.location.state.pdata })
        // }

        this.fetchpddata();
    }

    // goBack = () => {
    //     this.props.history.goBack();
    // }

    fetchpddata = () =>{
        const pddata = {
            fetchpddata : 'yes',
            // patientid : this.props.location.state.pdata.patient_id,
            // patientid : this.state.pdata.patient_id,
            patientid : this.props.match.params.puid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/patient.php",pddata).then((res)=>{
            console.log(res.data)
            this.setState({findallpatient:res.data.findallpatient})
            this.setState({unique_pid:this.props.match.params.puid})
            this.setState({metaarr:res.data.metaarr})
            this.setState({findthispatient:res.data.findthispatient})
            this.setState({mainscreen:"profile"})
            // console.log(res.data.metaarr)
        })
    }

    render(){
        return(
            <>
                {/* <div>
                    <button className="btn btn-primary backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                </div> */}

                {/* patient dashboard header */}
                {/* <div className="card p-3"> */}
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                                {/* <label>Select Patient</label> */}
                                <select className="form-control mt-1" value={this.state.unique_pid}>
                                    <option>Select Patient</option>
                                    {
                                        this.state.findallpatient.map((res,index)=>{
                                            return(
                                                <option key={index} value={res.unique_pid}>{res.patient_name}</option>
                                                )
                                            })
                                        }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-2 d-flex align-items-center">
                            {/* { (this.props.location.state.pdata.gender == 'm') ? ("Male") : ("Female")  } ({ this.state.metaarr.page }) */}
                            { (this.state.metaarr.psex == 'm') ? ("Male") : ("Female")  } ({ this.state.metaarr.page })
                        </div>
                        <div className="col-sm-4 d-flex align-items-center">
                            {/* {  "( ID : " + this.props.location.state.pdata.unique_pid + " )"  } */}
                            {  "( ID : " + this.state.unique_pid + " )"  }
                        </div>
                    </div>
                {/* </div> */}
                {/* patient dashboard header */}
                <hr style={{marginBottom:"0"}} />
                {/* patient dashboard sidebar */}
                <div className="p-1" style={{width:"220px",border:"1px solid #dee2e6",borderTopWidth:"0",position:"absolute",height:"75%"}}>
                    
                    <p style={{color:"#0088c1"}}><b>Patient</b></p>
                    <div className="d-flex " style={{marginLeft:"10px"}} onClick={()=>{this.setState({ mainscreen : "profile" });/*this.props.history.push({pathname:'/patient_dashboard/profile/'+this.state.unique_pid})*/}} ><box-icon  type='solid' name='user-detail' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Profile</div>
                    <div className="d-flex pt-2" style={{marginLeft:"10px"}} onClick={()=>{this.setState({ mainscreen : "appointments" })}}><box-icon  type='solid' name='calendar' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Appointments</div>
                    <div className="d-flex pt-2" style={{marginLeft:"10px"}}><box-icon  type='solid' name='chat' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Communication</div>
                    
                    <p style={{color:"#0088c1",marginTop:"12px"}}><b>EMR</b></p>
                    <div className="d-flex " style={{marginLeft:"10px"}}><box-icon   name='plus-medical' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Prescription</div>
                    
                    <p style={{color:"#0088c1",marginTop:"12px"}}><b>Billings</b></p>
                    <div className="d-flex " style={{marginLeft:"10px"}}><box-icon  type='solid' name='receipt' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Invoice</div>
                    <div className="d-flex pt-2" style={{marginLeft:"10px"}}><box-icon  name='rupee' style={{marginRight:"7px",fill:"#747679"}} ></box-icon> Payments</div>
                </div>
                {/* patient dashboard sidebar */}

                {/* patient main screen */}
                <div style={{marginLeft:"220px",padding:"5px"}}>

                    {/* profile screen start */}
                    {
                        (this.state.mainscreen == "profile") ? (<>
                            <Profile profilemetadata={this.state.metaarr} profiledata={this.state.findthispatient} uniqueid={this.state.unique_pid} />
                            {/* <p>Profile Screen</p> */}
                        </>) : (<></>)
                    }
                    {/* profile screen end */}

                    {/* appointement screen start */}
                    {
                        (this.state.mainscreen == "appointments") ? (<>
                            <Appointmentpd uniqueid={this.state.unique_pid} profiledata={this.state.findthispatient} />
                            {/* <p>Appointement Screen</p> */}
                        </>) : (<></>)
                    }
                    {/* appointement screen end */}
                </div>
                {/* patient main screen */}
            </>
        )
    }
}