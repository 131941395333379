import axios from "axios";
import React, { Component } from "react";

// import smileEmoji from '../assets/smile.png';
// import smile1Emoji from '../assets/smile1.png';
// import confuseEmoji from '../assets/confused.png';
// import sadEmoji from '../assets/sad.png';
// import sad1Emoji from '../assets/sad1.png';
// import disapptEmoji from '../assets/dissapointment.png';
import swal from 'sweetalert';
// import humanBody from '../assets/humanbody.png';
// import ImageMarker, { Marker } from 'react-image-marker';

import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';

export default class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            metaarr: [],
            profiledata: [],
            imgfile: null,
            markers: [],
            otherfollwdiseas: false,
            consentmodal : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            imgcaptured : null,
            signmodel : false,
        }

        this.fetchprfileddata();
        this.fethcconsetnform();
        this.handleimgChange = this.handleimgChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    fethcconsetnform = () =>{
        const consentdata = {
            checkconsent : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',consentdata).then((res)=>{
            // console.log(res.data);
            this.setState({consentsigned:res.data.consentsigned})
            if(res.data.consentsigned !== false){
                this.setState({imgcaptured : res.data.checkconsent['signed']})
            }
        })
    }

    // CustomMarker = (marker) => {
    //     return (
    //         <div className="image-marker__marker image-marker__marker--default text-dark" data-testid="marker">{marker['itemNumber'] + 1}</div>
    //     );
    // };

    handleChange = (event) => {
        let name = event.target.name
        this.setState({ [name]: event.target.value });

        // if (event.target.value === "other" && name === "other") {
        //     this.setState({ otherfollwdiseas: !this.state.otherfollwdiseas })
        // }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const updatepdata = {
            updatepatient : "yes",
            puid : this.props.match.params.puid,
            patient_name : this.state.patient_name,
            mobile_number : this.state.mobile_number,
            email : this.state.email,
            pdob : this.state.pdob,
            psex : this.state.psex,
            paddres : this.state.paddres,
            pdistrict : this.state.pdistrict,
            pcity : this.state.pcity,
            
            // page : this.state.page,
            // pheight : this.state.pheight,
            // pweight : this.state.pweight,
            // poccup : this.state.poccup,
            // pmarried : this.state.pmarried,
            // pspouse : this.state.pspouse,
            // pref : this.state.pref,
            // pbill : this.state.pbill,
            // ppycash : this.state.ppycash,
            // pcpresult : this.state.pcpresult,
            // pmajourcmpl : this.state.pmajourcmpl,
            // pcddev : this.state.pcddev,
            // poffwrk : this.state.poffwrk,
            // prectreat : this.state.prectreat,
            // pprblmget : this.state.pprblmget,
            // panycndworse : this.state.panycndworse,
            // emoji : this.state.emoji,
            // paffcthmlife : this.state.paffcthmlife,
            // paffctoccuplife : this.state.paffctoccuplife,
            // paffctrectlife : this.state.paffctrectlife,
            // paffctrestlife : this.state.paffctrestlife,
            // pmeddiag : this.state.pmeddiag,
            // pdrugtk : this.state.pdrugtk,
            // pphypast : this.state.pphypast,
            // pdoctnm : this.state.pdoctnm,
            // pdoctconslt : this.state.pdoctconslt,
            // pproblem : this.state.pproblem,
            // pcovidvaccine : this.state.pcovidvaccine,
            // pallergiestomedicine : this.state.pallergiestomedicine,
            // bloodpressure : this.state.bloodpressure,
            // Diabetes : this.state.Diabetes,
            // Arthritis : this.state.Arthritis,
            // autoimmuned : this.state.autoimmuned,
            // Cancer : this.state.Cancer,
            // other : this.state.other,
            // markers : JSON.stringify(this.state.markers),
        }
        console.log(updatepdata)
        axios.post('https://clinic.dnyanasha.com/ws/patient.php',updatepdata).then((res)=>{
            // console.log(res.data)
            if(res.data.updatepatient === true){
                swal("Patient Updated", "Patient Updated Successfully added..!", "success").then((swalres)=>{})
            }
        })
    }

    fetchprfileddata = () => {
        const pddata = {
            fetchprfileddata: 'yes',
            patientid: this.props.match.params.puid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/patient.php", pddata).then((res) => {
            console.log(res.data)
            this.setState({ metaarr: res.data.metaarr })
            this.setState({ profiledata: res.data.findthispatient })

            this.setState({ patient_name: res.data.findthispatient.patient_name })
            this.setState({ mobile_number: res.data.findthispatient.mobile_number })
            this.setState({ email: res.data.findthispatient.email })
            this.setState({ psex: res.data.findthispatient.gender })
            this.setState({ pdob: res.data.findthispatient.dob })
            this.setState({ paddres: res.data.findthispatient.address })
            this.setState({ pdistrict: res.data.findthispatient.district })
            this.setState({ pcity: res.data.findthispatient.city })
            // this.setState({ psex: res.data.metaarr.psex })
            // this.setState({ pdob: res.data.metaarr.pdob })
            // this.setState({ paddres: res.data.metaarr.paddres })
            // this.setState({ pdistrict: res.data.metaarr.pdistrict })
            // this.setState({ pcity: res.data.metaarr.pcity })

            // this.setState({ page: res.data.metaarr.page })
            // this.setState({ pheight: res.data.metaarr.pheight })
            // this.setState({ pweight: res.data.metaarr.pweight })
            // this.setState({ poccup: res.data.metaarr.poccup })
            // this.setState({ pmarried: res.data.metaarr.pmarried })
            // this.setState({ pspouse: res.data.metaarr.pspouse })
            // this.setState({ pref: res.data.metaarr.pref })
            // this.setState({ pbill: res.data.metaarr.pbill })
            // this.setState({ ppycash: res.data.metaarr.ppycash })
            // this.setState({ pcpresult: res.data.metaarr.pcpresult })
            // this.setState({ pmajourcmpl: res.data.metaarr.pmajourcmpl })
            // this.setState({ pcddev: res.data.metaarr.pcddev })
            // this.setState({ poffwrk: res.data.metaarr.poffwrk })
            // this.setState({ prectreat: res.data.metaarr.prectreat })
            // this.setState({ pprblmget: res.data.metaarr.pprblmget })
            // this.setState({ panycndworse: res.data.metaarr.panycndworse })
            // this.setState({ emoji: res.data.metaarr.emoji })
            // this.setState({ paffcthmlife: res.data.metaarr.paffcthmlife })
            // this.setState({ paffctoccuplife: res.data.metaarr.paffctoccuplife })
            // this.setState({ paffctrectlife: res.data.metaarr.paffctrectlife })
            // this.setState({ paffctrestlife: res.data.metaarr.paffctrestlife })
            // this.setState({ pmeddiag: res.data.metaarr.pmeddiag })
            // this.setState({ pdrugtk: res.data.metaarr.pdrugtk })
            // this.setState({ pphypast: res.data.metaarr.pphypast })
            // this.setState({ pdoctnm: res.data.metaarr.pdoctnm })
            // this.setState({ pdoctconslt: res.data.metaarr.pdoctconslt })
            // this.setState({ pproblem: res.data.metaarr.pproblem })
            // this.setState({ pcovidvaccine: res.data.metaarr.pcovidvaccine })
            // this.setState({ pallergiestomedicine: res.data.metaarr.pallergiestomedicine })
            // this.setState({ bloodpressure: res.data.metaarr.bloodpressure })
            // this.setState({ Diabetes: res.data.metaarr.Diabetes })
            // this.setState({ Arthritis: res.data.metaarr.Arthritis })
            // this.setState({ autoimmuned: res.data.metaarr.autoimmuned })
            // this.setState({ Cancer: res.data.metaarr.Cancer })
            // this.setState({ other: res.data.metaarr.other })
            // this.setState({ markerdescription: res.data.metaarr.markerdescription })
            // this.setState({ markers: JSON.parse(res.data.metaarr.markers) })
            // if (res.data.metaarr.other == "other") { this.setState({ otherfollwdiseas: true }) }

        })
    }

    handleimgChange = (event) => {
        this.setState({ imgfile: event.target.files[0] })
    }

    closeModal = () => {
        this.setState({ consentmodal : false })
    }

    signcloseModal = () => {
        this.setState({ signmodel : false })
    }

    openconsentform = () =>{
        this.setState({consentmodal:true})
    }

    opensignmodel = () =>{
        this.setState({ signmodel : true })   
    }

    clear = () => {
        this.sigPad.clear()
    }

    trim = () => {
        this.setState({imgcaptured: this.sigPad.getTrimmedCanvas().toDataURL('image/png')})
        this.signcloseModal();
    }

    handleconsentsubmit = (e) =>{
        e.preventDefault();
        const dataconsent = {
            submitconsent : "yes",
            puid : this.props.match.params.puid,
            imgcaptured : this.state.imgcaptured,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',dataconsent).then((res)=>{
            if(res.data.saveconsent > 0){
                swal("Consent Saved", "Consent Saved Successfully..!", "success").then((swalres) => { });
            }
        })
    }
 
    render() {
        return (
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                {/* <Patient_Dashboard /> */}
                <div style={{ marginLeft: "220px", padding: "5px" }}>

                    <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-primary btn-sm my-2" onClick={this.openconsentform} >View Consent Form</button>
                    </div>
                    <Modal
                        isOpen={this.state.consentmodal}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="Signataure Model"
                        ariaHideApp={false}
                    >
                        <h5 className="text-center">You'r Consent Form. Please Sign before Traetment.</h5>
                        <hr />
                        <p>
                            <table>
                                <tr>
                                    <td className="d-flex" style={{width:"30px"}}>1.</td>
                                    <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                                </tr>
                                <tr>
                                    <td className="d-flex" style={{width:"30px"}}>2.</td>
                                    <td>I confirm that the Core Concept team explained the procedure and treatment to me thoroughly to me and how it will help me with my current condition.</td>
                                </tr>
                                <tr>
                                    <td className="d-flex" style={{width:"30px"}}>3.</td>
                                    <td>I understand the risk and complications if I do not follow the aftercare instructions or follow ups as advised.</td>
                                </tr>
                                <tr>
                                    <td className="d-flex" style={{width:"30px"}}>4.</td>
                                    <td>I confirm that the information provided by me is true to my knowledge and I am fully responsible for any information that I hide or withhold for the purpose of this treatment and I indemnify Core Concept and its team against any losses and damages arising out of withholding or hiding such information.</td>
                                </tr>
                                <tr>
                                    <td className="d-flex" style={{width:"30px"}}>5.</td>
                                    <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                                </tr>
                            </table>
                        </p>
                        <hr />
                        <div className="d-flex justify-content-between px-4">
                            <div className="text-center">
                                <p className="m-0 "><b>{new Date().getDate() + " " + this.state.monthname[new Date().getMonth()] + " " + new Date().getFullYear()}</b></p>
                                <p className="m-0 ">Date</p>
                            </div>
                            <div className="text-center">
                                <p className="m-0 ">
                                    {
                                        (this.state.imgcaptured === null ) ? (
                                            <div style={{border:"1px solid #000",height:"60px",width:"120px"}} onClick={this.opensignmodel}></div>
                                        ) : (
                                            <div style={{border:"1px solid #000",height:"60px",width:"120px"}} onClick={this.opensignmodel}><img style={{height:"60px",width:"120px"}} src={this.state.imgcaptured} /></div>
                                        )
                                    }
                                </p>
                                <p className="m-0 ">Digital Signature</p>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-primary mt-2 hideonprint" onClick={this.handleconsentsubmit}>Submit</button>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={this.state.signmodel}
                        onRequestClose={this.signcloseModal}
                        style={this.state.customStyles}
                        contentLabel="Signataure Model"
                        ariaHideApp={false}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <SignatureCanvas penColor='black' canvasProps={{width: 200, height: 100, className: 'sigCanvas'}} ref={(ref) => { this.sigPad = ref }} />
                            </div>
                            <div className="col-sm-12">
                                <button className="btn btn-danger btn-sm" onClick={this.clear}> Clear </button>
                                <button className="btn btn-primary btn-sm mx-4" onClick={this.trim}> Capture </button>
                            </div>
                        </div>
                    </Modal>
                    <hr />

                    <div className="container-fluid">
                        <form onSubmit={this.handleSubmit}>
                            <div className="row mt-2 p-3">
                                <div className="col-sm-2">
                                    {(this.state.profiledata.profile_picture == "") ? (
                                    <img src={(this.state.imgfile) ? URL.createObjectURL(this.state.imgfile) :  this.state.profiledata.pro_pic_data} style={{ height: "120px", width: "120px" }} />) : (
                                        <img src={(this.state.imgfile) ? URL.createObjectURL(this.state.imgfile) : "https://clinic.dnyanasha.com/ws/" + this.state.profiledata.profile_picture} style={{ height: "120px", width: "120px" }} />
                                    )}
                                </div>
                                <div className="col-sm-5">
                                    <div className="form-group">
                                        <label>Update Profile Picture</label>
                                        <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg, image/jpg" onChange={this.handleimgChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control" value={this.state.patient_name} name="patient_name" onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Patient ID</label>
                                        <input type="text" className="form-control" name="puid" value={this.state.profiledata.unique_pid} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <input type="number" minLength={10} maxLength={10} min={1} className="form-control" value={this.state.mobile_number} name="mobile_number" onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>E-Mail ID</label>
                                        <input type="text" className="form-control" value={this.state.email} name="email" onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <label className="form-label">Gender</label>
                                    <select className="form-select" onChange={this.handleChange} value={this.state.psex} name="psex">
                                        <option value="">Select Gender</option>
                                        <option value="m">Male</option>
                                        <option value="f">Female</option>
                                    </select>
                                </div>
                                <div className="col-sm-5">
                                    <label className="form-label">DOB</label>
                                    <input type="date" className="form-control" placeholder="Date" value={this.state.pdob} name="pdob" onChange={this.handleChange} />
                                </div>


                                {/* <div className="col-sm-3">
                                    <label className="col-form-label">Age</label>
                                    <input type="number" min={"1"} className="form-control splin" placeholder="Age" value={this.state.page} name="page" onChange={this.handleChange} />
                                </div>
                                <div className="col-sm-3">
                                    <label className="col-form-label">Height</label>
                                    <input type="number" min={"1"} className="form-control splin" placeholder="Height" value={this.state.pheight} name="pheight" onChange={this.handleChange} />cm
                                </div>
                                <div className="col-sm-3">
                                    <label className="col-form-label">Weight</label>
                                    <input type="number" min={"1"} className="form-control splin" placeholder="Weight" value={this.state.pweight} name="pweight" onChange={this.handleChange} />kg
                                </div> */}
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-1 col-form-label">Address</label>
                                <div className="col-sm-11">
                                    <input type="text" className="form-control" placeholder="Address" value={this.state.paddres} name="paddres" onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-1 col-form-label">City</label>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control" placeholder="City" value={this.state.pcity} name="pcity" onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-1 col-form-label">District</label>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control" placeholder="District" value={this.state.pdistrict} name="pdistrict" onChange={this.handleChange} />
                                </div>
                            </div>

                            {/* <div className="row mt-2">
                                <label className="col-sm-2 col-form-label">Occupation</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" placeholder="Occupation" value={this.state.poccup} name="poccup" onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-1 col-form-label">DOB</label>
                                <div className="col-sm-5">
                                    <input type="date" className="form-control" placeholder="Date" value={this.state.pdob} name="pdob" onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Married" id="pmarriedyes" name="pmarried" checked={(this.state.pmarried == "Married") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pmarriedyes">Married</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="single" id="pmarriedno" name="pmarried" checked={(this.state.pmarried == "single") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pmarriedno">Single</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">Name of Spouse / Father</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="Name of Spouse / Father /" value={this.state.pspouse} name="pspouse" onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">Referred to our office by</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="Referred to our office by " value={this.state.pref} name="pref" onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">who is responsible for your bill?</label>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="self" id="pbillself" name="pbill" checked={(this.state.pbill == "self") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pbillself">Self</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Soupse" id="pbillspous" name="pbill" checked={(this.state.pbill == "Soupse") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pbillspous">Soupse</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="employer" id="pbillemployer" name="pbill" checked={(this.state.pbill == "employer") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pbillemployer">Employer</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">How will payment be made?</label>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Cash" id="ppaymentcash" name="ppycash" checked={(this.state.ppycash == "Cash") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="ppaymentcash">Cash</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Cheque" id="ppaymentchq" name="ppycash" checked={(this.state.ppycash == "Cheque") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="ppaymentchq">Cheque</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Digital" id="ppaymentdigi" name="ppycash" checked={(this.state.ppycash == "Digital") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="ppaymentdigi">Digital</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-8 col-form-label">Is this complaint resulting from any accident?</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Yes" id="pcpresultYes" name="pcpresult" checked={(this.state.pcpresult == "Yes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pcpresultYes">Yes</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="No" id="pcpresultNo" name="pcpresult" checked={(this.state.pcpresult == "No") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pcpresultNo">No</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Please describe only your major complaint</label>
                                <div className="col-sm-12">
                                    <textarea className="form-control" rows="2" name="pmajourcmpl" value={this.state.pmajourcmpl} onChange={this.handleChange}></textarea>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">How this condition develop?</label>
                                <div className="col-sm-12">
                                    <textarea className="form-control" rows="2" name="pcddev" value={this.state.pcddev} onChange={this.handleChange}></textarea>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-8 col-form-label">Are you currently off work because of your problem?</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Yes" id="poffwrkYes" checked={(this.state.poffwrk == "Yes") ? true : false} name="poffwrk" onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="poffwrkYes">Yes</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="No" id="poffwrkNo" checked={(this.state.poffwrk == "No") ? true : false} name="poffwrk" onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="poffwrkNo">No</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Have you received any treatment for this condition? If yes, where, when and what were the results?</label>
                                <div className="col-sm-12">
                                    <textarea className="form-control" rows="2" name="prectreat" value={this.state.prectreat} onChange={this.handleChange}></textarea>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">Has this problem been getting</label>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="better" id="pprblmgetbetter" name="pprblmget" checked={(this.state.pprblmget == "better") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pprblmgetbetter">Better</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Worse" id="pprblmgetWorse" name="pprblmget" checked={(this.state.pprblmget == "Worse") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pprblmgetWorse">Worse</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Stay_the_same" id="pprblmgetStay_the_same" name="pprblmget" checked={(this.state.pprblmget == "Stay_the_same") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pprblmgetStay_the_same">Stay the same</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Is there anything you do that makes your condition worse</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" placeholder="Is there anything you do that makes your condition worse" name="panycndworse" value={this.state.panycndworse} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Descirbe Your Pain <span>How severe is the Pain Tick at Rest</span></label>
                                <div className="col-sm-12">
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "smileEmoji") ? "clickedemoji" : ""}`} src={smileEmoji} onClick={() => this.setState({ emoji: "smileEmoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "smile1Emoji") ? "clickedemoji" : ""}`} src={smile1Emoji} onClick={() => this.setState({ emoji: "smile1Emoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "confuseEmoji") ? "clickedemoji" : ""}`} src={confuseEmoji} onClick={() => this.setState({ emoji: "confuseEmoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "sadEmoji") ? "clickedemoji" : ""}`} src={sadEmoji} onClick={() => this.setState({ emoji: "sadEmoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "sad1Emoji") ? "clickedemoji" : ""}`} src={sad1Emoji} onClick={() => this.setState({ emoji: "sad1Emoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "disapptEmoji") ? "clickedemoji" : ""}`} src={disapptEmoji} onClick={() => this.setState({ emoji: "disapptEmoji" })} alt="emoji" width={"100px"} /></span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">How has this condition affected your life?</label>
                                <label className="col-sm-3 col-form-label">A) Home life</label>
                                <div className="col-sm-9 mt-1">
                                    <input type="text" className="form-control" placeholder="Home life" name="paffcthmlife" value={this.state.paffcthmlife} onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-3 col-form-label">B) Occupational life</label>
                                <div className="col-sm-9 mt-1">
                                    <input type="text" className="form-control" placeholder="Occupational life" name="paffctoccuplife" value={this.state.paffctoccuplife} onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-3 col-form-label">C) Recreational life</label>
                                <div className="col-sm-9 mt-1">
                                    <input type="text" className="form-control" placeholder="Recreational life" name="paffctrectlife" value={this.state.paffctrectlife} onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-3 col-form-label">D) Reset and sleep life</label>
                                <div className="col-sm-9 mt-1">
                                    <input type="text" className="form-control" placeholder="Reset and sleep life" name="paffctrestlife" value={this.state.paffctrestlife} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-6 col-form-label">Any medical diagnosis of your complaint</label>
                                <div className="col-sm-6">
                                    <input type="text" className="form-control" placeholder="any medical diagnosis of your complaint" name="pmeddiag" value={this.state.pmeddiag} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-6 col-form-label">Drugs you take now</label>
                                <div className="col-sm-6">
                                    <input type="text" className="form-control" placeholder="Drugs you take now" name="pdrugtk" value={this.state.pdrugtk} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-8 col-form-label">Any physical therapist consulted in the past</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="yes" id="pphypastyes" name="pphypast" checked={(this.state.pphypast == "yes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pphypastyes">Yes</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="No" id="pphypastNo" name="pphypast" checked={(this.state.pphypast == "No") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pphypastNo">No</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-1 col-form-label">Doctor Name</label>
                                <div className="col-sm-11">
                                    <input type="text" className="form-control" placeholder="Doctor Name" name="pdoctnm" value={this.state.pdoctnm} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-1 col-form-label">Date consulted</label>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control" placeholder="Date consulted" name="pdoctconslt" value={this.state.pdoctconslt} onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-2 col-form-label">For what problem</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" placeholder="For what problem" name="pproblem" value={this.state.pproblem} onChange={this.handleChange} />
                                </div>
                            </div>

                            <br></br>
                            <hr />
                            <h5>IF YOU ARE IN PAIN, PLEASE MARK THE EXACT LOCATION OF PAIN ON THIS DIAGRAM</h5>

                            <ImageMarker
                                src={humanBody}
                                markers={this.state.markers}
                                onAddMarker={(marker) => { let m = this.state.markers; console.log(m); m.push(marker); this.setState({ markers: m }) }}
                                markerComponent={(marker) => this.CustomMarker(marker)}
                            />

                            <span className="btn btn-primary" onClick={() => { let m = this.state.markers; m = m.slice(0, -1); this.setState({ markers: m }) }}>Remove Mark</span><br />
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Marks Decription</label>
                                <textarea className="form-control" rows="5" name="markerdescription" value={this.state.markerdescription} onChange={this.handleChange}></textarea>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Have you been vaccinated for covid?</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="yes" id="pcovidvaccineyes" name="pcovidvaccine" checked={(this.state.pcovidvaccine == "yes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pcovidvaccineyes">Yes</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="no" id="pcovidvaccineno" name="pcovidvaccine" checked={(this.state.pcovidvaccine == "no") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pcovidvaccineno">No</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Do you have any known allergies to any medicine, ointment, food etc?</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="yes" id="pallergiestomedicineyes" name="pallergiestomedicine" checked={(this.state.pallergiestomedicine == "yes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pallergiestomedicineyes">Yes</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="no" id="pallergiestomedicineno" name="pallergiestomedicine" checked={(this.state.pallergiestomedicine == "no") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pallergiestomedicineno">No</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Do you have any of the following?</label>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="bloodpressure" id="bloodpressureid" name="bloodpressure" checked={(this.state.bloodpressure == "bloodpressure") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="bloodpressureid"> Blood Pressure</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="Diabetes" id="Diabetesid" name="Diabetes" checked={(this.state.Diabetes == "Diabetes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="Diabetesid"> Diabetes</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="Arthritis" id="Arthritisid" name="Arthritis" checked={(this.state.Arthritis == "Arthritis") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="Arthritisid"> Arthritis</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="autoimmuned" id="autoimmunedid" name="autoimmuned" checked={(this.state.autoimmuned == "autoimmuned") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="autoimmunedid"> Auto Immune Disease</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="Cancer" id="Cancerid" name="Cancer" checked={(this.state.Cancer == "Cancer") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="Cancerid"> Cancer</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="other" id="otherid" name="other" checked={(this.state.other == "other") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="otherid"> Any other: Please specify</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    {
                                        (this.state.otherfollwdiseas) ? (<>
                                            <input type="text" className="form-control" placeholder="Other Reason" name="otherfollwdiseas" value={this.state.otherfollwdiseas} onChange={this.handleChange} />
                                        </>) : (<></>)
                                    }
                                </div>
                            </div> */}

                            <div>
                                <button type="submit" className="btn btn-primary mt-2">Update</button>
                            </div>

                        </form>
                    </div>

                </div>
            </>
        )
    }
}