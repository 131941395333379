import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";

export default class Completeprocedure extends Component{
    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px"}}>

                </div>
            </>
        )
    }
}