import React from "react";
import Webcam from "react-webcam";
import Modal from 'react-modal';
import axios from "axios";
import swal from 'sweetalert';

export default class Add_patientfromad extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            imageSrc : "",
            imgfile : null,
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              },
              videoConstraints : {
                width: "500",
                height: "500",
                facingMode: "user"
            },
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fetchpdata();
    }

    fetchpdata = () =>{
        const datap = {
            fetchapdata : 'yes',
        }
        axios.post("https://clinic.dnyanasha.com/ws/patient.php",datap).then((res)=>{
            console.log(res.data);
            this.setState({pid:res.data.pid})
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }

    handleChange = (event) => {
        let name = event.target.name
        this.setState({ [name] : event.target.value });
    }

    handleimgChange = (event) => {
        this.setState({imgfile:event.target.files[0]})
    }

    opencameramodel = () =>{
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const datapatient = {
            add_patient : 'yes',
            fullname : this.state.fullname, 
            pmail : this.state.pmail,
            pid : this.state.pid,
            fdate    : this.state.fdate,
            pcity    : this.state.pcity,
            pdistrict: this.state.pdistrict, 
            pphone    : this.state.pphone,
            pdob    : this.state.pdob,
            psex : this.state.psex,
            paddres  : this.state.paddres,
            imageSrc : this.state.imageSrc,

            bloodgroup : this.state.bloodgroup,
            ppincode : this.state.ppincode,
            referredby : this.state.referredby,
        }
        if(this.state.pphone.length === 10){
            axios.post('https://clinic.dnyanasha.com/ws/patient.php',datapatient).then((res)=>{
                if(res.data.savepatient > 0)
                {
                    if(this.state.imgfile == null)
                    { 
                        swal("Patient Added", "Patient Successfully added..!", "success").then((swalres)=>{ this.props.history.push(  '/add_appointment' , { patientid : res.data.savepatient, fromad : "yes" }); }) 
                    } else {
                        var formdata = new FormData;
                        formdata.append("saveproimg",'yes');
                        formdata.append("imagefile",this.state.imgfile);
                        formdata.append("patientid",res.data.savepatient);
                        formdata.append("pid",this.state.pid);
                        axios.post("https://clinic.dnyanasha.com/ws/patient.php",formdata).then((ress)=>{
                            if(ress.data.updatepropicurl == true)
                            {
                                swal("Patient Added", "Patient Successfully added..!", "success").then((swalres)=>{
                                    if(swalres)
                                    {
                                        this.props.history.push(  '/add_appointment')
                                    }
                                });
                            }
                        })
                    }
                } else {
                    swal(res.data.savepatient, res.data.savepatient, "error").then((swalres)=>{});
                }
            })
        } else {
            swal("Error", "Invalid Mobile Number..!", "error").then((swalres)=>{})
        }
    }

    changecamerapos = () =>{
        const currentFacingMode = this.state.videoConstraints.facingMode;
        this.setState({
            videoConstraints: {
              ...this.state.videoConstraints,
              facingMode: currentFacingMode === "user" ? "environment" : "user"
            }
        });
    }

    render(){
        return(
            <>
                <div className="card p-3">
                    <div className="row">
                        <div className="col-sm-12">
                            <h6>Add Patient</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="core-container" id="printablediv">
                                        <form onSubmit={this.handleSubmit} style={{paddingRight:"70px",paddingLeft:"70px"}}>
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <img src={(this.state.imgfile) ? URL.createObjectURL(this.state.imgfile) : (this.state.imageSrc) ? this.state.imageSrc  : null } style={{height:"120px",width:"120px"}} />
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="form-group">
                                                            <label>Profile Picture</label>
                                                            <div className="input-group">
                                                                <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg, image/jpg" onChange={this.handleimgChange} />
                                                                <div className="input-group-append"><span className="input-group-text" onClick={this.opencameramodel} style={{borderRadius:"0 0.375rem 0.375rem 0",cursor:"pointer"}}><box-icon type='solid' name='camera-plus'></box-icon></span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-5">
                                                    <div className="col-sm-1">
                                                        <label>Date/दिनांक</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input type="date" className="form-control" placeholder="Date"  name="fdate" onChange={this.handleChange}/>
                                                    </div>
                                                    <div className="col-sm-2" style={{width:"110px"}}>
                                                        <label>Patient ID</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input type="text" className="form-control" name="patientid" value={this.state.pid} readOnly />
                                                    </div>
                                                </div>


                                                <div className="form-group row">
                                                    <label className="col-sm-1 col-form-label">Name / नांव</label>
                                                    <div className="col-sm-5">
                                                        <input type="text" className="form-control" placeholder="Full Name" name="fullname" onChange={this.handleChange} required />
                                                    </div>
                                                    <label className="col-sm-1 col-form-label">Mobile No. / फोन नं.</label>
                                                    <div className="col-sm-5">
                                                        <input type="number" maxLength={10} className="form-control" placeholder="Mobile Number" name="pphone" onChange={this.handleChange} required />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-sm-1 col-form-label">Email / ईमेल</label>
                                                    <div className="col-sm-5">
                                                        <input type="email" className="form-control" placeholder="Email / ईमेल" name="pmail" onChange={this.handleChange}/>
                                                    </div>
                                                    <label className="col-sm-1 form-label">Gender / लिंग</label>
                                                    <div className="col-sm-5">{/*splin*/ }
                                                        <select className="form-select " onChange={this.handleChange} name="psex" required>
                                                            <option value="">select Gender</option>
                                                            <option value="m">Male</option>
                                                            <option value="f">Female</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    {/* <div className="col-sm-4 col-form-label"> */}
                                                        <label className="col-sm-2 form-label">DOB / जन्म दिनांक</label>
                                                    {/* </div> */}
                                                    <div className="col-sm-4 col-form-label">
                                                        <input type="date" className="form-control" placeholder="Date" name="pdob" onChange={this.handleChange}/>
                                                    </div>
                                                    <label className="col-sm-2 form-label">Blood Group / ब्लड ग्रुप</label>
                                                    <div className="col-sm-4 col-form-label">
                                                        <input type="text" className="form-control" placeholder="Blood Group" name="bloodgroup" onChange={this.handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-sm-1 col-form-label">Address / पत्ता</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className="form-control" placeholder="Address" name="paddres" onChange={this.handleChange}/>
                                                    </div>
                                                    <label className="col-sm-1 col-form-label">Pincode / पिनकोड</label>
                                                    <div className="col-sm-4">
                                                        <input type="number" className="form-control" placeholder="Pincode" name="ppincode" onChange={this.handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-sm-1 col-form-label">City / शहर</label>
                                                    <div className="col-sm-5">
                                                        <input type="text" className="form-control" placeholder="City" name="pcity" onChange={this.handleChange}/>
                                                    </div>
                                                    <label className="col-sm-1 col-form-label">District / जिल्हा</label>
                                                    <div className="col-sm-5">
                                                        <input type="text" className="form-control" placeholder="District" name="pdistrict" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-1 col-form-label">Referred By</label>
                                                    <div className="col-sm-5">
                                                        <input type="text" className="form-control" placeholder="Referred By" name="referredby" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary mt-2">Submit</button>
                                                    {/* <button type="submit" className="btn btn-primary mt-2">Next</button> */}
                                                </div>

                                        </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={this.state.customStyles}
                    contentLabel="Department Modal"
                    ariaHideApp={false}
                >
                    <Webcam 
                        audio={false}
                        height={"50%"}
                        screenshotFormat="image/jpeg"
                        width={"50%"}
                        videoConstraints={this.state.videoConstraints}
                    >

                        {({ getScreenshot }) => (<>
                            <div className="d-flex">
                            <button className="btn btn-primary btn-sm mt-2"
                                onClick={() => {
                                    const imageSrc = getScreenshot();
                                    this.setState({imageSrc:imageSrc})
                                    console.log(imageSrc);
                                    this.closeModal()
                                }}
                            >
                                Capture photo
                            </button>
                            <button className="btn btn-primary btn-sm mx-2 mt-2" onClick={this.changecamerapos}><box-icon type='solid' style={{fill:"#fff"}} name='analyse'></box-icon></button>
                            </div>
                        </>)}
                    </Webcam>
                </Modal>
            </>
        )
    }
}