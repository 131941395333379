import axios from "axios";
import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import swal from "sweetalert";

export default class Labfiles extends Component{

    constructor(props){
        super(props);
        this.state = {
            labfile : null,
            alllabfiles : [],
        }

        this.fetchlabfiles();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    fetchlabfiles = () =>{
        const datalabfiles = {
            fetchlabfiles : "yes",
            puid:this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/labfileupload.php',datalabfiles).then((res)=>{
            this.setState({alllabfiles : res.data.findlabfile})
        })
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        var formdata = new FormData;
        formdata.append("addlabfile",'yes');
        formdata.append("labfile",this.state.labfile);
        formdata.append("puid",this.props.match.params.puid);
        axios.post('https://clinic.dnyanasha.com/ws/labfileupload.php',formdata).then((res)=>{
            this.fetchlabfiles();
            this.setState({labfile:null})
        })
    }

    handleFileUpload = (event) => {
        // console.log(event.target.files[0]);
        this.setState({labfile:event.target.files[0]})
    };

    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px"}}>
                    <div className="row mt-2">
                        <div className="col-sm-10">
                            <input type={"file"} className="form-control" onChange={this.handleFileUpload} />
                        </div>
                        <div className="col-sm-2">
                            <button className="btn btn-primary btn-sm mt-1" onClick={this.handleSubmit}>Submit</button>
                        </div>
                    </div>
                    <hr />

                    <div className="row mt-2">
                        {
                            this.state.alllabfiles.map((res,index)=>{
                                return(
                                    <div className="col-sm-12">
                                        File {index+1} : <a href={`https://clinic.dnyanasha.com/ws/uploads/labfile/${res.labfilename}`} target={"_blank"}>{res.labfilename}</a>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </>
        )
    }
}