import axios from "axios";
import DataTable from 'react-data-table-component'
import React, { Component } from "react";
import swal from "sweetalert";
import Modal from 'react-modal';

export default class Invoicepuid extends Component{

    constructor(props){
        super(props);
        this.state = {
            columns : [
                {
                    name:"Appointment Date",
                    selector:(row)=>row.schedule_date
                },
                {
                    name:"Time",
                    selector:(row)=><span>{row.start_time} - {row.end_time}</span>,
                    wrap:true,
                },
                {
                    name:"Invoice Number",
                    selector:(row)=>row.invoice_number
                },
                {
                    name:"Total Amount",
                    selector:(row)=>row.total_amount
                },
                {
                    name:"Action",
                    cell:(row)=>{return(<>
                                        <button className="btn btn-sm btn-primary" onClick={()=>{this.props.history.push('/invoice/'+this.props.match.params.puid+'/view/'+row.appointment_id)}}>View Innvoice</button>
                                </>)},
                    width:"170px"
                },
            ],
            results : [],
            invoicearray : [],
            finallreceipt : [],
            totalgenrated : 0,
            findtotalpaid : 0,
            balanceamt : 0,
            pname : "",
            gender : "",
            mobileno : "",
            age : "",
            email : "",
            loadinginv : true,
            modalpayout : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
            paymentdate : new Date().toISOString().substr(0, 10), //getFullYear() + "-" + new Date().getMonth() + "-" + new Date().getDate(),
            payrecarray : [],
            rem_amount : 0,
        }

        this.fetchmaininnpdata()
    }

    fetchmaininnpdata = () =>{
        this.setState({loadinginv:true})
        const datapmi = {
            fetchmaininnpdata : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/invoice.php',datapmi).then((res)=>{
            console.log(res.data)
            this.setState({ findallapp : res.data.findallapp })
            this.setState({ results : res.data.findallapp })
            this.setState({ invoicearray : res.data.invoicearray })
            this.setState({ finallreceipt : res.data.finallreceipt })
            this.setState({ totalgenrated : res.data.totalgenrated })
            this.setState({ findtotalpaid : res.data.findtotalpaid })
            this.setState({ balanceamt : (res.data.totalgenrated - res.data.findtotalpaid) })

            this.setState({pname : res.data.findpatientdetails['patient_name']})
            this.setState({gender : res.data.findpatientdetails['gender']})
            this.setState({mobileno : res.data.findpatientdetails['mobile_number']})
            this.setState({age : res.data.findpatientdetails['dob']})
            this.setState({email : res.data.findpatientdetails['email']})
            this.setState({loadinginv:false})
            
        })

        axios.post('https://clinic.dnyanasha.com/ws/paymentrecived.php',{fetchpayrec : "yes",puid : this.props.match.params.puid}).then((res)=>{
            console.log(res.data)
            this.setState({payrecarray : res.data.payrecarray})
            this.setState({rem_amount : res.data.rem_amount})
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    payamountout = () => {
        this.setState({ modalpayout : true })
    }

    closeModal = () => {
        this.setState({ modalpayout : false })
    }

    handlemop = (e) =>{
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value === "cash"){
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
        if(e.target.value === "cheque"){
            this.setState({checkdetails : true})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
        if(e.target.value === "card"){
            this.setState({checkdetails : false})
            this.setState({carddetails : true})
            this.setState({upidetails : false})
        }
        if(e.target.value === "upi"){
            this.setState({upidetails : true})
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
        }
        if(e.target.value === ""){
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
    }

    paymentpaidtoclinic = () =>{
        const datapay = {
            paymentpaidtoclinic : "yes",
            amttopay : this.state.amttopay,
            modeopay : this.state.modeopay,
            bankname : this.state.bankname,
            chequeno : this.state.chequeno,
            last4digino : this.state.last4digino,
            transactionno : this.state.transactionno,
            paymentdate : this.state.paymentdate,
            puid : this.props.match.params.puid,
        }
        console.log(datapay)
        axios.post('https://clinic.dnyanasha.com/ws/paymentrecived.php',datapay).then((res)=>{
            console.log(res.data)
            if(res.data.savepayrec > 0)
            // swal("Amount Paid", "Amount Paid Successfully..!", "success").then((swalres)=>{
                this.fetchmaininnpdata();
                this.closeModal();
            // });
        })
    }

    clearthisinvoice = (invoice_number) =>{
        console.log(invoice_number)
        axios.post("https://clinic.dnyanasha.com/ws/paymentrecived.php",{payclearinvoice:"yes",invoice_number:invoice_number,puid : this.props.match.params.puid}).then((res)=>{
            console.log(res.data);
            if(res.data.updateremamt === true || res.data.saveacceptname > 0 ){
                this.fetchmaininnpdata();
            }
        })
    }

    render(){
        if(this.state.loadinginv === true){
            return(<>
                <h1 className="text-center">Loading Please Wait...!</h1>
            </>)
        } else {
        return(
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                        </div>
                        {/* <DataTable
                            columns={this.state.columns} 
                            data={this.state.results} 
                            style={{textAlign:"center"}}
                            // title="All Doctors"
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight='450px'
                            highlightOnHover
                            subHeader
                            onRowClicked={(row)=>{this.props.history.push('/invoice/'+this.props.match.params.puid+'/view/'+row.appointment_id)}}
                            subHeaderComponent={
                                <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                    <div style={{fontSize:"22px"}}>All Invoices</div>
                                    <input 
                                        type="text"
                                        placeholder='search here'
                                        className='w-25 form-control'
                                        value={this.state.search}
                                        onChange={(e)=>this.setSearch(e.target.value)}
                                    />
                                </div>
                            }
                        /> */}

                            <hr />
                            <div className="row">
                                <div className="col-sm-6">
                                    <p className="m-0"><b>Patient Name : </b>{this.state.pname}</p>
                                    <p className="m-0"><b>Gender : </b>{ (this.state.gender == 'm') ? ("Male") : ("Female")  }</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="m-0"><b>Mobile No. : </b>{this.state.mobileno}</p>
                                    <p className="m-0"><b>Age : </b>{ new Date().getFullYear() - new Date(this.state.age).getFullYear() }</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="m-0"><b>Email : </b>{this.state.email}</p>
                                </div>
                            </div>

                            <hr />
                                <div className="row">
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <button className="btn btn-primary btn-sm mx-3" onClick={this.payamountout}>Pay Amount</button>
                                    </div>
                                </div>
                            <hr />

                                <p className="m-0" style={{fontSize:"20px"}}>Paid Reciept</p>
                                <table className="table">
                                    <thead>
                                        <tr style={{fontSize:"13px"}} >
                                            <th style={{width:"105px"}}>PAID DATE</th>
                                            <th>PAY RECIPT NO.</th>
                                            <th>MODE OF PAYMENT</th>
                                            <th>TOTAL AMOUNT (₹)</th>
                                            <th>REMANING AMOUNT (₹)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.payrecarray.map((items,index)=>{
                                                return(<>
                                                        <tr style={{fontSize:"13px"}}>
                                                            <td>{items.payemnt_date}</td>
                                                            <td>{items.payment_reciept_no}</td>
                                                            <td>{items.modeofpay}</td>
                                                            <td>{items.amount}</td>
                                                            <td>{items.rem_amount}</td>
                                                        </tr>
                                                </>)
                                            })
                                        }
                                    </tbody>
                                </table>

                            <hr />

                            <p className="m-0" style={{fontSize:"20px"}}>Invoices</p>
                            <table className="table">
                                <thead>
                                    <tr style={{fontSize:"13px"}} >
                                        <th style={{width:"105px"}}>CREADTED DATE</th>
                                        <th>INVOICE NO.</th>
                                        <th>TOWARDS</th>
                                        <th>TOTAL AMOUNT (₹)</th>
                                        <th>PAID AMOUNT (₹)</th>
                                        <th>BALANCE (₹)</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.invoicearray.map((item,index)=>{
                                        return(
                                            <>
                                                <tr style={{fontSize:"13px",cursor:"pointer"}}>
                                                    <td onClick={()=>{this.props.history.push('/invoice/'+this.props.match.params.puid+'/view/'+item.invoice_number)}}  style={{width:"105px"}}><b>{item.created_at}</b></td>
                                                    <td onClick={()=>{this.props.history.push('/invoice/'+this.props.match.params.puid+'/view/'+item.invoice_number)}} ><b>{item.invoice_number}</b></td>
                                                    <td onClick={()=>{this.props.history.push('/invoice/'+this.props.match.params.puid+'/view/'+item.invoice_number)}}  dangerouslySetInnerHTML={{ __html: item.treatname}}>{}</td>
                                                    <td onClick={()=>{this.props.history.push('/invoice/'+this.props.match.params.puid+'/view/'+item.invoice_number)}} >{item.totalamt}</td>
                                                    <td onClick={()=>{this.props.history.push('/invoice/'+this.props.match.params.puid+'/view/'+item.invoice_number)}} >{item.paidamt}</td>
                                                    <td onClick={()=>{this.props.history.push('/invoice/'+this.props.match.params.puid+'/view/'+item.invoice_number)}} >{item.totalamt - item.paidamt}</td>
                                                    <td>
                                                        {((item.totalamt - item.paidamt) === 0 || this.state.rem_amount === 0 ) ? <></> : <button className="btn btn-primary btn-sm" onClick={()=>this.clearthisinvoice(item.invoice_number)}>Clear Bill</button>}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                                </tbody>
                                <tfoot>
                                    <tr style={{fontSize:"13px"}}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{this.state.totalgenrated} <b> : Total Invoice</b> </td>
                                        <td>{this.state.findtotalpaid} <b> : Total Paid</b></td>
                                        <td>{(this.state.balanceamt < 0) ? (<><b style={{color:"#01bfdb"}}>({this.state.balanceamt})Advance Amount</b></>) : (<><b style={{color:"#dc3545"}}>({this.state.balanceamt})Remaning Amount</b></>)}</td>
                                    </tr>
                                </tfoot>
                            </table>

                            <p className="m-0" style={{fontSize:"20px"}}>Payment</p>
                            <table className="table">
                                <thead>
                                    <tr style={{fontSize:"13px"}} >
                                        <th>PAID DATE</th>
                                        <th>RECEIPT NO.</th>
                                        <th>TOTAL AMOUNT (₹)</th>
                                        <th>TOWARDS</th>
                                        <th>MODE OF PAYMENT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.finallreceipt.map((item,index)=>{
                                        return(
                                            <>
                                                <tr style={{fontSize:"13px"}}>
                                                    <td><b>{item.payment_date}</b></td>
                                                    <td><b>{item.recept_no}</b></td>
                                                    <td>{item.amount}</td>
                                                    {/* style={{color:"#01bfdb"}} */}
                                                    <td>{item.invoiceno}</td>
                                                    <td>{item.modeofpay}</td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                                </tbody>
                            </table>


                            <hr />

                            <div className="row" style={{fontSize:"13px"}}>
                                <div className="col-sm-3"> <b>Total Invoice : </b> {this.state.totalgenrated}</div>
                                <div className="col-sm-3"> <b>Total Paid : </b> {this.state.findtotalpaid}</div>
                                <div className="col-sm-3">
                                    {
                                        (this.state.balanceamt < 0) ? (<><b><p style={{color:"#01bfdb"}}>Advance Amount({this.state.balanceamt})</p></b></>) : (<><b><p style={{color:"#dc3545"}}>Remaning Amount({this.state.balanceamt})</p></b></>)
                                    }
                                </div>
                            </div>


                    </div>
                </div>

                <Modal
                    isOpen={this.state.modalpayout}
                    onRequestClose={this.closeModal}
                    style={this.state.customStyles}
                    contentLabel="Add Invoice"
                    ariaHideApp={false}
                >
                    <h5>Pay Amount</h5>
                    <div className="row">
                        <div className="col-sm-10">
                            <div className="form-group d-flex">
                                <label className="form-label">Payment Date</label>
                                <input type={"date"} className="form-control mx-2 w-50" value={this.state.paymentdate} name="paymentdate" onChange={(event)=>this.setState({ paymentdate: event.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label className="form-label">Amount to Pay</label>
                                <input type={"number"} min={0} className="form-control" name="amttopay" value={this.state.amttopay} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label className="form-label">Mode of Payment</label>
                                <select className="form-control" onChange={this.handlemop} name="modeopay" value={this.state.modeopay} >
                                    <option value={""}>Select Mode</option>
                                    <option value={"upi"}>UPI</option>
                                    <option value={"cash"}>Cash</option>
                                    <option value={"cheque"}>Cheque</option>
                                    <option value={"card"}>Card</option>
                                </select>
                            </div>
                        </div>
                        {
                            (this.state.checkdetails) ? (<>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label">Bank Name</label>
                                    <input type={"text"} className="form-control" name="bankname" value={this.state.bankname} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label">Cheque No.</label>
                                    <input type={"number"} min={1} className="form-control" name="chequeno" value={this.state.chequeno} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                </div>
                            </div>
                            </>) : (<></>)
                        }
                        {
                            (this.state.carddetails) ? (<>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label">Last 4 Digit</label>
                                    <input type={"text"} className="form-control" name="last4digino" value={this.state.last4digino} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                </div>
                            </div>
                            </>) : (<></>)
                        }
                        {
                            (this.state.upidetails) ? (<>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label">Transaction No.</label>
                                        <input type={"text"} className="form-control" name="transactionno" value={this.state.transactionno} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                    </div>
                                </div>
                            </>) : (<></>)
                        }
                    </div>
                    <div className="row">
                        <div className="col-sm-1 mt-3">
                            <button className="btn btn-sm btn-primary" onClick={this.paymentpaidtoclinic}>Submit</button>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
    }
}