import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";

export default class Adddietplan extends Component{

    constructor(props){
        super(props);
        this.state = {
            imgfile : null,
            showadddietplan : false,
            finddietplan : [],
            dietplanlist : [],
            enablediscdp : false,
            discountvaldp : 0,
            distypedp : "PERCENT",
            // fileurlhost : window.location.origin+"/",
            fileurlhost : "https://clinic.dnyanasha.com/ws/",
            docotrid : 0,
        }

        this.handleimgChange = this.handleimgChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDietSubmit = this.handleDietSubmit.bind(this);

        if(localStorage.getItem('user') == "Doctor"){ this.setState({docotrid : localStorage.getItem("user_id")}) }

        this.fetchalldietplan();
        // console.log(window.location.origin);
    }

    fetchalldietplan = () =>{
        const datadp = {
            fetchdietplan : "yes",

        }
        axios.post("https://clinic.dnyanasha.com/ws/treatmentsplans.php",datadp).then((res)=>{
            console.log(res.data);
            this.setState({finddietplan:res.data.finddietplan});
        })
    }

    handleChange = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleimgChange = (event) =>{
        this.setState({imgfile:event.target.files[0]})
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        var formdata = new FormData;
        formdata.append("adddietplan",'yes');
        formdata.append("imagefile",this.state.imgfile);
        formdata.append("dietname",this.state.dietname);
        formdata.append("dietprice",this.state.dietprice);
        // formdata.append("puid",this.props.match.params.puid);
        axios.post("https://clinic.dnyanasha.com/ws/treatmentsplans.php",formdata).then((res)=>{
            console.log(res.data)
            if(res.data.savedietplan > 0){
                swal("Diet Plan Added", "Diet Plan Added Successfully..!", "success").then((swalres)=>{
                    this.setState({showadddietplan:false})
                    this.fetchalldietplan();
                });
            }
        })
    }

    hidedietp = () =>{
        this.setState({ showadddietplan : false })
    }

    adddietp = () =>{
        this.setState({ showadddietplan : true })
    }

    adddietplist = (item) =>{
        let dp = this.state.dietplanlist;
        dp.push(item);
        this.setState({dietplanlist:[item]})
        this.setState({proditeprice:item.dietprice})
        this.setState({totalvaldp:item.dietprice})
        this.setState({dietproid:item.dietpid})
    }

    chnagedistypedp = (e) =>{
        this.setState({distypedp:e.target.value})
        if(e.target.value == "PERCENT")
        {
            if(this.state.discountvaldp > 100){
                this.setState({errmesg : "Percent can't be more than 100."})
            } else {
                let toatlafterdis =  this.state.proditeprice - (this.state.proditeprice * (this.state.discountvaldp / 100));
                this.setState({totalvaldp : toatlafterdis})
            }
        }
        if(e.target.value == "NUMBER")
        {
            this.setState({errmesg : ""})
            if(this.state.proditeprice < this.state.discountvaldp){
                this.setState({errmesg : "Discount can't be more than Price."})
            } else {
                let toatlafterdis = this.state.proditeprice - this.state.discountvaldp;
                this.setState({totalvaldp : toatlafterdis})
            }
        }
    }

    handleChangeDisdp = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
        if(this.state.distypedp == "PERCENT")
        {
            if(event.target.value > 100){
                this.setState({errmesg : "Percent can't be more than 100."})
            } else {
                this.setState({errmesg : ""})
                let toatlafterdis =  this.state.proditeprice - (this.state.proditeprice * (event.target.value / 100));
                this.setState({totalvaldp : toatlafterdis})
            }
        }
        if(this.state.distypedp == "NUMBER")
        {
            this.setState({errmesg : ""})
            if(this.state.proditeprice < event.target.value){
                this.setState({errmesg : "Discount can't be more than Price."})
            } else {
                let toatlafterdis = this.state.proditeprice - event.target.value;
                this.setState({totalvaldp : toatlafterdis})
            }
        }
    }

    handleDietSubmit = (event) =>{
        event.preventDefault();
        const datadp = {
            savepdp : "yes",
            fromdatedp : this.state.fromdatedp,
            todatedp : this.state.todatedp,
            puid : this.props.match.params.puid,
            dietproid : this.state.dietproid,
            docotrid : this.state.docotrid,
            distypedp : this.state.distypedp,
            discountvaldp : this.state.discountvaldp,
            proditeprice : this.state.proditeprice,
        }
        axios.post("https://clinic.dnyanasha.com/ws/treatmentsplans.php",datadp).then((res)=>{
            console.log(res.data);
            if(res.data.savepdietplan){
                swal("Diet Assigned", "Diet Plan Assigned Successfully..!", "success").then((swalres)=>{
                    this.setState({dietplanlist:[]})
                }); 
            }
        })
    }

    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"226px",padding:"5px"}}>
                    <div className="row">
                        <div className="col-sm-8" style={{ borderRight:"1px solid #c9c9c9" }}>
                            <h3>Diet Plans Add</h3>

                            <div className="row" style={{fontSize:"11px",borderBottom:"1px solid #c9c9c9"}}>
                                <div className="col-sm-2">DIET NAME</div>
                                <div className="col-sm-4">FROM - TO DATE</div>
                                <div className="col-sm-2">PRICE</div>
                                <div className="col-sm-2">DISCOUNT</div>
                                <div className="col-sm-1">TOTAL</div>
                                <div className="col-sm-1"></div>
                            </div>

                            {
                                this.state.dietplanlist.map((items,index)=>{
                                    return(
                                        <>
                                            <form key={index} onSubmit={this.handleDietSubmit}>
                                                <div className="row">
                                                    <div className="col-sm-2" style={{fontSize:"13px"}}>
                                                        <p className="m-0"><b>{items.dietname}</b></p>
                                                        <a href={this.state.fileurlhost+items.dietfile} target="_blank">Diet File</a>
                                                    </div>
                                                    <div className="col-sm-4" style={{fontSize:"13px"}}>
                                                        <div className="row">
                                                            <div className="col-sm-4 d-flex align-items-center">From :</div>
                                                            <div className="col-sm-8 mt-1">
                                                                <input type="date" className="w-100" name="fromdatedp" onChange={this.handleChange} />
                                                            </div>
                                                            <div className="col-sm-4 d-flex align-items-center">To : </div>
                                                            <div className="col-sm-8 mt-1">
                                                                <input type="date" className="w-100" name="todatedp" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2" style={{fontSize:"13px"}}>
                                                        {/* {items.dietprice} */}
                                                        <input type="number" name="proditeprice" value={this.state.proditeprice} className="w-100 mt-1" onChange={(e)=>{this.setState({proditeprice:e.target.value})}} />
                                                    </div>
                                                    <div className="col-sm-2" style={{fontSize:"13px"}}>
                                                        {
                                                            (this.state.enablediscdp == false) ? (<>
                                                                <p className="m-0 mt-1" style={{color:"#0095CC",cursor:"pointer"}} onClick={()=>{this.setState({ enablediscdp : true })}} >+ add discount</p>
                                                            </> ) : (<>
                                                                <div className="d-flex mt-1">
                                                                    <input type="text" name="discountvaldp" min="0" value={this.state.discountvaldp} onChange={this.handleChangeDisdp} className="w-50" />
                                                                    <select name="distypedp" onChange={this.chnagedistypedp/*(e)=>{}*/} value={this.state.distypedp}>
                                                                        <option value="PERCENT">%</option>
                                                                        <option value="NUMBER">INR</option>
                                                                    </select>
                                                                </div>
                                                                <p className="text-danger m-0">{this.state.errmesg}</p>
                                                            </>)
                                                        }
                                                    </div>
                                                    <div className="col-sm-1">{this.state.totalvaldp}</div>
                                                    <div className="col-sm-1 p-0 text-danger d-flex justify-content-end">
                                                        <p style={{borderBottom:"1px solid #c9c9c9",borderLeft:"1px solid #c9c9c9",borderRadius:"0 0 0 50%",height:"18px",width:"15px",display:"flex",alignItems:"flex-end",justifyContent:"center",cursor:"pointer"}} onClick={()=>{this.setState({dietplanlist:[]})}}>x</p>
                                                    </div>
                                                </div>

                                                <div className="row mt-1"><div className="col-sm-9"></div><div className="col-sm-3"><button className="btn btn-sm btn-primary" type="submit">Save Diet Plan</button></div></div>
                                            </form>
                                        </>
                                    )
                                })
                            }
                        </div>

                        <div className="col-sm-4" style={{padding:"0"}}>
                            <div className="d-flex justify-content-end">
                                {
                                    (this.state.showadddietplan) ? (<>
                                        <button className="btn btn-sm btn-primary" onClick={this.hidedietp} style={{marginBottom:"5px",marginRight:"10px"}}> x Cancel</button>
                                    </>) : (<>
                                        <button className="btn btn-sm btn-primary" onClick={this.adddietp} style={{marginBottom:"5px",marginRight:"10px"}}> + Add</button>
                                    </>)
                                }
                            </div>
                            {
                                (this.state.showadddietplan) ? (<>
                                    <form onSubmit={this.handleSubmit} >
                                        <div style={{ padding:"10px",background:"#eeeeee" }}>
                                            <input type="file" className="form-control" onChange={this.handleimgChange} required/>
                                            <input type="ditename" name="dietname" className="form-control mt-1" onChange={this.handleChange} placeholder="Diet Name" required />
                                            <input type="number" name="dietprice" className="form-control mt-1" onChange={this.handleChange} placeholder="Diet Price" required />
                                            <button className="btn btn-primary btn-sm mt-1" type="submit">Submit</button>
                                        </div>
                                    </form>
                                </>) : (<></>)
                            }

                            <div style={{ padding:"10px",background:"#eeeeee" }}>
                                <input type="text" className="form-control" name="drugsearch" placeholder="Search...." />
                            </div>

                            <hr style={{color:"cecece",margin:"0"}}/>

                            <div>   
                                {
                                    this.state.finddietplan.map((item,index)=>{
                                        return(
                                            <>
                                                <div key={index} onClick={()=>this.adddietplist(item)} style={{fontSize:"14px",margin:"0",borderBottom:"1px solid #c9c9c9",padding:"1px",cursor:"pointer"}} className="d-flex justify-content-between">
                                                    <p className="m-0" >{item.dietname}</p>
                                                    <p className="m-0" >{item.dietprice	}</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>




                    {/* <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="col-form-label">Upload Diet Plan</label>
                                <input type="file" className="form-control" onChange={this.handleimgChange} />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <button className="btn btn-primary btn-sm" onClick={this.handleSubmit}>Submit</button>
                        </div>
                    </div> */}
                </div>
            </>
        )
    }
}