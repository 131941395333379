import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert";
import Headerpd from "./patient_dashboard/header";
import Sidebarpd from "./patient_dashboard/sidebar";

import Printheader from './assets/printheaderdiet.png';
import Printfooter from './assets/printfooter.png';

export default class AddditeDetailsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            findappdetails: [],
            findappdetailp: [],
            pname : "",
            labfiles : null,
            labfilesup : [],
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.filehandle = this.filehandle.bind(this);

        if (this.props.match.params.appid != undefined) {
            this.fetchaddfdata()
        }
    }

    filehandle = (event) =>{
        console.log(event.target.files)
        this.setState({labfiles:event.target.files})
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            // console.log(event.target.name+" = "+event.target.value + " = " + event.target.checked);
            if (event.target.checked === true) {
                this.setState({ [name]: "true" })
            } else {
                this.setState({ [name]: "" })
            }
        } else {
            this.setState({ [name]: value })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        // console.log(event);
        var formdata = new FormData();
        formdata.append('adddietdetiaform',"yes");
        formdata.append('appid',this.props.match.params.appid);
        console.log(this.state.labfiles)
        if(this.state.labfiles == null){}else{
            for(let i=0;i<this.state.labfiles.length;i++){
                formdata.append('labfiles[]', this.state.labfiles[i]);
            }
        }
        const dataadfarr = {
            adddietdetiaform: "yes",
            appid: this.props.match.params.appid,
            labfiles : this.state.labfiles,
        };
        for (let i = 0; i < event.target.length; i++) {
            if (event.target[i].type === "number" || event.target[i].type === "text") {
                // console.log(event.target[i].name+" = "+event.target[i].value);
                formdata.append(event.target[i].name, event.target[i].value);
                dataadfarr[event.target[i].name] = event.target[i].value;
            }
            if (event.target[i].type === "checkbox") {
                // console.log(event.target[i].name+" = "+event.target[i].value + " = " + event.target[i].checked);
                formdata.append(event.target[i].name, event.target[i].checked);
                dataadfarr[event.target[i].name] = event.target[i].checked;
            }
            if (event.target[i].type === "radio") {
                // console.log(event.target[i].name+" = "+event.target[i].value + " = " + event.target[i].checked);
                if (event.target[i].checked === true) {
                    formdata.append(event.target[i].name, event.target[i].value);
                    dataadfarr[event.target[i].name] = event.target[i].value;
                }
            }
            if (event.target[i].type === "time") {
                // console.log(event.target[i].name+" = "+ event.target[i].value);
                formdata.append(event.target[i].name, event.target[i].value);
                dataadfarr[event.target[i].name] = event.target[i].value;
            }
        }
        // console.log(dataadfarr);
        console.log(formdata);
        // console.log(Object.entries(dataadfarr).length);
        axios.post('https://clinic.dnyanasha.com/ws/dietmain.php', formdata).then((res) => {
            console.log(res.data)
            if (res.data.savedietmeta > 0) {
                swal("Diet Details Added", "Diet Details Successfully added..!", "success").then((swalres) => { });
                this.fetchaddfdata();
                this.props.history.push(  '/patient')
            }
            if (res.data.updatedietform === true) {
                swal("Diet Details Updated", "Diet Details Successfully updated..!", "success").then((swalres) => { });
                this.fetchaddfdata();
                this.props.history.push(  '/patient')
            }
        })
    }

    fetchaddfdata = () => {
        const dataaddfd = {
            fetchaddfdata: "yes",
            user: localStorage.getItem("user"),
            userid: localStorage.getItem("user_id"),
            appid: this.props.match.params.appid,
        }
        // console.log(dataaddfd)
        axios.post('https://clinic.dnyanasha.com/ws/dietmain.php', dataaddfd).then((res) => {
            console.log(res.data)
            this.setState({ findappdetails: res.data.findappdetails })
            this.setState({ findappdetailp: res.data.findappdetailp })
            this.setState({ pname: res.data.findappdetailp['patient_name'] })
            // console.log(res.data.findditeformdetails)
            var findditeformdetails = res.data.findditeformdetails;
            for (let j = 0; j < findditeformdetails.length; j++) {
                // console.log( { [findditeformdetails[j]['form_key']] : findditeformdetails[j]['form_value'] } )
                if(findditeformdetails[j]['form_key'] === "labfilesup"){
                    console.log(JSON.parse(findditeformdetails[j]['form_value']));
                    this.setState({ [findditeformdetails[j]['form_key']]: JSON.parse(findditeformdetails[j]['form_value']) })
                } else {
                    this.setState({ [findditeformdetails[j]['form_key']]: findditeformdetails[j]['form_value'] })
                }
            }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 100px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 70%;
                                            height: 100px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">${printContents}</div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 1)
    }

    deletelabfile = (item) =>{
        const datadelfile = {
            datadelfile : "yes",
            appid: this.props.match.params.appid,
            filename : item,
        }
        axios.post('https://clinic.dnyanasha.com/ws/dietmain.php', datadelfile).then((res) => {
            console.log(res.data);
            if(res.data.updatefile === true){
                swal("File Deleted", "File Deleted Successfully..!", "success").then((swalres) => { });
                this.fetchaddfdata();
            }
        })
    }

    render() {
        return (
            <>
                {
                    (this.props.match.params.puid != undefined) ? (<>
                        <Headerpd puid={this.props.match.params.puid} />
                        <Sidebarpd puid={this.props.match.params.puid} />
                    </>
                    ) : (<></>)
                }
                <div className={(this.props.match.params.puid != undefined) ? "pdcss" : ""}>

                    <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                        <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                    </div>

                    <hr />
                
                    <form onSubmit={this.handleSubmit} id="printablediv">
                        <h3 className="text-decoration-underline text-center">Diet History</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <p><b>Personal Information : </b></p>
                            </div>
                            <div className="col-sm-6">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Name : <b className="ms-2"> { this.state.pname }</b></p>
                            </div>
                            <div className="col-sm-6">
                                <p> Date: <b className="ms-2"> {new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()}</b></p>
                            </div>
                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Age: <input type={"number"} onChange={this.handleChange} name="age" value={this.state.age} className="form-control w-auto p-0 ms-3" min={1} /> </p>
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Weight: <input type={"number"} onChange={this.handleChange} name="weight" value={this.state.weight} className="form-control w-auto p-0 ms-3 mx-1" min={1} /> kg </p>
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Height: <input type={"number"} onChange={this.handleChange} name="height" value={this.state.height} className="form-control w-auto p-0 ms-3 mx-1" min={1} /> ft </p>
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Type of Diet: <input type={"radio"} className="ms-3" onChange={this.handleChange} name="typeofdiet" value="veg" id="veg" checked={(this.state.typeofdiet === "veg") ? true : false} /><label htmlFor="veg" className="ms-2">Veg</label><input type={"radio"} onChange={this.handleChange} id="nonveg" checked={(this.state.typeofdiet === "nonveg") ? true : false} value="nonveg" name="typeofdiet" className="ms-3" /><label htmlFor="nonveg" className="ms-2">Non-Veg</label></p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Body Frame:</p>
                            <div className="col-sm-12 ps-5">
                                <p className="d-flex align-items-center"><input type={"radio"} className="mx-2" onChange={this.handleChange} name="bodyframe" checked={(this.state.bodyframe === "small") ? true : false} value={"small"} id="small" /><label htmlFor="small" >1. Small & thin\ tall & thin\ underdeveloped</label> </p>
                                <p className="d-flex align-items-center"><input type={"radio"} className="mx-2" onChange={this.handleChange} name="bodyframe" checked={(this.state.bodyframe === "medium") ? true : false} value={"medium"} id="medium" /><label htmlFor="medium" >2. Medium build\balanced\proportiona</label> </p>
                                <p className="d-flex align-items-center"><input type={"radio"} className="mx-2" onChange={this.handleChange} name="bodyframe" checked={(this.state.bodyframe === "thick") ? true : false} value={"thick"} id="thick" /><label htmlFor="thick" >3. Thick ,tall\short, well developed</label> </p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Profession: <input type={"text"} name="profession" value={this.state.profession} onChange={this.handleChange} className="form-control w-auto p-0 ms-3" /> </p>
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Work Timings: <input type={"text"} name="worktimings" value={this.state.worktimings} onChange={this.handleChange} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                <p><b>PERSONAL HISTORY</b></p>
                            </div>
                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Lab Reports : <input type={"file"} multiple={true} className="mx-3 form-control w-50" name="labreports" onChange={this.filehandle} /></p>
                            </div>

                            {
                                (this.state.labfilesup.length != 0) ? (
                                        <div className="col-sm-12">
                                            {this.state.labfilesup.map((item,index)=>{
                                                return(
                                                    <p className="d-flex"><b>File {index+1} : </b><a target={"_blank"} href={"https://clinic.dnyanasha.com/ws/uploads/labfiles/"+item}>{item}</a> <box-icon name='trash' color="red" class='mx-4' style={{cursor:"pointer"}} onClick={()=>this.deletelabfile(item)}></box-icon> </p>
                                                )
                                            })}
                                        </div>
                                    ) : (<></>)
                            }

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center">
                                    <span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Have you done Diet consultation before: Yes/ NO If yes: specify:
                                    <input type={"radio"} className="ms-3" name="dietconsultedbefore" checked={(this.state.dietconsultedbefore === "yesdcb") ? true : false} value={"yesdcb"} id="yesdcb" onChange={(e) => { this.setState({ dietconsyesbox: e.target.value }); this.handleChange(e); }} /><label htmlFor="yesdcb" className="ms-2">Yes</label>
                                    <input type={"radio"} className="ms-3" name="dietconsultedbefore" checked={(this.state.dietconsultedbefore === "nodcb") ? true : false} value={"nodcb"} id="nodcb" onChange={(e) => { this.setState({ dietconsyesbox: false }); this.handleChange(e); }} /><label htmlFor="nodcb" className="ms-2">No</label>
                                    {(this.state.dietconsyesbox) ? (<input type={"text"} name="dietconsyes" value={this.state.dietconsyes} className="form-control w-auto p-0 ms-3" onChange={(e)=>this.setState({[e.target.name]:e.target.value})} />) : (<></>)}
                                </p>
                                <p className="d-flex align-items-center">
                                    <span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Any family history of Hypertension, diabetes, heart diseases, cancer (Motherside/ Fatherside):
                                    <input type={"text"} name="familyhistory" value={this.state.familyhistory} onChange={this.handleChange} className="form-control w-auto p-0 ms-3" />
                                </p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Any personal health problems/ history:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Orthopedic === 'true') ? true : false} value="Orthopedic" id="Orthopedicid" name="Orthopedic" /> <label htmlFor="Orthopedicid"> a. Orthopedic Problems </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Digestive === 'true') ? true : false} value="Digestive" id="Digestiveid" name="Digestive" /> <label htmlFor="Digestiveid"> b. Digestive System Problem </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Constipation === 'true') ? true : false} value="Constipation" id="Constipationid" name="Constipation" /> <label htmlFor="Constipationid"> c. Constipation </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Indigestion === 'true') ? true : false} value="Indigestion" id="Indigestionid" name="Indigestion" /> <label htmlFor="Indigestionid"> d. Indigestion </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Inflamed === 'true') ? true : false} value="Inflamed" id="Inflamedid" name="Inflamed" /> <label htmlFor="Inflamedid"> e. Inflamed Bowel </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Suffer === 'true') ? true : false} value="Suffer" id="Sufferid" name="Suffer" /> <label htmlFor="Sufferid"> f. Suffer from Diarrhea Often </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Anemia === 'true') ? true : false} value="Anemia" id="Anemiaid" name="Anemia" /> <label htmlFor="Anemiaid"> g. Anemia </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Hypertension === 'true') ? true : false} value="Hypertension" id="Hypertensionid" name="Hypertension" /> <label htmlFor="Hypertensionid"> h. Hypertension </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Diabetes === 'true') ? true : false} value="Diabetes" id="Diabetesid" name="Diabetes" /> <label htmlFor="Diabetesid"> i. Diabetes </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cardiovascular === 'true') ? true : false} value="Cardiovascular" id="Cardiovascularid" name="Cardiovascular" /> <label htmlFor="Cardiovascularid"> j. Cardiovascular Problem </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Kidney === 'true') ? true : false} value="Kidney" id="Kidneyid" name="Kidney" /> <label htmlFor="Kidneyid"> k. Kidney Problem </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Infections === 'true') ? true : false} value="Infections" id="Infectionsid" name="Infections" /> <label htmlFor="Infectionsid"> l. Infections </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Allergies === 'true') ? true : false} value="Allergies" id="Allergiesid" name="Allergies" /> <label htmlFor="Allergiesid"> m. Allergies </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Motion === 'true') ? true : false} value="Motion" id="Motionid" name="Motion" /> <label htmlFor="Motionid"> n. Motion habit </label></p>
                                <p className="d-flex align-items-center m-0 ms-4"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Regular === 'true') ? true : false} value="Regular" id="Regularid" name="Regular" /> <label htmlFor="Regularid"> 1. Regular </label></p>
                                <p className="d-flex align-items-center m-0 ms-4"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Irregular === 'true') ? true : false} value="Irregular" id="Irregularid" name="Irregular" /> <label htmlFor="Irregularid"> 2. Irregular </label></p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Weight Gain Pattern:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="weightgainpattern" checked={(this.state.weightgainpattern === "gainloss") ? true : false} value={"gainloss"} id="gainloss" /> <label htmlFor="gainloss"> Gains and Lose Weight Easily if puts mind to it. </label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="weightgainpattern" checked={(this.state.weightgainpattern === "gainonly") ? true : false} value={"gainonly"} id="gainonly" /> <label htmlFor="gainonly"> Gains Weight Easily, Difficult to Loose Unless Exercise </label></p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Do you exercise?:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="doyouexercise" checked={(this.state.doyouexercise === "regularid") ? true : false} value={"regularid"} id="regularid" /> <label htmlFor="regularid"> Regular</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="doyouexercise" checked={(this.state.doyouexercise === "irregularid") ? true : false} value={"irregularid"} id="irregularid" /> <label htmlFor="irregularid"> Irregular</label></p>
                            </div>


                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Type of Exercise:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Walk === 'true') ? true : false} value="Walk" name="Walk" id="Walkid" /> <label htmlFor="Walkid"> Walk</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.GYM === 'true') ? true : false} value="GYM" name="GYM" id="GYMid" /> <label htmlFor="GYMid"> GYM</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Aerobics === 'true') ? true : false} value="Aerobics" name="Aerobics" id="Aerobicsid" /> <label htmlFor="Aerobicsid"> Aerobics</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Yoga === 'true') ? true : false} value="Yoga" name="Yoga" id="Yogaid" /> <label htmlFor="Yogaid"> Yoga</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Other === 'true') ? true : false} value="Other" name="Other" id="Otherid" /> <label htmlFor="Otherid"> Other</label></p>
                            </div>


                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Walking Style:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="Walkstyle" checked={(this.state.Walkstyle === "Light") ? true : false} value={"Light"} id="Light" /> <label htmlFor="Light"> Light</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="Walkstyle" checked={(this.state.Walkstyle === "Fast") ? true : false} value={"Fast"} id="Fast" /> <label htmlFor="Fast"> Fast</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="Walkstyle" checked={(this.state.Walkstyle === "Medium") ? true : false} value={"Medium"} id="Medium" /> <label htmlFor="Medium"> Medium</label></p>
                            </div>


                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Psychological Problems:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Fatigue === 'true') ? true : false} value="Fatigue" name="Fatigue" id="Fatigueid" /> <label htmlFor="Fatigueid"> Fatigue</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Stress === 'true') ? true : false} value="Stress" name="Stress" id="Stressid" /> <label htmlFor="Stressid"> Stress</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Depression === 'true') ? true : false} value="Depression" name="Depression" id="Depressionid" /> <label htmlFor="Depressionid"> Depression</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Mood === 'true') ? true : false} value="Mood" name="Mood" id="Moodid" /> <label htmlFor="Moodid"> Mood swing</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Anorexia === 'true') ? true : false} value="Anorexia" name="Anorexia" id="Anorexiaid" /> <label htmlFor="Anorexiaid"> Anorexia</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Drowsiness === 'true') ? true : false} value="Drowsiness" name="Drowsiness" id="Drowsinessid" /> <label htmlFor="Drowsinessid"> Drowsiness</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Headache === 'true') ? true : false} value="Headache" name="Headache" id="Headacheid" /> <label htmlFor="Headacheid"> Headache</label></p>
                            </div>


                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Sleeping Pattern:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="sleepingpattern" checked={(this.state.sleepingpattern === "Soundid") ? true : false} value="Soundid" id="Soundid" /> <label htmlFor="Soundid"> Sound</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="sleepingpattern" checked={(this.state.sleepingpattern === "Disturbedid") ? true : false} value="Disturbedid" id="Disturbedid" /> <label htmlFor="Disturbedid"> Disturbed & alert</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="sleepingpattern" checked={(this.state.sleepingpattern === "Heavyid") ? true : false} value="Heavyid" id="Heavyid" /> <label htmlFor="Heavyid"> Heavy</label></p>
                            </div>


                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Resistance:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="resistance" checked={(this.state.resistance === "Poorid") ? true : false} value="Poorid" id="Poorid" /> <label htmlFor="Poorid"> Poor Tendency and Prone to Allergies</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="resistance" checked={(this.state.resistance === "Mediumid") ? true : false} value="Mediumid" id="Mediumid" /> <label htmlFor="Mediumid"> Medium and Prone to Infections</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="resistance" checked={(this.state.resistance === "Strongid") ? true : false} value="Strongid" id="Strongid" /> <label htmlFor="Strongid"> Strong and Tendency to Chronic Infections</label></p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Psychological Mind Set:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Nervous === 'true') ? true : false} value="Nervous" name="Nervous" id="Nervousid" /> <label htmlFor="Nervousid"> Nervous</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Angry === 'true') ? true : false} value="Angry" name="Angry" id="Angryid" /> <label htmlFor="Angryid"> Angry</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Desirous === 'true') ? true : false} value="Desirous" name="Desirous" id="Desirousid" /> <label htmlFor="Desirousid"> Desirous</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Communicative === 'true') ? true : false} value="Communicative" name="Communicative" id="Communicativeid" /> <label htmlFor="Communicativeid"> Communicative</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Caring === 'true') ? true : false} value="Caring" name="Caring" id="Caringid" /> <label htmlFor="Caringid"> Caring</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Devoted === 'true') ? true : false} value="Devoted" name="Devoted" id="Devotedid" /> <label htmlFor="Devotedid"> Devoted and Loyal</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Flexible === 'true') ? true : false} value="Flexible" name="Flexible" id="Flexibleid" /> <label htmlFor="Flexibleid"> Flexible and Takes Changes Well</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Tolerant === 'true') ? true : false} value="Tolerant" name="Tolerant" id="Tolerantid" /> <label htmlFor="Tolerantid"> Tolerant and Accept Things When no Other Choice</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Patient === 'true') ? true : false} value="Patient" name="Patient" id="Patientid" /> <label htmlFor="Patientid"> Patient and Accepts Well</label></p>
                            </div>


                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Are you Taking any Dietary/ Health Supplements: <input type={"text"} onChange={this.handleChange} name="dietaryhealth" value={this.state.dietaryhealth} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>
                        </div>
                        <hr />

                        <div className="row">

                            <div className="col-sm-12">
                                <p><b>DIET RECALL</b></p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Psychological Problems:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="psychologicalprob" checked={(this.state.psychologicalprob === "Variableid") ? true : false} value="Variableid" id="Variableid" /> <label htmlFor="Variableid"> Variable</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="psychologicalprob" checked={(this.state.psychologicalprob === "Excessive") ? true : false} value="Excessive" id="Excessive" /> <label htmlFor="Excessive"> Strong and Excessive</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="psychologicalprob" checked={(this.state.psychologicalprob === "Steadyid") ? true : false} value="Steadyid" id="Steadyid" /> <label htmlFor="Steadyid"> Steady and Slow</label></p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Which is your main meal of the day: <input type={"text"} onChange={this.handleChange} name="mealofday" value={this.state.mealofday} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>How often do you skip meals?</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} checked={(this.state.skipmeals === "Rarelyid") ? true : false} value="Rarelyid" name="skipmeals" id="Rarelyid" /> <label htmlFor="Rarelyid"> Rarely</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} checked={(this.state.skipmeals === "Regularlyid") ? true : false} value="Regularlyid" name="skipmeals" id="Regularlyid" /> <label htmlFor="Regularlyid"> Regularly</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} checked={(this.state.skipmeals === "Neverid") ? true : false} value="Neverid" name="skipmeals" id="Neverid" /> <label htmlFor="Neverid"> Never</label></p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Do you experience felling of hunger during the day? When? : <input type={"text"} onChange={this.handleChange} name="hungeryduringday" value={this.state.hungeryduringday} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center">
                                    <span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Do you eat in between the Main Meals:
                                    <input type="radio" className="ms-3" name="mainmeals" checked={(this.state.mainmeals === "yesmm") ? true : false} value={"yesmm"} id="yesmm" onChange={this.handleChange} /><label htmlFor="yesmm" className="ms-2">Yes</label>
                                    <input type="radio" className="ms-3" name="mainmeals" checked={(this.state.mainmeals === "nomm") ? true : false} value={"nomm"} id="nomm" onChange={this.handleChange} /><label htmlFor="nomm" className="ms-2">No</label>
                                </p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Please List Foods/ Food Items Which you Like Most: <input type={"text"} onChange={this.handleChange} name="foodlikemost" value={this.state.foodlikemost} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Please List Any Foods you Dislike or will not Eat: <input type={"text"} onChange={this.handleChange} name="fooddislikemost" value={this.state.fooddislikemost} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <p className="d-flex align-items-center">
                                <span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Do you have any food allergy: Yes/ NO :
                                <input type={"radio"} className="ms-3" name="foodalregy" checked={(this.state.foodalregy === "yesfa") ? true : false} value={"yesfa"} id="yesfa" onChange={(e) => { this.setState({ foodalregyyes: e.target.value }); this.handleChange(e); }} /><label htmlFor="yesfa" className="ms-2">Yes</label>
                                <input type={"radio"} className="ms-3" name="foodalregy" checked={(this.state.foodalregy === "nofa") ? true : false} value={"nofa"} id="nofa" onChange={(e) => { this.setState({ foodalregyyes: false }); this.handleChange(e); }} /><label htmlFor="nofa" className="ms-2">No</label>
                                {(this.state.foodalregyyes) ? (<input type={"text"} name="foodalergyyes" className="form-control w-auto p-0 ms-3" value={this.state.foodalergyyes} onChange={this.handleChange} />) : (<></>)}
                            </p>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Number of Meals Per Day: <input type={"number"} min={"1"} onChange={this.handleChange} name="mealperday" value={this.state.mealperday} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Are Your Meal Timings Regular: <input type={"text"} onChange={this.handleChange} name="mealtimeregular" value={this.state.mealtimeregular} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> How Many Fruits do you Eat Per Day: <input type={"number"} min={"1"} onChange={this.handleChange} name="fruitperday" value={this.state.fruitperday} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>How Many Times in a Week You Eat Sweets:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="sweetinweek" checked={(this.state.sweetinweek === "Everydayid") ? true : false} value={"Everydayid"} id="Everydayid" /> <label htmlFor="Everydayid"> Everyday</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="sweetinweek" checked={(this.state.sweetinweek === "onceaweek") ? true : false} value={"onceaweek"} id="onceaweek" /> <label htmlFor="onceaweek"> Once a Week</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="sweetinweek" checked={(this.state.sweetinweek === "Occasionaly") ? true : false} value={"Occasionaly"} id="Occasionaly" /> <label htmlFor="Occasionaly"> Occasionaly</label></p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> How Much Water do You Drink Per Day: <input type={"number"} min={"1"} onChange={this.handleChange} name="waterdringlass" value={this.state.waterdringlass} className="form-control w-auto p-0 ms-3 mx-2" /> Glasses /  <input type={"number"} min={"1"} onChange={this.handleChange} name="waterdrinlit" value={this.state.waterdrinlit} className="form-control w-auto p-0 ms-3 mx-2" /> Lit</p>
                            </div>


                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Which Oil is Used for Cooking:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Groundnut === 'true') ? true : false} value="Groundnut" name="Groundnut" id="Groundnutid" /> <label htmlFor="Groundnutid"> Groundnut Oil</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sunflower === 'true') ? true : false} value="Sunflower" name="Sunflower" id="Sunflowerid" /> <label htmlFor="Sunflowerid"> Sunflower Oil</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Rice === 'true') ? true : false} value="Rice" name="Rice" id="Riceid" /> <label htmlFor="Riceid"> Rice bran Oil</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.soyabean === 'true') ? true : false} value="soyabean" name="soyabean" id="soyabeanid" /> <label htmlFor="soyabeanid"> soyabean Oil</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.blended === 'true') ? true : false} value="blended" name="blended" id="blendedid" /> <label htmlFor="blendedid"> blended Oil</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Til === 'true') ? true : false} value="Til" name="Til" id="Tilid" /> <label htmlFor="Tilid"> Til Oil</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Olive === 'true') ? true : false} value="Olive" name="Olive" id="Oliveid" /> <label htmlFor="Oliveid"> Olive Oil</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Otheroil === 'true') ? true : false} value="Otheroil" name="Otheroil" id="Otheroilid" /> <label htmlFor="Otheroilid"> Other</label></p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Quantity Per Month: <input type={"number"} min={'true'} onChange={this.handleChange} name="quantitypermonth" value={this.state.quantitypermonth} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> How Much Ghee/ Butter is Used in Daily Diet: <input type={"text"} onChange={this.handleChange} name="gheebutterdaily" value={this.state.gheebutterdaily} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Do You Drink Milk Regularly</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cows === 'true') ? true : false} value="Cows" name="Cows" id="Cowsid" /> <label htmlFor="Cowsid"> Cows</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Buffalo === 'true') ? true : false} value="Buffalo" name="Buffalo" id="Buffaloid" /> <label htmlFor="Buffaloid"> Buffalo</label></p>
                            </div>

                            <p className="d-flex align-items-center">
                                <span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span> Do You Drink Fruit Juices/ Canned Juices :
                                <input type={"radio"} className="ms-3" name="fruitjuicecj" checked={(this.state.fruitjuicecj === "yesfjcj") ? true : false} value={"yesfjcj"} id="yesfjcj" onChange={(e) => { this.setState({ fruitjuicecjs: e.target.value }); this.handleChange(e); }} /><label htmlFor="yesfjcj" className="ms-2">Yes</label>
                                <input type={"radio"} className="ms-3" name="fruitjuicecj" checked={(this.state.fruitjuicecj === "nofjcj") ? true : false} value={"nofjcj"} id="nofjcj" onChange={(e) => { this.setState({ fruitjuicecjs: false }); this.handleChange(e); }} /><label htmlFor="nofjcj" className="ms-2">No</label>
                                {(this.state.fruitjuicecjs) ? (<input type="text" name="fruitjuicecjyes" value={this.state.fruitjuicecjyes} className="form-control w-auto p-0 ms-3" onChange={this.handleChange} />) : (<></>)}
                            </p>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>How Many Times in a Week You Eat Non Veg:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="eatnonveg" checked={(this.state.eatnonveg === "nonvegdaily") ? true : false} value={"nonvegdaily"} id="nonvegdaily" /> <label htmlFor="nonvegdaily"> Daily</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="eatnonveg" checked={(this.state.eatnonveg === "onceaweeknon") ? true : false} value={"onceaweeknon"} id="onceaweeknon" /> <label htmlFor="onceaweeknon"> Once a Week or More Than That</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="eatnonveg" checked={(this.state.eatnonveg === "once10d") ? true : false} value={"once10d"} id="once10d" /> <label htmlFor="once10d"> Once in 10 Days</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="eatnonveg" checked={(this.state.eatnonveg === "oncemon") ? true : false} value={"oncemon"} id="oncemon" /> <label htmlFor="oncemon"> Once a Month</label></p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>What do You Prefer in Non-Veg:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Eggsnames === 'true') ? true : false} value="Eggsnames" name="Eggsnames" id="Eggsidd" /> <label htmlFor="Eggsidd"> Eggs</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Chickennames === 'true') ? true : false} value="Chickennames" name="Chickennames" id="Chickenidd" /> <label htmlFor="Chickenidd"> Chicken</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Fishnames === 'true') ? true : false} value="Fishnames" name="Fishnames" id="Fishidd" /> <label htmlFor="Fishidd"> Fish</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Muttonnames === 'true') ? true : false} value="Muttonnames" name="Muttonnames" id="Muttonidd" /> <label htmlFor="Muttonidd"> Mutton</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Allnames === 'true') ? true : false} value="Allnames" name="Allnames" id="Allidd" /> <label htmlFor="Allidd"> All</label></p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Do You Drink Cold Drinks:
                                    <input type={"radio"} className="mx-2" onChange={this.handleChange} name="drinkcoldrink" checked={(this.state.drinkcoldrink === "yescolddrink") ? true : false} value={"yescolddrink"} id="yescolddrink" /> <label htmlFor="yescolddrink"> Yes</label>
                                    <input type={"radio"} className="mx-2" onChange={this.handleChange} name="drinkcoldrink" checked={(this.state.drinkcoldrink === "nocolddrink") ? true : false} value={"nocolddrink"} id="nocolddrink" /> <label htmlFor="nocolddrink"> No</label></p>
                            </div>


                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Do You Drink Hard Drinks:
                                <input type={"radio"} className="mx-2" onChange={this.handleChange} name="drinkhardrink" checked={(this.state.drinkhardrink === "yesharddrink") ? true : false} value={"yesharddrink"} id="yesharddrink" /> <label htmlFor="yesharddrink"> Yes</label>
                                <input type={"radio"} className="mx-2" onChange={this.handleChange} name="drinkhardrink" checked={(this.state.drinkhardrink === "noharddrink") ? true : false} value={"noharddrink"} id="noharddrink" /> <label htmlFor="noharddrink"> No</label></p>
                            <div className="col-sm-12 ps-5">
                                <p className="d-flex align-items-center">Frequency <input type={"text"} onChange={this.handleChange} name="hardrinkfrequency" value={this.state.hardrinkfrequency} className="form-control w-auto p-0 ms-3" /> </p>
                                <p className="d-flex align-items-center">Type <input type={"text"} onChange={this.handleChange} name="hardrinktype" value={this.state.hardrinktype} className="form-control w-auto p-0 ms-3" /> </p>
                                <p className="d-flex align-items-center">Quantity <input type={"text"} onChange={this.handleChange} name="hardrinkquantity" value={this.state.hardrinkquantity} className="form-control w-auto p-0 ms-3" /> </p>
                            </div>

                            <div className="col-sm-12">
                                <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Do You Smoke:
                                    <input type={"radio"} className="mx-2" onChange={this.handleChange} name="doyousmoke" checked={(this.state.doyousmoke === "smokeyes") ? true : false} value={"smokeyes"} id="smokeyes" /> <label htmlFor="smokeyes"> Yes</label>
                                    <input type={"radio"} className="mx-2" onChange={this.handleChange} name="doyousmoke" checked={(this.state.doyousmoke === "smokeno") ? true : false} value={"smokeno"} id="smokeno" /> <label htmlFor="smokeno"> No</label></p>
                            </div>

                            <p className="d-flex align-items-center"><span style={{ fontSize: "10px" }}>&#9679; &nbsp;&nbsp;</span>Outside Food Consumption:</p>
                            <div className="col-sm-12 ps-5 mb-3">
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="outsidefoodconsumption" checked={(this.state.outsidefoodconsumption === "foodcondaily") ? true : false} value={"foodcondaily"} id="foodcondaily" /> <label htmlFor="foodcondaily"> Daily</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="outsidefoodconsumption" checked={(this.state.outsidefoodconsumption === "foodcononceaweek") ? true : false} value={"foodcononceaweek"} id="foodcononceaweek" /> <label htmlFor="foodcononceaweek"> Once a Week or More Than That</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="outsidefoodconsumption" checked={(this.state.outsidefoodconsumption === "foodcononce10") ? true : false} value={"foodcononce10"} id="foodcononce10" /> <label htmlFor="foodcononce10"> Once in 10 Days</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="outsidefoodconsumption" checked={(this.state.outsidefoodconsumption === "foodcononce15") ? true : false} value={"foodcononce15"} id="foodcononce15" /> <label htmlFor="foodcononce15"> Once in 15 Days</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="outsidefoodconsumption" checked={(this.state.outsidefoodconsumption === "foodcononcemonth") ? true : false} value={"foodcononcemonth"} id="foodcononcemonth" /> <label htmlFor="foodcononcemonth"> Once in a Month</label></p>
                                <p className="d-flex align-items-center m-0"> <input type={"radio"} className="mx-2" onChange={this.handleChange} name="outsidefoodconsumption" checked={(this.state.outsidefoodconsumption === "foodconrarely") ? true : false} value={"foodconrarely"} id="foodconrarely" /> <label htmlFor="foodconrarely"> Rarely</label></p>
                            </div>
                        </div>
                        <hr />

                        <div className="row">

                            <div className="col-sm-12">
                                <p><b>DIET RECALL (AVERAGE):</b></p>
                            </div>

                            <div className="col-sm-12">
                                <table className="table table-bordered table-responsive">
                                    <thead style={{ borderWidth: "1px 1px 1px 1px", textAlign: "center" }}>
                                        <tr>
                                            <th>MEAL</th>
                                            <th>TIMING</th>
                                            <th>MENU</th>
                                            <th>QUANTITY</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>EARLY MORNING</b></td>
                                            <td><input type={"time"} className="form-control w-75 m-auto" name="earlymorningtime" value={this.state.earlymorningtime} onChange={this.handleChange} /></td>
                                            <td><input type={"text"} className="form-control" name="earlymorningmenu" value={this.state.earlymorningmenu} onChange={this.handleChange} /></td>
                                            <td><input type={"number"} min={"1"} className="form-control w-25 m-auto" name="earlymorningqty" value={this.state.earlymorningqty} onChange={this.handleChange} /></td>
                                        </tr>
                                        <tr>
                                            <td><b>BREAKFAST</b></td>
                                            <td><input type={"time"} className="form-control w-75 m-auto" name="breakfasttime" value={this.state.breakfasttime} onChange={this.handleChange} /></td>
                                            <td><input type={"text"} className="form-control" name="breakfastmenu" value={this.state.breakfastmenu} onChange={this.handleChange} /></td>
                                            <td><input type={"number"} min={"1"} className="form-control w-25 m-auto" name="breakfastqty" value={this.state.breakfastqty} onChange={this.handleChange} /></td>
                                        </tr>
                                        <tr>
                                            <td><b>MID MORNING</b></td>
                                            <td><input type={"time"} className="form-control w-75 m-auto" name="minmorningtime" value={this.state.minmorningtime} onChange={this.handleChange} /></td>
                                            <td><input type={"text"} className="form-control" name="minmorningmenu" value={this.state.minmorningmenu} onChange={this.handleChange} /></td>
                                            <td><input type={"number"} min={"1"} className="form-control w-25 m-auto" name="minmorningqty" value={this.state.minmorningqty} onChange={this.handleChange} /></td>
                                        </tr>
                                        <tr>
                                            <td><b>LUNCH</b></td>
                                            <td><input type={"time"} className="form-control w-75 m-auto" name="lunchtime" value={this.state.lunchtime} onChange={this.handleChange} /></td>
                                            <td><input type={"text"} className="form-control" name="lunchmenu" value={this.state.lunchmenu} onChange={this.handleChange} /></td>
                                            <td><input type={"number"} min={"1"} className="form-control w-25 m-auto" name="lunchqty" value={this.state.lunchqty} onChange={this.handleChange} /></td>
                                        </tr>
                                        <tr>
                                            <td><b>SNACKS</b></td>
                                            <td><input type={"time"} className="form-control w-75 m-auto" name="snackstime" value={this.state.snackstime} onChange={this.handleChange} /></td>
                                            <td><input type={"text"} className="form-control" name="snacksmenu" value={this.state.snacksmenu} onChange={this.handleChange} /></td>
                                            <td><input type={"number"} min={"1"} className="form-control w-25 m-auto" name="snacksqty" value={this.state.snacksqty} onChange={this.handleChange} /></td>
                                        </tr>
                                        <tr>
                                            <td><b>DINNER</b></td>
                                            <td><input type={"time"} className="form-control w-75 m-auto" name="dinnertime" value={this.state.dinnertime} onChange={this.handleChange} /></td>
                                            <td><input type={"text"} className="form-control" name="dinnermenu" value={this.state.dinnermenu} onChange={this.handleChange} /></td>
                                            <td><input type={"number"} min={"1"} className="form-control w-25 m-auto" name="dinnerqty" value={this.state.dinnerqty} onChange={this.handleChange} /></td>
                                        </tr>
                                        <tr>
                                            <td><b>BEFORE BEDTIME</b></td>
                                            <td><input type={"time"} className="form-control w-75 m-auto" name="beforebedtime" value={this.state.beforebedtime} onChange={this.handleChange} /></td>
                                            <td><input type={"text"} className="form-control" name="beforebedmenu" value={this.state.beforebedmenu} onChange={this.handleChange} /></td>
                                            <td><input type={"number"} min={"1"} className="form-control w-25 m-auto" name="beforebedqty" value={this.state.beforebedqty} onChange={this.handleChange} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <button className="btn btn-primary hideonprint" name="adddietform" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                    {/* {(this.props.match.params.puid != undefined) ? (</div>) : (<></>) */}
                </div>
            </>
        )
    }
}