import axios from "axios";
import React, { Component } from "react";
import DataTable from 'react-data-table-component';

import usericon from './assets/usericon.png';

export default class Treatmentplanst extends Component{

    constructor(props){
        super(props);
        this.state = {
            columns : [
                {
                  name:"",
                  cell:(row)=>{ { 
                    if(row.profile_picture === ""){
                        if(row.pro_pic_data === ""){
                            var imgurl = usericon;
                        } else {
                            var imgurl = row.pro_pic_data;
                        }
                    } else {
                        var imgurl = "https://clinic.dnyanasha.com/ws/"+row.profile_picture;
                    }
                  } 
                    return(<> <img src={imgurl} style={{height:"40px",width:"40px",opacity:"0.5"}} /> </>) },
                },
                {
                  name:"Patient ID",
                  selector:(row)=>row.unique_pid
                },
                {
                  name:"Patient Name",
                  selector:(row)=>row.patient_name
                },
                {
                    name:"Mobile Number",
                    selector:(row)=>row.mobile_number
                },
                {
                    name:"Action",
                    // cell:(row)=><><button className="btn btn-primary" onClick={()=>this.props.history.push({ pathname: '/patient_dashboard',state:{pdata : row}})}>View</button></>
                    cell:(row)=><><button className="btn btn-sm btn-primary" onClick={()=>this.props.history.push({ pathname: '/treatment/'+row.unique_pid+'/viewtreatmentplans' })}>View Treatments</button></>
                },
            ],
            results : [],
            patientlist : [],
        }
        this.fetchdatatpt();
    }

    fetchdatatpt = () =>{
        const datatpt = {
            fetchdatatpt : 'yes',
            user : localStorage.getItem("user"),
            userid : localStorage.getItem("user_id")
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',datatpt).then((res)=>{
            console.log(res.data)
            this.setState({patientlist : res.data.findallpatient})
            this.setState({results : res.data.findallpatient})
        })
    }


    setSearch = (svalue) => {
        if(svalue == "" || svalue == undefined || svalue == null ){
            this.setState({search:svalue})
            this.setState({results:this.state.patientlist})
        }
        else {
            if(!isNaN(svalue)){
                var result = this.state.patientlist.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.patient_name.toLowerCase().match(this.state.search);
                });
            } else if(svalue.charAt(0) == "p"){
                var result = this.state.patientlist.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.unique_pid.toLowerCase().match(this.state.search);
                });
            } else {
                var result = this.state.patientlist.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.patient_name.toLowerCase().match(this.state.search);
                });
            }
            this.setState({results:result})
        }
    }


    render(){
        return(
            <>
                <div className="row">
                    <div className="col-sm-10">
                        <h5>Treatments</h5>
                    </div>
                    <div className="col-sm-2">
                        {/* <button className="btn btn-primary" onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/addtreatment') }} >+ Add</button> */}
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-12">
                        <DataTable
                            columns={this.state.columns} 
                            data={this.state.results} 
                            style={{textAlign:"center"}}
                            // title="All Doctors"
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight='450px'
                            highlightOnHover
                            subHeader
                            onRowClicked={(row)=>{this.props.history.push({ pathname: '/treatment/'+row.unique_pid+'/viewtreatmentplans' })}}
                            subHeaderComponent={
                                <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                    <div style={{fontSize:"22px"}}>All Patients</div>
                                    <input 
                                        type="text"
                                        placeholder='search here'
                                        className='w-25 form-control'
                                        value={this.state.search}
                                        onChange={(e)=>this.setSearch(e.target.value)}
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>
            </>
        )
    }
}