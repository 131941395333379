import React, { Component, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


function Checklogin() {
    
    const history = useHistory();
    // console.log((history.location.pathname).replace("/",""));
    const urlpath = (history.location.pathname).replace("/","");
    // console.log( JSON.parse(localStorage.getItem("roleslugarr")) );
    const accessarr = JSON.parse(localStorage.getItem("roleslugarr"));
    
    // console.log( accessarr.indexOf(urlpath) > -1 );
    const [accessvalue , setAccessvalue] = useState(false);
    if(accessarr.indexOf(urlpath) > -1){
        if(accessarr.indexOf(urlpath) == accessvalue){setAccessvalue(true);}
    } else {
        if(accessarr.indexOf(urlpath) == accessvalue){setAccessvalue(false);}
    }
    // setAccessvalue(true);
    // setAccessvalue(false);
    // const 
    
    // useEffect(() => {
    // });

    return(
        <></>
    )
}

export default Checklogin;


// export default class Checklogin extends Component {
    
//     constructor(props){
//         super(props);
//         this.state = {
//             accessvalue : false
//         }

//         // var urlpath = (this.props.history.location.pathname).replace("/","");
//         // var accessarr = JSON.parse(localStorage.getItem("roleslugarr"));
//         // if(accessarr.indexOf(urlpath) > -1)
//         // {
//         //     this.setState({ accessvalue : true })
//         // } else {
//         //     this.setState({ accessvalue : false })
//         // }

//         // console.log(this.props)
//         this.aa();
//     }

//     aa = () => {
//         console.log(this.props)
//     }

//     render(){
//         return(
//             <></>
//         )
//     }
// }