import React from "react";
import axios from "axios";
import swal from 'sweetalert';
import DataTable from 'react-data-table-component'
import Modal from 'react-modal';

export default class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded : false,
            expanded1 : false,
            rolelist : [],
            results : [],
            search : [],
            stafflist : [],
            columns : [
                {
                  name:"Staff ID",
                  selector:(row)=>row.staff_id
                },
                {
                  name:"Name",
                  selector:(row)=>row.staff_name
                },
                {
                  name:"Email",
                  selector:(row)=>row.staff_email
                },
                {
                  name:"Mobile Number",
                  selector:(row)=>row.mobile_number
                },
                {
                  name:"Role",
                  selector:(row)=>row.role_name
                },
                {
                    name:"Action",
                    cell:(row)=>{
                        return(
                            <>
                                <div><span className="btn btn-sm btn-primary me-2 p-1" style={{height:"34px"}} onClick={()=>this.editstaff(row)}><box-icon type='solid' style={{fill:"#fff"}} name='edit'></box-icon></span></div>
                                <select className="form-control" onChange={(e) => this.changedrstatus(e,row.staff_id)} value={row.status}>
                                    <option value="Y">Active</option>
                                    <option value="N">De-Active</option>
                                </select>
                            </>
                        )
                    }
                }
            ],
            isdoctor : localStorage.getItem("user"),
            schlist : [],
            editmodel : false,
            customStyles1 : {
                content: {
                  top: '50%',
                  left: '60%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '60%'
                },
            },
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitcat = this.handleSubmitcat.bind(this);
        this.handleChangesch = this.handleChangesch.bind(this);
        this.handleSubmitsch = this.handleSubmitsch.bind(this);
        this.handleSubmitedit = this.handleSubmitedit.bind(this);

        this.fetchstaffdata();
        this.setSearch();
        this.fetchschdata();

        this.fetchaddstaffdata();
    }

    handleSubmitedit = (event) =>{
        event.preventDefault();
        const editstaff = {
            editstaff : 'yes',
            staff_idedit : this.state.staff_name,
            staff_nameedit : this.state.staff_name,
            staff_emailedit : this.state.staff_email,
            mobile_numberedit : this.state.mobile_number,
            roleedit : this.state.role,
        }
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',editstaff).then((res)=>{
            if(res.data.updatestaff){
                swal("Staff Updated", "Staff Successfully Updated..!", "success").then((swalres)=>{
                    this.fetchstaffdata();
                });
            }
        })
    }

    editstaff = (row) =>{
        this.setState({staff_idedit:row.staff_idedit})
        this.setState({staff_nameedit:row.staff_name})
        this.setState({staff_emailedit:row.staff_email})
        this.setState({mobile_numberedit:row.mobile_number})
        this.setState({roleedit:row.role_id})
        this.setState({editmodel:true})
    }

    closeModal = () => {
        this.setState({editmodel:false})
    }

    changedrstatus = (event,staff_id) =>{
        console.log(event.target.value)
        console.log(staff_id)
        const datads = {
            changestatusstaff : "yes",
            staff_id : staff_id,
            status : event.target.value,
        }
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',datads).then((res)=>{
            if(res.data.updatedrstatus){
                swal({title:"Status Changed", text:"Staff Status Changed Successfully..!", icon:"success",timer:1000}).then((swalres)=>{
                    this.fetchstaffdata();
                });
            }
        })
    }

    handleChangesch = (event) => {
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmitsch = (event) => {
        event.preventDefault();
        const data = {
            add_schedule : 'yes',
            day : this.state.day,
            intime : this.state.intime,
            outtime : this.state.outtime,
            mode : this.state.mode,
            docid : localStorage.getItem("user_id")
        }
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',data).then((res)=>{
            console.log(res.data);
            if(res.data.savesch > 0)
            {
                swal("Schedule Added", "Doctor Schedule Added Successfully..!", "success").then((swalres)=>{
                    console.log(swalres);
                    this.fetchschdata();
                });
            }
        })
    }

    handleSubmitcat = (event) =>{
        event.preventDefault();
        const data = {
            add_category : 'yes',
            category_name : this.state.category_name,
            doctor_id : localStorage.getItem("user_id"),
        }
        console.log(data);
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',data).then((res)=>{
            console.log(res.data);
            if(res.data.savecat > 0)
            {
                swal("Category Added", "Category Successfully added..!", "success").then((swalres)=>{
                    console.log(swalres);
                    this.setState({expanded1 : !this.state.expanded1})
                });
            }
        })
    }
    
    fetchschdata = () => {
        const datasch = {
            fetchschdata : 'yes',
            docid : localStorage.getItem("user_id")
        }
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',datasch).then((res)=>{
            console.log(res.data);
            this.setState({ schlist : res.data.findschall })
        })
    }

    fetchstaffdata = () => {
        const datastaff = {
            fetch_staff : 'yes',
        }
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',datastaff).then((res)=>{
            console.log(res.data);
            this.setState({ stafflist : res.data.findstafflist });
            this.setState({ results : res.data.findstafflist });
        })
    }

    setSearch = (svalue) => {
        if(svalue == "" || svalue == undefined || svalue == null ){
            console.log(svalue)
            this.setState({search:svalue})
            this.setState({results:this.state.stafflist})
        }
        else {
            console.log(svalue)
            var result = this.state.stafflist.filter((resfilter)=>{
                this.setState({search:svalue.toLowerCase()});
                // return resfilter.doctor_name.toLowerCase().match(this.state.search.toLowerCase());
                return resfilter.staff_name.toLowerCase().match(this.state.search);
            });
            this.setState({results:result})
        }
    }

    handleChange = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const data = {
            add_staff : 'yes',
            staff_name : this.state.staff_name,
            staff_email : this.state.staff_email,
            mobile_number : this.state.mobile_number,
            role : this.state.role,
        }
        console.log(data);
        axios.post('https://clinic.dnyanasha.com/ws/settings.php',data).then((res)=>{
            console.log(res.data);
            if(res.data.savestaff > 0)
            {
                swal("Staff Added", "Staff Successfully added..!", "success").then((swalres)=>{
                    this.fetchstaffdata();
                    this.setState({expanded : !this.state.expanded})
                });
            }
            if(res.data.savestaff == "Staff already add"){
                swal("Staff Already Added", "Staff is Already Added..!", "error").then((swalres)=>{
                });
            }
        })
    }

    fetchaddstaffdata = () =>{
        const data = {
            fetch_role : 'yes',
        }
        console.log(data);
        axios.post('https://clinic.dnyanasha.com/ws/rolepermission.php',data).then((res)=>{
            console.log(res.data);
            this.setState({ rolelist : res.data.findroles });
        })
    }
    

    render(){
        return(
            <>
                {/* Add category start */}
                {
                    (this.state.isdoctor == "Doctor") ? (<>
                        {/* <div className="card mb-3" style={{ display: this.state.expanded1 ? 'block' : 'none', transition: "2s" ,transitionTimingFunction: "ease" }}>
                            <form onSubmit={this.handleSubmitcat}>
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5>Add Category</h5>
                                    <div>
                                        <a className="btn btn-danger" style={{marginLeft:"10px"}} onClick={() => this.setState({expanded1 : !this.state.expanded1}) }>Hide</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="control-label">Category Name</label>
                                                <input type="text" className="form-control" name="category_name" onChange={this.handleChange} required />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary mt-2">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                    </>) : (<></>)
                }
                {/* Add category end */}

                {/* Add staff start */}
                <div className="card mb-3" style={{ display: this.state.expanded ? 'block' : 'none', transition: "2s" ,transitionTimingFunction: "ease" }}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5>Add Staff</h5>
                            <div>
                                <a className="btn btn-danger" style={{marginLeft:"10px"}} onClick={() => this.setState({expanded : !this.state.expanded}) }>Hide</a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Staff Name</label>
                                        <input type="text" className="form-control" name="staff_name" onChange={this.handleChange} required />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">E-Mail ID</label>
                                        <input type="email" className="form-control" name="staff_email" onChange={this.handleChange} required />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Mobile Number</label>
                                        <input type="text" pattern="\d*" maxLength="10" minLength="10" className="form-control" name="mobile_number" onChange={this.handleChange} required />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Select Role</label>
                                        <select className="form-control" name="role" onChange={this.handleChange} required>
                                                <option value="">Select Role</option>
                                                {
                                                    this.state.rolelist.map( (res,index) =>{
                                                        if(res.role_name == "Doctor" || res.role_name == "Patient" || res.role_name == "Guest Doctor"){} else {
                                                            return(
                                                                <option key={index} value={res.role_id} >{res.role_name}</option>
                                                            )
                                                        }
                                                    })
                                                }
                                            </select>
                                    </div>
                                </div>
                                <div className="col-sm-12 d-flex justify-content-center">
                                    <button type="submit" className="btn btn-primary mt-2">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* Add staff end */}

                <div className="card p-3">
                    <div className="row">
                        <div className="col-sm-12">
                            <button className="btn btn-primary btn-sm" onClick={() => {this.setState({expanded : !this.state.expanded}); this.fetchaddstaffdata() } }>Add Staff</button>
                            <button className="btn btn-primary btn-sm mx-2" onClick={()=>{this.props.history.push("/addtherapy")}}>All Therapy</button>
                            {
                                (this.state.isdoctor == "Doctor") ? (
                                    <>
                                        {/* <button className="btn btn-primary" style={{marginLeft:"10px"}} onClick={() => {this.setState({expanded1 : !this.state.expanded1}); } }>Add Category</button> */}
                                    </>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </div>

                {
                    (this.state.isdoctor == "Doctor") ? 
                    (<>
                        <div className="card p-3 mt-4">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div style={{fontSize:"22px"}}>Doctor Schedule</div>
                                </div>

                                <div className="col-sm-12">
                                    <form onSubmit={this.handleSubmitsch}>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label className="control-label">Select Day</label>
                                                    <select className="form-control" name="day" onChange={this.handleChangesch} required>
                                                        <option value="">Select Day</option>
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thursday">Thursday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                        <option value="Sunday">Sunday</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <label className="control-label">In-Time</label>
                                                    <input type="time" className="form-control"  name="intime" onChange={this.handleChangesch} required />
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <label className="control-label">Out-Time</label>
                                                    <input type="time" className="form-control"  name="outtime" onChange={this.handleChangesch} required />
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <label className="control-label">Mode</label>
                                                    <select className="form-control" name="mode" onChange={this.handleChangesch} required>
                                                        <option value="">Select Mode</option>
                                                        <option value="Monday">In-Clinic</option>
                                                        <option value="Monday">Online</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mt-3">
                                                <div className="d-flex justify-content-center align-center"><button className="btn btn-primary" type="submit">Submit</button></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                    
                                <div className="col-sm-12 mt-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Day</th>
                                                <th>In-Time</th>
                                                <th>Out-Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.schlist.map((item,index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{item.day}</td>
                                                            <td>{item.in_time}</td>
                                                            <td>{item.out_time}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                        {/* <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>In-Time</th>
                                                    <th>Out-Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Monday</td>
                                                    <td><input type="time" className="form-control w-50"  name="monintime" onChange={this.handleChangesch} /></td>
                                                    <td><input type="time" className="form-control w-50"  name="monouttime"  /></td>
                                                </tr>
                                                <tr>
                                                    <td>Tuesday</td>
                                                    <td><input type="time" className="form-control w-50"  name="tueintime"  /></td>
                                                    <td><input type="time" className="form-control w-50"  name="tueouttime"  /></td>
                                                </tr>
                                                <tr>
                                                    <td>Wednesday</td>
                                                    <td><input type="time"  className="form-control w-50" name="wenintime"  /></td>
                                                    <td><input type="time"  className="form-control w-50" name="wenouttime"  /></td>
                                                </tr>
                                                <tr>
                                                    <td>Thursday</td>
                                                    <td><input type="time" className="form-control w-50"  name="thuintime"  /></td>
                                                    <td><input type="time" className="form-control w-50"  name="thuouttime"  /></td>
                                                </tr>
                                                <tr>
                                                    <td>Friday</td>
                                                    <td><input type="time"  className="form-control w-50" name="friintime"  /></td>
                                                    <td><input type="time" className="form-control w-50"  name="friouttime"  /></td>
                                                </tr>
                                                <tr>
                                                    <td>Saturday</td>
                                                    <td><input type="time" className="form-control w-50"  name="satintime"  /></td>
                                                    <td><input type="time" className="form-control w-50"  name="satouttime"  /></td>
                                                </tr>
                                                <tr>
                                                    <td>Sunday</td>
                                                    <td><input type="time" className="form-control w-50"  name="sunintime"  /></td>
                                                    <td><input type="time" className="form-control w-50"  name="sunouttime"  /></td>
                                                </tr>
                                            </tbody>
                                        </table> */}

                                        {/* <button className="btn btn-primary" type="submit">Submit</button> */}


                                        {/* <div>
                                            <p>Monday</p> <input type="time" />
                                            <p>Tuesday</p>
                                            <p>Wednesday</p>
                                            <p>Thursday</p>
                                            <p>Friday</p>
                                            <p>Saturday</p>
                                            <p>Sunday</p>
                                        </div> */}
                            </div>
                        </div>
                    </>) : (<>
                    </>)
                }

                <div className="card p-3 mt-4">
                    <div className="row">
                        <div className="col-sm-12">
                        <DataTable
                            columns={this.state.columns} 
                            data={this.state.results} 
                            style={{textAlign:"center"}}
                            // title="All Doctors"
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight='450px'
                            highlightOnHover
                            subHeader
                            subHeaderComponent={
                                <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                    <div style={{fontSize:"22px"}}>All Staff</div>
                                    <input 
                                        type="text"
                                        placeholder='search here'
                                        className='w-25 form-control'
                                        value={this.state.search}
                                        onChange={(e)=>this.setSearch(e.target.value)}
                                    />
                                </div>
                            }
                        />
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.editmodel}
                    onRequestClose={this.closeModal}
                    style={this.state.customStyles1}
                    contentLabel="Edit Staff"
                    ariaHideApp={false}
                >
                    <h5>Edit Staff</h5>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="control-label">Staff Name</label>
                                <input type="text" className="form-control" name="staff_nameedit" value={this.state.staff_nameedit} onChange={this.handleChange} required />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="control-label">E-Mail ID</label>
                                <input type="email" className="form-control" name="staff_emailedit" value={this.state.staff_emailedit} onChange={this.handleChange} required />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="control-label">Mobile Number</label>
                                <input type="text" pattern="\d*" maxLength="10" minLength="10" className="form-control" name="mobile_numberedit" value={this.state.mobile_numberedit} onChange={this.handleChange} required />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="control-label">Select Role</label>
                                <select className="form-control" name="roleedit" value={this.state.roleedit} onChange={this.handleChange} required>
                                        <option value="">Select Role</option>
                                        {
                                            this.state.rolelist.map( (res,index) =>{
                                                if(res.role_name == "Doctor" || res.role_name == "Patient" || res.role_name == "Guest Doctor"){} else {
                                                    return(
                                                        <option key={index} value={res.role_id} >{res.role_name}</option>
                                                    )
                                                }
                                            })
                                        }
                                    </select>
                            </div>
                        </div>
                        <div className="col-sm-12 d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary mt-2" onClick={this.handleSubmitedit}>Submit</button>
                        </div>
                    </div>
                </Modal>

            </>
        )
    }
}