import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import SignatureCanvas from 'react-signature-canvas';
import Modal from 'react-modal';
import Select from 'react-select'

export default class Addtreatment extends Component{

    constructor(props){
        super(props);
        this.state = {
            showaddprocedure : false,
            findqllprocedure : [],
            procedurelistadd : [],
            procedureqty : 1,
            enabledisc : false,
            totalval : 0,
            discountval : 0,
            distype : "PERCENT",
            errmesg : "",
            shownotes : false,
            docotrid : 0,
            searchQuery: '',
            filteredListt: [],
            findapptreat : [],
            // consentsigned : null,
            consentsigned : true,
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
            imgcaptured : null,
            options : [],
            // rows: [{therapyname: "",noofdays: "",priceou: ""}],
            rows: [],
        }
        // console.log(this.props)

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDis = this.handleChangeDis.bind(this);
        this.handleTreatmentSubmit = this.handleTreatmentSubmit.bind(this);

        this.fetchallprocedure();
        // this.fetchconsent();
        if(localStorage.getItem('user') == "Doctor"){ this.setState({docotrid : localStorage.getItem("user_id")}) }
    }

    fetchconsent = () => {
        const consentdata = {
            checkconsent : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',consentdata).then((res)=>{
            console.log(res.data);
            this.setState({consentsigned:res.data.consentsigned})
        })
    }

    fetchallprocedure = () =>{
        const datapro = {
            fetchallprocedure : "yes",
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',datapro).then((res)=>{
            console.log(res.data);
            this.setState({findqllprocedure:res.data.findqllprocedure})
            this.setState({filteredListt:res.data.findqllprocedure})
            this.setState({findapptreat:res.data.findapptreat})
        })

        const dataaddmodel = {
            dataaddmodel : "yes"
        }
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",dataaddmodel).then((res)=>{
            this.setState({ findalltherypy : res.data.findalltherypy })
            var treatarr = [];
            for(var i=0;i<res.data.findalltherypy.length;i++){
                treatarr.push({value:res.data.findalltherypy[i]['procedure_id'],label:res.data.findalltherypy[i]['procedure_name']+" "+res.data.findalltherypy[i]['procedure_price']})
            }
            this.setState({options:treatarr})
        })
    }

    adddprocedure = () =>{
        this.setState({ showaddprocedure : true })
    }

    hideprocedure = () =>{
        this.setState({ showaddprocedure : false })
    }

    handleChange = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const dataprocedure = {
            addprocedure : 'yes',
            proceudrename : this.state.proceudrename,
            procedureprice : this.state.procedureprice,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',dataprocedure).then((res)=>{
            console.log(res.data);
            if(res.data.saveprocedure > 0)
            {
                swal("Procedure Added", "Procedure Successfully Added..!", "success").then((swalres)=>{
                    this.setState({showaddprocedure:false})
                    this.fetchallprocedure();
                });
            }
        })
    }

    addprocedurelist = (item) =>{
        console.log(item);
        // this.setState({procedureid : item.procedure_id})
        // this.setState({proprice:item.procedure_price})
        const items = {
            treatid : item.procedure_id,
            therapyname: item.procedure_name,
            noofdays: "",
            priceou: item.procedure_price,
            tnotes : item.tnotes,
        };
        this.setState({ rows: [...this.state.rows, items] });



        // // console.log(item);
        // let pla = this.state.procedurelistadd;
        // pla.push(item)
        // // this.setState({procedurelistadd:pla})
        // this.setState({procedurelistadd:[item]})
        // this.setState({totalval : this.state.procedureqty * item.procedure_price})
        // // this.setState({proqty:item.procedure_price})
        // this.setState({proprice:item.procedure_price})
        // this.setState({procedureid : item.procedure_id})
    }

    handleChange = idx => e => {
        console.log("qq")
        const { name, value } = e.target;
        console.log(name + " = " + value)
        const rows = [...this.state.rows];
        let crow = rows[idx]
        crow[name] = value;
        rows[idx] = crow
        this.setState({ rows });
    };

    chnagedistype = (e) =>{
        this.setState({distype:e.target.value})
        if(e.target.value == "PERCENT")
        {
            if(this.state.discountval > 100){
                this.setState({errmesg : "Percent can't be more than 100."})
            } else {
                let toatlafterdis =  (this.state.proprice * this.state.procedureqty) - ((this.state.proprice * this.state.procedureqty) * (this.state.discountval / 100));
                this.setState({totalval : toatlafterdis})
            }
        }
        if(e.target.value == "NUMBER")
        {
            this.setState({errmesg : ""})
            if((this.state.proprice * this.state.procedureqty) < this.state.discountval){
                this.setState({errmesg : "Discount can't be more than Price."})
            } else {
                let toatlafterdis = (this.state.proprice * this.state.procedureqty) - this.state.discountval;
                this.setState({totalval : toatlafterdis})
            }
        }
    }

    handleChangeDis = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
        if(this.state.distype == "PERCENT")
        {
            if(event.target.value > 100){
                this.setState({errmesg : "Percent can't be more than 100."})
            } else {
                this.setState({errmesg : ""})
                let toatlafterdis =  (this.state.proprice * this.state.procedureqty) - ((this.state.proprice * this.state.procedureqty) * (event.target.value / 100));
                this.setState({totalval : toatlafterdis})
            }
        }
        if(this.state.distype == "NUMBER")
        {
            this.setState({errmesg : ""})
            if((this.state.proprice * this.state.procedureqty) < event.target.value){
                this.setState({errmesg : "Discount can't be more than Price."})
            } else {
                let toatlafterdis = (this.state.proprice * this.state.procedureqty) - event.target.value;
                this.setState({totalval : toatlafterdis})
            }
        }
    }

    handleTreatmentSubmit = (event) =>{
        event.preventDefault();
        const tdata = {
            addtreatment : 'yes',
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
            treatrowsall : this.state.rows,
            // tnotes : this.state.tnotes,
            // procedureid : this.state.procedureid,
            // proqty : this.state.procedureqty,
            // proprice : this.state.proprice,
            // discountval : this.state.discountval,
            // distype : this.state.distype,
            // totalval : this.state.totalval,
            // docotrid : this.state.docotrid,
            // fromdate : this.state.fromdate,
            // todate : this.state.todate,
            // countdays : this.state.countdays,
        }
        console.log(tdata);
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',tdata).then((res)=>{
            console.log(res.data)
            if(res.data.savetreatment > 0)
            {
                this.setState({rows:[]});
                this.setState({procedurelistadd:[]});
                this.fetchallprocedure();
                // swal("Treatment Added", "Treatment Successfully added..!", "success").then((swalres)=>{
                // });
            }
        })
    }

    countdays = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }
    
    componentDidUpdate(){
        // console.log("cdm called")
        if(this.state.fromdate != null && this.state.todate != null){
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const firstDate = new Date(this.state.fromdate);
            const secondDate = new Date(this.state.todate);
    
            const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
            // console.log(diffDays)
            if(this.state.countdays != diffDays+1){
                this.setState({countdays:diffDays+1})
            }
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    handleSearchQueryChange = (event) => {
        const searchQuery = event.target.value;
        const filteredListt = this.state.findqllprocedure.filter(item =>
          item.procedure_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        this.setState({
          searchQuery,
          filteredListt
        });
    }

    deletetherapy = (treatment_id) =>{
        // console.log(treatment_id)
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if(willDelete){
                axios.post("https://clinic.dnyanasha.com/ws/treatmentsplans.php",{deletetherapy:"yes",treatment_id:treatment_id}).then((res)=>{
                    // console.log(res.data)
                    this.fetchallprocedure();
                })
            } else {}
        })
    }

    prescription = () =>{
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/viewaddprescription/"+this.props.match.params.appid);
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
    }

    opensignmodel = () =>{
        this.setState({ modalIsOpen : true })
    }

    clear = () => {
        this.sigPad.clear()
    }

    trim = () => {
        this.setState({imgcaptured: this.sigPad.getTrimmedCanvas().toDataURL('image/png')})
        this.closeModal();
    }

    handleconsentsubmit = (e) =>{
        e.preventDefault();
        const dataconsent = {
            submitconsent : "yes",
            puid : this.props.match.params.puid,
            imgcaptured : this.state.imgcaptured,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',dataconsent).then((res)=>{
            if(res.data.saveconsent > 0){
                swal({title:"Consent Saved", text:"Consent Saved Successfully..!", icon:"success",timer:1000})
                this.fetchconsent();
            }
        })
    }

    gotoinnvoice = () =>{
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/invoicepd/view/"+this.props.match.params.appid);
    }

    handlechangetreatid = (e) =>{
        console.log(e['value'])
        const datatid = {
            fetchtreatdata : "yes",
            tid:e['value']
        }
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",datatid).then((res)=>{
            console.log(res.data)
            this.setState({price1unit:res.data.findtreatdetials['procedure_price']})
        })
    }

    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }

    render(){
        const { filteredListt } = this.state;
        if(this.state.consentsigned === false){
            return(<>
                    <Headerpd puid={this.props.match.params.puid} />
                    <Sidebarpd puid={this.props.match.params.puid} />
                    <div style={{marginLeft:"220px",padding:"5px"}}>
                        {/* <div className="d-flex flex-column align-items-center"> */}
                            <h5 className="text-center">You Have'nt Sign Consent Form. Please Sign before Traetment.</h5>
                            {/* <button className="btn btn-primary btn-sm">Sign Consent</button> */}
                            <hr />
                            <p className="text-center"><b>Consent Form</b></p>
                            <p>
                                <table>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>1.</td>
                                        <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>2.</td>
                                        <td>I confirm that the Core Concept team explained the procedure and treatment to me thoroughly to me and how it will help me with my current condition.</td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>3.</td>
                                        <td>I understand the risk and complications if I do not follow the aftercare instructions or follow ups as advised.</td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>4.</td>
                                        <td>I confirm that the information provided by me is true to my knowledge and I am fully responsible for any information that I hide or withhold for the purpose of this treatment and I indemnify Core Concept and its team against any losses and damages arising out of withholding or hiding such information.</td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>5.</td>
                                        <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                                    </tr>
                                </table>
                            </p>
                            
                            <hr />
                            
                            <div className="d-flex justify-content-between px-4">
                                <div className="text-center">
                                    <p className="m-0 "><b>{new Date().getDate() + " " + this.state.monthname[new Date().getMonth()] + " " + new Date().getFullYear()}</b></p>
                                    <p className="m-0 ">Date</p>
                                </div>
                                <div className="text-center">
                                    <p className="m-0 ">
                                        {
                                            (this.state.imgcaptured === null ) ? (
                                                <div style={{border:"1px solid #000",height:"60px",width:"120px"}} onClick={this.opensignmodel}></div>
                                            ) : (
                                                <div style={{border:"1px solid #000",height:"60px",width:"120px"}} onClick={this.opensignmodel}><img style={{height:"60px",width:"120px"}} src={this.state.imgcaptured} /></div>
                                            )
                                        }
                                    </p>
                                    <p className="m-0 ">Digital Signature</p>
                                </div>
                            </div>

                            <div>
                                <button className="btn btn-primary mt-2 hideonprint" onClick={this.handleconsentsubmit}>Submit</button>
                            </div>

                            <Modal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                style={this.state.customStyles}
                                contentLabel="Signataure Model"
                                ariaHideApp={false}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SignatureCanvas penColor='black' canvasProps={{width: 200, height: 100, className: 'sigCanvas'}} ref={(ref) => { this.sigPad = ref }} />
                                    </div>
                                    <div className="col-sm-12">
                                        <button className="btn btn-danger btn-sm" onClick={this.clear}> Clear </button>
                                        <button className="btn btn-primary btn-sm mx-4" onClick={this.trim}> Capture </button>
                                    </div>
                                </div>
                            </Modal>

                        {/* </div> */}
                    </div>
            </>)
        }
        if(this.state.consentsigned === true){
            return(
                <>
                    <Headerpd puid={this.props.match.params.puid} />
                    <Sidebarpd puid={this.props.match.params.puid} />
                    <div style={{marginLeft:"226px",padding:"5px"}}>

                        <div className="d-flex justify-content-between align-items-center">
                            <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                            {/* <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button> */}
                            <div><button className="btn btn-primary btn-sm my-2 mx-3" onClick={this.gotoinnvoice} >Go to Invoice</button>
                            <button className="btn btn-primary btn-sm my-2" onClick={this.prescription} >Prescription</button></div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-sm-8" style={{ borderRight:"1px solid #c9c9c9" }}>
                                <h3>Treatment Plans</h3>
                                <div className="row" style={{fontSize:"11px",borderBottom:"1px solid #c9c9c9"}}>
                                    <div className="col-sm-4">TREAMENTS</div>
                                    <div className="col-sm-4">
                                        TOTAL AMOUNT
                                        {/* <div className="row">
                                            <div className="col-sm-5">QTY</div>
                                            <div className="col-sm-1">X</div>
                                            <div className="col-sm-5">COST</div>
                                        </div> */}
                                    </div>
                                    {/* <div className="col-sm-3">CONCESSION</div> */}
                                    <div className="col-sm-3">DAYS</div>
                                    <div className="col-sm-1">DELETE</div>
                                    {/* <div className="col-sm-1">X</div> */}
                                </div>
                                {
                                    this.state.procedurelistadd.map((items,index)=>{
                                        return(
                                            <>
                                                {/* <form key={index} onSubmit={this.handleTreatmentSubmit}>
                                                    <div className="row" style={{padding:"10px 4px 10px 4px",borderBottom:"1px solid #c9c9c9"}}>
                                                        <div className="col-sm-3" style={{fontSize:"13px"}}>
                                                            <p className="m-0"><b>{items.procedure_name}</b></p>
                                                            {
                                                                (this.state.shownotes) ? (<><textarea rows="2" name="tnotes" onChange={(e)=>{this.setState({tnotes:e.target.value})}} className="w-100"></textarea></>) : (<>
                                                                    <p className="m-0" style={{color:"#0095CC",cursor:"pointer"}} onClick={()=>{this.setState({shownotes:true})}}>+ add notes</p>
                                                                </>)
                                                            }
                                                        </div>
                                                        <div className="col-sm-3" style={{fontSize:"13px"}}>
                                                            <div className="row">
                                                                <div className="col-sm-5"><input type="number" name="proqty" className="w-100" onChange={(e)=>{this.setState({procedureqty:e.target.value});this.setState({totalval : e.target.value * items.procedure_price})}} value={this.state.procedureqty} min="1" /></div>
                                                                <div className="col-sm-1">X </div>
                                                                <div className="col-sm-5 p-0"><input type="number" name="proprice" className="w-100" value={items.procedure_price} onChange={(e)=>{this.setState({proprice:e.target.value})}} /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{fontSize:"13px"}}>
                                                            {
                                                                (this.state.enabledisc == false) ? (<>
                                                                    <p className="m-0" style={{color:"#0095CC",cursor:"pointer"}} onClick={()=>{this.setState({ enabledisc : true })}} >+ add concession</p>
                                                                </>) : (<>
                                                                    <div className="d-flex">
                                                                        <input type="number" name="discountval" min="0" value={this.state.discountval} onChange={this.handleChangeDis} className="w-50" />
                                                                        <select name="distype" onChange={this.chnagedistype} value={this.state.distype}>
                                                                            <option value="PERCENT">%</option>
                                                                            <option value="NUMBER">INR</option>
                                                                        </select>
                                                                    </div>
                                                                    <p className="text-danger m-0">{this.state.errmesg}</p>
                                                                </>)
                                                            }
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <p className="m-0">{this.state.totalval}</p>
                                                        </div>
                                                        <div className="col-sm-1 p-0 text-danger d-flex justify-content-end">
                                                            <p style={{borderBottom:"1px solid #c9c9c9",borderLeft:"1px solid #c9c9c9",borderRadius:"0 0 0 50%",height:"18px",width:"15px",display:"flex",alignItems:"flex-end",justifyContent:"center",cursor:"pointer"}} onClick={()=>{this.setState({procedurelistadd:[]})}}>x</p>
                                                        </div>

                                                        <div className="col-sm-4" style={{fontSize:"13px"}}>
                                                            Form Date : <input type="date" className="w-100" value={this.state.fromdate} name="fromdate" onChange={this.countdays} />
                                                        </div>
                                                        <div className="col-sm-4" style={{fontSize:"13px"}}>
                                                            To Date : <input type="date" className="w-100" value={this.state.todate} name="todate" onChange={this.countdays} />
                                                        </div>
                                                        <div className="col-sm-4" style={{fontSize:"13px"}}>
                                                            Days : <input type="number" className="w-100" name="countdays" value={this.state.countdays} />
                                                        </div>

                                                    </div>
                                                    <div className="row mt-1"><div className="col-sm-9"></div><div className="col-sm-3"><button className="btn btn-sm btn-primary">Save Treatment</button></div></div>
                                                </form> */}
                                            </>
                                        )
                                    })
                                }

                                <form onSubmit={this.handleTreatmentSubmit}>
                                    <div className="row" style={{padding:"10px 4px 10px 4px",borderBottom:"1px solid #c9c9c9",fontSize:"13px"}}>
                                        {
                                            this.state.rows.map((item,index)=>{
                                                return(<>
                                                        <div className="col-sm-4">
                                                            Therapy Name :
                                                            <input type="text" className="w-100" name="therapyname" value={item.therapyname}  />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            No. of Days :
                                                            <input type="number" className="w-100" name="noofdays" value={item.noofdays} onChange={this.handleChange(index)} />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            Price (1 unit) :
                                                            <input type="text" className="w-100" name="priceou" value={item.priceou} onChange={this.handleChange(index)} />
                                                        </div>
                                                        <div className="col-sm-1 p-0 text-danger d-flex justify-content-end">
                                                            <p style={{borderBottom:"1px solid #c9c9c9",borderLeft:"1px solid #c9c9c9",borderRadius:"0 0 0 50%",height:"18px",width:"15px",display:"flex",alignItems:"flex-end",justifyContent:"center",cursor:"pointer"}} onClick={this.handleRemoveSpecificRow(index)}>x</p>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <textarea rows="1" name="tnotes" placeholder="Notes" value={item.tnotes} onChange={this.handleChange(index)} className="w-50 mt-1"></textarea>
                                                            {/* {
                                                                (this.state.shownotes) ? (<><textarea rows="1" name="tnotes" placeholder="Notes" onChange={this.handleChange(index)} className="w-50 mt-1"></textarea></>) : (<>
                                                                    <p className="m-0" style={{color:"#0095CC",cursor:"pointer"}} onClick={()=>{this.setState({shownotes:true})}}>+ add notes</p>
                                                                </>)
                                                            } */}
                                                        </div>
                                                        <input type="text" className="w-100" name="treatid" value={item.treatid} hidden />
                                                        <hr className="mt-2 mb-2" />
                                                </>)
                                            })
                                        }
                                    </div>
                                    {(this.state.rows.length === 0 ) ? (<></>) : (
                                        <div className="row mt-1"><div className="col-sm-2"></div><div className="col-sm-7"></div><div className="col-sm-3"><button className="btn btn-sm btn-primary">Save Treatment</button></div></div>
                                    )}
                                </form>

                                {
                                    this.state.findapptreat.map((itemss,index)=>{
                                        return(
                                            <>
                                                <div key={index} className="row pt-2 pb-2" style={{fontSize:"12px",borderBottom:"1px solid #c9c9c9"}}>
                                                    <div className="col-sm-4"><b>{itemss.procedure_name}</b>
                                                    <p className="m-0" >{(itemss.tnotes === "") ? (<></>) : (<><b>Notes : </b>{itemss.tnotes}</>) }</p></div>
                                                    {/* <div className="col-sm-3">
                                                        <div className="row">
                                                            <div className="col-sm-5 text-end">{itemss.quantity}</div>
                                                            <div className="col-sm-1">*</div>
                                                            <div className="col-sm-5">₹ {itemss.tcost}</div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-sm-3">{(itemss.discount !== "0") ? (itemss.discount) : ("₹ 0") }</div> */}
                                                    <div className="col-sm-4"><b>₹ {itemss.amount}</b></div>
                                                    <div className="col-sm-3"><b>{itemss.totaldays} Days </b></div>
                                                    <div className="col-sm-1"><box-icon name='trash' style={{fill:"red",cursor:"pointer"}} onClick={()=>this.deletetherapy(itemss.treatment_id)} ></box-icon></div>

                                                    {/* <div className="col-sm-4"><b>From Date : </b>{itemss.fromdate}</div>
                                                    <div className="col-sm-4"><b>To Date : </b>{itemss.todate}</div> */}
                                                </div>
                                            </>
                                        )
                                    })
                                }

                            </div>

                            <div className="col-sm-4" style={{padding:"0"}}>
                                
                                <div className="d-flex justify-content-end">
                                    {
                                        (this.state.showaddprocedure) ? (<>
                                            <button className="btn btn-sm btn-primary" onClick={this.hideprocedure} style={{marginBottom:"5px",marginRight:"10px"}}> x Cancel</button>
                                        </>) : (<>
                                            <button className="btn btn-sm btn-primary" onClick={this.adddprocedure} style={{marginBottom:"5px",marginRight:"10px"}}> + Add</button>
                                        </>)
                                    }
                                </div>

                                {
                                    (this.state.showaddprocedure) ? (<>
                                    <form onSubmit={this.handleSubmit}>
                                        <div style={{ padding:"10px",background:"#eeeeee" }}>
                                            <div className="row mt-1">
                                                <div className="col-sm-6"><input type="text" className="w-100 h-100" name="proceudrename" placeholder="Name" onChange={this.handleChange} required /></div>
                                                <div className="col-sm-5"><input type="number" className="w-100 h-100" name="procedureprice" placeholder="INR" onChange={this.handleChange} required /></div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-sm-12"><button type="submit" className="btn btn-sm btn-primary w-100 h-100">Save Procedure</button></div>
                                            </div>
                                        </div>
                                    </form>
                                    </>) : (<></>)
                                }

                                <div style={{ padding:"10px",background:"#eeeeee" }}>
                                    <input type="text" className="form-control" name="drugsearch" placeholder="Search...." value={this.state.searchQuery} onChange={this.handleSearchQueryChange} />
                                </div>

                                <hr style={{color:"cecece",margin:"0"}}/>

                                <div style={{height:"400px",overflow:"scroll"}}>
                                    {
                                        filteredListt.map((item,index)=>{
                                            return(
                                                <>
                                                    <div key={index} onClick={()=>this.addprocedurelist(item)} style={{fontSize:"14px",margin:"0",borderBottom:"1px solid #c9c9c9",padding:"1px",cursor:"pointer"}} className="d-flex justify-content-between">
                                                        <p className="m-0" >{item.procedure_name}</p>
                                                        <p className="m-0" >{item.procedure_price}</p>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </>
            )
        }
    }
}