import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import DataTable from 'react-data-table-component'
import axios from "axios";

export default class Prescription extends Component{

    constructor(props){
        super(props);
        this.state = {
            columns : [
                // {
                //     name:"Appoitment ID",
                //     selector:(row)=>row.appointment_id
                // },
                {
                    name:"Department",
                    selector:(row)=>row.dept_name
                },
                {
                    name:"Appointed Date",
                    selector:(row)=>row.schedule_date
                },
                {
                    name:"Start and End Time",
                    selector:(row)=>row.start_time+" - "+row.end_time
                },
                {
                    name:"Mode",
                    selector:(row)=>{ if(row.mode == "inclinic"){ return(<>In-Clinic</>) } if(row.mode == "videocons"){ return(<>Video Consultation</>) } }
                },
                {
                    name:"Action",
                    cell:(row)=>{return(<button onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/viewaddprescription/'+row.appointment_id+'') }} className="btn btn-sm btn-primary">View Prescription</button>)}
                }
                // {
                //     name:"Status",
                //     selector:(row)=>row.astatus
                // },
                // {
                //     name:"Action",
                //     cell:(row)=>{return(<>
                //                     {/* <OverlayTrigger placement="top" overlay={(props)=>{return(<Tooltip {...props}>Tooltip for the register button</Tooltip>)}}> */}
                //                         {/* <box-icon style={{cursor:"pointer"}} alt="Edit" title="Edit" type='solid' name='edit'></box-icon> */}
                //                         <box-icon style={{cursor:"pointer",marginLeft:"5px"}} name='show' alt="View" title="View" onClick={()=>this.viewmodel(row)} ></box-icon>
                //                     {/* </OverlayTrigger> */}
                //                     {/* <button className="btn btn-primary">View</button> */}
                //                 </>)}
                // },
            ],
            results : [],
            findpapp:[],
        }

        this.fetchprecapp();
    }

    fetchprecapp = () =>{
        const datapa = {
            fetchapppres : 'yes',
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/prescription.php',datapa).then((res)=>{
            console.log(res.data)
            this.setState({findpapp:res.data.findpapp})
            this.setState({results:res.data.findpapp})
        })
    }

    openprescription = (row) =>{
        this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/viewaddprescription/'+row.appointment_id+'')
    }


    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px"}}>
                    {/* <p>Previous Prescriptions</p> */}
                    {/* <button className="btn btn-primary">Add New Precription</button> */}

                    <div className="row">
                        <div className="col-sm-12">
                            <DataTable
                                columns={this.state.columns} 
                                data={this.state.results} 
                                style={{textAlign:"center"}}
                                // title="All Doctors"
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight='450px'
                                highlightOnHover
                                subHeader
                                onRowClicked={this.openprescription}
                                subHeaderComponent={
                                    <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                        {/* <div style={{fontSize:"22px"}}>Appointments for Prescription</div> */}
                                        <div style={{fontSize:"22px"}}>Date Wise Prescription</div>
                                        <input 
                                            type="text"
                                            placeholder='search here'
                                            className='w-25 form-control'
                                            value={this.state.search}
                                            onChange={(e)=>this.setSearch(e.target.value)}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>

                </div>
            </>
        )
    }
}