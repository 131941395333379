import React, { Component } from "react";
import smileEmoji from './assets/smile.png';
import smile1Emoji from './assets/smile1.png';
import confuseEmoji from './assets/confused.png';
import sadEmoji from './assets/sad.png';
import sad1Emoji from './assets/sad1.png';
import disapptEmoji from './assets/dissapointment.png';
import humanBody from './assets/humanbody.png';
import ImageMarker, { Marker } from 'react-image-marker';
import axios from "axios";
import swal from "sweetalert";

import Headerpd from "./patient_dashboard/header";
import Sidebarpd from "./patient_dashboard/sidebar";

import Printheader from './assets/printheader.png';
import Printfooter from './assets/printfooter.png';


export default class AddphysotherapyDetailsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            findpid : [],
        }

        this.fetchphydata();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    fetchphydata = () => {
        const pddata = {
            fetchphysotherpydata: 'yes',
            patientid: this.props.match.params.puid,
            appid: this.props.match.params.appid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/patient.php", pddata).then((res) => {
            console.log(res.data)
            this.setState({ findpid: res.data.findpid })
            this.setState({ psex: res.data.metaarr.psex })
            this.setState({ pdob: res.data.metaarr.pdob })
            this.setState({ paddres: res.data.metaarr.paddres })
            this.setState({ pdistrict: res.data.metaarr.pdistrict })
            this.setState({ pcity: res.data.metaarr.pcity })
            this.setState({ page: res.data.metaarr.page })
            this.setState({ pheight: res.data.metaarr.pheight })
            this.setState({ pweight: res.data.metaarr.pweight })
            this.setState({ poccup: res.data.metaarr.poccup })
            this.setState({ pmarried: res.data.metaarr.pmarried })
            this.setState({ pspouse: res.data.metaarr.pspouse })
            this.setState({ pref: res.data.metaarr.pref })
            this.setState({ pbill: res.data.metaarr.pbill })
            this.setState({ ppycash: res.data.metaarr.ppycash })
            this.setState({ pcpresult: res.data.metaarr.pcpresult })
            this.setState({ pmajourcmpl: res.data.metaarr.pmajourcmpl })
            this.setState({ pcddev: res.data.metaarr.pcddev })
            this.setState({ poffwrk: res.data.metaarr.poffwrk })
            this.setState({ prectreat: res.data.metaarr.prectreat })
            this.setState({ pprblmget: res.data.metaarr.pprblmget })
            this.setState({ panycndworse: res.data.metaarr.panycndworse })
            this.setState({ emoji: res.data.metaarr.emoji })
            this.setState({ paffcthmlife: res.data.metaarr.paffcthmlife })
            this.setState({ paffctoccuplife: res.data.metaarr.paffctoccuplife })
            this.setState({ paffctrectlife: res.data.metaarr.paffctrectlife })
            this.setState({ paffctrestlife: res.data.metaarr.paffctrestlife })
            this.setState({ pmeddiag: res.data.metaarr.pmeddiag })
            this.setState({ pdrugtk: res.data.metaarr.pdrugtk })
            this.setState({ pphypast: res.data.metaarr.pphypast })
            this.setState({ pdoctnm: res.data.metaarr.pdoctnm })
            this.setState({ pdoctconslt: res.data.metaarr.pdoctconslt })
            this.setState({ pproblem: res.data.metaarr.pproblem })
            this.setState({ pcovidvaccine: res.data.metaarr.pcovidvaccine })
            this.setState({ pallergiestomedicine: res.data.metaarr.pallergiestomedicine })
            this.setState({ bloodpressure: res.data.metaarr.bloodpressure })
            this.setState({ Diabetes: res.data.metaarr.Diabetes })
            this.setState({ Arthritis: res.data.metaarr.Arthritis })
            this.setState({ autoimmuned: res.data.metaarr.autoimmuned })
            this.setState({ Cancer: res.data.metaarr.Cancer })
            this.setState({ other: res.data.metaarr.other })
            this.setState({ markerdescription: res.data.metaarr.markerdescription })
            this.setState({ markers: JSON.parse(res.data.metaarr.markers) })
            if (res.data.metaarr.other == "other") { this.setState({ otherfollwdiseas: true }) }
        })
    }

    CustomMarker = (marker) => {
        return (
            <div className="image-marker__marker image-marker__marker--default text-dark" data-testid="marker">{marker['itemNumber'] + 1}</div>
        );
        // <div style={{border:"1px solid block",height:"15px",width:"15px"}} data-testid="marker"></div>
        // return (
        //     <>
        //         <input type="text" className="form-control p-0" style={{width:"50px"}}  />
        //         <div className="image-marker__marker image-marker__marker--default" data-testid="marker"></div>
        //     </>
        // );
    };

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            if (event.target.checked === true) {
                this.setState({ [name]: true })
            } else {
                this.setState({ [name]: false })
            }
        } else {
            this.setState({ [name]: value })
        }
        // let name = event.target.name
        // this.setState({ [name]: event.target.value });
        if (event.target.value === "other" && name === "other") {
            this.setState({ otherfollwdiseas: !this.state.otherfollwdiseas })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const datapatientphys = {
            addpatientmeta: "yes",
            appid: this.props.match.params.appid,
            psex: this.state.psex,
            page: this.state.page,
            poccup: this.state.poccup,
            pheight: this.state.pheight,
            pweight: this.state.pweight,
            pmarried: this.state.pmarried,
            pspouse: this.state.pspouse,
            pref: this.state.pref,
            pbill: this.state.pbill,
            ppycash: this.state.ppayment,
            pcpresult: this.state.pcpresult,
            pmajourcmpl: this.state.pmajourcmpl,
            pcddev: this.state.pcddev,
            poffwrk: this.state.poffwrk,
            prectreat: this.state.prectreat,
            pprblmgetbetter: this.state.pprblmget,
            panycndworse: this.state.panycndworse,
            paffcthmlife: this.state.paffcthmlife,
            paffctoccuplife: this.state.paffctoccuplife,
            paffctrectlife: this.state.paffctrectlife,
            paffctrestlife: this.state.paffctrestlife,
            pmeddiag: this.state.pmeddiag,
            pdrugtk: this.state.pdrugtk,
            pphypast: this.state.pphypast,
            pdoctnm: this.state.pdoctnm,
            pdoctconslt: this.state.pdoctconslt,
            pproblem: this.state.pproblem,
            phistory: this.state.phistory,
            pobservation: this.state.pobservation,
            markers: JSON.stringify(this.state.markers),
            emoji: this.state.emoji,
            pcovidvaccine: this.state.pcovidvaccine,
            pallergiestomedicine: this.state.pallergiestomedicine,
            bloodpressure: this.state.bloodpressure,
            Diabetes: this.state.Diabetes,
            Arthritis: this.state.Arthritis,
            autoimmuned: this.state.autoimmuned,
            Cancer: this.state.Cancer,
            other: this.state.other,
            // pdyhaof : this.state.pdyhaof,
            otherfollwdiseas: this.state.otherfollwdiseas,
            markerdescription: this.state.markerdescription,
        }
        axios.post('https://clinic.dnyanasha.com/ws/patient.php', datapatientphys).then((res) => {
            if (res.data.savepatientmeta > 0) {
                // swal("Physiotherapy Added", "Physiotherapy Successfully added..!", "success").then((swalres) => {
                //     this.props.history.push(  '/patient')
                // });
                this.props.history.push('/patient_dashboard/'+res.data.unique_pid+'/consultation');
            }
            if (res.data.updatepmeta === true) {
                // swal("Physiotherapy Updated", "Physiotherapy Successfully updated..!", "success").then((swalres) => { 
                //     this.props.history.push(  '/patient')
                // });
                this.props.history.push('/patient_dashboard/'+res.data.unique_pid+'/consultation');
            }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 100px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 70%;
                                            height: 100px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">${printContents}</div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 1)
    }

    render() {
        return (
            <>
                {
                    (this.props.match.params.puid != undefined) ? (<>
                        <Headerpd puid={this.props.match.params.puid} />
                        <Sidebarpd puid={this.props.match.params.puid} />
                    </>
                    ) : (<></>)
                }
                <div className={(this.props.match.params.puid != undefined) ? "pdcss" : ""}>
                    
                    <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                        <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                    </div>
                    
                    <hr />
                    <div id="printablediv">
                    <div className="row" style={{ paddingRight: "70px", paddingLeft: "70px" }}>
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-between">
                                <p className="m-0">Name : <b>{this.state.findpid['patient_name']} {(this.state.findpid['gender'] === "f") ? " Female " : " Male " }
                                    {(this.state.findpid['dob'] !== '') ?   new Date().getFullYear() - new Date(this.state.findpid['dob']).getFullYear() : <></>} Yrs
                                </b></p>
                                <p className="m-0">Patient ID : <b>{this.state.findpid['unique_pid']}</b></p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="m-0">Mobile No. : <b>{this.state.findpid['mobile_number']}</b></p>
                                <p className="m-0">E-Mail : <b>{this.state.findpid['email']}</b></p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <form onSubmit={this.handleSubmit} style={{ paddingRight: "70px", paddingLeft: "70px" }} id="printablediv">
                        <div className="form-group row">
                            {/* <div className="col-sm-5">
                            <label className="col-sm form-label">Gender / लिंग</label>
                            <select className="form-select splin" onChange={this.handleChange} name="psex" required>
                                <option value="">select Gender</option>
                                <option value="m">Male</option>
                                <option value="f">Female</option>
                            </select>
                        </div> */}

                                {/* <div className="col-sm-3">
                                    <label className="col-form-label">Age / वय</label>
                                    <input type="number" min={"1"} className="form-control splin" placeholder="Age" value={this.state.page} name="page" onChange={this.handleChange} />
                                </div> */}
                                <label className="col-sm-1 col-form-label">DOB</label>
                                <div className="col-sm-5">
                                    {(this.state.findpid['dob'] !== '') ?
                                        <input type="date" className="form-control" placeholder="Date" value={this.state.findpid['dob']} name="pdob"  />
                                    :
                                        <input type="date" className="form-control" placeholder="Date" value={this.state.pdob} name="pdob" onChange={this.handleChange} />
                                    }
                                </div>
                                <div className="col-sm-3">
                                    <label className="col-form-label">Height / उंची</label>
                                    <input type="number" min={"1"} className="form-control splin mx-1" placeholder="Height" value={this.state.pheight} name="pheight" onChange={this.handleChange} /> ft
                                </div>
                                <div className="col-sm-3">
                                    <label className="col-form-label">Weight / वजन</label>
                                    <input type="number" min={"1"} className="form-control splin mx-1" placeholder="Weight" value={this.state.pweight} name="pweight" onChange={this.handleChange} /> kg
                                </div>
                        </div> {/*------form-group----- */}

                        <div className="row mt-2">
                                
                               
                        </div>
                        {/* <div className="form-group row mt-3">
                            <div className="col-sm-6">
                                <label className="col-form-label">Occupation / व्यवसाय</label>
                                <input type="text" className="form-control" placeholder="Occupation" name="poccup" value={this.state.poccup} onChange={this.handleChange} />
                            </div> */}
                            {/* <div className="col-sm-3">
                                <label className="col-form-label">Height / उंची in CM</label>
                                <input type="number" min={"1"} className="form-control splin" placeholder="Height / उंची" name="pheight" value={this.state.pheight} onChange={this.handleChange} />
                            </div>
                            <div className="col-sm-3">
                                <label className="col-form-label">Weight / वजन in KG</label>
                                <input type="number" min={"1"} className="form-control splin" placeholder="Weight / वजन" name="pweight" value={this.state.pweight} onChange={this.handleChange} />
                            </div> */}
                        {/* </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-2 col-form-label">Occupation / व्यवसाय</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" placeholder="Occupation" value={this.state.poccup} name="poccup" onChange={this.handleChange} />
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Married" id="pmarriedyes" name="pmarried" checked={(this.state.pmarried == "Married") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pmarriedyes">Married / विवाहित</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="single" id="pmarriedno" name="pmarried" checked={(this.state.pmarried == "single") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pmarriedno">Single / अविवाहित</label>
                                </div>
                            </div>

                        {/* <div className="form-group row">
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Married" id="pmarriedyes" name="pmarried" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pmarriedyes">Married / विवाहित</label>
                            </div>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="single" id="pmarriedno" name="pmarried" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pmarriedno">Single / अविवाहित</label>
                            </div>
                            <div className="col-sm-6">

                            </div>

                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">Name of Spouse / Father / पती / पत्नी / वडीलांचे नांव </label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="Name of Spouse / Father /" value={this.state.pspouse} name="pspouse" onChange={this.handleChange} />
                                </div>
                            </div>

                            {/* <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">Referred to our office by / आमच्या क्लिनिकचा संदर्भ कोठून मिळाला ?</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="Referred to our office by " value={this.state.pref} name="pref" onChange={this.handleChange} />
                                </div>
                            </div> */}

                        {/* <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Name of Spouse / Father / पती / पत्नी / वडीलांचे नांव  </label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" placeholder="Name of Spouse / Father /" name="pspouse" onChange={this.handleChange} />
                            </div>
                        </div> {/*------form-group----- 
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Referred to our office by / आमच्या क्लिनिकचा संदर्भ कोठून मिळाला ? </label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" placeholder="Referred to our office by" name="pref" onChange={this.handleChange} />
                            </div>
                        </div> ------form-group----- */}

                            

                        {/* <div className="form-group row">
                            <label className="col-sm-3 col-form-label">who is responsible for your bill? / बिल भरण करणार</label>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="self" id="pbillself" name="pbill" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pbillself">Self / स्वत</label>
                            </div>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Soupse" id="pbillspous" name="pbill" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pbillspous">Soupse /  जोडीदार</label>
                            </div>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="employer" id="pbillemployer" name="pbill" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pbillemployer">Employer / नियोक्ता</label>
                            </div>
                        </div> ------form-group----- */}

                            


                        {/* <div className="form-group row">
                            <label className="col-sm-3 col-form-label">How will payment be made? / बिल भरणा कसे कराल</label>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Cash" id="ppaymentcash" name="ppayment" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="ppaymentcash">Cash / रोख</label>
                            </div>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Cheque" id="ppaymentchq" name="ppayment" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="ppaymentchq">Cheque / चेक</label>
                            </div>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Digital" id="ppaymentdigi" name="ppayment" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="ppaymentdigi">Digital / डिजीटल </label>
                            </div>
                        </div> ------form-group----- */}

                        

                        {/* <div className="form-group row">
                            <label className="col-sm-8 col-form-label">Is this complaint resulting from any accident? / आपली ही तक्रार अपघातामूळे निमाऺण झाली आहे का?</label>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Yes" id="pcpresultYes" name="pcpresult" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pcpresultYes">Yes / होय</label>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="No" id="pcpresultNo" name="pcpresult" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pcpresultNo">No / नाही</label>
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Please describe only your major complaint / कृपया आपली मुख्य तक्रार नोंदवावी</label>
                                <div className="col-sm-12">
                                    <textarea className="form-control" rows="2" name="pmajourcmpl" value={this.state.pmajourcmpl} onChange={this.handleChange}></textarea>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <label className="col-sm-8 col-form-label">Is this complaint resulting from any accident? / आपली ही तक्रार अपघातामूळे निर्माण झाली आहे का?</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Yes" id="pcpresultYes" name="pcpresult" checked={(this.state.pcpresult == "Yes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pcpresultYes">Yes / होय</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="No" id="pcpresultNo" name="pcpresult" checked={(this.state.pcpresult == "No") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pcpresultNo">No / नाही</label>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">How this condition develop? /  हि तक्रार कशामुळे उद्भवली?</label>
                                <div className="col-sm-12">
                                    <textarea className="form-control" rows="2" name="pcddev" value={this.state.pcddev} onChange={this.handleChange}></textarea>
                                </div>
                            </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Please describe only your major complaint / कृपया आपली मुख्य तक्रार नोंदवावी</label>
                            <div className="col-sm-12">
                                <textarea className="form-control" rows="2" name="pmajourcmpl" onChange={this.handleChange}></textarea>
                            </div>
                        </div> {/*------form-group-----
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">How this condition develop? /  हि तक्रार कशामुळे उद्भवली?</label>
                            <div className="col-sm-12">
                                <textarea className="form-control" rows="2" name="pcddev" onChange={this.handleChange}></textarea>
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-8 col-form-label">Are you currently off work because of your problem? / या कारणास्तव तुम्ही कामावरून सुट्टी घेतली आहे का?</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Yes" id="poffwrkYes" checked={(this.state.poffwrk == "Yes") ? true : false} name="poffwrk" onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="poffwrkYes">Yes / होय</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="No" id="poffwrkNo" checked={(this.state.poffwrk == "No") ? true : false} name="poffwrk" onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="poffwrkNo">No / नाही</label>
                                </div>
                            </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-8 col-form-label">Are you currently off work because of your problem? / या कारणास्तव तुम्ही कामावरून सुट्टी घेतली आहे का?</label>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Yes" id="poffwrkYes" name="poffwrk" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="poffwrkYes">Yes / होय</label>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="No" id="poffwrkNo" name="poffwrk" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="poffwrkNo">No / नाही</label>
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Have you received any treatment for this condition? If yes, where, when and what were the results? / आपण संबंधित तक्रारीसाठी आधी कोणती उपचार पद्धती अवलंबली आहे का? असल्यास कुठे, केंव्हा आणि काय परिणाम झाला?</label>
                                <div className="col-sm-12">
                                    <textarea className="form-control" rows="2" name="prectreat" value={this.state.prectreat} onChange={this.handleChange}></textarea>
                                </div>
                            </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Have you received any treatment for this condition? If yes, where, when and what were the results? / आपण संबंधित तक्रारीसाठी आधी कोणती उपचार पद्धती अवलंबली आहे का? असल्यास कुठे, केंव्हा आणि काय परिणाम झाला?</label>
                            <div className="col-sm-12">
                                <textarea className="form-control" rows="2" name="prectreat" onChange={this.handleChange}></textarea>
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">Has this problem been getting / आपली तक्रार</label>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="better" id="pprblmgetbetter" name="pprblmget" checked={(this.state.pprblmget == "better") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pprblmgetbetter">Better /  बरी होते आहे?</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Worse" id="pprblmgetWorse" name="pprblmget" checked={(this.state.pprblmget == "Worse") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pprblmgetWorse">Worse / वाढली आहे</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Stay_the_same" id="pprblmgetStay_the_same" name="pprblmget" checked={(this.state.pprblmget == "Stay_the_same") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pprblmgetStay_the_same">Stay the same / तशीच आहे?</label>
                                </div>
                            </div>  

                        {/* <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Has this problem been getting / आपली तक्रार</label>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="better" id="pprblmgetbetter" name="pprblmget" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pprblmgetbetter">Better /  बरी होते आहे?</label>
                            </div>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Worse" id="pprblmgetWorse" name="pprblmget" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pprblmgetWorse">Worse / वाढली आहे</label>
                            </div>
                            <div className="col-sm-3 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="Stay_the_same" id="pprblmgetStay_the_same" name="pprblmget" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pprblmgetStay_the_same">Stay the same / तशीच आहे?</label>
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Is there anything you do that makes your condition worse / आपली तक्रार ज्यामुळे वाढते आहे अशी काही करणे आहेत का?</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" placeholder="Is there anything you do that makes your condition worse" name="panycndworse" value={this.state.panycndworse} onChange={this.handleChange} />
                                </div>
                            </div>


                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Is there anything you do that makes your condition worse / आपली तक्रार ज्यामुळे वाढते आहे अशी काही करणे आहेत का?</label>
                            <div className="col-sm-12">
                                <input type="text" className="form-control" placeholder="Is there anything you do that makes your condition worse" name="panycndworse" onChange={this.handleChange} />
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Descirbe Your Pain  / आपल्या दुखण्याची तीव्रता चिन्हा द्वारे दर्शवा <span>How severe is the Pain Tick at Rest</span></label>
                                <div className="col-sm-12">
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "smileEmoji") ? "clickedemoji" : ""}`} src={smileEmoji} onClick={() => this.setState({ emoji: "smileEmoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "smile1Emoji") ? "clickedemoji" : ""}`} src={smile1Emoji} onClick={() => this.setState({ emoji: "smile1Emoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "confuseEmoji") ? "clickedemoji" : ""}`} src={confuseEmoji} onClick={() => this.setState({ emoji: "confuseEmoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "sadEmoji") ? "clickedemoji" : ""}`} src={sadEmoji} onClick={() => this.setState({ emoji: "sadEmoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "sad1Emoji") ? "clickedemoji" : ""}`} src={sad1Emoji} onClick={() => this.setState({ emoji: "sad1Emoji" })} alt="emoji" width={"100px"} /></span>
                                    <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "disapptEmoji") ? "clickedemoji" : ""}`} src={disapptEmoji} onClick={() => this.setState({ emoji: "disapptEmoji" })} alt="emoji" width={"100px"} /></span>
                                </div>
                            </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Descirbe Your Pain / आपल्या दुखण्याची तीव्रता चिन्हा द्वारे दर्शवा<span>How severe is the Pain Tick at Rest</span></label>
                            <div className="col-sm-12">
                                <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "smileEmoji") ? "clickedemoji" : ""}`} src={smileEmoji} onClick={() => this.setState({ emoji: "smileEmoji" })} alt="emoji" width={"100px"} /></span>
                                <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "smile1Emoji") ? "clickedemoji" : ""}`} src={smile1Emoji} onClick={() => this.setState({ emoji: "smile1Emoji" })} alt="emoji" width={"100px"} /></span>
                                <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "confuseEmoji") ? "clickedemoji" : ""}`} src={confuseEmoji} onClick={() => this.setState({ emoji: "confuseEmoji" })} alt="emoji" width={"100px"} /></span>
                                <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "sadEmoji") ? "clickedemoji" : ""}`} src={sadEmoji} onClick={() => this.setState({ emoji: "sadEmoji" })} alt="emoji" width={"100px"} /></span>
                                <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "sad1Emoji") ? "clickedemoji" : ""}`} src={sad1Emoji} onClick={() => this.setState({ emoji: "sad1Emoji" })} alt="emoji" width={"100px"} /></span>
                                <span className="emojis" style={{ cursor: "pointer" }}><img className={` ${(this.state.emoji == "disapptEmoji") ? "clickedemoji" : ""}`} src={disapptEmoji} onClick={() => this.setState({ emoji: "disapptEmoji" })} alt="emoji" width={"100px"} /></span>
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">How has this condition affected your life? / ह्या परिस्थिती मुळे आपल्या जीवनात बदल झाले आहेत का?</label>
                                <label className="col-sm-3 col-form-label">A) Home life / घरगुती जीवन</label>
                                <div className="col-sm-9 mt-1">
                                    <input type="text" className="form-control" placeholder="Home life" name="paffcthmlife" value={this.state.paffcthmlife} onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-3 col-form-label">B) Occupational life / व्यावसायिक जीवन</label>
                                <div className="col-sm-9 mt-1">
                                    <input type="text" className="form-control" placeholder="Occupational life" name="paffctoccuplife" value={this.state.paffctoccuplife} onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-3 col-form-label">C) Recreational life / मनोरंजक जीवन</label>
                                <div className="col-sm-9 mt-1">
                                    <input type="text" className="form-control" placeholder="Recreational life" name="paffctrectlife" value={this.state.paffctrectlife} onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-3 col-form-label">D) Reset and sleep life / झोप व आराम</label>
                                <div className="col-sm-9 mt-1">
                                    <input type="text" className="form-control" placeholder="Reset and sleep life" name="paffctrestlife" value={this.state.paffctrestlife} onChange={this.handleChange} />
                                </div>
                            </div>


                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">How has this condition affected your life? / ह्या परिस्थिती मुळे आपल्या जीवनात बदल झाले आहेत का?</label>
                            <label className="col-sm-2 col-form-label">A) Home life / घरगुती जीवन</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" placeholder="Home life" name="paffcthmlife" onChange={this.handleChange} />
                            </div>
                            <label className="col-sm-2 col-form-label">B) Occupational life / व्यावसायिक जीवन</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" placeholder="Occupational life" name="paffctoccuplife" onChange={this.handleChange} />
                            </div>
                            <label className="col-sm-2 col-form-label">C) Recreational life / मनोरंजक जीवन</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" placeholder="Recreational life" name="paffctrectlife" onChange={this.handleChange} />
                            </div>
                            <label className="col-sm-2 col-form-label">D) Reset and sleep life / झोप व आराम</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" placeholder="Reset and sleep life" name="paffctrestlife" onChange={this.handleChange} />
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-6 col-form-label">Any medical diagnosis of your complaint / आपल्या तक्रारीवर कोणते वैद्यकिय निदान झाले आहे का</label>
                                <div className="col-sm-6">
                                    <input type="text" className="form-control" placeholder="any medical diagnosis of your complaint" name="pmeddiag" value={this.state.pmeddiag} onChange={this.handleChange} />
                                </div>
                            </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-6 col-form-label">Any medical diagnosis of your complaint / आपल्या तक्रारीवर कोणते वैद्यकिय निदान झाले आहे का</label>
                            <div className="col-sm-6">
                                <input type="text" className="form-control" placeholder="any medical diagnosis of your complaint" name="pmeddiag" onChange={this.handleChange} />
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-6 col-form-label">Drugs you take now / आपण कोणती औषधे घेता</label>
                                <div className="col-sm-6">
                                    <input type="text" className="form-control" placeholder="Drugs you take now" name="pdrugtk" value={this.state.pdrugtk} onChange={this.handleChange} />
                                </div>
                            </div>
                            
                        {/* <div className="form-group row">
                            <label className="col-sm-6 col-form-label">Drugs you take now / आपण कोणती औषधे घेता</label>
                            <div className="col-sm-6">
                                <input type="text" className="form-control" placeholder="Drugs you take now" name="pdrugtk" onChange={this.handleChange} />
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-8 col-form-label">Any physical therapist consulted in the past / या आधी कोणत्या फिजिओथेरपीस्टला दाखविले आहे का</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="yes" id="pphypastyes" name="pphypast" checked={(this.state.pphypast == "yes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pphypastyes">Yes / होय</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="No" id="pphypastNo" name="pphypast" checked={(this.state.pphypast == "No") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pphypastNo">No / नाही</label>
                                </div>
                            </div>


                        {/* <div className="form-group row">
                            <label className="col-sm-8 col-form-label">Any physical therapist consulted in the past / या आधी कोणत्या फिजिओथेरपीस्टला दाखविले आहे का</label>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="yes" id="pphypastyes" name="pphypast" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pphypastyes">Yes / होय</label>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="No" id="pphypastNo" name="pphypast" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pphypastNo">No / नाही</label>
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-1 col-form-label">Doctor Name</label>
                                <div className="col-sm-11">
                                    <input type="text" className="form-control" placeholder="Doctor Name" name="pdoctnm" value={this.state.pdoctnm} onChange={this.handleChange} />
                                </div>
                            </div>


                        {/* <div className="form-group row">
                            <label className="col-sm-1 col-form-label">Doctor Name</label>
                            <div className="col-sm-11">
                                <input type="text" className="form-control" placeholder="Doctor Name" name="pdoctnm" onChange={this.handleChange} />
                            </div>
                        </div> ------form-group----- */}

                            <div className="row mt-2">
                                <label className="col-sm-1 col-form-label">Date consulted</label>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control" placeholder="Date consulted" name="pdoctconslt" value={this.state.pdoctconslt} onChange={this.handleChange} />
                                </div>
                                <label className="col-sm-2 col-form-label">For what problem</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" placeholder="For what problem" name="pproblem" value={this.state.pproblem} onChange={this.handleChange} />
                                </div>
                            </div>


                        {/* <div className="form-group row">
                            <label className="col-sm-1 col-form-label">Date consulted</label>
                            <div className="col-sm-5">
                                <input type="text" className="form-control" placeholder="Date consulted" name="pdoctconslt" onChange={this.handleChange} />
                            </div>
                            <label className="col-sm-2 col-form-label">For what problem</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" placeholder="For what problem" name="pproblem" onChange={this.handleChange} />
                            </div>
                        </div> ------form-group----- */}
                        <br></br>
                        <hr />
                        <h5>IF YOU ARE IN PAIN, PLEASE MARK THE EXACT LOCATION OF PAIN ON THIS DIAGRAM</h5>
                        <h5>तुम्हाला वेदना होत असल्यास, कृपया या चित्रावर वेदनांचे अचूक स्थान चिन्हांकित करा</h5>

                        {/* <img src={humanBody} alt="human body" width={"100%"} /> */}

                        <ImageMarker
                            src={humanBody}
                            markers={this.state.markers}
                            onAddMarker={(marker) => { let m = this.state.markers; m.push(marker); this.setState({ markers: m }) }}
                            markerComponent={(marker) => this.CustomMarker(marker)}
                        />

                        <span className="btn btn-primary" onClick={() => { let m = this.state.markers; m = m.slice(0, -1); this.setState({ markers: m }) }}>Remove Mark</span><br />
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Marks Decription</label>
                                <textarea className="form-control" rows="5" name="markerdescription" value={this.state.markerdescription} onChange={this.handleChange}></textarea>
                            </div>
                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Marks Decription</label>
                            <textarea className="form-control" rows="5" name="markerdescription" onChange={this.handleChange}></textarea>
                        </div> */}

                        {/* onAddMarker={(marker) => this.setState({ markers : [...prev, marker] })   */}
                        {/* // setMarkers((prev) => [...prev, marker])} */}


                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Have you been vaccinated for covid? / तुम्हाला कोविडची लसीकरण करण्यात आले आहे का?</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="yes" id="pcovidvaccineyes" name="pcovidvaccine" checked={(this.state.pcovidvaccine == "yes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pcovidvaccineyes">Yes / होय</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="no" id="pcovidvaccineno" name="pcovidvaccine" checked={(this.state.pcovidvaccine == "no") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pcovidvaccineno">No / नाही</label>
                                </div>
                            </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Have you been vaccinated for covid? / तुम्हाला कोविडची लसीकरण करण्यात आले आहे का?</label>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="yes" id="pcovidvaccineyes" name="pcovidvaccine" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pcovidvaccineyes">Yes / होय</label>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="no" id="pcovidvaccineno" name="pcovidvaccine" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pcovidvaccineno">No / नाही</label>
                            </div>
                        </div> */}

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Do you have any known allergies to any medicine, ointment, food etc? / तुम्हाला कोणत्याही औषध, मलम, खाद्यपदार्थ इत्यादींबद्दल काही ज्ञात ऍलर्जी आहे का?</label>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="yes" id="pallergiestomedicineyes" name="pallergiestomedicine" checked={(this.state.pallergiestomedicine == "yes") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pallergiestomedicineyes">Yes / होय</label>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="no" id="pallergiestomedicineno" name="pallergiestomedicine" checked={(this.state.pallergiestomedicine == "no") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pallergiestomedicineno">No / नाही</label>
                                </div>
                            </div>
                            
                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Do you have any known allergies to any medicine, ointment, food etc? / तुम्हाला कोणत्याही औषध, मलम, खाद्यपदार्थ इत्यादींबद्दल काही ज्ञात ऍलर्जी आहे का?</label>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="yes" id="pallergiestomedicineyes" name="pallergiestomedicine" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pallergiestomedicineyes">Yes / होय</label>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center">
                                <input type="radio" className="form-check-input m-0" value="no" id="pallergiestomedicineno" name="pallergiestomedicine" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="pallergiestomedicineno">No / नाही</label>
                            </div>
                        </div> */}

                            <div className="row mt-2">
                                <label className="col-sm-12 col-form-label">Do you have any of the following? / तुमच्याकडे खालीलपैकी काही आहे का?</label>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="bloodpressure" id="bloodpressureid" name="bloodpressure" checked={(this.state.bloodpressure === true) ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="bloodpressureid"> Blood Pressure / रक्तदाब</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="Diabetes" id="Diabetesid" name="Diabetes" checked={(this.state.Diabetes === true) ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="Diabetesid"> Diabetes / मधुमेह</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="Arthritis" id="Arthritisid" name="Arthritis" checked={(this.state.Arthritis === true) ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="Arthritisid"> Arthritis / संधिवात</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="autoimmuned" id="autoimmunedid" name="autoimmuned" checked={(this.state.autoimmuned === true) ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="autoimmunedid"> Auto Immune Disease / स्वयंप्रतिरोधक रोग</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="Cancer" id="Cancerid" name="Cancer" checked={(this.state.Cancer === true) ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="Cancerid"> Cancer / कर्करोग</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input m-0" value="other" id="otherid" name="other" checked={(this.state.other === true) ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="otherid"> Any other: Please specify / इतर कोणतेही: कृपया निर्दिष्ट करा</label>
                                </div>
                                <div className="col-sm-12 d-flex align-items-center">
                                    {
                                        (this.state.otherfollwdiseas) ? (<>
                                            <input type="text" className="form-control" placeholder="Other Reason" name="otherfollwdiseas" value={this.state.otherfollwdiseas} onChange={this.handleChange} />
                                        </>) : (<></>)
                                    }
                                </div>
                            </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Do you have any of the following? / तुमच्याकडे खालीलपैकी काही आहे का?</label>
                            <div className="col-sm-12 d-flex align-items-center">
                                <input type="checkbox" className="form-check-input m-0" value="bloodpressure" id="bloodpressureid" name="bloodpressure" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="bloodpressureid"> Blood Pressure / रक्तदाब</label>
                            </div>
                            <div className="col-sm-12 d-flex align-items-center">
                                <input type="checkbox" className="form-check-input m-0" value="Diabetes" id="Diabetesid" name="Diabetes" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="Diabetesid"> Diabetes / मधुमेह</label>
                            </div>
                            <div className="col-sm-12 d-flex align-items-center">
                                <input type="checkbox" className="form-check-input m-0" value="Arthritis" id="Arthritisid" name="Arthritis" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="Arthritisid"> Arthritis / संधिवात</label>
                            </div>
                            <div className="col-sm-12 d-flex align-items-center">
                                <input type="checkbox" className="form-check-input m-0" value="autoimmuned" id="autoimmunedid" name="autoimmuned" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="autoimmunedid"> Auto Immune Disease / स्वयंप्रतिरोधक रोग</label>
                            </div>
                            <div className="col-sm-12 d-flex align-items-center">
                                <input type="checkbox" className="form-check-input m-0" value="Cancer" id="Cancerid" name="Cancer" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="Cancerid"> Cancer / कर्करोग</label>
                            </div>
                            <div className="col-sm-12 d-flex align-items-center">
                                <input type="checkbox" className="form-check-input m-0" value="other" id="otherid" name="other" onChange={this.handleChange} />
                                <label className="col-form-label" htmlFor="otherid"> Any other: Please specify / इतर कोणतेही: कृपया निर्दिष्ट करा </label>
                            </div>
                            <div className="col-sm-12 d-flex align-items-center">
                                {
                                    (this.state.otherfollwdiseas) ? (<>
                                        <input type="text" className="form-control" placeholder="Other Reason" name="otherfollwdiseas" onChange={this.handleChange} />
                                    </>) : (<></>)
                                }
                            </div>

                        </div> */}

                        {/* <h6>FOR DOCTOR USE ONLY</h6>
                    <div className="form-group row">
                        <label className="col-sm-12 col-form-label">History</label>
                        <div className="col-sm-12">
                            <textarea className="form-control" rows="5" name="phistory" onChange={this.handleChange}></textarea>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Observation</label>
                        <div className="col-sm-12">
                            <textarea className="form-control" rows="5" name="pobservation" onChange={this.handleChange}></textarea>
                        </div>
                    </div> */}

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">who is responsible for your bill? / बिल भरण करणार</label>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="self" id="pbillself" name="pbill" checked={(this.state.pbill == "self") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pbillself">Self / स्वत</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Soupse" id="pbillspous" name="pbill" checked={(this.state.pbill == "Soupse") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pbillspous">Soupse /  जोडीदार</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="employer" id="pbillemployer" name="pbill" checked={(this.state.pbill == "employer") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="pbillemployer">Employer / नियोक्ता</label>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <label className="col-sm-3 col-form-label">How will payment be made? / बिल भरणा कसे कराल</label>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Cash" id="ppaymentcash" name="ppycash" checked={(this.state.ppycash == "Cash") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="ppaymentcash">Cash / रोख</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Cheque" id="ppaymentchq" name="ppycash" checked={(this.state.ppycash == "Cheque") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="ppaymentchq">Cheque / चेक</label>
                                </div>
                                <div className="col-sm-3 d-flex align-items-center">
                                    <input type="radio" className="form-check-input m-0" value="Digital" id="ppaymentdigi" name="ppycash" checked={(this.state.ppycash == "Digital") ? true : false} onChange={this.handleChange} />
                                    <label className="col-form-label" htmlFor="ppaymentdigi">Digital / डिजीटल</label>
                                </div>
                            </div>


                    <p className="mt-2"><b>Fees are payable at the time of examinations and treatments taken, unless other arrangements are made in advance.</b></p>

                    <hr />
                    <p className="text-center"><b>Disclaimer</b></p>
                    <p>Our treatments and consultations are based on the information provided by you.
                        Any misinformation or withholding any information from the practitioner may result into incorrect diagnosis 
                        and line of treatment which may affect the Patient’s health, 
                        Core Concept shall not be held liable in such cases. 
                        The Patient shall indemnify Core Concept in case of any damages,
                        losses and health issues arising out of misinformation or withholding of such information.</p>

                    {/* <p className="text-center"><b>Consent Form</b></p> */}
                    <p>
                        {/* <table>
                            <tr>
                                <td className="d-flex" style={{width:"30px"}}>1.</td>
                                <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                            </tr>
                            <tr>
                                <td className="d-flex" style={{width:"30px"}}>2.</td>
                                <td>I confirm that the Core Concept team explained the procedure and treatment to me thoroughly to me and how it will help me with my current condition.</td>
                            </tr>
                            <tr>
                                <td className="d-flex" style={{width:"30px"}}>3.</td>
                                <td>I understand the risk and complications if I do not follow the aftercare instructions or follow ups as advised.</td>
                            </tr>
                            <tr>
                                <td className="d-flex" style={{width:"30px"}}>4.</td>
                                <td>I confirm that the information provided by me is true to my knowledge and I am fully responsible for any information that I hide or withhold for the purpose of this treatment and I indemnify Core Concept and its team against any losses and damages arising out of withholding or hiding such information.</td>
                            </tr>
                            <tr>
                                <td className="d-flex" style={{width:"30px"}}>5.</td>
                                <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                            </tr>
                        </table> */}
                        {/* 1. I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.<br />
                        2. I confirm that the Core Concept team explained the procedure and treatment to me thoroughly to me and how it will help me with my current condition.<br />
                        3. I understand the risk and complications if I do not follow the aftercare instructions or follow ups as advised.<br />
                        4. I confirm that the information provided by me is true to my knowledge and I am fully responsible for any information that I hide or withhold for the purpose of this treatment and I indemnify Core Concept and its team against any losses and damages arising out of withholding or hiding such information.<br />
                        5. I voluntarily give my consent to the doctors, therapists and consultants of Core Concept for performing the treatment and procedure on me. */}
                    </p>
                    
                    <hr />
                        <div>
                            <button type="submit" className="btn btn-primary mt-2 hideonprint">Submit</button>
                        </div>
                    </form>
                </div>
                </div>
            </>
        )
    }
}