import React from "react";
import Modal from 'react-modal';
import axios from "axios";
import DataTable from 'react-data-table-component'
import swal from 'sweetalert';

export default class Doctor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded : false,
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '30%'
                },
              },
            customStyles1 : {
                content: {
                  top: '50%',
                  left: '60%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '60%'
                },
              },
            deptlist : [],
            doctorlist : [],
            columns : [
                {
                  name:"Doc ID",
                  sortable: true,
                  selector:(row)=>row.doctor_id,
                  width: "90px",
                  margin: "0px"
                },
                {
                  name:"Doctor Name",
                  selector:(row)=>row.doctor_name
                },
                {
                  name:"E-Mail",
                  selector:(row)=>row.doc_email,
                  wrap:true
                },
                {
                    name:"Mobile Number",
                    selector:(row)=>row.mobile_number
                },
                {
                    name:"Department",
                    selector:(row)=>row.dept_name
                },
                {
                    name:"Registration No.",
                    selector:(row)=>row.registration_no
                },
                {
                    name:"Assigned Color",
                    cell:(row)=><div style={{width:"50px",height:"10px",background:row.doc_color}}></div>
                },
                {
                    name:"Status",
                    cell:(row)=>{return(<>
                                            <div><span className="btn btn-sm btn-primary me-2 p-1" style={{height:"34px"}} onClick={()=>this.editdoc(row)}><box-icon type='solid' style={{fill:"#fff"}} name='edit'></box-icon></span></div>
                                            <select className="form-control" onChange={(e) => this.changedrstatus(e,row.doctor_id)} value={row.status}>
                                                <option value="Y">Active</option>
                                                <option value="N">De-Active</option>
                                            </select>
                                        </>)},
                    width:"180px"
                }
            ],
            search : [],
            results : [],
            editmodel : false,
            editdata : [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeModal = this.handleChangeModal.bind(this);
        this.handleSubmitModal = this.handleSubmitModal.bind(this);
        this.handleEditdoc = this.handleEditdoc.bind(this);


        this.fetchdoctor();
        this.setSearch();
    }

    editdoc = (row) =>{
        console.log(row);
        this.fetchadddocdata();
        this.setState({editdata:row})
        this.setState({editdoctortype : row['doctortype']})
        this.setState({editdepartment : row['dept_id']})
        this.setState({editconsultaionfeesfirst : row['consultaionfeesfirst']})
        this.setState({editconsultaionfeesother : row['consultaionfeesother']})
        this.setState({doc_email : row['doc_email']})
        this.setState({editmodel:true})
    }

    handleEditdoc = (e) =>{
        e.preventDefault();
        const dataedit = {
            editdoctor : 'yes',
            doc_email : this.state.doc_email,
            editdepartment : this.state.editdepartment,
            editdoctortype : this.state.editdoctortype,
            editconsultaionfeesfirst : this.state.editconsultaionfeesfirst,
            editconsultaionfeesother : this.state.editconsultaionfeesother,
        }
        axios.post('https://clinic.dnyanasha.com/ws/doctor.php',dataedit).then((res)=>{
            console.log(res.data)
            if(res.data.updatedoctor === true)
            {
                swal("Doctor Updated", "Doctor Successfully updated..!", "success").then((swalres)=>{
                    this.setState({editmodel:false})
                    this.fetchdoctor();
                });
            } 
        })
    }

    changedrstatus = (event,docid) =>{
        console.log(event.target.value)
        console.log(docid)
        const datads = {
            changestatusdr : "yes",
            docid : docid,
            status : event.target.value,
        }
        axios.post('https://clinic.dnyanasha.com/ws/doctor.php',datads).then((res)=>{
            if(res.data.updatedrstatus){
                swal("Status Changed", "Doctor Status Changed Successfully..!", "success").then((swalres)=>{
                    this.fetchdoctor();
                });
            }
        })
    }

    fetchdoctor = () => {
        const datadoc = {
            fetchdoctor : 'yes',
        }
        axios.post('https://clinic.dnyanasha.com/ws/doctor.php',datadoc).then((res)=>{
            console.log(res.data)
            this.setState({ doctorlist : res.data.finddoctor})
            this.setState({results: res.data.finddoctor})
        })
    }

    handleChangeModal = (event) => {
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmitModal = (event) => {
        event.preventDefault();
        const datamodal = {
            add_dept : "yes",
            dept_name : this.state.dept_name,
        }
        console.log(datamodal);
        axios.post('https://clinic.dnyanasha.com/ws/department.php',datamodal).then((res)=>{
            console.log(res.data);
            if(res.data.savedept > 0)
            {
                swal("Department Added", "Department Successfully added..!", "success").then((swalres)=>{
                    console.log(swalres);
                    this.closeModal();
                });
            }
        })
    }

    handleChange = (event) => {
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if(this.state.mobile_number.length === 10){
            const data = {
                add_doctor : 'yes',
                doctor_name : this.state.doctor_name,
                doctor_email : this.state.doctor_email,
                mobile_number : this.state.mobile_number,
                department : this.state.department,
                registation_number : this.state.registation_number,
                assign_color : this.state.assign_color,
                doctortype : this.state.doctortype,
                consultaionfeesfirst : this.state.consultaionfeesfirst,
                consultaionfeesother : this.state.consultaionfeesother,
            }
            console.log(data);
            axios.post('https://clinic.dnyanasha.com/ws/doctor.php',data).then((res)=>{
                if(res.data.savedoctor > 0)
                {
                    swal("Doctor Added", "Doctor Successfully added..!", "success").then((swalres)=>{
                        console.log(swalres);
                        this.setState({expanded : !this.state.expanded})
                    });
                } else {
                    swal(res.data.savedoctor, res.data.savedoctor, "error").then((swalres)=>{})
                }
            })
        } else {
            swal("Error", "Invalid Mobile Number....!", "error").then((swalres)=>{});
        }
    }

    fetchadddocdata = () =>{
        const datamodal = {
            fetch_add_doc_data : "yes",
            dept_name : this.state.dept_name,
        }
        // console.log(datamodal);
        axios.post('https://clinic.dnyanasha.com/ws/department.php',datamodal).then((res)=>{
            console.log(res.data);
            this.setState({ deptlist : res.data.finddept })
        })
    }

    openModal = () => {
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
        this.setState({editmodel:false})
    }

    setSearch = (svalue) => {
        if(isNaN(+svalue)){
            if(svalue == "" || svalue == undefined || svalue == null ){
                // console.log(svalue)
                this.setState({search:svalue})
                this.setState({results:this.state.doctorlist})
            }
            else {
                // console.log(svalue)
                var result = this.state.doctorlist.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    // return resfilter.doctor_name.toLowerCase().match(this.state.search.toLowerCase());
                    return resfilter.doctor_name.toLowerCase().match(this.state.search);
                });
                this.setState({results:result})
            }
        } else {
            if(svalue == "" || svalue == undefined || svalue == null ){
                // console.log(svalue)
                this.setState({search:svalue})
                this.setState({results:this.state.doctorlist})
            }
            else {
                // console.log(svalue)
                var result = this.state.doctorlist.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    // return resfilter.doctor_name.toLowerCase().match(this.state.search.toLowerCase());
                    return resfilter.mobile_number.toLowerCase().match(this.state.search);
                });
                this.setState({results:result})
            }

        }
    }
    
    render(){
        return(
            <>
                {/* <div> */}
                    <div className="card mb-3" style={{ display: this.state.expanded ? 'block' : 'none', transition: "2s" ,transitionTimingFunction: "ease" }}>
                        <form onSubmit={this.handleSubmit}>
                        {/* <div style={{ height: this.state.expanded ? 'auto' : '0px', transition: "2s" ,transitionTimingFunction: "ease" }}> */}
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5>Add Doctor</h5>
                                <div>
                                    <a className="btn btn-primary" onClick={this.openModal}>Add Department</a>
                                    <a className="btn btn-danger" style={{marginLeft:"10px"}} onClick={() => this.setState({expanded : !this.state.expanded}) }>Hide</a>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Doctor Name</label>
                                            <input type="text" className="form-control" name="doctor_name" onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Doctor E-Mail ID</label>
                                            <input type="email" className="form-control" name="doctor_email" onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Mobile Number</label>
                                            <input type="number" className="form-control" name="mobile_number" onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Select Department</label>
                                            <select className="form-control" name="department" onChange={this.handleChange} required>
                                                <option value="">Select Department</option>
                                                {
                                                    this.state.deptlist.map( (response,index) =>{
                                                        return(
                                                            <option key={index} value={response.dept_id}>{response.dept_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Registration Number</label>
                                            <input type="text" className="form-control" name="registation_number" onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Doctor Type</label>
                                            <select className="form-control" name="doctortype" value={this.state.doctortype} onChange={this.handleChange}>
                                                <option value={""}>Select Type</option>
                                                <option value={"inhouse"}>In-House Doctor</option>
                                                <option value={"guestdoc"}>Guest Doctor</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label className="control-label">Consultation Fees 1st </label>
                                            <input type="number" min={1} className="form-control" name="consultaionfeesfirst" onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label className="control-label">Consultation Fees After 1st</label>
                                            <input type="number" min={1} className="form-control" name="consultaionfeesother" onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Assign Color</label>
                                            <input type="color" className="form-control" name="assign_color" style={{width:"50px",height:"38px"}} onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary text-white addrolebtn" type="submit" >Submit</button>
                            </div>
                        </form>
                    </div>

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="Department Modal"
                        ariaHideApp={false}
                    >
                        <h5>Add Department</h5>
                        <form onSubmit={this.handleSubmitModal}>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Department Name</label>
                                            <input type="text" className="form-control" name="dept_name" onChange={this.handleChangeModal} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <button className="btn btn-primary text-white addrolebtn" type="submit" >Submit</button>
                                    <a onClick={this.closeModal} className="btn btn-danger" style={{marginLeft:"10px"}}>close</a>
                                </div>
                            </div>
                        </form>
                    </Modal>

                    <Modal
                        isOpen={this.state.editmodel}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles1}
                        contentLabel="Edit Doctor"
                        ariaHideApp={false}
                    >
                        <h5>Edit Doctor</h5>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="from-group">
                                    <label className="form-label"> <b>Doctor Name * </b></label>
                                    <input type={"text"} className="form-control" value={this.state.editdata['doctor_name']} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="from-group">
                                    <label className="form-label"> <b>Doctor Email * </b></label>
                                    <input type={"text"} className="form-control" value={this.state.editdata['doc_email']} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="from-group">
                                    <label className="form-label"> <b>Doctor Mobile Number * </b></label>
                                    <input type={"text"} className="form-control" value={this.state.editdata['mobile_number']} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="from-group">
                                    <label className="form-label"> <b>Registration Number * </b></label>
                                    <input type={"text"} className="form-control" value={this.state.editdata['registration_no']} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label">Select Department</label>
                                    <select className="form-control" name="editdepartment" value={this.state.editdepartment} onChange={this.handleChange} required>
                                        <option value="">Select Department</option>
                                        {
                                            this.state.deptlist.map( (response,index) =>{
                                                return(
                                                    <option key={index} value={response.dept_id}>{response.dept_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label">Doctor Type</label>
                                    <select className="form-control" name="editdoctortype" value={this.state.editdata['doctortype']} onChange={this.handleChange}>
                                        <option value={""}>Select Type</option>
                                        <option value={"inhouse"}>In-House Doctor</option>
                                        <option value={"guestdoc"}>Guest Doctor</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label">Consultation Fees 1st </label>
                                    <input type="number" min={1} className="form-control" name="editconsultaionfeesfirst" value={this.state.editconsultaionfeesfirst} onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label">Consultation Fees After 1st</label>
                                    <input type="number" min={1} className="form-control" name="editconsultaionfeesother" value={this.state.editconsultaionfeesother} onChange={this.handleChange} required />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <button className="btn btn-sm btn-primary mt-2" onClick={this.handleEditdoc}>Submit</button>
                            </div>
                        </div>
                    </Modal>

                    <div className="card p-3">
                        <div className="row">
                            <div className="col-sm-12 cardheadper">
                                <h6>All Doctor</h6>
                                <button className="btn btn-primary text-white addrolebtn" onClick={() => {this.setState({expanded : !this.state.expanded}); this.fetchadddocdata() } }>Add Doctor</button>
                            </div>
                        </div>
                    </div>

                    <div className="card p-3 mt-4">
                        <div className="row">
                            <div className="col-sm-12">
                            <DataTable
                                columns={this.state.columns} 
                                data={this.state.results} 
                                style={{textAlign:"center"}}
                                // title="All Doctors"
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight='450px'
                                highlightOnHover
                                subHeader
                                subHeaderComponent={
                                    <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                        <div style={{fontSize:"22px"}}>All Doctors</div>
                                        <input 
                                            type="text"
                                            placeholder='search by name or number'
                                            className='w-25 form-control'
                                            value={this.state.search}
                                            onChange={(e)=>this.setSearch(e.target.value)}
                                        />
                                    </div>
                                }
                            />
                            </div>
                        </div>
                    </div>

                {/* </div> */}
            </>
        )
    }
}