import axios from "axios";
import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import DataTable from 'react-data-table-component'
import Printheader from '../assets/printheader.png';
import Printfooter from '../assets/printfooter.png';
import SignatureCanvas from 'react-signature-canvas';
import Modal from 'react-modal';
import swal from "sweetalert";

export default class Treatmentplans extends Component{

    constructor(props){
        super(props);
        this.state = {
            findalltreatments:[],
            Physiotherapy : false,
            Dietitian : false,
            findalldiettreat : [],
            dname:"",
            findpatientappoint : [],
            findallapp : [],
            // consentsigned : null,
            consentsigned : true,
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
            imgcaptured : null,
        }

        // this.fetchconsent();
        this.finddepttype();
        this.fetchdatatp(); 
        this.fetchdatadp();

        this.handleconsentsubmit = this.handleconsentsubmit.bind(this);
    }

    fetchconsent = () => {
        const consentdata = {
            checkconsent : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',consentdata).then((res)=>{
            console.log(res.data);
            this.setState({consentsigned:res.data.consentsigned})
        })
    }

    finddepttype = () =>{
        const datatp = {
            finddepttype : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',datatp).then((res)=>{
            console.log(res.data);
            this.setState({Physiotherapy:res.data.Physiotherapy})
            this.setState({Dietitian:res.data.Dietitian})
        })
    }

    fetchdatatp = () =>{
        const datatp = {
            fetchtpdata : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',datatp).then((res)=>{
            console.log(res.data);
            this.setState({findalltreatments:res.data.findalltreatments})
            this.setState({findallapp:res.data.findallapp})
            this.setState({findpid:res.data.findpid})
        })
    }

    fetchdatadp = () =>{
        const datatp = {
            fetchdpdata : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',datatp).then((res)=>{
            console.log(res.data);
            this.setState({findalldiettreat:res.data.findalldiettreat})
            this.setState({findpatientappoint:res.data.findpatientappoint})
        })
    }

    viewthreapy = (treatment_id,appointment_id) =>{
        this.props.history.push('/patient_dashboard/'+this.props.match.params.puid+'/treatmentplans/view/'+treatment_id+"/"+appointment_id);
    }

    viewdiet = (diettreatment) =>{
        this.props.history.push('/patient_dashboard/'+this.props.match.params.puid+'/viewdietplans/view/'+diettreatment);
    } 

    // finddoctor = (did) =>{
    //     if(did == "0"){
    //         if(this.state.dname == ""){this.setState({dname:"No Name"})}
    //         return "";
    //         // this.setState({dname:""})
    //     } else {
    //         // const dname = "";
    //         var dname = "";
    //         axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',{finddname:"yes",did:did}).then((res)=>{
    //             // console.log(res.data.finddname['doctor_name']);
    //             dname = res.data.finddname['doctor_name'];
    //             if(this.state.dname == ""){this.setState({dname:dname})}
    //             return dname;
    //         })
    //         // console.log(dname);
    //         // axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',{finddname:"yes",did:did}).then((res)=>{
    //         //     console.log(res.data)
    //         //     console.log(res.data.finddname.doctor_name)
    //         //     dname = res.data.finddname.doctor_name;
    //         // })
    //     }
    // } 

    printform = (printid) =>{
        let printContents = document.getElementById(printid).innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 100px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 70%;
                                            height: 100px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            <div style='display: flex;justify-content: space-between;padding: 0px 13px;'>
                                                <p><b>Patient Name : </b>${this.state.findpid['patient_name']}</p>
                                                <p><b>Mobile Number : </b>${this.state.findpid['mobile_number']}</p>
                                            </div>
                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
        setTimeout(() => {
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload();
        }, 10)
    }

    opensignmodel = () =>{
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
    }

    clear = () => {
        this.sigPad.clear()
    }

    trim = () => {
        this.setState({imgcaptured: this.sigPad.getTrimmedCanvas().toDataURL('image/png')})
        this.closeModal();
    }

    handleconsentsubmit = (e) =>{
        e.preventDefault();
        const dataconsent = {
            submitconsent : "yes",
            puid : this.props.match.params.puid,
            imgcaptured : this.state.imgcaptured,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',dataconsent).then((res)=>{
            if(res.data.saveconsent > 0){
                swal({title:"Consent Saved", text:"Consent Saved Successfully..!", icon:"success",timer:1000})
                this.fetchconsent();
            }
        })
    }

    render(){
        if(this.state.consentsigned === false){
            return(<>
                    <Headerpd puid={this.props.match.params.puid} />
                    <Sidebarpd puid={this.props.match.params.puid} />
                    <div style={{marginLeft:"220px",padding:"5px"}}>
                        {/* <div className="d-flex flex-column align-items-center"> */}
                            <h5 className="text-center">You Have'nt Sign Consent Form. Please Sign before Traetment.</h5>
                            {/* <button className="btn btn-primary btn-sm">Sign Consent</button> */}
                            <hr />
                            <p className="text-center"><b>Consent Form</b></p>
                            <p>
                                <table>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>1.</td>
                                        <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>2.</td>
                                        <td>I confirm that the Core Concept team explained the procedure and treatment to me thoroughly to me and how it will help me with my current condition.</td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>3.</td>
                                        <td>I understand the risk and complications if I do not follow the aftercare instructions or follow ups as advised.</td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>4.</td>
                                        <td>I confirm that the information provided by me is true to my knowledge and I am fully responsible for any information that I hide or withhold for the purpose of this treatment and I indemnify Core Concept and its team against any losses and damages arising out of withholding or hiding such information.</td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex" style={{width:"30px"}}>5.</td>
                                        <td>I authorize Core Concept, its doctors, therapists, consultants to perform the treatment or necessary procedure on/to me.</td>
                                    </tr>
                                </table>
                            </p>
                            
                            <hr />
                            
                            <div className="d-flex justify-content-between px-4">
                                <div className="text-center">
                                    <p className="m-0 "><b>{new Date().getDate() + " " + this.state.monthname[new Date().getMonth()] + " " + new Date().getFullYear()}</b></p>
                                    <p className="m-0 ">Date</p>
                                </div>
                                <div className="text-center">
                                    <p className="m-0 ">
                                        {
                                            (this.state.imgcaptured === null ) ? (
                                                <div style={{border:"1px solid #000",height:"60px",width:"120px"}} onClick={this.opensignmodel}></div>
                                            ) : (
                                                <div style={{border:"1px solid #000",height:"60px",width:"120px"}} onClick={this.opensignmodel}><img style={{height:"60px",width:"120px"}} src={this.state.imgcaptured} /></div>
                                            )
                                        }
                                    </p>
                                    <p className="m-0 ">Digital Signature</p>
                                </div>
                            </div>

                            <div>
                                <button className="btn btn-primary mt-2 hideonprint" onClick={this.handleconsentsubmit}>Submit</button>
                            </div>

                            <Modal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                style={this.state.customStyles}
                                contentLabel="Signataure Model"
                                ariaHideApp={false}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SignatureCanvas penColor='black' canvasProps={{width: 200, height: 100, className: 'sigCanvas'}} ref={(ref) => { this.sigPad = ref }} />
                                    </div>
                                    <div className="col-sm-12">
                                        <button className="btn btn-danger btn-sm" onClick={this.clear}> Clear </button>
                                        <button className="btn btn-primary btn-sm mx-4" onClick={this.trim}> Capture </button>
                                    </div>
                                </div>
                            </Modal>

                        {/* </div> */}
                    </div>
            </>)
        }
        if(this.state.consentsigned === true){
            return(
                <>
                    <Headerpd puid={this.props.match.params.puid} />
                    <Sidebarpd puid={this.props.match.params.puid} />
                    <div style={{marginLeft:"220px",padding:"5px",background:"#e7e7e7"}}>
                        
                        {(this.state.Physiotherapy) ? (<>
                        <div className="row">
                            <div className="col-sm-10">
                                <h5>Protocol (Treatments)</h5>
                            </div>
                            <div className="col-sm-2">
                                {/* <button className="btn btn-primary" onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/addtreatment') }} >+ Add</button> */}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                {
                                    this.state.findallapp.map((item,index)=>{ var printid = "printablediv"+index;
                                        return(
                                            <div key={index} id={printid}>
                                                <div className="row mb-1 ms-0">
                                                    <div className="col-sm-4"><b>Appointment Date : </b>{item.schedule_date}</div>
                                                    <div className="col-sm-6"><b>Time : </b>{item.start_time} - {item.end_time}</div>
                                                    <div className="col-sm-2">
                                                        <button className="btn btn-primary btn-sm" onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/addtreatment/'+item.appointment_id) }} >+ Add</button>
                                                        <button className="btn btn-primary btn-sm mx-2" onClick={()=>this.printform(printid)} >Print</button>
                                                    </div>
                                                </div>
                                                <p className="m-0"> </p>
                                                <div className="card">
                                                    <div className="card-header">
                                                        <div className="row" style={{fontSize:"11px"}}>
                                                            <div className="col-sm-3"><b>PROCEDURE</b></div>
                                                            <div className="col-sm-3"><b>DAYS</b></div>
                                                            <div className="col-sm-2"><b>TOTAL</b></div>
                                                            <div className="col-sm-3"><b>NOTES</b></div>
                                                            <div className="col-sm-1"><b>ACTION</b></div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-1">
                                                            {
                                                                (item.treatment.length === 0) ? (<div className="col-sm-12 text-center">No Data</div>) : 
                                                                (
                                                                    item.treatment.map((ress,index)=>{
                                                                        return(<>
                                                                                <div className="row mt-1 mb-1" style={{fontSize:"15px"}}>
                                                                                    <div className="col-sm-3">{ress.procedure_name}</div>
                                                                                    <div className="col-sm-3">{ress.totaldays}</div>
                                                                                    <div className="col-sm-2">{(ress.discount_type == "PERCENT") ? (ress.amount - (ress.tcost * (ress.discount/100))) : (ress.amount - ress.discount)}</div>
                                                                                    <div className="col-sm-3">{ress.tnotes}</div>
                                                                                    <div className="col-sm-1"><button style={{fontSize:"13px"}} onClick={()=>this.viewthreapy(ress.treatment_id,item.appointment_id)} className="btn btn-primary btn-sm">View</button></div>
                                                                                    {/* <div className="col-sm-2">{(ress.discount_type == "PERCENT") ? (<>{(ress.tcost * (ress.discount/100))}</>) : (<>{ress.discount}</>) } { (ress.discount_type == "PERCENT") ? (<>({ress.discount}%)</>) : (<>Rs.</>) }</div>
                                                                                    <div className="col-sm-2">{(ress.discount_type == "PERCENT") ? (ress.amount - (ress.tcost * (ress.discount/100))) : (ress.amount - ress.discount)}</div> */}
                                                                                </div>
                                                                                <hr className="m-0" />
                                                                            </>)
                                                                    })
                                                                )
                                                            }
                                                            {/* {this.fetchapptreat(item.appointment_id)} */}
                                                    </div>
                                                    <div className="card-footer">
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    })
                                }
                                {/* {
                                    this.state.findalltreatments.map((item,index)=>{
                                        var estimatetotal = (item.discount_type == "PERCENT") ? (item.amount - (item.tcost * (item.discount/100))) : (item.amount - item.discount);
                                        return(
                                            <>
                                                <p key={index} className="m-0">{item.created_at}</p>
                                                        <div className="card" key={index+10000}>
                                                            <div className="card-header">
                                                                <div className="row" style={{fontSize:"11px"}}>
                                                                    <div className="col-sm-4"><b>PROCEDURE</b></div>
                                                                    <div className="col-sm-2"><b>COST</b></div>
                                                                    <div className="col-sm-2"><b>DISCOUNT</b></div>
                                                                    <div className="col-sm-2"><b>TOTAL</b></div>
                                                                    <div className="col-sm-2"><b>NOTES</b></div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body p-1">
                                                                <div className="row" style={{fontSize:"15px"}}>
                                                                    <div className="col-sm-4">{item.procedure_name}</div>
                                                                    <div className="col-sm-2">{item.amount}</div>
                                                                    <div className="col-sm-2">{(item.discount_type == "PERCENT") ? (<>{(item.tcost * (item.discount/100))}</>) : (<>{item.discount}</>) } { (item.discount_type == "PERCENT") ? (<>({item.discount}%)</>) : (<>Rs.</>) }</div>
                                                                    <div className="col-sm-2">{(item.discount_type == "PERCENT") ? (item.amount - (item.tcost * (item.discount/100))) : (item.amount - item.discount)}</div>
                                                                    <div className="col-sm-2">{item.tnotes}</div>
                                                                </div>
                                                            </div>
                                                            <div className="card-footer">
                                                                <div className="row" style={{fontSize:"13px"}}>
                                                                    <div className="col-sm-3 d-flex align-items-center">Estimated amount : {estimatetotal}</div>
                                                                    <div className="col-sm-3 d-flex align-items-center">From Date : {item.fromdate}</div>
                                                                    <div className="col-sm-3 d-flex align-items-center">To Date : {item.todate}</div>
                                                                    <div className="col-sm-3 d-flex justify-content-end"><button style={{fontSize:"13px"}} onClick={()=>this.viewthreapy(item.treatment_id)} className="btn btn-primary btn-sm">View</button></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                            </>
                                        )
                                    })
                                } */}
                            </div>
                        </div>
                        </>) : (<></>) }

                        {/* <hr style={{margin:"1.5rem 0",borderTop:"3px solid #616161",opacity:"1"}} /> */}

                        {/* {(this.state.Dietitian) ? (<>
                            <div className="row">
                                <div className="col-sm-10">
                                    <h5>Diet Plans</h5>
                                </div>
                                <div className="col-sm-2">
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="col-sm-12">
                                    {
                                        this.state.findpatientappoint.map((item,index)=>{
                                            return(
                                                <div key={index}>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="row" style={{fontSize:"11px"}}>
                                                                <div className="col-sm-3"><b>Appointment Date</b></div>
                                                                <div className="col-sm-3"><b>Appointment Time</b></div>
                                                                <div className="col-sm-3"><b>Add Dite</b></div>
                                                                <div className="col-sm-3"><b>View Results</b></div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body p-1">
                                                            <div className="row" style={{fontSize:"13px"}}>
                                                                <div className="col-sm-3 m-auto">{item.schedule_date}</div>
                                                                <div className="col-sm-3 m-auto">{item.start_time} - {item.end_time}</div>
                                                                <div className="col-sm-3 m-auto"><button className="btn btn-primary btn-sm" onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/adddietplanew/'+item.appointment_id) }}>Add</button></div>
                                                                <div className="col-sm-3 m-auto"><button className="btn btn-primary btn-sm" onClick={()=>{ this.props.history.push(  '/patient_dashboard/'+this.props.match.params.puid+'/resultdietplan/'+item.appointment_id) }}>View</button></div>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer"></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>) : (<></>) } */}
                    </div>
                </>
            )
        }
    }
}