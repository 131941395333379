import axios from "axios";
import React from "react";
import DataTable from 'react-data-table-component'

export default class PrecriptionMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results : [],
            findpresmain : [],
        }

        if(localStorage.getItem("user") === "Admin"){
            this.state = {
                columns : [
                    {
                        name:"Patient Name",
                        selector:(row)=>row.patient_name
                    },
                    {
                        name:"Department",
                        selector:(row)=>row.dept_name
                    },
                    {
                        name:"Appointed Date",
                        selector:(row)=>row.schedule_date
                    },
                    {
                        name:"Start and End Time",
                        selector:(row)=>row.start_time+" - "+row.end_time
                    },
                    {
                        name:"Mode",
                        selector:(row)=>{ if(row.mode == "inclinic"){ return(<>In-Clinic</>) } if(row.mode == "videocons"){ return(<>Video Consultation</>) } }
                    },
                    {
                        name:"Action",
                        cell:(row)=>{return(<button onClick={()=>{ this.props.history.push(  '/prescription/viewprescription/'+row.appointment_id+'') }} className="btn btn-sm btn-primary">View Prescription</button>)}
                    }
                ],
            }
        } else {
            this.state = {
                columns : [
                    {
                        name:"Department",
                        selector:(row)=>row.dept_name
                    },
                    {
                        name:"Appointed Date",
                        selector:(row)=>row.schedule_date
                    },
                    {
                        name:"Start and End Time",
                        selector:(row)=>row.start_time+" - "+row.end_time
                    },
                    {
                        name:"Mode",
                        selector:(row)=>{ if(row.mode == "inclinic"){ return(<>In-Clinic</>) } if(row.mode == "videocons"){ return(<>Video Consultation</>) } }
                    },
                    {
                        name:"Action",
                        cell:(row)=>{return(<button onClick={()=>{ this.props.history.push(  '/prescription/viewprescription/'+row.appointment_id+'') }} className="btn btn-sm btn-primary">View Prescription</button>)}
                    }
                ],
            }
        }

        this.fetchpreceptionmain()
    }

    fetchpreceptionmain = () =>{
        const datapa = {
            fetchpreceptionmain : 'yes',
            user : localStorage.getItem("user"),
            user_id : localStorage.getItem("user_id"),
        }
        axios.post('https://clinic.dnyanasha.com/ws/prescription.php',datapa).then((res)=>{
            // console.log(res.data)
            if(res.data.findpresmain.length > 0){
                this.setState({findpresmain:res.data.findpresmain})
                this.setState({results:res.data.findpresmain})
            }
        })
    }

    setSearch = (svalue) => {
        if(svalue == "" || svalue == undefined || svalue == null ){
            this.setState({search:svalue})
            this.setState({results:this.state.findpresmain})
        }
        else {
            if(!isNaN(svalue) || svalue.includes("-")){
                var result = this.state.findpresmain.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.schedule_date.toLowerCase().match(this.state.search);
                });
            } else {
                var result = this.state.findpresmain.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.patient_name.toLowerCase().match(this.state.search);
                });
            }
            this.setState({results:result})
        }
    }

    render(){
        return(
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <DataTable
                            columns={this.state.columns} 
                            data={this.state.results} 
                            style={{textAlign:"center"}}
                            // title="All Doctors"
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight='450px'
                            highlightOnHover
                            subHeader
                            onRowClicked={(row)=>{this.props.history.push(  '/prescription/viewprescription/'+row.appointment_id+'')}}
                            subHeaderComponent={
                                <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                    {/* <div style={{fontSize:"22px"}}>Appointments for Prescription</div> */}
                                    <div style={{fontSize:"22px"}}>Date Wise Prescription</div>
                                    <input 
                                        type="text"
                                        placeholder='search here'
                                        className='w-25 form-control'
                                        value={this.state.search}
                                        onChange={(e)=>this.setSearch(e.target.value)}
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>
            </>
        )
    }
}