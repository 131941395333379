import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import Printheader from '../assets/printheaderpre.png';
import Printfooter from '../assets/printfooter.png';

export default class Viewaddprescription extends Component{

    constructor(props){
        super(props);
        this.state = {
            showadddrug : false,
            findalldrug : [],
            prescdrug : [],
            addinstruction : false,
            morning : "0",
            noon : "0",
            night : "0",
            findallppresc : [],
            searchQuery: '',
            filteredListd: [],
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
        }
        // console.log(this.props)

        this.fetchalldrug();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitpresc = this.handleSubmitpresc.bind(this);
        this.handleChangepresc = this.handleChangepresc.bind(this);
    }

    fetchalldrug = () =>{
        const data = {
            fetchalldrug : 'yes',
            appid : this.props.match.params.appid,
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/prescription.php',data).then((res)=>{
            console.log(res.data)
            this.setState({findalldrug:res.data.findalldrug})
            this.setState({filteredListd:res.data.findalldrug})
            this.setState({findallppresc:res.data.findallppresc})
            this.setState({findpid:res.data.findpid})
        })
    }

    handleChange = (event) => {
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const drugdata = {
            adddrug : 'yes',
            drugname : this.state.drugname,
            drugtype : this.state.drugtype,
            strengthnumber : this.state.strengthnumber,
            strengthtype : this.state.strengthtype,
            instructions : this.state.instructions,
        }
        console.log(drugdata);
        axios.post('https://clinic.dnyanasha.com/ws/prescription.php',drugdata).then((res)=>{
            console.log(res.data)
            if(res.data.savedrug > 0)
            {
                swal("Drug Added", "Drug Successfully added..!", "success").then((swalres)=>{
                    console.log(swalres);
                    this.setState({showadddrug:false})
                    this.fetchalldrug();
                });
            }
        })
    }

    adddrugs = () =>{
        this.setState({showadddrug:true})
    }
    
    hidedrugs = () =>{
        this.setState({showadddrug:false})
    }

    addprecspdrug = (item) =>{
        console.log(item)
        let qq = this.state.prescdrug;
        qq.push(item);
        this.setState({prescdrug:qq})
        // this.setState({prescdrug:[item]})
        console.log(this.state.prescdrug)
    }

    handleSubmitpresc = (event,index) =>{
        event.preventDefault();
        // console.log(event);
        // console.log(index);
        const datapre = {
            saveprescription : 'yes',
            strength : this.state.strength,
            strengthtype : this.state.strengthtype,
            instruction : this.state.instruction,
            duration : this.state.duration,
            durationtype : this.state.durationtype,
            morning : this.state.morning,
            noon : this.state.noon,
            night : this.state.night,
            appid : this.props.match.params.appid,
            puid : this.props.match.params.puid,
            drugid : this.state.prescdrug[0].drug_id,
            beforeafterfood : this.state.beforeafterfood,
        }
        console.log(datapre);
        axios.post('https://clinic.dnyanasha.com/ws/prescription.php',datapre).then((res)=>{
            // console.log(res.data)
            if(res.data.saveprescription > 0)
            {
                this.fetchalldrug();
                this.removepresc(index);
                // swal("Save Precscription", "Precscription Successfully Added..!", "success").then((swalres)=>{
                //     this.setState({prescdrug:[]})
                // })
            }
        })
    }

    handleChangepresc = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    goBack = () => {
        this.props.history.goBack();
    }

    // <div>
      //  <div className="" style="width:100%;font-size:12px;">Name : <b>${this.state.findpid['patient_name']} &nbsp;&nbsp; ${ (this.state.findpid['gender'] == 'm') ? ("Male") : ("Female")  } (${ new Date().getFullYear() - new Date(this.state.findpid['dob']).getFullYear() }) Yrs</b></div>
        //<div class="d-flex"><div className="" style="width:100%;font-size:12px;">Mob. No. : <b>${this.state.findpid['mobile_number']}</b></div>
        //<div className="" style="width:100%;font-size:12px;">Patient ID : <b>${this.state.findpid['unique_pid']}</b></div></div>
        //<div className="" style="width:100%;font-size:12px;">Email : <b>${this.state.findpid['email']}</b></div>
    //</div> */}

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        var padetils = `
                            <hr class="m-0" />
                                <div class="d-flex justify-content-around w-100">
                                    <p class="m-0"><b>${this.state.findpid['patient_name']}</b></p>
                                    <p class="m-0">${ new Date().getFullYear() - new Date(this.state.findpid['dob']).getFullYear() } Yrs / ${ (this.state.findpid['gender'] == 'm') ? ("Male") : ("Female")  }</p>
                                    <p class="m-0">${ new Date().getDate() + " " + this.state.monthname[new Date().getMonth()] + " " + new Date().getFullYear()}</p>
                                </div>
                            <hr class="m-0" />
                        `;
        document.body.innerHTML = `
                                    <style>
                                        div {
                                            font-size:15px !important;
                                        }
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 120px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 100%;
                                            height: 120px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        .content {
                                            width:100%;
                                        }
                                        </style>
                                        <table style="width:100%;">
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            ${padetils}

                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        `;
                                        setTimeout(() => {
                                            window.print();
                                            document.body.innerHTML = originalContents;
                                            window.location.reload();
                                        }, 500)
                                        // <div class="watermark"><p>Coreconcept</p></div>
    }

    handleSearchQueryChange = (event) => {
        const searchQuery = event.target.value;
        const filteredListd = this.state.findalldrug.filter(item =>
          item.drug_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        this.setState({
          searchQuery,
          filteredListd
        });
    }

    removepresc = (index) =>{
        let qq = this.state.prescdrug;
        qq.splice(index,1);
        this.setState({prescdrug:qq})
    }

    gotoinnvoice = () =>{
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/invoicepd/view/"+this.props.match.params.appid);
    }

    deleteprescription = (prescription_id) =>{
        // console.log(prescription_id);
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if(willDelete){
                axios.post("https://clinic.dnyanasha.com/ws/prescription.php",{deleteprescription:"yes",prescription_id:prescription_id}).then((res)=>{
                    if(res.data.deleteprescription === true){this.fetchalldrug()}
                })
            } else {}
        })
        
    }

    opentreatment = () =>{
        // this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/treatmentplans");
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/addtreatment/"+this.props.match.params.appid);
    }

    render(){
        const { filteredListd } = this.state;
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"226px",padding:"5px"}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                        <div><button className="btn btn-primary btn-sm mx-3" onClick={this.opentreatment}>Treatment Plan</button>
                            <button className="btn btn-primary btn-sm my-2 mx-3" onClick={this.gotoinnvoice} >Go to Invoice</button>
                        <button className="btn btn-primary btn-sm my-2 mx-3" onClick={this.printform} >Print</button></div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-8" style={{ borderRight:"1px solid #c9c9c9" }}>
                            <div id="printablediv">
                                <div className="row hideonprint" style={{fontSize:"11px",borderBottom:"1px solid #c9c9c9"}}>
                                    {/* style={{width:"150px"}} */}<div className="col-sm-3 p-0 px-3" >DRUG NAME</div>
                                    {/* style={{width:"100px"}} */}<div className="col-sm-2 text-center p-0" >STRENGTH</div>
                                    {/* style={{width:"100px"}} */}<div className="col-sm-2 text-center p-0" >DURATION</div>
                                    {/* style={{width:"165px"}} */}<div className="col-sm-4 p-0" ><div className="row"><div className="p-0 text-center" style={{width:"38%"}}>MORNING</div><div className="p-0" style={{width:"35%"}}>NOON</div><div className="p-0" style={{width:"25%"}}>NIGHT</div></div></div>
                                    {/* style={{width:"100px"}} */}<div className="col-sm-1 p-0" ></div>
                                </div>
                                {
                                    this.state.prescdrug.map((items,index)=>{
                                        return(<>
                                            <form key={index} onSubmit={(e)=>this.handleSubmitpresc(e,index)}>
                                                <div className="row" style={{padding:"10px 4px 10px 4px",borderBottom:"1px solid #c9c9c9"}}>
                                                    <div className="col-sm-3" style={{fontSize:"13px"}}><b>{items.drug_name} {items.drug_type}</b></div>
                                                    <div className="col-sm-2" style={{paddingLeft:"5px",paddingRight:"5px"}}>
                                                        <input type="text" className="w-50" name="strength" value={items.strength_number} onChange={this.handleChangepresc} style={{height:"23px"}} />
                                                        <select className="w-50" name="strengthtype" onChange={this.handleChangepresc}><option value="mg">mg</option></select>
                                                        <div style={{fontSize:"10.1px",color:"#0a9ed7",cursor:"pointer"}} onClick={()=>{this.setState({addinstruction:!this.state.addinstruction})}} ><b> + add instruction</b></div>
                                                        {
                                                            (this.state.addinstruction) ? (<>
                                                                <input type="text" className="w-100" onChange={this.handleChangepresc} name="instruction" placeholder="instruction" />
                                                            </>) : (<></>)
                                                        }
                                                    </div>
                                                    <div className="col-sm-2" style={{paddingLeft:"5px",paddingRight:"5px"}}><input type="text" onChange={this.handleChangepresc} name="duration" style={{height:"23px"}} className="w-50" required /><select className="w-50" name="durationtype" onChange={this.handleChangepresc} required ><option value="">select</option><option value="day">day</option><option value="week">week</option><option value="month">month</option></select></div>
                                                    <div className="col-sm-4">
                                                        <div className="row">
                                                            <div className="col-sm-4"><input type="number" max="1" min="0" onChange={this.handleChangepresc} name="morning" value={this.state.morning} className="w-100 h-100" required/></div>
                                                            <div className="col-sm-4"><input type="number" max="1" min="0" onChange={this.handleChangepresc} name="noon" value={this.state.noon} className="w-100 h-100" required/></div>
                                                            <div className="col-sm-4"><input type="number" max="1" min="0" onChange={this.handleChangepresc} name="night" value={this.state.night} className="w-100 h-100" required/></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6 d-flex p-0"><input type="radio" onChange={this.handleChangepresc} id="af" name="beforeafterfood" value="after_food" /> <label htmlFor="af" style={{fontSize:"13px"}}> After Food</label></div>
                                                            <div className="col-sm-6 d-flex p-0"><input type="radio" onChange={this.handleChangepresc} id="bf" name="beforeafterfood" value="before_food" /> <label htmlFor="bf" style={{fontSize:"13px"}}> Before Food</label></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-1 p-0 text-danger d-flex justify-content-end">
                                                        <p style={{borderBottom:"1px solid #c9c9c9",borderLeft:"1px solid #c9c9c9",borderRadius:"0 0 0 50%",height:"18px",width:"15px",display:"flex",alignItems:"flex-end",justifyContent:"center",cursor:"pointer"}} onClick={()=>{this.removepresc(index)/*this.setState({prescdrug:[]})*/}}>x</p>
                                                    </div>
                                                </div>
                                                <div className="row d-flex justify-content-end mt-2 pb-2" style={{borderBottom:"1px solid #c9c9c9"}}>
                                                    <button type="submit" className="btn btn-sm btn-primary" style={{width:"140px",marginRight:"20px"}} >Save Prescription</button>
                                                </div>
                                            </form>
                                        </>)
                                    })
                                }

                                    {
                                        this.state.findallppresc.map((item,index)=>{ //console.log(item)
                                            return(
                                                <>
                                                    <div className="row" key={index} style={{fontSize:"14px"}}>
                                                        <div className="d-flex justify-content-around">
                                                            <p className="m-0 mt-2 w-100 text-start">{item.drug_type} {item.drug_name}</p>
                                                            <p className="m-0 mt-2 w-100">{item.duration} In {(item.morning === "1") ? ("Morning") : (<></>)} {(item.noon === "1") ? (", Afternoon") : (<></>)} {(item.night === "1") ? (", Night") : (<></>)}</p>
                                                            <p className="m-0 mt-2 w-100 text-end">{item.duration} Per {item.duration_type}</p>
                                                            <p className="m-0 mt-2 w-100 text-end hideonprint"><box-icon name='trash' style={{fill:"red",cursor:"pointer"}} onClick={()=>this.deleteprescription(item.prescription_id)} ></box-icon></p>
                                                        </div>
                                                        {(item.pinstruction !== "") ? (<p className="m-0"><b>Instruction : </b>{item.pinstruction}</p>) : (<></>)}
                                                    </div>
                                                    {/* <div className="row" key={index}>
                                                        <div className="p-0 px-3" style={{fontSize:"14px",width:"160px"}}><b>{item.drug_name} {item.drug_type}</b><br /><b>Notes : </b>{item.instruction}</div>
                                                        <div className="p-0" style={{fontSize:"14px",width:"100px"}}>{item.strength_number} {item.strength}</div>
                                                        <div className="p-0" style={{fontSize:"14px",width:"100px"}}>{item.duration} {item.duration_type}</div>
                                                        <div className="p-0" style={{width:"165px"}}>
                                                            <div className="row">
                                                                <div className="" style={{fontSize:"14px",width:"33%"}}>{item.morning}</div>
                                                                <div className="" style={{fontSize:"14px",width:"35%"}}>{item.noon}</div>
                                                                <div className="" style={{fontSize:"14px",width:"25%"}}>{item.night}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 d-flex p-0" style={{fontSize:"14px"}}>
                                                                    {(item.after_food == "1") ? (<><p>After Food</p></>) : (<></>) }
                                                                    {(item.before_food == "1") ? (<><p>Before Food</p></>) : (<></>) }
                                                                </div>
                                                                <div className="col-sm-6"></div>
                                                            </div>
                                                        </div>
                                                        <div className="p-0" style={{width:"100px"}}></div>
                                                    </div> */}
                                                </>
                                            )
                                        })
                                    }
                            </div>
                        </div>


                        <div className="col-sm-4" style={{padding:"0"}}>

                            <div className="d-flex justify-content-end">
                                {
                                    (this.state.showadddrug) ? (<>
                                        <button className="btn btn-sm btn-primary" onClick={this.hidedrugs} style={{marginBottom:"5px",marginRight:"10px"}}> x Cancel</button>
                                    </>) : (<>
                                        <button className="btn btn-sm btn-primary" onClick={this.adddrugs} style={{marginBottom:"5px",marginRight:"10px"}}> + Add Drug</button>
                                    </>)
                                }
                            </div>

                            {
                                (this.state.showadddrug) ? (<>
                                    <form onSubmit={this.handleSubmit}>
                                        <div style={{ padding:"10px",background:"#eeeeee" }}>
                                            <div className="row">
                                                <div className="col-sm-3" style={{fontSize:"14px"}}>Drug</div>
                                                <div className="col-sm-5"><input className="w-100 h-100" type="text" name="drugname" onChange={this.handleChange} required /></div>
                                                <div className="col-sm-4"><select className="w-100 h-100" name="drugtype" onChange={this.handleChange} required><option value="">Type</option><option value="TAB">TAB</option><option value="CAP">CAP</option><option value="SPRAY">SPRAY</option><option value="CREAM">CREAM</option></select></div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-sm-3" style={{fontSize:"14px"}}>Strength</div>
                                                <div className="col-sm-5"><input className="w-100 h-100" type="text" name="strengthnumber" onChange={this.handleChange}  /></div>
                                                <div className="col-sm-4"><select name="strengthtype" className="w-100 h-100" onChange={this.handleChange} ><option value="">Strength</option><option value="mg">mg</option><option value="mcg">mcg</option><option value="ml">ml</option></select></div>
                                            </div>
                                            <div className="row mt-1"><div className="col-sm-12"><input className="form-control w-100 h-100" onChange={this.handleChange} type="text" name="instructions" placeholder="Instruction go here" /></div></div>
                                            <div className="row mt-1"><div className="col-sm-12"><button className="btn btn-sm btn-primary w-100 h-100" type="submit">Save Drug</button></div></div>
                                        </div>
                                    </form>
                                </>) : (<></>)
                            }
                            
                            <div style={{ padding:"10px",background:"#eeeeee" }}>
                                <input type="text" className="form-control" name="drugsearch" placeholder="Search Drug Name...."  value={this.state.searchQuery} onChange={this.handleSearchQueryChange} />
                            </div>
                            
                            <hr style={{color:"cecece",margin:"0"}}/>
                            
                            <div>
                                <div style={{background:"#eeeeee" }}><p style={{fontSize:"14px",margin:"0"}} >ALL DRUGS</p></div>
                            </div>
                            
                            <hr style={{color:"cecece",margin:"0"}}/>

                            <div style={{height:"380px",overflow:"scroll"}}>
                                {
                                    filteredListd.map((item,index)=>{
                                        return(
                                            <>
                                                <p key={index} style={{fontSize:"14px",margin:"0",borderBottom:"1px solid #c9c9c9",padding:"1px",cursor:"pointer"}} onClick={()=>this.addprecspdrug(item)} >{item.drug_type} {item.drug_name} {item.strength_number} {item.strength}</p>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        
                        </div>
                    </div>
                </div>
            </>
        )
    }
}