import axios from "axios";
import React, { Component } from "react";
import DataTable from 'react-data-table-component'
import Modal from 'react-modal';
import swal from "sweetalert";

import Select from 'react-select'
import usericon from './assets/usericon.png';


import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import Tooltip from "tooltip.js";

class Dashboard extends Component {

    constructor(props) {
        super(props); 
        this.state = {  
            findtodayapp : [],
            results : [],
            data : [
                ["Department", "Doctors"],
                // ["Physiotherapy", 60],
                // ["Dietitian", 40],
            ],
            options : {
                title: "Doctors as per Departments",
                pieHole: 0.4,
                is3D: false,
            },
            data1 : [
                ["Department", "Doctors"],
                ["Physiotherapy", 80],
                ["Dietitian", 20],
            ],
            options1 : {
                title: "Patients as per Departments",
                pieHole: 0.4,
                is3D: false,
            },

            columns : [
                {
                    name:"ID",
                    selector:(row)=>row.appointment_id,
                    width:"80px",
                    margin:"0px"
                },
                {
                    name:"Patient Name",
                    selector:(row)=>row.patient_name,
                    wrap:true
                },
                {
                    name:"Department",
                    selector:(row)=>row.dept_name,
                },
                {
                    name:"Schedule Date",
                    selector:(row)=>row.schedule_date,
                },
                {
                    name:"Start and End Time",
                    selector:(row)=>row.start_time+" - "+row.end_time,
                    wrap:true
                },
                {
                    name:"Mode",
                    selector:(row)=>{ if(row.mode == "inclinic"){ return(<>In-Clinic</>) } if(row.mode == "videocons"){ return(<>Video Consultation</>) } },
                },
                {
                    name:"Status",
                    selector:(row)=>{if(row.astatus === "P"){return(<span className="badgesecond badge-warning">Pending</span>)}if(row.astatus === "C"){return(<span className="badgesecond badge-danger">Canceled</span>)}if(row.astatus === "A"){return(<span className="badgesecond badge-success">Approved</span>)}if(row.astatus === "R"){return(<span className="badgesecond badge-reschdule">Re-Schedule</span>)}},
                    width:"100px",
                },
                {
                    name:"Action",
                    cell:(row)=>{return(<>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <box-icon style={{cursor:"pointer",marginLeft:"5px"}} name='show' alt="View" title="View" onClick={()=>this.viewmodel(row)} ></box-icon>
                                            {(row.dept_name==="Dietitian")?(<button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push( "/adddietdeatilform/"+row.appointment_id)}}>View Details</button>):(<></>)}
                                            {(row.dept_name==="Physiotherapy")?(<button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push( "/addphysotherapydeatilform/"+row.appointment_id)}}>View Details</button>):(<></>)}
                                        </div>
                                    </>)},
                    width:"180px",
                    margin:"0px",
                    padding:"0px"
                },
            ],
            modalIsOpen : false,
            appviewmodal : false,
            customStyles1 : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '40%'
                },
            },
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '30%',
                  paddingTop: '5px'
                },
            },
            reschudlemod : false,
            acceptmod : false,
            cancelmod : false,
            fromtoarr : [],
            events : [],
            appaddmodal : false,
            findpatient : [],
            findpatientdetails:[],
            deptlist : [],
            finddoctor:[],
            type : "inclinic",
            department : "1",
            doctor_id : "1",
            datemesg : "",
            showsubmitapp : true,
            todaysdate : new Date().toISOString().split('T')[0],
            todaysdateshow:"Today's",
            consulttype : "T",
            editapp:false,
        }

        if(localStorage.getItem("user") == "Patient"){this.handlechangepidforp()}

        this.fetchdashboard();
        this.handleChange = this.handleChange.bind(this);
        this.submitmodal = this.submitmodal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitappdash = this.handleSubmitappdash.bind(this);

        this.editappsubmit = this.editappsubmit.bind(this);

        this.timeoutId = null;

    }

    handlechangepidforp = () =>{
        const data = {
            fetchpatientdetail : 'yes',
            pid : localStorage.getItem("user_id")
        }
        axios.post("https://clinic.dnyanasha.com/ws/appointment.php",data).then((res)=>{
            this.setState({findpatientdetails:res.data.findpatientdetails})
            this.setState({pid:res.data.findpatientdetails['patient_id']})
        })
    }

    fetchdashboard = () =>{
        const datadash = {
            fetchdashboard : "yes",
            user : localStorage.getItem("user"),
            userid : localStorage.getItem("user_id"),
        }
        axios.post('https://clinic.dnyanasha.com/ws/dashboard.php',datadash).then((res)=>{
            console.log(res.data)
            this.setState({ data : res.data.deptarr })
            this.setState({ data1 : res.data.pdeptarr })
            this.setState({ findtodayapp : res.data.findtodayapp })
            this.setState({ results : res.data.findtodayapp })
            // this.setState({ events : res.data.eventarr })
            this.setState({ donecount : res.data.findalltreatstatus['done'] })
            this.setState({ engagedcount : res.data.findalltreatstatus['engaged'] })
            this.setState({ waitingcount : res.data.findalltreatstatus['waiting'] })
            this.setState({ totalcount : res.data.findalltreatstatus['totalcount'] })
            // console.log(res.data.eventarr);
        })

        axios.post("https://clinic.dnyanasha.com/ws/eventarr.php",{eventarr:"yes"}).then((res)=>{
            console.log(res.data);
            // this.setState({ events : res.data.eventarr })
        })


        const appdata = {
            fetchdept : "yes",
        }
        axios.post("https://clinic.dnyanasha.com/ws/appointment.php",appdata).then((res)=>{
            this.setState({deptlist:res.data.finddept})
            this.setState({ findpatient:res.data.findpatient})
            
            var patientarray = [];
            for(var i=0;i<res.data.findpatient.length;i++){
                patientarray.push({value:res.data.findpatient[i]['patient_id'],label:res.data.findpatient[i]['patient_name']})
            }
            this.setState({options:patientarray})
        })

        axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{fetchdocdetails:'yes',deptid:"1"}).then((res)=>{
            this.setState({finddoctor:res.data.finddoctor})
        })
    }

    viewmodel = (item) =>{
        console.log(item)
        this.setState({appointment_id : item.appointment_id})
        this.setState({schedule_date : item.schedule_date})
        this.setState({start_time : item.start_time})
        this.setState({end_time : item.end_time})
        this.setState({dept_name : item.dept_name})
        this.setState({doctor_name : item.doctor_name})
        this.setState({doc_id : item.doc_id})
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ appviewmodal : false })
        this.setState({ modalIsOpen : false })
        this.setState({ appaddmodal : false })
        this.setState({ editapp : false })
        this.setState({showsubmitapp:true})
    }

    submitmodal = (event) =>{
        event.preventDefault();
        console.log(this.state.astatus)
        if(this.state.astatus == "A")
        {
            this.setState({ acceptmod : true })
            this.setState({ reschudlemod : false })
            this.setState({ cancelmod : false })
            axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{changestatus:'yes',appointment_id:this.state.appointment_id,status:this.state.astatus}).then((res)=>{
            if(res.data.updatestatus == true)
            {
                swal("Appointment Updated", "Appointment Updated Successfully..!", "success").then((swalres)=>{
                    
                });
            }    
            })
        }
        else if(this.state.astatus == "C")
        {
            this.setState({ cancelmod : true })
            this.setState({ acceptmod : false })
            this.setState({ reschudlemod : false })
            axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{changestatus:'yes',appointment_id:this.state.appointment_id,status:this.state.astatus}).then((res)=>{
            if(res.data.updatestatus == true)
            {
                swal("Appointment Updated", "Appointment Updated Successfully..!", "success").then((swalres)=>{
                    
                });
            }    
            })
        }
        else if(this.state.astatus == "R")
        {
            this.setState({ reschudlemod : true })
            this.setState({ acceptmod : false })
            this.setState({ cancelmod : false })
        }
        else
        {
            this.setState({ cancelmod : false })
            this.setState({ acceptmod : false })
            this.setState({ reschudlemod : false })
        }

    }

    handleChange = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name] : value })
        if(name === "astatus")
        {
            if(value === "")
            {
                this.setState({ cancelmod : false })
                this.setState({ acceptmod : false })
                this.setState({ reschudlemod : false })
            }
        }
        if(name === "schedule_date")
        {
            var selectdate = new Date(value).setHours(0,0,0,0);
            var todaydate = new Date().setHours(0,0,0,0);
            if(selectdate > todaydate){
                if(this.state.doctor_id == ""){this.setState({datemesg : "Kindly Select Doctor...!" })} else{
                    axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{checkdocsch:"yes",doctor_id:this.state.doc_id,selectdate:value}).then((res)=>{
                        console.log(res.data);
                        this.setState({ datemesg : res.data.status })
                        this.setState({ fromtoarr : res.data.fromtoarr })
                    })
                }
            } else if(selectdate === todaydate){
                axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{checkdocsch:"yes",doctor_id:this.state.doctor_id,selectdate:event.target.value}).then((res)=>{
                    console.log(res.data);
                    this.setState({ datemesg : res.data.status })
                    this.setState({ fromtoarr : res.data.fromtoarr })
                })
            } else if(selectdate < todaydate) {
                this.setState({datemesg : "Can't Enter Previous date...!" })
            } else {
                this.setState({datemesg : "Kindly Enter the date...!" })
            }
        }

        if( name == "department")
        {
            this.setState({[name]: event.target.value});
            axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{fetchdocdetails:'yes',deptid:event.target.value}).then((res)=>{
                this.setState({finddoctor:res.data.finddoctor})
            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const datare = {
            reschduleappoitment : 'yes',
            schedule_date : this.state.schedule_date,
            availableslot : this.state.availableslot,
            appointment_id : this.state.appointment_id,
        }
        console.log(datare)
        axios.post('https://clinic.dnyanasha.com/ws/appointment.php',datare).then((res)=>{
            if(res.data.updateapp === true){
                swal("Appointment Re-Schedule", "Appointment Re-Schedule Successfully..!", "success").then((swalres)=>{});
                axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{changestatus:'yes',appointment_id:this.state.appointment_id,status:"R"}).then((res)=>{})
            }
        })
    }

    openappviewpat = (e) =>{
        const datappcal = {
            fetchcalapp : "yes",
            appid : e.event.id,
        }
        axios.post("https://clinic.dnyanasha.com/ws/dashboard.php",datappcal).then((res)=>{
            console.log(res.data);
            this.setState({ findcalappdetail : res.data.findcalappdetail })
            this.setState({ appidforchg : e.event.id })
            this.setState({ patient_name : res.data.findcalappdetail['patient_name'] })
            this.setState({ gender : res.data.findcalappdetail['gender'] })
            this.setState({ dob : res.data.findcalappdetail['dob'] })
            this.setState({ puid : res.data.findcalappdetail['unique_pid'] })
            this.setState({ department : res.data.findcalappdetail['dept_name'] })
            this.setState({ doctor : res.data.findcalappdetail['doctor_name'] })
            this.setState({ schedule_date : res.data.findcalappdetail['schedule_date'] })
            this.setState({ time : res.data.findcalappdetail['start_time']+" - "+res.data.findcalappdetail['end_time'] })
            this.setState({ treatment_status : res.data.findcalappdetail['treatment_status'] })
            this.setState({ waiting_time : res.data.findcalappdetail['waiting_time'] })
            this.setState({ engage_time : res.data.findcalappdetail['engage_time'] })
            this.setState({ done_time : res.data.findcalappdetail['done_time'] })
            this.setState({ appointment_id : res.data.findcalappdetail['appointment_id'] })
            this.setState({ unique_pid : res.data.findcalappdetail['unique_pid'] })
            this.setState({ pro_pic_data : res.data.findcalappdetail['pro_pic_data'] })
            this.setState({ profile_picture : res.data.findcalappdetail['profile_picture'] })
            this.setState({ mobile_number : res.data.findcalappdetail['mobile_number'] })
            this.setState({ email : res.data.findcalappdetail['email'] })
            this.setState({ mode : res.data.findcalappdetail['mode'] })
            this.setState({ doctor_name : res.data.findcalappdetail['doctor_name'] })
            this.setState({ start_time : res.data.findcalappdetail['start_time'] })
            this.setState({ fortime : res.data.findcalappdetail['fortime'] })
            this.setState({ appviewmodal : true })
        })
    }

    openappviewpatsidelist = (appointment_id) =>{
        const datappcal = {
            fetchcalapp : "yes",
            appid : appointment_id,
        }
        axios.post("https://clinic.dnyanasha.com/ws/dashboard.php",datappcal).then((res)=>{
            console.log(res.data);
            this.setState({ findcalappdetail : res.data.findcalappdetail })
            this.setState({ appidforchg : appointment_id })
            this.setState({ patient_name : res.data.findcalappdetail['patient_name'] })
            this.setState({ gender : res.data.findcalappdetail['gender'] })
            this.setState({ dob : res.data.findcalappdetail['dob'] })
            this.setState({ puid : res.data.findcalappdetail['unique_pid'] })
            this.setState({ department : res.data.findcalappdetail['dept_name'] })
            this.setState({ doctor : res.data.findcalappdetail['doctor_name'] })
            this.setState({ schedule_date : res.data.findcalappdetail['schedule_date'] })
            this.setState({ time : res.data.findcalappdetail['start_time']+" - "+res.data.findcalappdetail['end_time'] })
            this.setState({ treatment_status : res.data.findcalappdetail['treatment_status'] })
            this.setState({ waiting_time : res.data.findcalappdetail['waiting_time'] })
            this.setState({ engage_time : res.data.findcalappdetail['engage_time'] })
            this.setState({ done_time : res.data.findcalappdetail['done_time'] })
            this.setState({ appointment_id : res.data.findcalappdetail['appointment_id'] })
            this.setState({ unique_pid : res.data.findcalappdetail['unique_pid'] })
            this.setState({ appviewmodal : true })
        })
    }

    changetreatstatus = (status,appointment_id) =>{
        // event.preventDefault();
        const datacts = {
            changetreatstatus : "yes",
            treatstatus : status,//event.target.value,
            appidforchg : appointment_id,//this.state.appidforchg
        }
        axios.post("https://clinic.dnyanasha.com/ws/dashboard.php",datacts).then((res)=>{
            console.log(res.data);
            this.setState({treatment_status:status})//event.target.value})
            var a = new Date();
            var lt = a.getHours()  + ":" + a.getMinutes() + ":" + a.getSeconds();
            if(status === "W"){this.setState({waiting_time:lt})}
            if(status === "E"){this.setState({engage_time:lt})}
            if(status === "D"){this.setState({done_time:lt})}
            this.fetchdashboard();
        })
    }

    openaddappfrmdash = (dateStr) =>{
        console.log(dateStr);
        console.log(dateStr.split("T")[1].split("+")[0]);
        this.setState({schdate:dateStr.split("T")[0]})
        this.setState({availableslot:dateStr.split("T")[1].split("+")[0]})
        this.setState({appaddmodal:true})
    }

    handlechangepid = (event) =>{
        this.setState({pid:event['value']})
        const data = {
            fetchpatientdetail : 'yes',
            pid : event['value']
        }
        axios.post("https://clinic.dnyanasha.com/ws/appointment.php",data).then((res)=>{
            this.setState({findpatientdetails:res.data.findpatientdetails})
        })
    }

    handleSubmitappdash = (event) =>{
        this.setState({showsubmitapp:false})
        event.preventDefault();
        const dataappdash = {
            add_appoinment : 'yes',
            user : localStorage.getItem('user'),
            type : this.state.type,
            pid : this.state.pid,
            department : this.state.department,
            category : this.state.category,
            schdate : this.state.schdate,
            apptime : this.state.apptime,
            fortime : this.state.fortime,
            notice : this.state.notice,
            fees : this.state.fees,
            doctor_id : this.state.doctor_id,
            pprocedure : this.state.pprocedure,
            availableslot : this.state.availableslot,
            consulttype : this.state.consulttype,
        }
        // console.log(dataappdash)
        axios.post('https://clinic.dnyanasha.com/ws/appointment.php',dataappdash).then((res)=>{
            if(res.data.saveappoitment > 0)
            {
                // if (this.props.location.state && this.props.location.state.fromad) {
                //     if(this.props.location.state.fromad === "yes"){
                //         if(this.state.department === '1'){
                //             this.props.history.push( "/addphysotherapydeatilform/"+res.data.saveappoitment)
                //         }
                //         if(this.state.department === '2'){
                //             this.props.history.push( "/adddietdeatilform/"+res.data.saveappoitment)
                //         }
                //     }
                // } else {
                    swal({title:"Appointment Added", text:"Appointment Added Successfully..!", icon:"success",timer:1000}).then((swalres)=>{
                        // this.props.history.goBack();
                        if(this.state.consulttype === "T"){this.handleDatesSet();this.closeModal()}else{
                            if(this.state.department === '1'){
                                this.props.history.push( "/addphysotherapydeatilform/"+res.data.saveappoitment)
                            }
                            if(this.state.department === '2'){
                                this.props.history.push( "/adddietdeatilform/"+res.data.saveappoitment)
                            }
                        }
                    });
                // }
            }
        })
    }

    addpatientform = () => {
        this.props.history.push("/add_patient");
    }

    backsch = (where) =>{
        // console.log(where)
        // if(where === "back"){
            axios.post("https://clinic.dnyanasha.com/ws/dashboard.php",{fetchtodayappsch:"yes",whereto:where,todaysdate:this.state.todaysdate}).then((res)=>{
                console.log(res.data);
                if(new Date().toISOString().split('T')[0] === res.data.newdate){
                    this.setState({todaysdateshow:"Today's"})
                } else {
                    this.setState({todaysdateshow:res.data.newdate})
                }
                this.setState({todaysdate:res.data.newdate})
                this.setState({findtodayapp:res.data.findtodayapp})
                this.setState({ donecount : res.data.findalltreatstatus['done'] })
                this.setState({ engagedcount : res.data.findalltreatstatus['engaged'] })
                this.setState({ waitingcount : res.data.findalltreatstatus['waiting'] })
                this.setState({ totalcount : res.data.findalltreatstatus['totalcount'] })
            })
        // }
        // if(where === "forward"){

        // }
    }

    handleDatesSet = (info) => {
        // console.log("Info : ", info);
        
        // console.log("start : ", start);
        if(info !== undefined){
            const { start, end } = info;
            this.setState({ eventstarttime : start })
            this.setState({ eventendtime : end })
            axios.post("https://clinic.dnyanasha.com/ws/eventarr.php",{eventarrastime:"yes",starttime:start,endtime:end}).then((res)=>{
                console.log(res.data);
                this.setState({ events : res.data.eventarr })
            })
        } else {
            axios.post("https://clinic.dnyanasha.com/ws/eventarr.php",{eventarrastime:"yes",starttime:this.state.eventstarttime,endtime:this.state.eventendtime}).then((res)=>{
                console.log(res.data);
                this.setState({ events : res.data.eventarr })
            })
        }
    };

    editappsubmit = () =>{
        const dataeditapp = {
            editappsubmit : "yes",
            schedule_date : this.state.schedule_date,
            start_time : this.state.start_time,
            fortime : this.state.fortime,
            appidforchg : this.state.appidforchg,
        }
        axios.post("https://clinic.dnyanasha.com/ws/dashboard.php",dataeditapp).then((res)=>{
            console.log(res.data);
            this.handleDatesSet();
            // this.fetchdashboard()
        })
    }

    render(){
        return(
            <>
                <div>
                    <div className="row mb-4">
                        <div className="col-sm-9">
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                initialView="timeGridWeek"
                                customButtons={{
                                }}
                                events={this.state.events}
                                // eventColor="#6870f6"
                                nowIndicator
                                allDaySlot={false}
                                slotEventOverlap={false}
                                slotMinTime="09:00"
                                dateClick={(e) => {
                                    this.openaddappfrmdash(e.dateStr);
                                }}
                                slotDuration='00:15'
                                eventClick={(e) => {
                                    this.openappviewpat(e)
                                }}
                                eventMouseEnter={(e)=>{
                                    this.timeoutId = setTimeout(() => {
                                        this.openappviewpat(e)
                                    }, 1000);
                                }}
                                eventMouseLeave = {(e)=>{
                                    // console.log(e)
                                    if (this.timeoutId) {
                                        clearTimeout(this.timeoutId);
                                        this.timeoutId = null;
                                      }
                                }}
                                droppable={true}
                                editable={true}
                                eventDrop={(e)=>{
                                    axios.post("https://clinic.dnyanasha.com/ws/dashboard.php",{updateappoitment : "yes",appid:e.event.id,starttime:e.event.startStr,endtime:e.event.endStr}).then((res)=>{if(res.data.updateappitment === true){this.fetchdashboard();}})
                                }}
                                datesSet={this.handleDatesSet}
                            />
                        </div>
                        <div className="col-sm-3" style={{fontSize:"13px"}}>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="btn btn-primary btn-sm d-flex" onClick={()=>{this.backsch("back")}}><box-icon name='chevron-left' style={{fill:"#fff"}}></box-icon></span>
                                <p className="text-center m-0 my-3"><b>{this.state.todaysdateshow} Schedule</b></p>
                                <span className="btn btn-primary btn-sm d-flex" onClick={()=>{this.backsch("forward")}}><box-icon name='chevron-right' style={{fill:"#fff"}}></box-icon></span>
                                {/* <div className="tooltip">
                                    Hover over me
                                    <div className="tooltiptext">
                                        <form>
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Email address</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter email" />
                                                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                                                    else.</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Password</label>
                                                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" />
                                            </div>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                                <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                            </div>
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                        </form>
                                    </div>
                                </div> */}
                            </div>
                            <hr className="m-0 my-2" />
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push(  '/add_appointment')}}>Add Walk-In Appointment</button>
                                <button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push(  '/add_patient/fromad')}}>Add Patient</button>
                            </div>
                            <hr className="m-0 my-2" />
                            <div className="d-flex justify-content-around">
                                <p className="text-center" style={{color:"#9897a4",fontSize:"11px"}}><b>TODAY</b> <br/> <span style={{background:"#797889",padding:"1px 10px 1px 10px",color:"#fff",borderRadius:"6px"}}>{this.state.totalcount}</span> </p>
                                <p className="m-0" style={{borderRight:"1.5px solid #e0e0e5"}}></p>
                                <p className="text-center" style={{color:"#9897a4",fontSize:"11px"}}>WAITING <br/> <span style={{background:"#ff0000",padding:"1px 10px 1px 10px",color:"#fff",borderRadius:"6px"}}>{this.state.waitingcount}</span> </p>
                                <p className="text-center" style={{color:"#9897a4",fontSize:"11px"}}>ENGAGED <br/> <span style={{background:"#01bfdb",padding:"1px 10px 1px 10px",color:"#fff",borderRadius:"6px"}}>{this.state.engagedcount}</span> </p>
                                <p className="text-center" style={{color:"#9897a4",fontSize:"11px"}}>DONE <br/> <span style={{background:"#00af00",padding:"1px 10px 1px 10px",color:"#fff",borderRadius:"6px"}}>{this.state.donecount}</span> </p>
                            </div>
                            <hr className="m-0" />
                            {/* style={{height:"650px",overflow:"scroll"}} */}
                            <div>
                                {
                                    this.state.findtodayapp.map((item,index)=>{ 
                                        var date1 = new Date();
                                        var datesd = "18:19";//item.start_time;
                                        date1.setHours(parseInt(datesd.split(":")[0],10))
                                        date1.setMinutes(parseInt(datesd.split(":")[1],10))
                                        var showtime = date1.toLocaleTimeString([],{hour:"numeric",minute:"2-digit"});
                                        // console.log(showtime);
                                        return(<>
                                            <div key={index} className="d-flex justify-content-around mt-1" style={{fontSize:"11px"}}>
                                                <div className="text-end" style={{width:"40px"}}>{item.start_time}</div>
                                                <div style={{width:"61%"}} className="text-start">
                                                    <b>
                                                        <span className="tooltip p-0" style={{fontSize:"11px"}} /* onMouseLeave={()=>console.log("leave")}  onMouseOver={()=>this.openappviewpatsidelist(item.appointment_id)} */>
                                                            <b>{item.patient_name}</b> {(item.newp === "yes") ? <span className="text-bg-success" style={{padding:"1px 2px 1px 2px"}}>N</span> : <></> }
                                                            <div className="tooltiptext">

                                                                <div className="row">
                                                                    <div className="col-sm-2">
                                                                        {
                                                                            (item.profile_picture === "") ? (
                                                                                (item.pro_pic_data === "") ? (
                                                                                    <img src={usericon} style={{height:"42px",width:"40px",borderRadius:"50%",border:"1px solid #000",paddingTop:"5px",opacity:"0.5"}} />
                                                                                ) : (
                                                                                    <img src={item.pro_pic_data} style={{height:"50px",borderRadius:"50%",width:"50px"}} />
                                                                                )
                                                                            ) : (
                                                                                <img src={"https://clinic.dnyanasha.com/ws/"+item.profile_picture} style={{height:"50px",width:"50px"}} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="col-sm-10">
                                                                        {
                                                                            (localStorage.getItem("user") === "Admin" || localStorage.getItem("user") === "Doctor" ) ? (
                                                                                <p className="m-0" style={{fontSize:"16px"}}><b className="pnamehoverdash" style={{cursor:"pointer"}} onClick={()=>{window.open('/patient_dashboard/'+item.unique_pid+'/consultation', '_blank')}}>{item.patient_name}</b></p>
                                                                            ) : (
                                                                                (localStorage.getItem("user") === "Staff") ?
                                                                                <p className="m-0" style={{fontSize:"16px"}}><b className="pnamehoverdash" style={{cursor:"pointer"}} onClick={()=>{window.open('/patient_dashboard/'+item.unique_pid+'/profile', '_blank')}}>{item.patient_name}</b></p> : <p className="m-0" style={{fontSize:"16px"}}><b>{item.patient_name}</b></p>
                                                                            )
                                                                        }
                                                                        <p className="m-0" style={{fontSize:"12px",color:"#4e4e4e"}}>{item.unique_pid}</p>
                                                                        <p className="m-0" style={{fontSize:"12px",color:"#4e4e4e"}}>{ (item.gender == 'm') ? ("Male") : ("Female")  } • { new Date().getFullYear() - new Date(item.dob).getFullYear() } Years</p>
                                                                    </div>
                                                                    <div className="col-sm-12 mt-3 mb-2">
                                                                        <p className="m-0" style={{fontSize:"12px",color:"#4e4e4e"}}>📞 &nbsp;&nbsp; {item.mobile_number}</p>
                                                                        <p className="m-0" style={{fontSize:"12px",color:"#4e4e4e"}}>✉ &nbsp;&nbsp; {item.email}</p>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="col-sm-6">
                                                                        <p className="m-0" style={{fontSize:"12px"}}>{(item.mode === "inclinic") ? (<><b>In-Clinic Appointment</b></>) : (<><b>On-Line Appointment</b></>) } with {item.doctor_name} at {item.start_time} for {item.fortime} mins</p>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                    {(item.treatment_status === "P") ? (<><div className="btndashcheck d-flex justify-content-center align-items-center" style={{border:"1px solid #ff0000",color:"#ff0000"}} onClick={()=>this.changetreatstatus("W",item.appointment_id)}>Check in</div></>) : (<></>)}
                                                                    {(item.treatment_status === "W") ? (<><div className="btndashcheck d-flex justify-content-center align-items-center" style={{border:"1px solid #01bfdb",color:"#01bfdb"}} onClick={()=>this.changetreatstatus("E",item.appointment_id)}>Engage</div></>) : (<></>)}
                                                                    {(item.treatment_status === "E") ? (<><div className="btndashcheck d-flex justify-content-center align-items-center" style={{border:"1px solid #00b001",color:"#00b001"}} onClick={()=>this.changetreatstatus("D",item.appointment_id)}>Check out </div></>) : (<></>)}
                                                                    </div>
                                                                    <div className="col-sm-12 mt-2">
                                                                        {/* <p className="m-0" style={{fontSize:"11px"}}>
                                                                            {(item.engage_time === "") ? (<></>) : (<><b>Waiting Time : </b> { (Math.abs(new Date(`2000-01-01T${item.waiting_time}`).getTime() - new Date(`2000-01-01T${item.engage_time}`).getTime()) / (1000 * 60)).toFixed(2) } min </>)}
                                                                        </p> */}
                                                                        <p className="m-0" style={{fontSize:"11px"}}>
                                                                            <b>Engaged Time : </b> { Math.floor(((Math.abs(new Date(new Date().toDateString() + ' ' + item.engage_time).getTime() - new Date().getTime()) / (1000 * 60)).toFixed(2)) / 60) +" Hours " + Math.floor(((Math.abs(new Date(new Date().toDateString() + ' ' + item.engage_time).getTime() - new Date().getTime()) / (1000 * 60)).toFixed(2)) % 60) + "Minutes" }
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        {(item.treatment_status === "D") ? (
                                                                            <p className="m-0 text-center" style={{fontSize:"11px",color:"#707f80",backgroundColor:"#fcf8e2"}}><b>Patient has Checked out.</b></p>
                                                                        ) : (<></>) }
                                                                    </div>
                                                                </div>
                                                                        {/* <select onChange={this.changetreatstatus} name="treatstatus" value={item.treatment_status}>
                                                                            <option value={"W"}>Check-In</option>
                                                                            <option value={"E"}>Ongoing</option>
                                                                            <option value={"D"}>Check-Out</option>
                                                                        </select> */}
                                                                        {/* <span><input type={"radio"} className="mx-1" name="treatstatus" id="waiting" value={"W"} checked={(item.treatment_status === "W") ? true : false} onChange={this.changetreatstatus} /><label htmlFor="waiting">Waiting</label> <span className="ms-3">{item.waiting_time}</span></span><br />
                                                                        <span><input type={"radio"} className="mx-1" name="treatstatus" id="engaged" value={"E"} checked={(item.treatment_status === "E") ? true : false} onChange={this.changetreatstatus} /><label htmlFor="engaged">Engaged</label><span className="ms-3">{item.engage_time}</span></span><br />
                                                                        <span><input type={"radio"} className="mx-1" name="treatstatus" id="done" value={"D"} checked={(item.treatment_status === "D") ? true : false} onChange={this.changetreatstatus} /><label htmlFor="done">Done</label><span className="ms-2">{item.done_time}</span></span><br /> */}
                                                            </div>
                                                        </span></b> <br/>{item.doctor_name}
                                                </div>
                                                {(item.treatment_status === "P") ? <div className="d-flex align-items-center"><span style={{background:"#797889",padding:"7px",height:"5px",borderRadius:"50%"}}></span></div> : <></> }
                                                {(item.treatment_status === "W") ? <div className="d-flex align-items-center"><span style={{background:"#ff0000",padding:"7px",height:"5px",borderRadius:"50%"}}></span></div> : <></> }
                                                {(item.treatment_status === "E") ? <div className="d-flex align-items-center"><span style={{background:"#01bfdb",padding:"7px",height:"5px",borderRadius:"50%"}}></span></div> : <></> }
                                                {(item.treatment_status === "D") ? <div className="d-flex align-items-center"><span style={{background:"#00af00",padding:"7px",height:"5px",borderRadius:"50%"}}></span></div> : <></> }
                                                <div></div>
                                            </div>
                                            <hr className="mt-2 mb-2" />
                                        </>)
                                    })
                                }
                            </div>
                        </div>
                    </div>
                                
                    <>
                    {/* <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Today's Appointments</h5>
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        columns={this.state.columns} 
                                        data={this.state.results} 
                                        style={{textAlign:"center"}}
                                        pagination
                                        fixedHeader
                                        fixedHeaderScrollHeight='450px'
                                        highlightOnHover
                                        subHeader
                                        subHeaderComponent={
                                            <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                                <div style={{fontSize:"22px"}}>Today's Appointments</div>
                                                <input 
                                                    type="text"
                                                    placeholder='search here'
                                                    className='w-25 form-control'
                                                    value={this.state.search}
                                                    onChange={(e)=>this.setSearch(e.target.value)}
                                                />
                                            </div>
                                        }
                                    />
                                    <Modal
                                        isOpen={this.state.modalIsOpen}
                                        onRequestClose={this.closeModal}
                                        style={this.state.customStyles}
                                        contentLabel="View Appointment Modal"
                                        ariaHideApp={false}
                                    >
                                        <h5>View Appointment</h5>

                                        <div className="row">
                                            <div className="col-sm-10">
                                                <div className="form-group">
                                                    <label className="form-check-label">Appoitment</label>
                                                    <select className="form-control" name="astatus" onChange={this.handleChange}>
                                                        <option value="">Select Status</option>
                                                        {
                                                            (localStorage.getItem("user") == "Patient") ? (<>
                                                                <option value="C">Canceled</option>
                                                                {
                                                                    (new Date(this.state.schedule_date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) ? (<></>) : (
                                                                        <option value="R">Re-Schedule</option>
                                                                    )
                                                                }

                                                        </>) : (<>
                                                            <option value="C">Canceled</option>
                                                            {
                                                                (new Date(this.state.schedule_date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) ? (<></>) : (
                                                                    <option value="R">Re-Schedule</option>
                                                                )
                                                            }
                                                            <option value="P">Pending</option>
                                                            <option value="A">Approved</option>
                                                        </>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-sm-2" style={{display:"flex",alignItems:"flex-end"}}>
                                                <button type="submit" className="btn btn-primary" onClick={this.submitmodal}>Submit</button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                {
                                                    (this.state.reschudlemod == true) ? (<>
                                                        <div className="row mt-3">
                                                            <div className="col-sm-12"><div className="text-center text-danger fw-bold">Re-Schedule</div></div>
                                                        </div>
                                                        <form onSubmit={this.handleSubmit}>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Scheduled Date : </label>
                                                                        <input type="date" className="form-control" name="schedule_date" onChange={this.handleChange} value={this.state.schedule_date}/>
                                                                        <p className="text-danger fw-bold form-control-sm m-0">{this.state.datemesg}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <label className="form-check-label" style={{fontSize:"12px"}}>At <span style={{color:"red"}}>*</span></label>
                                                                    <input type="time" className="form-control" placeholder="Time" name="availableslot" style={{fontSize:"12px"}}  value={this.state.availableslot} onChange={this.handleChange} required />
                                                                </div>
                                                                <div className="col-sm-12 mt-2">
                                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </>) : (<></>)
                                                }
                                                {
                                                    (this.state.acceptmod == true) ? (<>
                                                        <div className="row mt-3">
                                                            <div className="col-sm-12">
                                                                <div className="text-center text-success fw-bold">Appoitment Successfully Accepted</div>
                                                            </div>

                                                        <div className="row mt-2">
                                                            <div className="col-sm-3"><p>Department : </p></div>
                                                            <div className="col-sm-3"><p>{ this.state.dept_name }</p></div>
                                                            <div className="col-sm-2"><p>Doctor : </p></div>
                                                            <div className="col-sm-4"><p>{ this.state.doctor_name }</p></div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-3"><p>Scheduled At : </p></div>
                                                            <div className="col-sm-3"><p>{ this.state.schedule_date }</p></div>
                                                            <div className="col-sm-2"><p>Time : </p></div>
                                                            <div className="col-sm-4"><p>{ this.state.start_time } - { this.state.end_time }</p></div>
                                                        </div>

                                                        </div>
                                                    </>) : (<></>)
                                                }
                                                {
                                                    (this.state.cancelmod == true) ? (<>
                                                        <div className="row mt-3">
                                                            <div className="col-sm-12"><div className="text-center text-danger fw-bold">You'r Appointment is Canceled</div></div>
                                                        </div>
                                                        
                                                        <div className="row mt-2">
                                                            <div className="col-sm-3"><p>Department : </p></div>
                                                            <div className="col-sm-3"><p>{ this.state.dept_name }</p></div>
                                                            <div className="col-sm-2"><p>Doctor : </p></div>
                                                            <div className="col-sm-4"><p>{ this.state.doctor_name }</p></div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-3"><p>Scheduled At : </p></div>
                                                            <div className="col-sm-3"><p>{ this.state.schedule_date }</p></div>
                                                            <div className="col-sm-2"><p>Time : </p></div>
                                                            <div className="col-sm-4"><p>{ this.state.start_time } - { this.state.end_time }</p></div>
                                                        </div>
                                                        
                                                    </>) : (<></>)
                                                }
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div className="card-footer">
                                </div>
                            </div>
                        </div>
                    </div> */}
                    </>


                    <Modal
                        isOpen={this.state.appviewmodal}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="View Appointment Modal"
                        ariaHideApp={false}
                    >
                        {/* <h5>View Appointment</h5> */}


                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-end">
                                <div style={{cursor:"pointer"}} onClick={()=>{this.setState({editapp:!this.state.editapp})}} ><box-icon type='solid' name='edit-alt'></box-icon></div>
                            </div>
                        </div>

                        {
                            (this.state.editapp) ? (
                            <div className="row">
                                <div className="col-sm-12 d-flex mb-1">
                                    <input type={"date"} className="form-control mx-2" value={this.state.schedule_date} onChange={(e)=>{this.setState({schedule_date:e.target.value})}} style={{fontSize:"12px",height:"25px",padding:"0",paddingRight:"5px",paddingLeft:"5px"}} />
                                    <input type={"time"} className="form-control mx-2" value={this.state.start_time} onChange={(e)=>{this.setState({start_time:e.target.value})}}  style={{fontSize:"12px",height:"25px",padding:"0",paddingRight:"5px",paddingLeft:"5px"}} />
                                    <select className="form-select mx-2" name="fortime" value={this.state.fortime} onChange={(e)=>{this.setState({fortime:e.target.value})}}  style={{fontSize:"12px",height:"25px",padding:"0",paddingRight:"5px",paddingLeft:"5px"}} required>
                                        <option value="">Select</option>
                                        <option value="15">15 min</option>
                                        <option value="30">30 min</option>
                                        <option value="45">45 min</option>
                                        <option value="60">1 Hours</option>
                                        <option value="75">1:15 Hours</option>
                                        <option value="90">1:30 Hours</option>
                                        <option value="105">1:45 Hours</option>
                                        <option value="120">2 Hours</option>
                                        <option value="135">2:15 Hours</option>
                                        <option value="150">2:30 Hours</option>
                                        <option value="165">2:45 Hours</option>
                                        <option value="180">3 Hours</option>
                                        <option value="195">3:15 Hours</option>
                                        <option value="210">3:30 Hours</option>
                                        <option value="225">3:45 Hours</option>
                                        <option value="240">4 Hours</option>
                                        <option value="255">4:15 Hours</option>
                                        <option value="270">4:30 Hours</option>
                                        <option value="285">4:45 Hours</option>
                                    </select>
                                    <button className="btn btn-sm btn-primary" style={{fontSize:"12px",height:"25px",padding:"0",paddingRight:"5px",paddingLeft:"5px"}} onClick={this.editappsubmit}>Submit</button>
                                </div>
                            </div>
                            ) :(<></>)
                        }

                        <div className="row">
                            <div className="col-sm-2">
                            {
                                (this.state.profile_picture === "") ? (
                                    (this.state.pro_pic_data === "") ? (
                                        <img src={usericon} style={{height:"42px",width:"40px",borderRadius:"50%",border:"1px solid #000",paddingTop:"5px",opacity:"0.5"}} />
                                    ) : (
                                        <img src={this.state.pro_pic_data} style={{height:"50px",borderRadius:"50%",width:"50px"}} />
                                    )
                                ) : (
                                    <img src={"https://clinic.dnyanasha.com/ws/"+this.state.profile_picture} style={{height:"50px",width:"50px"}} />
                                )
                            }
                            </div>
                            <div className="col-sm-10">
                                {
                                    (localStorage.getItem("user") === "Admin" || localStorage.getItem("user") === "Doctor" ) ? (
                                        <p className="m-0" style={{fontSize:"16px"}}><b className="pnamehoverdash" style={{cursor:"pointer"}} onClick={()=>{window.open('/patient_dashboard/'+this.state.unique_pid+'/consultation', '_blank')}}>{this.state.patient_name}</b></p>
                                    ) : (
                                        (localStorage.getItem("user") === "Staff") ?
                                            <p className="m-0" style={{fontSize:"16px"}}><b className="pnamehoverdash" style={{cursor:"pointer"}} onClick={()=>{window.open('/patient_dashboard/'+this.state.unique_pid+'/profile', '_blank')}}>{this.state.patient_name}</b></p> : <p className="m-0" style={{fontSize:"16px"}}><b>{this.state.patient_name}</b></p>
                                    )
                                }
                                <p className="m-0" style={{fontSize:"12px",color:"#4e4e4e"}}>{this.state.unique_pid}</p>
                                <p className="m-0" style={{fontSize:"12px",color:"#4e4e4e"}}>{ (this.state.gender == 'm') ? ("Male") : ("Female")  } • { new Date().getFullYear() - new Date(this.state.dob).getFullYear() } Years</p>
                            </div>
                            <div className="col-sm-12 mt-3 mb-2">
                                <p className="m-0" style={{fontSize:"12px",color:"#4e4e4e"}}>📞 &nbsp;&nbsp; {this.state.mobile_number}</p>
                                <p className="m-0" style={{fontSize:"12px",color:"#4e4e4e"}}>✉ &nbsp;&nbsp; {this.state.email}</p>
                            </div>
                            <hr />
                            <div className="col-sm-6">
                                <p className="m-0" style={{fontSize:"12px"}}>{(this.state.mode === "inclinic") ? (<><b>In-Clinic Appointment</b></>) : (<><b>On-Line Appointment</b></>) } with {this.state.doctor_name} at {this.state.start_time} for {this.state.fortime} mins</p>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-12">
                                {(this.state.treatment_status === "P") ? (<><div className="btndashcheck d-flex justify-content-center align-items-center" style={{border:"1px solid #ff0000",color:"#ff0000"}} onClick={()=>this.changetreatstatus("W",this.state.appointment_id)}>Check in</div></>) : (<></>)}
                                {(this.state.treatment_status === "W") ? (<><div className="btndashcheck d-flex justify-content-center align-items-center" style={{border:"1px solid #01bfdb",color:"#01bfdb"}} onClick={()=>this.changetreatstatus("E",this.state.appointment_id)}>Engage</div></>) : (<></>)}
                                {(this.state.treatment_status === "E") ? (<><div className="btndashcheck d-flex justify-content-center align-items-center" style={{border:"1px solid #00b001",color:"#00b001"}} onClick={()=>this.changetreatstatus("D",this.state.appointment_id)}>Check out </div></>) : (<></>)}
                                {(this.state.treatment_status === "D") ? (
                                    <p className="m-0 text-center" style={{fontSize:"11px",color:"#707f80",backgroundColor:"#fcf8e2"}}><b>Patient has Checked out.</b></p>
                                ) : (<></>) }
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-sm-9">
                                {
                                    (localStorage.getItem("user") === "Admin" || localStorage.getItem("user") === "Doctor" ) ? (
                                        <p className="m-0">Patient Name <b style={{cursor:"pointer"}} onClick={()=>{this.props.history.push(  '/patient_dashboard/'+this.state.puid+'/consultation')}}>{this.state.patient_name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; { (this.state.gender == 'm') ? ("Male") : ("Female")  } ({ new Date().getFullYear() - new Date(this.state.dob).getFullYear() }) </b></p>
                                    ) : (
                                        <p className="m-0">Patient Name <b>{this.state.patient_name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; { (this.state.gender == 'm') ? ("Male") : ("Female")  } ({ new Date().getFullYear() - new Date(this.state.dob).getFullYear() }) </b></p>
                                    )
                                }
                            </div>
                            <div className="col-sm-3">
                                <p className="m-0">PID : <b>{this.state.puid}</b> </p>
                            </div>
                            <div className="col-sm-6">
                                <p className="m-0">Date : <b>{this.state.schedule_date}</b> </p>
                            </div>
                            <div className="col-sm-6">
                                <p className="m-0">Time : <b>{this.state.time}</b> </p>
                            </div>
                            <div className="col-sm-6">
                                <p className="m-0">Doctor : <b>{this.state.doctor}</b> </p>
                            </div>
                            <div className="col-sm-6">
                                <p className="m-0">Department : <b>{this.state.department}</b> </p>
                            </div>
                            <div className="col-sm-3 mt-2">
                                <button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push('/invoice/'+this.state.unique_pid+'/view/'+this.state.appointment_id)}}>View Innvoice</button>
                            </div>
                            {
                                (localStorage.getItem("user") === "Admin" || localStorage.getItem("user") === "Doctor") ? (
                                    <div className="col-sm-3 mt-2">
                                        <button className="btn btn-primary btn-sm" onClick={()=>{window.open('/patient_dashboard/'+this.state.unique_pid+'/consultation', '_blank')}}>Consultation</button>
                                    </div>
                                ) : (<></>)
                            }
                        </div> */}
                    </Modal>

                    <Modal
                        isOpen={this.state.appaddmodal}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles1}
                        contentLabel="Add Appointment Modal"
                        ariaHideApp={false}
                    >
                        <h6>Add Appointment</h6>
                        <hr className="m-0" />
                        <form onSubmit={this.handleSubmitappdash}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <p style={{margin:"0",fontSize:"12px"}}>Select Type <span style={{color:"red"}}>*</span></p>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group d-flex align-items-center" style={{padding:"0",fontSize:"12px"}}>
                                        <input className="form-check-input" style={{margin:"0"}} type="radio" name="type" id="inclinic" value="inclinic" onChange={this.handleChange} checked={this.state.type == "inclinic"} required/>
                                        <label className="form-check-label" htmlFor="inclinic">In-Clinic Appointment</label>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group d-flex align-items-center" style={{padding:"0",fontSize:"12px"}}>
                                        <input className="form-check-input" style={{margin:"0"}} type="radio" name="type" id="videocons" value="videocons" onChange={this.handleChange} checked={this.state.type == "videocons"} required/>
                                        <label className="form-check-label" htmlFor="videocons">Video Consultation</label>
                                    </div>
                                </div>
                            </div>

                            <div className=" row">
                                <div className='col-sm-6 form-group'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}> Patient <span style={{color:"red"}}>*</span></label>
                                    <div className="input-group">
                                        {
                                            (localStorage.getItem("user") === "Patient") ? (
                                                <select className="form-control" name="pname" value={localStorage.getItem("user_id")} onChange={()=>{}} disabled>
                                                    <option>Select Patient</option>
                                                    {
                                                        this.state.findpatient.map((item,index)=>{
                                                            return(<option key={index} value={item.patient_id}>{item.patient_name}</option>)
                                                        })
                                                    }
                                                </select>
                                            ) : (<>
                                                    <Select className="form-control p-0" style={{height:"25px",fontSize:"11px"}} options={this.state.options} name="pname" onChange={this.handlechangepid} required />
                                                    <div className="input-group-append"><span className="input-group-text" onClick={this.addpatientform} style={{borderRadius:"0 0.375rem 0.375rem 0",cursor:"pointer"}}><box-icon name='plus'></box-icon></span></div>
                                            </>)
                                        }
                                    </div>
                                </div>
                                <div className='col-sm-6 form-group'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>Patient ID <span style={{color:"red"}}>*</span></label>
                                    <input type="text" className="form-control" placeholder="Patient ID" style={{fontSize:"12px",height:"25px"}} value={this.state.findpatientdetails['unique_pid']} disabled />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className='col-sm-6'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>Mobile Number <span style={{color:"red"}}>*</span> </label>
                                    <input type="text" className="form-control" placeholder="Enter Mobile Number"   style={{fontSize:"12px",height:"25px"}} value={this.state.findpatientdetails['mobile_number']} disabled />
                                </div>
                                <div className='col-sm-6'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>Email ID </label>
                                    <input type="text" className="form-control" placeholder="Email ID"   style={{fontSize:"12px",height:"25px"}} value={this.state.findpatientdetails['email']} disabled />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className='col-sm-6'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>Department <span style={{color:"red"}}>*</span></label>
                                    <select className="form-select" name="department"   style={{fontSize:"12px",height:"25px",padding:"0",paddingRight:"5px",paddingLeft:"5px"}} value={this.state.department} onChange={this.handleChange} required>
                                        <option value="">Select Department</option>
                                        {
                                            this.state.deptlist.map((res,index)=>{
                                                return(
                                                    <option key={index} value={res.dept_id}>{res.dept_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='col-sm-6'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>Doctor</label>
                                    <select className="form-select" name="doctor_id"   style={{fontSize:"12px",height:"25px",padding:"0",paddingRight:"5px",paddingLeft:"5px"}} value={this.state.doctor_id} onChange={this.handleChange}>
                                        <option>Select Doctor</option>
                                        {
                                            this.state.finddoctor.map((item,index)=>{
                                                return(
                                                    <option key={index} value={item.doctor_id}>{item.doctor_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className='col-sm-6'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>Schedule On <span style={{color:"red"}}>*</span></label>
                                    <input type="date" className="form-control" placeholder="Schedule Date" name="schdate"   style={{fontSize:"12px",height:"25px"}} value={this.state.schdate} onChange={this.handleChange} required />
                                    { (this.state.datemesg === "") ? (<></>) : (<p className="text-danger fw-bold form-control-sm m-0">{this.state.datemesg}</p>)}
                                </div>
                                <div className='col-sm-6'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>At <span style={{color:"red"}}>*</span></label>
                                    <input type="time" className="form-control" placeholder="Time" name="availableslot"  style={{fontSize:"12px",height:"25px"}}  value={this.state.availableslot} onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-6'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>For <span style={{color:"red"}}>*</span></label>
                                    <select className="form-select" name="fortime"   style={{fontSize:"12px",height:"25px",padding:"0",paddingRight:"5px",paddingLeft:"5px"}} onChange={this.handleChange} required>
                                        <option value="">Select</option>
                                        <option value="15">15 min</option>
                                        <option value="30">30 min</option>
                                        <option value="45">45 min</option>
                                        <option value="60">1 Hours</option>
                                        <option value="75">1:15 Hours</option>
                                        <option value="90">1:30 Hours</option>
                                        <option value="105">1:45 Hours</option>
                                        <option value="120">2 Hours</option>
                                        <option value="135">2:15 Hours</option>
                                        <option value="150">2:30 Hours</option>
                                        <option value="165">2:45 Hours</option>
                                        <option value="180">3 Hours</option>
                                        <option value="195">3:15 Hours</option>
                                        <option value="210">3:30 Hours</option>
                                        <option value="225">3:45 Hours</option>
                                        <option value="240">4 Hours</option>
                                        <option value="255">4:15 Hours</option>
                                        <option value="270">4:30 Hours</option>
                                        <option value="285">4:45 Hours</option>
                                    </select>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group">
                                        <label className="form-check-label" style={{fontSize:"12px"}}>Consultation Type <span style={{color:"red"}}>*</span></label>
                                        <select className="form-select" name="consulttype" value={this.state.consulttype} style={{fontSize:"12px",height:"25px",padding:"0",paddingRight:"5px",paddingLeft:"5px"}} onChange={this.handleChange} required>
                                            <option value={"T"}>Treatment</option>
                                            <option value={"C"}>Consultation</option>
                                            <option value={"TC"}>Treatment & Consultation</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="form-group row d-none">

                            </div> */}

                            <div className="form-group row d-none">
                                {
                                    (this.state.type == "inclinic") ? 
                                    (<>
                                        <div className='col-sm-6'>
                                            <label className="form-check-label" style={{fontSize:"12px"}}>Planned Procedure</label>
                                            <textarea className="form-control" id="pprocedure" name="pprocedure"   style={{fontSize:"12px",height:"25px"}} onChange={this.handleChange} rows="2"></textarea>
                                        </div>
                                    </>) : (<>
                                        <div className='col-sm-6'>
                                            <label className="form-check-label" style={{fontSize:"12px"}}>Fees <span style={{color:"red"}}>*</span></label>
                                            <input type="number" className="form-control" name="fees" placeholder="Fees"   style={{fontSize:"12px",height:"25px"}} onChange={this.handleChange} required />
                                        </div>
                                    </>)
                                }

                                <div className='col-sm-6'>
                                    <label className="form-check-label" style={{fontSize:"12px"}}>Notes</label>
                                    <textarea className="form-control" id="notice" name="notice"   style={{fontSize:"12px",height:"25px"}} onChange={this.handleChange} rows="2"></textarea>
                                </div>
                            </div>
                            <hr />
                            <div className='text-end'>
                                {
                                    (this.state.showsubmitapp) ? (
                                        <button type="submit" className="btn btn-warning btn-sm">Submit</button>
                                    ) : (<></>)
                                }
                                <span className="btn btn-danger btn-sm mx-2" onClick={()=>{this.closeModal()}}>Cancel</span>
                            </div>

                        </form>
                    </Modal>
                </div>
            </>
        )
    }
}
export default Dashboard;