import axios from "axios";
import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import Modal from 'react-modal';
import DataTable from 'react-data-table-component'
import swal from "sweetalert";

export default class Invoicepd extends Component{

    constructor(props){
        super(props);

        this.state = {
            findallapp : [],
            invoicedata : [],
            // columns : [
            //     {
            //         name:"Invoice Number",
            //         selector:(row)=>row.invoice_number
            //     },
            //     {
            //         name:"Treatment",
            //         selector:(row)=>row.treatment_name
            //     },
            //     {
            //         name:"Unit Cost",
            //         selector:(row)=>row.unit_cost
            //     },
            //     {
            //         name:"Quantity",
            //         selector:(row)=>row.quantity
            //     },
            //     {
            //         name:"Discount",
            //         selector:(row)=>{return(<>{row.discount} {
            //             (row.discount != "") ? 
            //             ((row.discount_type == "PERCENT") ? "%" : "INR") : (<></>)
            //             }</>)}
            //     },
            //     {
            //         name:"Total Amount",
            //         selector:(row)=>{return(<>{ 
            //             (row.discount != "") ?
            //             (
            //                 ( (row.unit_cost * row.quantity) - ((row.discount_type == "PERCENT") ? ((row.unit_cost * row.quantity) * (row.discount/100)) : (row.discount) ) )
            //             ) : ((row.unit_cost * row.quantity))
            //             }</>)}
            //     },
            //     {
            //         name:"Status",
            //         cell:(row)=>{return( (row.status == "U") ? (<><span style={{background:"#dc3545",padding:"5px 10px",borderRadius:"20px"}}>Un-Paid</span></>) : ((row.status == "P") ? (<><span style={{background:"#28a745",padding:"5px 10px",borderRadius:"20px"}}>Paid</span></>) : (<></>) ) )}
            //     },
            //     {
            //         name:"Action",
            //         cell:(row)=>{return(<>
            //                             <box-icon style={{cursor:"pointer",marginLeft:"5px"}} name='show' alt="View" title="View" onClick={()=>this.changestatusp(row)} ></box-icon>
            //                     </>)}
            //     },
            // ],
            columns : [
                {
                    name:"Appointment Date",
                    selector:(row)=>row.schedule_date
                },
                {
                    name:"Time",
                    selector:(row)=><span>{row.start_time} - {row.end_time}</span>,
                    wrap:true,
                },
                {
                    name:"Invoice Number",
                    selector:(row)=>row.invoice_number
                },
                {
                    name:"Total Amount",
                    selector:(row)=>row.total_amount
                },
                // {
                //     name:"Status",
                //     cell:(row)=>{return( (row.innvoicestatus == "U") ? (<><span style={{background:"#dc3545",padding:"5px 10px",borderRadius:"20px"}}>Un-Paid</span></>) : ((row.innvoicestatus == "P") ? (<><span style={{background:"#28a745",padding:"5px 10px",borderRadius:"20px"}}>Paid</span></>) : (<></>) ) )}
                // },
                {
                    name:"Action",
                    cell:(row)=>{return(<>
                                        {/* <box-icon style={{cursor:"pointer",marginLeft:"5px"}} name='show' alt="View" title="View" onClick={()=>this.changestatusp(row)} ></box-icon> */}
                                        <button className="btn btn-sm btn-primary" onClick={()=>{this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/invoicepd/view/"+row.appointment_id)}}>View Innvoice</button>
                                </>)},
                    width:"170px"
                },
            ],
            results : [],
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
            openpayamt : false,
            customStyles1 : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
            invoicearray : [],
            checkdetails : false,
            carddetails : false,
            upidetails : false,
            finallreceipt : [],
            totalgenrated : 0,
            findtotalpaid : 0,
            balanceamt : 0,
        }

        this.fetchindata();
        this.handleChange = this.handleChange.bind(this);
    }

    fetchindata = () =>{
        const data = {
            fetchtindata : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post("https://clinic.dnyanasha.com/ws/invoice.php",data).then((res)=>{
            console.log(res.data)
            this.setState({ findallapp : res.data.findallapp })
            this.setState({ invoicedata : res.data.findallinvoice })
            // this.setState({ results : res.data.findallinvoice })
            this.setState({ results : res.data.findallapp })
            this.setState({ invoicearray : res.data.invoicearray })
            this.setState({ finallreceipt : res.data.finallreceipt })
            this.setState({ totalgenrated : res.data.totalgenrated })
            this.setState({ findtotalpaid : res.data.findtotalpaid })
            this.setState({ balanceamt : (res.data.totalgenrated - res.data.findtotalpaid) })
        })
    }

    changestatusp = (item) =>{
        this.setState({ innid : item.invoices_id })
        this.setState({ pstatus : item.status })
        this.setState({ stp : item.showtp })
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
    }

    handleChange = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name] : value })
    }

    submitmodal = (event) =>{
        event.preventDefault();
        const dataipd = {changepstatus:'yes',innid:this.state.innid,status:this.state.pstatus,stp:this.state.stp}
        axios.post('https://clinic.dnyanasha.com/ws/invoice.php',dataipd).then((res)=>{
        if(res.data.updaateps == true)
        {
            swal("Payment Status Updated", "Payment Status Updated Successfully..!", "success").then((swalres)=>{
                this.setState({ modalIsOpen : false })
                this.fetchindata();
            });
        }    
        })
    }

    openamtmodal = () =>{
        this.setState({ openpayamt : true })
    }

    closeModal = () => {
        this.setState({ openpayamt : false })
    }

    handlemop = (e) =>{
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value === "cash"){
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
        if(e.target.value === "cheque"){
            this.setState({checkdetails : true})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
        if(e.target.value === "card"){
            this.setState({checkdetails : false})
            this.setState({upidetails : false})
            this.setState({carddetails : true})
        }
        if(e.target.value === "upi"){
            this.setState({upidetails : true})
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
        }
        if(e.target.value === ""){
            this.setState({checkdetails : false})
            this.setState({carddetails : false})
            this.setState({upidetails : false})
        }
    }

    // payamoount = () =>{
    //     const datapay = {
    //         acceptpayment : "yes",
    //         amttopay : this.state.amttopay,
    //         modeopay : this.state.modeopay,
    //         bankname : this.state.bankname,
    //         chequeno : this.state.chequeno,
    //         last4digino : this.state.last4digino,
    //         transactionno : this.state.transactionno,
    //         puid : this.props.match.params.puid,
    //         appid : this.props.match.params.appid,
    //     }
    //     axios.post('https://clinic.dnyanasha.com/ws/invoice.php',datapay).then((res)=>{
    //         console.log(res.data)
    //         if(res.data.saverec > 0)
    //         // swal("Amount Paid", "Amount Paid Successfully..!", "success").then((swalres)=>{
    //             this.fetchinvoiceviewdtat();
    //             this.closeModal();
    //         // });
    //     })
    // }

    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px"}}>

                    <div className="d-flex justify-content-end align-items-center mt-2">
                        {/* <button className="btn btn-primary btn-sm mx-3" onClick={this.openamtmodal}>Pay Amount</button> */}
                        {/* <button className="btn btn-primary btn-sm">Create Innvoice</button> */}
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-12">
                            {/* {
                                this.state.findallapp.map((item,index)=>{ */}
                                    {/* return( */}
                                        {/* <DataTable
                                            columns={this.state.columns} 
                                            data={this.state.results} 
                                            style={{textAlign:"center"}}
                                            // title="All Doctors"
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight='450px'
                                            highlightOnHover
                                            subHeader
                                            onRowClicked={(row)=>{this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/invoicepd/view/"+row.appointment_id)}}
                                            subHeaderComponent={
                                                <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                                    <div style={{fontSize:"22px"}}>All Invoices</div>
                                                    <input 
                                                        type="text"
                                                        placeholder='search here'
                                                        className='w-25 form-control'
                                                        value={this.state.search}
                                                        onChange={(e)=>this.setSearch(e.target.value)}
                                                    />
                                                </div>
                                            }
                                        /> */}
                                        <p className="m-0" style={{fontSize:"20px"}}>Invoices</p>
                                        <table className="table">
                                            <thead>
                                                <tr style={{fontSize:"13px"}} >
                                                    <th style={{width:"105px"}}>CREADTED DATE</th>
                                                    <th>INVOICE NO.</th>
                                                    <th>TOWARDS</th>
                                                    <th>TOTAL AMOUNT (₹)</th>
                                                    <th>PAID AMOUNT (₹)</th>
                                                    <th>BALANCE (₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.invoicearray.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <tr style={{fontSize:"13px",cursor:"pointer"}} onClick={()=>{this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/invoicepd/view/"+item.invoice_number)}}>
                                                                <td style={{width:"105px"}}><b>{item.created_at}</b></td>
                                                                <td><b>{item.invoice_number}</b></td>
                                                                <td dangerouslySetInnerHTML={{ __html: item.treatname}}>{}</td>
                                                                <td>{item.totalamt}</td>
                                                                <td>{item.paidamt}</td>
                                                                <td>{item.totalamt - item.paidamt}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                            </tbody>
                                            <tfoot>
                                                <tr style={{fontSize:"13px"}}>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{this.state.totalgenrated} <b> : Total Invoice</b>  </td>
                                                    <td>{this.state.findtotalpaid} <b> : Total Paid</b> </td>
                                                    <td>{(this.state.balanceamt < 0) ? (<><b style={{color:"#01bfdb"}}>({this.state.balanceamt})Advance Amount</b></>) : (<><b style={{color:"#dc3545"}}>({this.state.balanceamt})Remaning Amount</b></>)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <p className="m-0" style={{fontSize:"20px"}}>Payment</p>
                                        <table className="table">
                                            <thead>
                                                <tr style={{fontSize:"13px"}} >
                                                    <th style={{width:"105px"}}>PAID DATE</th>
                                                    <th>RECEIPT NO.</th>
                                                    <th>TOTAL AMOUNT (₹)</th>
                                                    <th>TOWARDS</th>
                                                    <th>MODE OF PAYMENT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.finallreceipt.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <tr style={{fontSize:"13px"}}>
                                                                <td style={{width:"105px"}}><b>{item.payment_date}</b></td>
                                                                <td><b>{item.recept_no}</b></td>
                                                                <td>{item.amount}</td>
                                                                <td style={{color:"#01bfdb"}}>{item.invoiceno}</td>
                                                                <td>{item.modeofpay}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>

                                        <hr />

                                        <div className="row" style={{fontSize:"13px"}}>
                                            <div className="col-sm-3"> <b>Total Invoice : </b> {this.state.totalgenrated}</div>
                                            <div className="col-sm-3"> <b>Total Paid : </b> {this.state.findtotalpaid}</div>
                                            <div className="col-sm-3">
                                                {
                                                    (this.state.balanceamt < 0) ? (<><b><p style={{color:"#01bfdb"}}>Advance Amount({this.state.balanceamt})</p></b></>) : (<><b><p style={{color:"#dc3545"}}>Remaning Amount({this.state.balanceamt})</p></b></>)
                                                }
                                            </div>
                                        </div>

                                    {/* ) */}
                                {/* })
                            } */}
                            {/* <DataTable
                                columns={this.state.columns} 
                                data={this.state.results} 
                                style={{textAlign:"center"}}
                                // title="All Doctors"
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight='450px'
                                highlightOnHover
                                subHeader
                                subHeaderComponent={
                                    <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                        <div style={{fontSize:"22px"}}>All Invoices</div>
                                        <input 
                                            type="text"
                                            placeholder='search here'
                                            className='w-25 form-control'
                                            value={this.state.search}
                                            onChange={(e)=>this.setSearch(e.target.value)}
                                        />
                                    </div>
                                }
                            /> */}
                        </div>
                    </div>

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="View Appointment Modal"
                        ariaHideApp={false}
                    >
                        <h5>Update Payment Status</h5>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="form-check-label">Status</label>
                                    <select className="form-control" name="pstatus" value={this.state.pstatus} onChange={this.handleChange}>
                                        <option value="U">Un-Paid</option>
                                        <option value="P">Paid</option>
                                    </select>
                                </div>                        
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="form-check-label">Show To Patient</label>
                                    <select className="form-control" name="stp" value={this.state.stp} onChange={this.handleChange}>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-2 mt-2" style={{display:"flex",alignItems:"flex-end"}}>
                                <button type="submit" className="btn btn-primary" onClick={this.submitmodal}>Submit</button>
                            </div>
                        </div>
                    </Modal>


                    {/* <Modal
                        isOpen={this.state.openpayamt}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles1}
                        contentLabel="Pay Amount"
                        ariaHideApp={false}
                    >
                        <h5>Pay Amount</h5>
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label">Amount to Pay</label>
                                    <input type={"number"} min={0} className="form-control" name="amttopay" value={this.state.amttopay} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label">Mode of Payment</label>
                                    <select className="form-control" onChange={this.handlemop} name="modeopay" value={this.state.modeopay} >
                                        <option value={""}>Select Mode</option>
                                        <option value={"upi"}>UPI</option>
                                        <option value={"cash"}>Cash</option>
                                        <option value={"cheque"}>Cheque</option>
                                        <option value={"card"}>Card</option>
                                    </select>
                                </div>
                            </div>
                            {
                                (this.state.checkdetails) ? (<>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="form-label">Bank Name</label>
                                        <input type={"text"} className="form-control" name="bankname" value={this.state.bankname} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="form-label">Cheque No.</label>
                                        <input type={"number"} min={1} className="form-control" name="chequeno" value={this.state.chequeno} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                    </div>
                                </div>
                                </>) : (<></>)
                            }
                            {
                                (this.state.carddetails) ? (<>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="form-label">Last 4 Digit</label>
                                        <input type={"text"} className="form-control" name="last4digino" value={this.state.last4digino} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                    </div>
                                </div>
                                </>) : (<></>)
                            }
                            {
                                (this.state.upidetails) ? (<>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="form-label">Transaction No.</label>
                                            <input type={"text"} className="form-control" name="transactionno" value={this.state.transactionno} onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} />
                                        </div>
                                    </div>
                                </>) : (<></>)
                            }
                        </div>
                        <div className="row">
                            <div className="col-sm-1 mt-3">
                                <button className="btn btn-sm btn-primary" onClick={this.payamoount}>Submit</button>
                            </div>
                        </div>
                    </Modal> */}
                </div>
            </>
        )
    }
}