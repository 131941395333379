import axios from "axios";
import React, { Component, useEffect } from "react";
import swal from "sweetalert";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";

export default class ConsultationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // listening: false,
            // listening1: false,
            // transcript1: '',
            // transcript2: '',
            // phistory: "",
            // pobservation : "",
            // orthoneuroform : false,

            orthoforms : false,

            listening: false,
            listening1: false,
            transcript1: '',
            transcript2: '',
            chiefcomplaints: "",
            historyofconoctpo : "",
        }
        // this.recognition = new window.webkitSpeechRecognition();
        // this.recognition.continuous = true;
        // this.recognition.interimResults = true;
        // this.recognition.lang = 'en-US';
        // this.recognition1 = new window.webkitSpeechRecognition();
        // this.recognition2 = new window.webkitSpeechRecognition();
        // this.recognition1.continuous = true;
        // this.recognition1.interimResults = true;
        // this.recognition1.lang = 'en-US';
        // this.recognition2.continuous = true;
        // this.recognition2.interimResults = true;
        // this.recognition2.lang = 'en-US';
        // this.lastTranscript1 = '';
        // this.lastTranscript2 = '';


        this.recognition1 = new window.webkitSpeechRecognition();
        this.recognition2 = new window.webkitSpeechRecognition();
        this.recognition1.continuous = true;
        this.recognition1.interimResults = true;
        this.recognition1.lang = 'hi-IN';
        this.recognition2.continuous = true;
        this.recognition2.interimResults = true;
        this.recognition2.lang = 'hi-IN';
        this.lastTranscript1 = '';
        this.lastTranscript2 = '';

        this.fetchconsdata();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitcons = this.handleSubmitcons.bind(this);
    }

    fetchconsdata = () =>{
        const dataf = {
            fetchconsdata : 'yes',
            pid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/consultation.php',dataf).then((res)=>{
            console.log(res.data)
            // this.setState({phistory:res.data.findphodata['history']})
            // this.setState({pobservation:res.data.findphodata['observation']})
            if(res.data.findappdetails['dept_id'] == "1" ){ this.setState({orthoneuroform : true }) }

            var consulteddata = JSON.parse(res.data.findconsulteddata['consulteddata']);
            Object.entries(consulteddata).map(([key,value])=>{
                // console.log(key+" = "+value)
                this.setState({ [key] : value })
            })

        })
    }

    handleChange = (event) => {
        // let name = event.target.name;
        // this.setState({ [name]: event.target.value });
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            // console.log(event.target.name+" = "+event.target.value + " = " + event.target.checked);
            // console.log(name + " == " + value  + " == "+ event.target.checked)
            if (event.target.checked === true) {
                this.setState({ [name]: true })
            } else {
                this.setState({ [name]: false })
            }
        } else {
            this.setState({ [name]: value })
        }
    }

    handleSubmitcons = (event) =>{
        event.preventDefault();
        const dataon = { conssavenewwithdetails : "yes", puid: this.props.match.params.puid, appid: this.props.match.params.appid, }
        for (let i = 0; i < event.target.length; i++) {
            // console.log(event.target[i].name);
            if (event.target[i].type === "checkbox") {
                dataon[event.target[i].name] = event.target[i].checked
            } else{
                dataon[event.target[i].name] = event.target[i].value
            }
        }
        axios.post("https://clinic.dnyanasha.com/ws/consultation.php",dataon).then((res)=>{
            console.log(res.data);
            if(res.data.savepho > 0){this.setState({orthoforms:true})}
            if(res.data.updatepho === true){this.setState({orthoforms:true})}
        })
    }

    toggleListen1 = () => {
        this.setState(
            prevState => ({
                listening: !prevState.listening
            }),
            () => {
                if (this.state.listening) {
                this.recognition1.start();
                } else {
                this.recognition1.stop();
                }
            }
        );
    };

    toggleListen2 = () => {
        this.setState(
            prevState => ({
                listening1: !prevState.listening1
            }),
            () => {
                if (this.state.listening1) {
                    this.recognition2.start();
                } else {
                    this.recognition2.stop();
                }
            }
        );
    };

    componentDidMount() {
        this.recognition1.onresult = event => {
            const newTranscript = Array.from(event.results).map(result => result[0].transcript).join('');
            this.setState({ historyofconoctpo: newTranscript });
            //   if (!newTranscript.includes(this.lastTranscript1)) {
            //     this.lastTranscript1 = newTranscript;
            //     this.setState(prevState => ({
            //       transcript1: prevState.transcript1 + newTranscript
            //     }));
            //   }
        };
    
        this.recognition1.onend = () => {
            this.setState({ listening: false });
        };
    
        this.recognition2.onresult = event => {
            const newTranscript = Array.from(event.results).map(result => result[0].transcript).join('');
            this.setState({ chiefcomplaints: newTranscript });
            //   if (!newTranscript.includes(this.lastTranscript2)) {
            //     this.lastTranscript2 = newTranscript;
            //     this.setState(prevState => ({
            //       transcript2: prevState.transcript2 + newTranscript
            //     }));
            //   }
        };
    
        this.recognition2.onend = () => {
            this.setState({ listening: false });
        };
    }

    // handleSubmitcons = (event) => {
    //     event.preventDefault();
    //     const data = {
    //         conssavenew: 'yes',
    //         phistory: this.state.phistory,
    //         pobservation: this.state.pobservation,
    //         pid: this.props.match.params.puid,
    //         appid: this.props.match.params.appid,
    //     }
    //     console.log(data)
    //     axios.post('https://clinic.dnyanasha.com/ws/consultation.php',data).then((res)=>{
    //         console.log(res.data)
    //         if(res.data.savepho > 0){
    //             swal("Added Successfully", "Patient History & Observation Successfully Added..!", "success").then((swalres)=>{
    //                 this.fetchconsdata();
    //             });
    //         }
    //         if(res.data.updatepho == true){
    //             swal("Updated Successfully", "Patient History & Observation Successfully Updated..!", "success").then((swalres)=>{
    //                 this.fetchconsdata();
    //             });
    //         }
    //     })
    // }

    // toggleListen = () => {
    //     if(this.state.listening1) { this.setState({listening1:false}); this.recognition.stop(); }
    //     this.setState(
    //         prevState => ({
    //             listening: !prevState.listening
    //         }),
    //         () => {
    //             if (this.state.listening) {
    //                 this.recognition.start();
    //             } else {
    //                 this.recognition.stop();
    //             }
    //         }
    //     );
    // };

    // toggleListen1 = () => {
    //     if(this.state.listening) { this.setState({listening:false}); this.recognition.stop(); }
    //     this.setState(
    //         prevState => ({
    //             listening1: !prevState.listening1
    //         }),
    //         () => {
    //             if (this.state.listening1) {
    //                 this.recognition.start();
    //             } else {
    //                 this.recognition.stop();
    //             }
    //         }
    //     );
    // };

    // toggleListen1 = () => {
    //     this.setState(
    //       prevState => ({
    //         listening: !prevState.listening
    //       }),
    //       () => {
    //         if (this.state.listening) {
    //           this.recognition1.start();
    //         } else {
    //           this.recognition1.stop();
    //         }
    //       }
    //     );
    // };
    
    // toggleListen2 = () => {
    // this.setState(
    //     prevState => ({
    //     listening: !prevState.listening
    //     }),
    //     () => {
    //     if (this.state.listening) {
    //         this.recognition2.start();
    //     } else {
    //         this.recognition2.stop();
    //     }
    //     }
    // );
    // };

    // componentDidMount() {
    //     this.recognition1.onresult = event => {
    //       const newTranscript = Array.from(event.results)
    //         .map(result => result[0].transcript)
    //         .join('');
    //         this.setState({ phistory: newTranscript });
    //     //   if (!newTranscript.includes(this.lastTranscript1)) {
    //     //     this.lastTranscript1 = newTranscript;
    //     //     this.setState(prevState => ({
    //     //       transcript1: prevState.transcript1 + newTranscript
    //     //     }));
    //     //   }
    //     };
    
    //     this.recognition1.onend = () => {
    //       this.setState({ listening: false });
    //     };
    
    //     this.recognition2.onresult = event => {
    //       const newTranscript = Array.from(event.results)
    //         .map(result => result[0].transcript)
    //         .join('');
    //     this.setState({ pobservation: newTranscript });
    //     //   if (!newTranscript.includes(this.lastTranscript2)) {
    //     //     this.lastTranscript2 = newTranscript;
    //     //     this.setState(prevState => ({
    //     //       transcript2: prevState.transcript2 + newTranscript
    //     //     }));
    //     //   }
    //     };
    
    //     this.recognition2.onend = () => {
    //       this.setState({ listening: false });
    //     };
    //   }

    // componentDidMount() {
    //     this.recognition.onresult = event => {
    //         // let newTranscript = Array.from(event.results)
    //         //     .map(result => result[0].transcript)
    //         //     .join('');
    //         // if (!newTranscript.includes(this.lastTranscript)) {
    //         //     this.lastTranscript = newTranscript;
    //         //     this.setState(prevState => ({
    //         //         transcript: prevState.transcript + newTranscript
    //         //     }));
    //         // }
    //         const transcript = Array.from(event.results)
    //             .map(result => result[0].transcript)
    //             .join('');
    //         // var prevhistory = this.state.phistory;
    //         this.setState({ phistory: transcript });
    //     };

    //     this.recognition.onend = () => {
    //         this.setState({ listening: false });
    //     };
    // }

    goBack = () => {
        this.props.history.goBack();
    }

    orthoneuroforma = () => {
        // window.open("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroform/"+this.props.match.params.appid, '','width=600,left=200,top=200');
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroform/"+this.props.match.params.appid);
    }

    orthoneuroformb = () => {
        // window.open("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroform/"+this.props.match.params.appid, '','width=600,left=200,top=200');
        this.props.history.push("/patient_dashboard/"+this.props.match.params.puid+"/consultation/orthoneuroformb/"+this.props.match.params.appid);
    }

    render() {
        const { listening, transcript, listening1 } = this.state;
        return (
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{ marginLeft: "220px", padding: "5px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                    </div>
                    <hr />

                    <div>
                        <h5>Consultation</h5>
                    </div>
                    <hr />



                    <form onSubmit={this.handleSubmitcons}>

                        <div style={{fontSize:"12px",fontWeight:"bold",position:"sticky",top:0,background:"#fff"}} className="mt-3">
                            <p className="m-0 d-flex">Chief Complaints : 
                                <span className="d-flex p-0" style={{ border: "1px solid #747679", width: "30px", height: "30px", justifyContent: "center", alignItems: "center", margin: "0 0 8px 15px", borderRadius: "50%" }} onClick={this.toggleListen2}>{listening1 ?
                                    <box-icon type="solid" name='microphone' style={{ fill: "red", cursor: "pointer" }}></box-icon>
                                    :
                                    <box-icon type="solid" name='microphone' style={{ fill: "#747679", cursor: "pointer" }}></box-icon>
                                }</span>
                            </p>
                            <textarea className="w-100 p-0" rows={4} name="chiefcomplaints" value={this.state.chiefcomplaints} onChange={this.handleChange}></textarea>

                            <p className="m-0 d-flex">History of Condition (Onset Course & Treatment Prior Occurance) : 
                                <span className="d-flex p-0" style={{ border: "1px solid #747679", width: "30px", height: "30px", justifyContent: "center", alignItems: "center", margin: "0 0 8px 15px", borderRadius: "50%" }} onClick={this.toggleListen1}>{listening ?
                                    <box-icon type="solid" name='microphone' style={{ fill: "red", cursor: "pointer" }}></box-icon>
                                    :
                                    <box-icon type="solid" name='microphone' style={{ fill: "#747679", cursor: "pointer" }}></box-icon>
                                }</span>
                            </p>
                            <textarea className="w-100 p-0" rows={4} name="historyofconoctpo" value={this.state.historyofconoctpo} onChange={this.handleChange}></textarea>
                        </div>

                        <div style={{fontSize:"12px"}} className="mt-3">
                            <p className="m-0"><b>Diurnal Variation : </b></p>
                            <div className="d-flex justify-content-around">
                                <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Morning === true) ? true : false} id="Morningid" name="Morning" value={"Morning"} /> <label htmlFor="Morningid">Morning</label></span>
                                <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Daytime === true) ? true : false} id="Daytimeid" name="Daytime" value={"Daytime"} /> <label htmlFor="Daytimeid">Daytime</label></span>
                                <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Evening === true) ? true : false} id="Eveningid" name="Evening" value={"Evening"} /> <label htmlFor="Eveningid">Evening</label></span>
                                <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Night === true) ? true : false} id="Nightid" name="Night" value={"Night"} /> <label htmlFor="Nightid">Night</label></span>
                                <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Constant === true) ? true : false} id="Constantid" name="Constant" value={"Constant"} /> <label htmlFor="Constantid">Constant</label></span>
                                <span className="d-flex" ><input type={"checkbox"} className="me-1" onChange={this.handleChange} checked={(this.state.Intermittent === true) ? true : false} id="Intermittentid" name="Intermittent" value={"Intermittent"} /> <label htmlFor="Intermittentid">Intermittent</label></span>
                            </div>
                        </div>

                        <div style={{fontSize:"12px"}} className="mt-3">
                            <p className="m-0 mt-2"><b>Medical History : </b> <input type={"text"} name="medicalhistory" value={this.state.medicalhistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"85%"}} /> </p>
                            <p className="m-0 mt-2"><b>Past History : </b> <input type={"text"} name="pasthistory" value={this.state.pasthistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"87%"}} /> </p>
                            <p className="m-0 mt-2"><b>Any Previous Same Episode : </b> <input type={"text"} name="anyprevioussameepis" value={this.state.anyprevioussameepis} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"79%"}} /> </p>
                            <p className="m-0 mt-2"><b>Surgical History : </b> <input type={"text"} name="surgicalhistory" value={this.state.surgicalhistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"85%"}} /> </p>
                            <p className="m-0 mt-2"><b>Treatment / Therapy History : </b> <input type={"text"} name="treattherapyhistory" value={this.state.treattherapyhistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"78%"}} /> </p>
                            <p className="m-0 mt-2"><b>Family History : </b> <input type={"text"} name="familyhistory" value={this.state.familyhistory} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"86%"}} /> </p>
                        </div>

                        <div style={{fontSize:"12px"}} className="mt-4">
                            <p><b>Select a number from 0 to 10 to describe your intensity / severity of pain : </b></p>
                            <div>
                                <div className="d-flex justify-content-between" style={{width:"90%"}}><p>No Pain</p><p>Unbearable Pain</p></div>
                                <input type={"range"} min={0} max={10} step={1}  value={this.state.descintensityofpain} name="descintensityofpain" onChange={(e)=>{this.setState({[e.target.name]:e.target.value})}} style={{width:"90%"}} />
                                <div className="d-flex justify-content-between" style={{width:"90%"}}><p>0</p><p>|</p><p>|</p><p>|</p><p>|</p><p>5</p><p>|</p><p>|</p><p>|</p><p>|</p><p>10</p></div>
                            </div>
                        </div>

                        <div style={{fontSize:"12px"}} className="mt-4 d-flex">
                            <div style={{width:"50%"}}>
                                <p><b>Type of Pain : </b></p>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Aching === true) ? true : false} id="Achingid" name="Aching" value={"Aching"} /><label htmlFor="Achingid">Aching</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Heavy === true) ? true : false} id="Heavyid" name="Heavy" value={"Heavy"} /><label htmlFor="Heavyid">Heavy</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Burning === true) ? true : false} id="Burningid" name="Burning" value={"Burning"} /><label htmlFor="Burningid">Burning</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Itching === true) ? true : false} id="Itchingid" name="Itching" value={"Itching"} /><label htmlFor="Itchingid">Itching</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cramping === true) ? true : false} id="Crampingid" name="Cramping" value={"Cramping"} /><label htmlFor="Crampingid">Cramping</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sharp === true) ? true : false} id="Sharpid" name="Sharp" value={"Sharp"} /><label htmlFor="Sharpid">Sharp</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Deep === true) ? true : false} id="Deepid" name="Deep" value={"Deep"} /><label htmlFor="Deepid">Deep</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Shooting === true) ? true : false} id="Shootingid" name="Shooting" value={"Shooting"} /><label htmlFor="Shootingid">Shooting</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Dull === true) ? true : false} id="Dullid" name="Dull" value={"Dull"} /><label htmlFor="Dullid">Dull</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Throbbing === true) ? true : false} id="Throbbingid" name="Throbbing" value={"Throbbing"} /><label htmlFor="Throbbingid">Throbbing</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Tingling === true) ? true : false} id="Tinglingid" name="Tingling" value={"Tingling"} /><label htmlFor="Tinglingid">Tingling</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Getting_Longer === true) ? true : false} id="Getting_Longerid" name="Getting_Longer" value={"Getting_Longer"} /><label htmlFor="Getting_Longerid">Getting Longer</label></p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Getting_Shorter === true) ? true : false} id="Getting_Shorterid" name="Getting_Shorter" value={"Getting_Shorter"} /><label htmlFor="Getting_Shorterid">Getting Shorter</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Morning_Stiffness === true) ? true : false} id="Morning_Stiffnessid" name="Morning_Stiffness" value={"Morning_Stiffness"} /><label htmlFor="Morning_Stiffnessid">Morning Stiffness</label></p>
                                </div>
                            </div>
                            <div style={{width:"50%"}}>
                                <p><b>Aggravating Factors </b>(What makes your pain worse) : </p>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Activity === true) ? true : false} id="Activityid" name="Activity" value={"Activity"} /><label htmlFor="Activityid">Activity</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Squatting === true) ? true : false} id="Squattingid" name="Squatting" value={"Squatting"} /><label htmlFor="Squattingid">Squatting</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Traveling === true) ? true : false} id="Travelingid" name="Traveling" value={"Traveling"} /><label htmlFor="Travelingid">Traveling</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bendingaggfac === true) ? true : false} id="Bendingaggfacid" name="Bendingaggfac" value={"Bendingaggfac"} /><label htmlFor="Bendingaggfacid">Bending</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lifting === true) ? true : false} id="Liftingid" name="Lifting" value={"Lifting"} /><label htmlFor="Liftingid">Lifting</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Restaggfac === true) ? true : false} id="Restaggfacid" name="Restaggfac" value={"Restaggfac"} /><label htmlFor="Restaggfacid">Rest</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Prolonged_Sitting === true) ? true : false} id="Prolonged_Sittingid" name="Prolonged_Sitting" value={"Prolonged_Sitting"} /><label htmlFor="Prolonged_Sittingid">Prolonged Sitting</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Motion === true) ? true : false} id="Motionid" name="Motion" value={"Motion"} /><label htmlFor="Motionid">Motion</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"></p>
                                    {/* <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} id="Prolonged_Sittingid" name="Prolonged_Sitting" value={"Prolonged_Sitting"} /><label htmlFor="Prolonged_Sittingid">Prolonged Sitting</label></p> */}
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Working === true) ? true : false} id="Workingid" name="Working" value={"Working"} /><label htmlFor="Workingid">Working</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Walking === true) ? true : false} id="Walkingid" name="Walking" value={"Walking"} /><label htmlFor="Walkingid">Walking</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Climbing_Stairs === true) ? true : false} id="Climbing_Stairsid" name="Climbing_Stairs" value={"Climbing_Stairs"} /><label htmlFor="Climbing_Stairsid">Climbing Stairs</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bowel_Movements === true) ? true : false} id="Bowel_Movementsid" name="Bowel_Movements" value={"Bowel_Movements"} /><label htmlFor="Bowel_Movementsid">Bowel Movements</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Exercising === true) ? true : false} id="Exercisingid" name="Exercising" value={"Exercising"} /><label htmlFor="Exercisingid">Exercising</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lying_Downaggfac === true) ? true : false} id="Lying_Downaggfacid" name="Lying_Downaggfac" value={"Lying_Downaggfac"} /><label htmlFor="Lying_Downaggfacid">Lying Down</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Twisting === true) ? true : false} id="Twistingid" name="Twisting" value={"Twisting"} /><label htmlFor="Twistingid">Twisting</label></p>
                                </div>
                            </div>
                        </div>

                        <div style={{fontSize:"12px"}} className="mt-4 d-flex">
                            <div style={{width:"50%"}}>
                                <p><b>Relieing Factors </b>(What makes your pain better) : </p>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Rest === true) ? true : false } id="Restid" name="Rest" value={"Rest"} /><label htmlFor="Restid">Rest</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Changing_Position === true) ? true : false } id="Changing_Positionid" name="Changing_Position" value={"Changing_Position"} /><label htmlFor="Changing_Positionid">Changing Position</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Activityrf === true) ? true : false } id="Activityrfid" name="Activityrf" value={"Activityrf"} /><label htmlFor="Activityrfid">Activity</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bending === true) ? true : false } id="Bendingid" name="Bending" value={"Bending"} /><label htmlFor="Bendingid">Bending</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Exercises === true) ? true : false } id="Exercisesid" name="Exercises" value={"Exercises"} /><label htmlFor="Exercisesid">Exercises</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Ice_Packs === true) ? true : false } id="Ice_Packsid" name="Ice_Packs" value={"Ice_Packs"} /><label htmlFor="Ice_Packsid">Ice Packs</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Medication === true) ? true : false } id="Medicationid" name="Medication" value={"Medication"} /><label htmlFor="Medicationid">Medication</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Hot_Packs === true) ? true : false } id="Hot_Packsid" name="Hot_Packs" value={"Hot_Packs"} /><label htmlFor="Hot_Packsid">Hot Packs</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sitting_Standing === true) ? true : false } id="Sitting_Standingid" name="Sitting_Standing" value={"Sitting_Standing"} /><label htmlFor="Sitting_Standingid">Sitting/Standing</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Using_Brace === true) ? true : false } id="Using_Braceid" name="Using_Brace" value={"Using_Brace"} /><label htmlFor="Using_Braceid">Using Brace</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lying_Down === true) ? true : false } id="Lying_Downid" name="Lying_Down" value={"Lying_Down"} /><label htmlFor="Lying_Downid">Lying Down</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Alcohol === true) ? true : false } id="Alcoholid" name="Alcohol" value={"Alcohol"} /><label htmlFor="Alcoholid">Alcohol</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Stretching === true) ? true : false } id="Stretchingid" name="Stretching" value={"Stretching"} /><label htmlFor="Stretchingid">Stretching</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Relaxation_Therapy === true) ? true : false } id="Relaxation_Therapyid" name="Relaxation_Therapy" value={"Relaxation_Therapy"} /><label htmlFor="Relaxation_Therapyid">Relaxation Therapy</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Others === true) ? true : false } id="Othersid" name="Others" value={"Others"} /><label htmlFor="Othersid">Others</label></p>
                                </div>
                            </div>
                            <div style={{width:"50%"}}>
                                <p><b>Job Profile </b>(Characteristics and Brief Description) : </p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><textarea name="workmech" value={this.state.workmech} onChange={this.handleChange}></textarea></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100">Work : Mechanical Stresses</p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><textarea name="leisuremech" value={this.state.leisuremech} onChange={this.handleChange}></textarea></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100">Leisure : Mechanical Stresses</p>
                            </div>
                        </div>

                        <div style={{fontSize:"12px"}} className="mt-4 d-flex">
                            <div style={{width:"50%"}}>
                                <p><b>Personal History : </b></p>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Sleep : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.sleep_Normal === true) ? true : false} id="sleep_Normalid" name="sleep_Normal" value={"sleep_Normal"} /><label htmlFor="sleep_Normalid">Normal</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Disturbed === true) ? true : false} id="Disturbedid" name="Disturbed" value={"Disturbed"} /><label htmlFor="Disturbedid">Disturbed</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Appetite : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Appetite_Normal === true) ? true : false} id="Appetite_Normalid" name="Appetite_Normal" value={"Appetite_Normal"} /><label htmlFor="Appetite_Normalid">Normal</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Appetite_Reduced === true) ? true : false} id="Appetite_Reducedid" name="Appetite_Reduced" value={"Appetite_Reduced"} /><label htmlFor="Appetite_Reducedid">Reduced</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Bowel : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bowel_Normal === true) ? true : false} id="Bowel_Normalid" name="Bowel_Normal" value={"Bowel_Normal"} /><label htmlFor="Bowel_Normalid">Normal</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bowel_Disturbed === true) ? true : false} id="Bowel_Disturbedid" name="Bowel_Disturbed" value={"Bowel_Disturbed"} /><label htmlFor="Bowel_Disturbedid">Disturbed</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Bladder : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bladder_Normal === true) ? true : false} id="Bladder_Normalid" name="Bladder_Normal" value={"Bladder_Normal"} /><label htmlFor="Bladder_Normalid">Normal</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Bladder_Disturbed === true) ? true : false} id="Bladder_Disturbedid" name="Bladder_Disturbed" value={"Bladder_Disturbed"} /><label htmlFor="Bladder_Disturbedid">Disturbed</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Sleeping Position : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.sleepp_Prone === true) ? true : false} id="sleepp_Proneid" name="sleepp_Prone" value={"sleepp_Prone"} /><label htmlFor="sleepp_Proneid">Prone</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.sleepp_Supine === true) ? true : false} id="sleepp_Supineid" name="sleepp_Supine" value={"sleepp_Supine"} /><label htmlFor="sleepp_Supineid">Supine</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.sleepp_Side_lying === true) ? true : false} id="sleepp_Side_lyingid" name="sleepp_Side_lying" value={"sleepp_Side_lying"} /><label htmlFor="sleepp_Side_lyingid">Side lying R/L</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Surface : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Surface_Firm === true) ? true : false} id="Surface_Firmid" name="Surface_Firm" value={"Surface_Firm"} /><label htmlFor="Surface_Firmid">Firm</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Surface_Soft === true) ? true : false} id="Surface_Softid" name="Surface_Soft" value={"Surface_Soft"} /><label htmlFor="Surface_Softid">Soft</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Surface_Stag === true) ? true : false} id="Surface_Stagid" name="Surface_Stag" value={"Surface_Stag"} /><label htmlFor="Surface_Stagid">Stag</label></p>
                                </div>
                            </div>
                            <div style={{width:"50%"}} className="d-flex">
                                <div style={{width:"50%"}}>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Personal Habits : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Smoking === true) ? true : false } id="Smokingid" name="Smoking" value={"Smoking"} /><label htmlFor="Smokingid">Smoking</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.phAlcohol === true) ? true : false } id="phAlcoholid" name="phAlcohol" value={"phAlcohol"} /><label htmlFor="phAlcoholid">Alcohol</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Tobacco_Chewing === true) ? true : false } id="Tobacco_Chewingid" name="Tobacco_Chewing" value={"Tobacco_Chewing"} /><label htmlFor="Tobacco_Chewingid">Tobacco Chewing/Use</label></p>
                                </div>
                                <div style={{width:"50%"}}>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Vechile You Drive : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Four_Wheeler === true) ? true : false } id="Four_Wheelerid" name="Four_Wheeler" value={"Four_Wheeler"} /><label htmlFor="Four_Wheelerid">Four Wheeler</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Two_Wheeler === true) ? true : false } id="Two_Wheelerid" name="Two_Wheeler" value={"Two_Wheeler"} /><label htmlFor="Two_Wheelerid">Two Wheeler</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.vydTraveling === true) ? true : false } id="vydTravelingid" name="vydTraveling" value={"vydTraveling"} /><label htmlFor="vydTravelingid">Traveling</label></p>
                                </div>
                            </div>
                        </div>

                        <div style={{fontSize:"12px"}} className="mt-4">
                            <p className="m-0 mt-2"><b>Psycho - Social History : </b> <input type={"text"} name="psychosochis" value={this.state.psychosochis} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"80%"}} /> </p>
                            <p className="m-0 mt-2"><b>Drugs & Chemicals : </b> <input type={"text"} name="drugsachecm" value={this.state.drugsachecm} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"82%"}} /> </p>
                            <p className="m-0 mt-2"><b>Home Hobby Recreational or Occupational Factors : </b> <input type={"text"} name="homehubbyrecoccfac" value={this.state.homehubbyrecoccfac} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"65%"}} /> </p>
                            <p className="m-0 mt-2"><b>Secondary Complaints : </b> <input type={"text"} name="secondcompl" value={this.state.secondcompl} onChange={this.handleChange} className="m-0 p-0" style={{border:"0",borderBottom:"1px solid #000",width:"80%"}} /> </p>
                        </div>

                        <p className="m-0 mt-4"><b>POSTURE </b></p>
                        <div style={{fontSize:"12px"}} className="d-flex">
                            <div style={{width:"50%"}}>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Sitting : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sitting_Good === true) ? true : false} id="Sitting_Goodid" name="Sitting_Good" value={"Sitting_Good"} /><label htmlFor="Sitting_Goodid">Good</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sitting_Fair === true) ? true : false} id="Sitting_Fairid" name="Sitting_Fair" value={"Sitting_Fair"} /><label htmlFor="Sitting_Fairid">Fair</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Sitting_Poor === true) ? true : false} id="Sitting_Poorid" name="Sitting_Poor" value={"Sitting_Poor"} /><label htmlFor="Sitting_Poorid">Poor</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Lordosis (Cervical) : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cervical_Red === true) ? true : false} id="Cervical_Redid" name="Cervical_Red" value={"Cervical_Red"} /><label htmlFor="Cervical_Redid">Red</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cervical_Acc === true) ? true : false} id="Cervical_Accid" name="Cervical_Acc" value={"Cervical_Acc"} /><label htmlFor="Cervical_Accid">Acc</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Cervical_Normal === true) ? true : false} id="Cervical_Normalid" name="Cervical_Normal" value={"Cervical_Normal"} /><label htmlFor="Cervical_Normalid">Normal</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Lateral Shift : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Laterals_Right === true) ? true : false} id="Laterals_Rightid" name="Laterals_Right" value={"Laterals_Right"} /><label htmlFor="Laterals_Rightid">Right</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Laterals_Left === true) ? true : false} id="Laterals_Leftid" name="Laterals_Left" value={"Laterals_Left"} /><label htmlFor="Laterals_Leftid">Left</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Laterals_Nill === true) ? true : false} id="Laterals_Nillid" name="Laterals_Nill" value={"Laterals_Nill"} /><label htmlFor="Laterals_Nillid">Nill</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Correction of Posture : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.cop_Better === true) ? true : false} id="cop_Betterid" name="cop_Better" value={"cop_Better"} /><label htmlFor="cop_Betterid">Better</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.cop_Worse === true) ? true : false} id="cop_Worseid" name="cop_Worse" value={"cop_Worse"} /><label htmlFor="cop_Worseid">Worse</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.cop_no_effect === true) ? true : false} id="cop_no_effectid" name="cop_no_effect" value={"cop_no_effect"} /><label htmlFor="cop_no_effectid">No Effect</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Revelant : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.revelant_Yes === true) ? true : false} id="revelant_Yesid" name="revelant_Yes" value={"revelant_Yes"} /><label htmlFor="revelant_Yesid">Yes</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.revelant_No === true) ? true : false} id="revelant_Noid" name="revelant_No" value={"revelant_No"} /><label htmlFor="revelant_Noid">No</label></p>
                                </div>
                            </div>
                            <div style={{width:"50%"}}>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Standing : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Standing_Good === true) ? true : false } id="Standing_Goodid" name="Standing_Good" value={"Standing_Good"} /><label htmlFor="Standing_Goodid">Good</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Standing_Fair === true) ? true : false } id="Standing_Fairid" name="Standing_Fair" value={"Standing_Fair"} /><label htmlFor="Standing_Fairid">Fair</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Standing_Poor === true) ? true : false } id="Standing_Poorid" name="Standing_Poor" value={"Standing_Poor"} /><label htmlFor="Standing_Poorid">Poor</label></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><b>Lordosis (Lumbar) : </b></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lumbar_Red === true) ? true : false } id="Lumbar_Redid" name="Lumbar_Red" value={"Lumbar_Red"} /><label htmlFor="Lumbar_Redid">Red</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lumbar_Acc === true) ? true : false } id="Lumbar_Accid" name="Lumbar_Acc" value={"Lumbar_Acc"} /><label htmlFor="Lumbar_Accid">Acc</label></p>
                                    <p className="m-0 mb-1 d-flex align-items-center w-100"><input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Lumbar_Normal === true) ? true : false } id="Lumbar_Normalid" name="Lumbar_Normal" value={"Lumbar_Normal"} /><label htmlFor="Lumbar_Normalid">Normal</label></p>
                                </div>
                            </div>
                        </div>

                        <hr />

                        
                        

                        {
                            (this.state.orthoforms) ? (
                                <div className="row mb-3 mt-3">
                                    <div className="col-sm-12 d-flex justify-content-center">
                                        <button className="btn btn-primary btn-sm" onClick={this.orthoneuroforma}>CERVICAL SPINE & UPPERLIMB EXAMINATION</button>
                                        <button className="btn btn-primary btn-sm mx-3" onClick={this.orthoneuroformb}>LUMBAR SPINE & UPPERLIMB EXAMINATION</button>
                                        {/* <button className="btn btn-primary btn-sm" onClick={this.orthoneuroforma}>Ortho & Neuro Exam From - A</button>
                                        <button className="btn btn-primary btn-sm mx-3" onClick={this.orthoneuroformb}>Ortho & Neuro Exam From - B</button> */}
                                    </div>
                                </div>
                            ) : (<>
                            <div className="row mt-2">
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                            </>)
                        }

                    </form>





                    {/* {(this.state.orthoneuroform) ? (
                      <div className="row mb-3">
                          <div className="col-sm-12">
                              <button className="btn btn-primary btn-sm" onClick={this.orthoneuroforma}>Ortho & Neuro Exam From - A</button>
                              <button className="btn btn-primary btn-sm mx-3" onClick={this.orthoneuroformb}>Ortho & Neuro Exam From - B</button>
                          </div>
                      </div>
                    ) : (<></>) } */}




                    {/* <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={this.handleSubmitcons}>
                                <h6>FOR DOCTOR USE ONLY</h6>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">History</label>
                                    <span className="d-flex p-0" style={{ border: "1px solid #747679", width: "40px", height: "40px", justifyContent: "center", alignItems: "center", margin: "0 0 8px 15px", borderRadius: "50%" }} onClick={this.toggleListen1}>{listening ?
                                        <box-icon type="solid" name='microphone' style={{ fill: "red", cursor: "pointer" }}></box-icon>
                                        :
                                        <box-icon type="solid" name='microphone' style={{ fill: "#747679", cursor: "pointer" }}></box-icon>
                                    }</span>
                                    <div className="col-sm-12">
                                        <textarea className="form-control" rows="5" name="phistory" value={this.state.phistory} onChange={this.handleChange}></textarea>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Observation</label>
                                    <span className="d-flex p-0" style={{ border: "1px solid #747679", width: "40px", height: "40px", justifyContent: "center", alignItems: "center", margin: "0 0 8px 15px", borderRadius: "50%" }} onClick={this.toggleListen2}>{listening1 ?
                                        <box-icon type="solid" name='microphone' style={{ fill: "red", cursor: "pointer" }}></box-icon>
                                        :
                                        <box-icon type="solid" name='microphone' style={{ fill: "#747679", cursor: "pointer" }}></box-icon>
                                    }</span>
                                    <div className="col-sm-12">
                                        <textarea className="form-control" rows="5" name="pobservation" value={this.state.pobservation} onChange={this.handleChange}></textarea>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>
            </>
        )
    }
}