import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import DataTable from 'react-data-table-component'
import axios from "axios";
import Modal from 'react-modal';
import swal from "sweetalert";

export default class Appointmentpd extends Component {

    constructor(props){ 
        super(props);
        this.state = {
            findallpapp : [],
            columns : [
                // {
                //     name:"Appoitment ID",
                //     selector:(row)=>row.appointment_id,
                //     width:"50px",
                //     margin:"0px"
                // },
                {
                    name:"Department",
                    selector:(row)=>row.dept_name,
                    wrap:true
                },
                {
                    name:"Schedule Date",
                    selector:(row)=>row.schedule_date
                },
                {
                    name:"Start and End Time",
                    selector:(row)=>row.start_time+" - "+row.end_time,
                    wrap:true
                },
                {
                    name:"Mode",
                    selector:(row)=>{ if(row.mode == "inclinic"){ return(<>In-Clinic</>) } if(row.mode == "videocons"){ return(<>Video Consultation</>) } }
                },
                {
                    name:"Status",
                    selector:(row)=>{if(row.astatus === "P"){return(<span className="badgesecond badge-warning">Pending</span>)}if(row.astatus === "C"){return(<span className="badgesecond badge-danger">Canceled</span>)}if(row.astatus === "A"){return(<span className="badgesecond badge-success">Approved</span>)}if(row.astatus === "R"){return(<span className="badgesecond badge-reschdule">Re-Schedule</span>)}    },
                    width:"100px",
                },
                {
                    name:"Consultaion Type",
                    cell:(row)=>{
                        return(<>
                            <select className="form-select p-0 px-3" value={row.consulttype} style={{fontSize:"13px"}} onChange={(e)=>this.changeconsulttype(row.appointment_id,e)}>
                                <option value={"T"}>Treatment</option>
                                <option value={"C"}>Consultation</option>
                                <option value={"TC"}>Treatment & Consultation</option>
                            </select>
                        </>)
                    }
                },
                {
                    name:"Action",
                    cell:(row)=>{return(<>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <box-icon style={{cursor:"pointer",marginLeft:"5px"}} name='show' alt="View" title="View" onClick={()=>this.viewmodel(row)} ></box-icon>
                                                {(row.consulttype === "C" || row.consulttype === "TC") ? (<>
                                                    {(row.dept_name==="Dietitian")?(<button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push( "/patient_dashboard/"+this.props.match.params.puid+"/adddietdeatilform/"+row.appointment_id)}}>View Details</button>):(<></>)}
                                                    {(row.dept_name==="Physiotherapy")?(<button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push( "/patient_dashboard/"+this.props.match.params.puid+"/addphysotherapydeatilform/"+row.appointment_id)}}>View Details</button>):(<></>)}
                                                    </>) : (<></>) }
                                            </div>
                                        </>)},
                    width:"180px",
                    margin:"0px",
                    padding:"0px"
                },
            ],
            results : [],
            modalIsOpen : false,
            customStyles : {
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%'
                },
            },
            reschudlemod : false,
            acceptmod : false,
            cancelmod : false,
            fromtoarr : [],
        }

        // console.log(this.props)

        this.fetchappdata();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitmodal = this.submitmodal.bind(this);
    }

    changeconsulttype = (appointment_id,e) =>{
        // console.log(appointment_id)
        // console.log(e.target.value)
        axios.post("https://clinic.dnyanasha.com/ws/appointment.php",{changeconsulttype:"yes",appointment_id:appointment_id,consulttype:e.target.value}).then((res)=>{
            if(res.data.updateconsulttype){
                this.fetchappdata();
            }
        })
    }

    handleChange = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name] : value })
        if(name == "astatus")
        {
            if(value == "")
            {
                this.setState({ cancelmod : false })
                this.setState({ acceptmod : false })
                this.setState({ reschudlemod : false })
            }
        }
        if(name === "schedule_date")
        {
            var selectdate = new Date(value).setHours(0,0,0,0);
            var todaydate = new Date().setHours(0,0,0,0);
            if(selectdate > todaydate){
                // this.setState({datemesg : "greater" })
                if(this.state.doctor_id == ""){this.setState({datemesg : "Kindly Select Doctor...!" })} else{
                    axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{checkdocsch:"yes",doctor_id:this.state.doc_id,selectdate:value}).then((res)=>{
                        // console.log(res.data);
                        this.setState({ datemesg : res.data.status })
                        this.setState({ fromtoarr : res.data.fromtoarr })
                    })
                }
            } else if(selectdate === todaydate){
                // console.log("equal")
                // this.setState({datemesg : "equal" })
                axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{checkdocsch:"yes",doctor_id:this.state.doctor_id,selectdate:event.target.value}).then((res)=>{
                    // console.log(res.data);
                    this.setState({ datemesg : res.data.status })
                    this.setState({ fromtoarr : res.data.fromtoarr })
                })
            } else if(selectdate < todaydate) {
                // console.log("less")
                this.setState({datemesg : "Can't Enter Previous date...!" })
            } else {
                // console.log("enter")
                this.setState({datemesg : "Kindly Enter the date...!" })
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const datare = {
            reschduleappoitment : 'yes',
            rescheduleddate : this.state.rescheduleddate,
            rescheduledtime : this.state.rescheduledtime,
            fortime : this.state.fortime,
        }
        // console.log(datare)
        axios.post('https://clinic.dnyanasha.com/ws/appointment.php',datare).then((res)=>{
            // console.log(res.data)
        })
    }

    submitmodal = (event) =>{
        event.preventDefault();
        console.log(this.state.astatus)
        if(this.state.astatus == "A")
        {
            this.setState({ acceptmod : true })
            this.setState({ reschudlemod : false })
            this.setState({ cancelmod : false })
            axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{changestatus:'yes',appointment_id:this.state.appointment_id,status:this.state.astatus}).then((res)=>{
                if(res.data.updatestatus == true)
                {
                    swal("Appointment Updated", "Appointment Updated Successfully..!", "success").then((swalres)=>{
                        
                    });
                }
            })
        }
        else if(this.state.astatus == "C")
        {
            this.setState({ cancelmod : true })
            this.setState({ acceptmod : false })
            this.setState({ reschudlemod : false })
            axios.post('https://clinic.dnyanasha.com/ws/appointment.php',{changestatus:'yes',appointment_id:this.state.appointment_id,status:this.state.astatus}).then((res)=>{
                if(res.data.updatestatus == true)
                {
                    swal("Appointment Updated", "Appointment Updated Successfully..!", "success").then((swalres)=>{
                        
                    });
                }
            })
        }
        else if(this.state.astatus == "R")
        {
            this.setState({ reschudlemod : true })
            this.setState({ acceptmod : false })
            this.setState({ cancelmod : false })
        }
        else
        {
            this.setState({ cancelmod : false })
            this.setState({ acceptmod : false })
            this.setState({ reschudlemod : false })
        }

    }

    fetchappdata = () =>{
        const data = {
            fetchpappdata : 'yes',
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/appointment.php',data).then((res)=>{
            console.log(res.data)
            this.setState({findallpapp:res.data.findallpapp})
            this.setState({results:res.data.findallpapp})
        })
    }

    setSearch = (svalue) => {
        if(svalue == "" || svalue == undefined || svalue == null ){
            this.setState({search:svalue})
            this.setState({results:this.state.findallpapp})
        }
        else {
            if(!isNaN(svalue)){
                var result = this.state.findallpapp.filter((resfilter)=>{
                    this.setState({search:svalue.toLowerCase()});
                    return resfilter.schedule_date.toLowerCase().match(this.state.search);
                });
                this.setState({results:result})
            }
        }
    }

    viewmodel = (item) =>{
        console.log(item)
        this.setState({appointment_id : item.appointment_id})
        this.setState({schedule_date : item.schedule_date})
        this.setState({start_time : item.start_time})
        this.setState({end_time : item.end_time})
        this.setState({dept_name : item.dept_name})
        this.setState({doctor_name : item.doctor_name})
        this.setState({doc_id : item.doc_id})
        this.setState({ modalIsOpen : true })
    }

    closeModal = () => {
        this.setState({ modalIsOpen : false })
        this.fetchappdata();
    }

    openappdetails = (row) =>{
        // console.log("open")
        // console.log(row)
        if(row.consulttype === "C" || row.consulttype === "TC"){
            if(row.dept_name==="Dietitian"){
                this.props.history.push( "/patient_dashboard/"+this.props.match.params.puid+"/adddietdeatilform/"+row.appointment_id)
            }
            if(row.dept_name==="Physiotherapy"){
                this.props.history.push( "/patient_dashboard/"+this.props.match.params.puid+"/addphysotherapydeatilform/"+row.appointment_id)
            }
        }
    }

    render(){ 
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />

                <div style={{marginLeft:"220px",padding:"5px"}}>
                    <div className="row">
                        <div className="col-sm-12">
                            <DataTable
                                columns={this.state.columns} 
                                data={this.state.results} 
                                style={{textAlign:"center"}}
                                // title="All Doctors"
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight='450px'
                                highlightOnHover
                                subHeader
                                onRowClicked={this.openappdetails}
                                subHeaderComponent={
                                    <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                                        <div style={{fontSize:"22px"}}>All Appointments</div>
                                        <input 
                                            type="text"
                                            placeholder='search here'
                                            className='w-25 form-control'
                                            value={this.state.search}
                                            onChange={(e)=>this.setSearch(e.target.value)}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-sm-12">

                        </div>
                    </div> */}

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="View Appointment Modal"
                        ariaHideApp={false}
                    >
                        <h5>View Appointment</h5>
                        <div className="row">
                            <div className="col-sm-10">
                                <div className="form-group">
                                    <label className="form-check-label">Appoitment</label>
                                    <select className="form-control" name="astatus" onChange={this.handleChange}>
                                        <option value="">Select Status</option>
                                        {
                                        (localStorage.getItem("user") == "Patient") ? (<>
                                            <option value="C">Canceled</option>
                                            {
                                                (new Date(this.state.schedule_date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) ? (<></>) : (
                                                    <option value="R">Re-Schedule</option>
                                                )
                                            }

                                     </>) : (<>
                                        <option value="C">Canceled</option>
                                        {
                                            (new Date(this.state.schedule_date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) ? (<></>) : (
                                                <option value="R">Re-Schedule</option>
                                            )
                                        }
                                        <option value="P">Pending</option>
                                        <option value="A">Approved</option>
                                     </>)
                                    }
                                    </select>
                                </div>
                            </div>


                            <div className="col-sm-2" style={{display:"flex",alignItems:"flex-end"}}>
                                <button type="submit" className="btn btn-primary" onClick={this.submitmodal}>Submit</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                {
                                    (this.state.reschudlemod == true) ? (<>
                                        <div className="row mt-3">
                                            <div className="col-sm-12"><div className="text-center text-danger fw-bold">Re-Schedule</div></div>
                                        </div>
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Scheduled Date : </label>
                                                        <input type="date" className="form-control" name="schedule_date" onChange={this.handleChange} value={this.state.schedule_date}/>
                                                        <p className="text-danger fw-bold form-control-sm m-0">{this.state.datemesg}</p>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form-check-label">Available Slot <span style={{color:"red"}}>*</span></label>
                                                    <select className="form-select" name="availableslot" onChange={this.handleChange} required>
                                                        <option value="">Select Slot</option>
                                                        {
                                                            this.state.fromtoarr.map((res,index)=>{
                                                                return(
                                                                    <option key={index} value={res}>{res}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                {/* <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Time : </label>
                                                        <input type="time" className="form-control" name="rescheduledtime" onChange={this.handleChange} value={this.state.start_time}/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>For : </label>
                                                        <select className="form-select" name="fortime" onChange={this.handleChange}  required>
                                                            <option value="">Select</option>
                                                            <option value="15">15 min</option>
                                                            <option value="30">30 min</option>
                                                            <option value="60">1 Hours</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-sm-12 mt-2">
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </>) : (<></>)
                                }
                                {
                                    (this.state.acceptmod == true) ? (<>
                                        <div className="row mt-3">
                                            <div className="col-sm-12">
                                                <div className="text-center text-success fw-bold">Appoitment Successfully Accepted</div>
                                            </div>

                                        <div className="row mt-2">
                                            <div className="col-sm-3"><p>Department : </p></div>
                                            <div className="col-sm-3"><p>{ this.state.dept_name }</p></div>
                                            <div className="col-sm-2"><p>Doctor : </p></div>
                                            <div className="col-sm-4"><p>{ this.state.doctor_name }</p></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3"><p>Scheduled At : </p></div>
                                            <div className="col-sm-3"><p>{ this.state.schedule_date }</p></div>
                                            <div className="col-sm-2"><p>Time : </p></div>
                                            <div className="col-sm-4"><p>{ this.state.start_time } - { this.state.end_time }</p></div>
                                        </div>

                                        </div>
                                    </>) : (<></>)
                                }
                                {
                                    (this.state.cancelmod == true) ? (<>
                                        <div className="row mt-3">
                                            <div className="col-sm-12"><div className="text-center text-danger fw-bold">You'r Appointment is Canceled</div></div>
                                        </div>
                                        
                                        <div className="row mt-2">
                                            <div className="col-sm-3"><p>Department : </p></div>
                                            <div className="col-sm-3"><p>{ this.state.dept_name }</p></div>
                                            <div className="col-sm-2"><p>Doctor : </p></div>
                                            <div className="col-sm-4"><p>{ this.state.doctor_name }</p></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3"><p>Scheduled At : </p></div>
                                            <div className="col-sm-3"><p>{ this.state.schedule_date }</p></div>
                                            <div className="col-sm-2"><p>Time : </p></div>
                                            <div className="col-sm-4"><p>{ this.state.start_time } - { this.state.end_time }</p></div>
                                        </div>
                                        
                                    </>) : (<></>)
                                }
                            </div>
                        </div>
                    </Modal>
                </div>

            </>
        )
    }
}