import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";

import Logog from '../assets/Core-Concept-Logo.png';
import Printback from '../assets/printback.png';
import Printheader from '../assets/printheaderdiet.png';
import Printfooter from '../assets/printfooter.png';
import Modal from 'react-modal';

export default class Adddietplannew extends Component{

    constructor(props){
        super(props);
        this.state = {
            rows: [{time: "",meal: "",menu: ""}],
            pname : "",
            anthropometric : {},
            fees : 1
            // dietcheckmodel : false,
            // customStyles : {
            //     content: {
            //       top: '50%',
            //       left: '50%',
            //       right: 'auto',
            //       bottom: 'auto',
            //       marginRight: '-50%',
            //       transform: 'translate(-50%, -50%)',
            //       width: '50%'
            //     },
            //   },
        }

        this.fetchdietplandata();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangevalue = this.handleChangevalue.bind(this);
    }

    fetchdietplandata = () =>{
        const dietplandata = {
            fetchdietplandata : "yes",
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',dietplandata).then((res)=>{
            console.log(res.data)
            this.setState({ rows : JSON.parse(res.data.finddietplan.dietplandata) })
            this.setState({ notes : res.data.finddietplan.notes })
            this.setState({ fromdate : res.data.finddietplan.fromdate })
            this.setState({ todate : res.data.finddietplan.todate })
            this.setState({ daysmesaure : res.data.finddietplan.results_days })
            this.setState({ anthropometric : JSON.parse(res.data.finddietplan.mesurments) })
            this.setState({ pname : res.data.findpid['patient_name'] })
        })
    }

    handleChangevalue = (e) =>{
        var value = e.target.value;
        const am = this.state.anthropometric;
        am[value] = e.target.checked;
        this.setState({anthropometric:am})
    }

    handleChange = idx => e => {
        const { name, value } = e.target;
        console.log(name + " = " + value)
        const rows = [...this.state.rows];
        let crow = rows[idx]
        crow[name] = value;
        rows[idx] = crow
        this.setState({ rows });
    };

    handleAddRow = () => {
        const item = {
            time: "",
            meal: "",
            menu: ""
        };
        this.setState({ rows: [...this.state.rows, item] });
    }

    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }

    handleSubmit = () =>{
        console.log(this.state.rows)
        console.log(this.state.anthropometric)
        const datasavedietplan = {
            savediteplan : "yes",
            rows : this.state.rows,
            puid : this.props.match.params.puid,
            appid : this.props.match.params.appid,
            notes : this.state.notes,
            fromdate : this.state.fromdate,
            todate : this.state.todate,
            daysmesaure : this.state.daysmesaure,
            anthropometric : this.state.anthropometric,
            fees : this.state.fees,
        }
        console.log(datasavedietplan)
        axios.post('https://clinic.dnyanasha.com/ws/treatmentsplans.php',datasavedietplan).then((res)=>{
            console.log(res.data)
            if(res.data.savedietplan > 0){
                swal("Diet Plan Added", "Diet Plan Added Successfully..!", "success").then((swalres)=>{
                    this.fetchdietplandata();
                });
            }
            if(res.data.updatedietplan === true){
                swal("Diet Plan Updated", "Diet Plan Updated Successfully..!", "success").then((swalres)=>{
                    this.fetchdietplandata();
                });
            }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    // opendietcheckmodel = () =>{
    //     this.setState({ dietcheckmodel : true })
    // }

    // closeModal = () => {
    //     this.setState({ dietcheckmodel : false })
    // }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .borderonprint {
                                            border : none !important;
                                            background : none;
                                        }
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 100px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 70%;
                                            height: 100px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">${printContents}</div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>Coreconcept</p></div>
                                        `;
        setTimeout(() => {
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload();
        }, 1)
    }

    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"226px",padding:"5px"}}>

                    <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-primary btn-sm backbtn my-2" onClick={this.goBack}><box-icon name='arrow-back' className="backicon" color="#fff"></box-icon> Back</button>
                        <button className="btn btn-primary btn-sm my-2" onClick={this.printform} >Print</button>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="from-group">
                                <label className="form-label"> <b> Fees * </b></label>
                                <input type={'number'} min={1} className="form-control " name="fees" value={this.state.fees} onChange={(e)=>{ this.setState({ [e.target.name] : e.target.value }) }}/>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div id="printablediv">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 className="text-decoration-underline text-center"><b>WEIGHT LOSS DIET PLAN</b></h4>
                            </div>
                            <div className="w-50">
                                <p className="d-flex align-items-center">Patient Name : <b className="ms-2"> { this.state.pname }</b></p>
                            </div>
                            <div className="w-50">
                                <p> Date: <b className="ms-2"> {new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()}</b></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <button className="btn btn-primary btn-sm mb-3 hideonprint" onClick={this.handleAddRow}>Add</button>
                                <table className="table table-bordered table-responsive">
                                    <thead style={{ borderWidth: "1px 1px 1px 1px", textAlign: "center" }}>
                                        <tr>
                                            <th></th>
                                            <th>TIMING</th>
                                            <th>MEAL</th>
                                            <th>MENU</th>
                                            <th className="hideonprint"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <td>0</td>
                                            <td><input type={"time"} className="form-control w-75 m-auto" /></td>
                                            <td><input type={"text"} className="form-control" /></td>
                                            <td><input type={"text"} className="form-control" /></td>
                                        </tr> */}
                                        {this.state.rows.map((item, idx) => {
                                            return(
                                                    <tr>
                                                        <td>{idx + 1}</td>
                                                        <td><input type={"time"} onChange={this.handleChange(idx)} name="time" value={this.state.rows[idx].time} className="form-control m-auto borderonprint" /></td>
                                                        <td><input type={"text"} onChange={this.handleChange(idx)} name="meal" value={this.state.rows[idx].meal} className="form-control borderonprint" /></td>
                                                        {/* <td><input type={"text"} onChange={this.handleChange(idx)} name="menu" value={this.state.rows[idx].menu} className="form-control" /></td> */}
                                                        <td><textarea onChange={this.handleChange(idx)} name="menu" value={this.state.rows[idx].menu} className="form-control borderonprint"></textarea></td>
                                                        <td className="hideonprint"><span className="btn btn-sm btn-danger" onClick={this.handleRemoveSpecificRow(idx)}>Remove Row</span></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="from-group">
                                    <label className="form-label"> <b> Notes * </b></label>
                                    <textarea className="form-control w-100 borderonprint" name="notes" value={this.state.notes} onChange={(e)=>{ this.setState({ [e.target.name] : e.target.value }) }}></textarea>
                                </div>
                                <div className="row mt-2 hideonprint">
                                    <div className="col-sm-6">
                                        <div className="from-group">
                                            <label className="form-label"> <b> From Date * </b></label>
                                            <input type={'date'} className="form-control" name="fromdate" value={this.state.fromdate} onChange={(e)=>{ this.setState({ [e.target.name] : e.target.value }) }} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="from-group">
                                            <label className="form-label"> <b> To Date * </b></label>
                                            <input type={'date'} className="form-control" name="todate" value={this.state.todate} onChange={(e)=>{ this.setState({ [e.target.name] : e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 hideonprint">
                                    <div className="col-sm-6">
                                        <div className="from-group">
                                            <label className="form-label"> <b> Measure in Days * </b></label>
                                            <input type={'number'} min={1} className="form-control " name="daysmesaure" value={this.state.daysmesaure} onChange={(e)=>{ this.setState({ [e.target.name] : e.target.value }) }}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2 hideonprint">
                                    <div className="col-sm-6">
                                        {/* <label className="form-label"> <b> Get Input of Anthropometric: * <span className="btn btn-primary btn-sm" onClick={this.opendietcheckmodel}>Edit</span> </b></label> */}
                                        <label className="form-label"> <b> Get Input of Anthropometric: *</b></label>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="weightid" name="anthropometric" value={"weight"} checked={this.state.anthropometric["weight"]} /><label htmlFor="weightid"> Weight </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="heightid" name="anthropometric" value={"height"} checked={this.state.anthropometric["height"]} /><label htmlFor="heightid"> Height </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="upperarmid" name="anthropometric" value={"upperarm"} checked={this.state.anthropometric["upperarm"]} /><label htmlFor="upperarmid"> Upper Arm </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="chestid" name="anthropometric" value={"chest"} checked={this.state.anthropometric["chest"]} /><label htmlFor="chestid"> Chest </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="waistid" name="anthropometric" value={"waist"} checked={this.state.anthropometric["waist"]} /><label htmlFor="waistid"> Waist </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="lowerabdomenid" name="anthropometric" value={"lowerabdomen"} checked={this.state.anthropometric["lowerabdomen"]} /><label htmlFor="lowerabdomenid"> Lower Abdomen </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="hipsid" name="anthropometric" value={"hips"} checked={this.state.anthropometric["hips"]} /><label htmlFor="hipsid"> Hips </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="thighid" name="anthropometric" value={"thigh"} checked={this.state.anthropometric["thigh"]} /><label htmlFor="thighid"> Thigh </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="calfid" name="anthropometric" value={"calf"} checked={this.state.anthropometric["calf"]} /><label htmlFor="calfid"> Calf </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="bmiid" name="anthropometric" value={"bmi"} checked={this.state.anthropometric["bmi"]} /><label htmlFor="bmiid"> BMI </label></p>
                                        <p className="d-flex align-items-center m-0"><input type={"checkbox"} onChange={this.handleChangevalue} className="mx-2" id="ibwtid" name="anthropometric" value={"ibwt"} checked={this.state.anthropometric["ibwt"]} /><label htmlFor="ibwtid"> IBW </label></p>
                                        {/* <p className="d-flex align-items-center m-0"> <input type={"checkbox"} className="mx-2" onChange={this.handleChange} checked={(this.state.Kidney === "1") ? true : false} value="Kidney" id="Kidneyid" name="Kidney" /> <label htmlFor="Kidneyid"> k. Kidney Problem </label></p> */}
                                    </div>
                                </div>


                                <button className="btn btn-primary mt-3 hideonprint" onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>

                    {/* <Modal
                        isOpen={this.state.dietcheckmodel}
                        onRequestClose={this.closeModal}
                        style={this.state.customStyles}
                        contentLabel="Department Modal"
                        ariaHideApp={false}
                    >
                        <h5>Diet Patient Inputs of Anthropometric</h5>
                    </Modal> */}

                </div>
            </>
        )
    }
}