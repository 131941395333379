import React from "react";
import axios from "axios";
import swal from 'sweetalert';
import DataTable from 'react-data-table-component'
import Modal from 'react-modal';

export default class AddTherapy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            findallreceipt : [],
            billedarr : [],
            unbilledarr : [],
            todaydate : new Date().toISOString().substr(0, 10),
            modeofpay : ""
        }

        this.fetchreportdata()
    }

    fetchreportdata = () =>{
        axios.post("https://clinic.dnyanasha.com/ws/report.php",{fetchreportdata : "yes",todaydate:this.state.todaydate,modeofpay:this.state.modeofpay}).then((res)=>{
            console.log(res.data)
            this.setState({findallreceipt:res.data.findallreceipt})
            this.setState({billedarr:res.data.billedarr})
            this.setState({unbilledarr:res.data.unbilledarr})
        })
    }

    render(){
        var totalbilledamt = 0;
        var totalunbilledamt = 0;
        return(
            <>
                <div className="row">
                    <div className="col-sm-6">
                        
                        <div className="d-flex align-items-center mb-3">
                            <h5>Daily Report</h5>
                            <input className="form-control mx-2 w-25" type={"date"} name="todaydate" value={this.state.todaydate} onChange={(event)=>this.setState({ todaydate: event.target.value })} />
                            <select className="form-control mx-2 w-25" name="modeofpay" onChange={(e)=>{this.setState({ modeofpay : e.target.value })}}>
                                <option value={""}>Select Mode of Payment</option>
                                <option value={"cheque"}>Cheque</option>
                                <option value={"card"}>Card</option>
                                <option value={"cash"}>Cash</option>
                                <option value={"upi"}>UPI</option>
                            </select>
                            <button className="btn btn-primary btn-sm" onClick={this.fetchreportdata}>Show</button>
                        </div>
                        
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <b>Billed Report</b>
                                {/* <input className="form-control mx-2 w-25" type={"date"} name="todaydate" value={this.state.todaydate} onChange={(event)=>this.setState({ todaydate: event.target.value })} />
                                <button className="btn btn-primary btn-sm" onClick={this.fetchreportdata}>Show</button> */}
                            </div>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr style={{fontSize:"13px"}}>
                                            <th>Patient Name</th>
                                            <th>Treatment</th>
                                            <th>Pay Mode</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.billedarr.map((item,index)=>{
                                                totalbilledamt = totalbilledamt + item.totalamt;
                                                return(
                                                    <tr key={index} style={{fontSize:"13px"}}>
                                                        <td>{item.pname}</td>
                                                        <td  dangerouslySetInnerHTML={{ __html: item.treatname}}>{}</td>
                                                        <td>{item.modeofpay}</td>
                                                        <td>{item.totalamt}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer">
                                <p>Total Amount : <b>{totalbilledamt}</b></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6" style={{marginTop:"32px"}}>
                        <div className="card">
                            <div className="card-header">
                                <b>Un-Billed Report</b>
                            </div>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr style={{fontSize:"13px"}}>
                                            <th>Patient Name</th>
                                            <th>Treatment</th>
                                            <th>Pay Mode</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.unbilledarr.map((item,index)=>{
                                                totalunbilledamt = totalunbilledamt + item.totalamt;
                                                return(
                                                    <tr key={index} style={{fontSize:"13px"}}>
                                                        <td>{item.pname}</td>
                                                        <td  dangerouslySetInnerHTML={{ __html: item.treatname}}>{}</td>
                                                        <td>{item.modeofpay}</td>
                                                        <td>{item.totalamt}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer">
                                <p>Total Amount : <b>{totalunbilledamt}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
