import axios from "axios";
import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import swal from "sweetalert";

export default class Communication extends Component{

    constructor(props){
        super(props);
        this.state = {
            findpdetail:[],
            findallcommunication : [],
            commfile : null,
            fileurlhost : "https://clinic.dnyanasha.com/ws/uploads/communicationfiles/",
            commessage : ""
        }

        this.fetchcommdata();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) =>{
        let name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        if(this.state.commessage === "" ){
            swal("Error", "Please Write Some Message...!", "error").then((swalres)=>{
            });
        } else {
            var formdata = new FormData;
            formdata.append("addcommmessage",'yes');
            formdata.append("commessage",this.state.commessage);
            formdata.append("commfile",this.state.commfile);
            formdata.append("puid",this.props.match.params.puid);
            formdata.append("luserid",localStorage.getItem("user_id"));
            formdata.append("luser",localStorage.getItem("user"));

            console.log(formdata);
            axios.post('https://clinic.dnyanasha.com/ws/communication.php',formdata).then((res)=>{
                console.log(res.data)
                if(res.data.savecommunication > 0)
                {
                    this.setState({commessage:""})
                    this.fetchcommdata();
                }
            })
        }
    }

    fetchcommdata = () =>{
        const cdata = {
            fetchcommdata : 'yes',
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/communication.php',cdata).then((res)=>{
            console.log(res.data);
            this.setState({findpdetail:res.data.findpdetail})
            this.setState({ findallcommunication : res.data.findallcommunication })
        })
    }

    handleFileUpload = (event) => {
        console.log(event.target.files[0]);
        this.setState({commfile:event.target.files[0]})
    };

    render(){
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px"}}>

                    <div className="d-flex flex-column justify-content-between position-absolute h-75" style={{width: "-webkit-fill-available"}}>
                        <div className="row p-1 m-0" style={{backgroundColor:"#e0e0e0"}}>
                            <div className="col-sm-12 d-flex align-items-center">
                                {
                                    (localStorage.getItem("user") == "Patient") ? (<>
                                        <box-icon type='solid' style={{fill:"#797979"}} name='user-circle'></box-icon><p className="m-0">Doctor and Staff</p>
                                    </>) : (<>
                                        <img src={"https://clinic.dnyanasha.com/ws/"+this.state.findpdetail.profile_picture} style={{height:"50px",width:"50px",borderRadius:"50%"}} />
                                        &nbsp;&nbsp;&nbsp;<p className="m-0">{this.state.findpdetail.patient_name}</p>
                                    </>)
                                }
                            </div>
                        </div>

                        <div className="row h-75 w-100 m-0">
                            <div className="col-sm-12 h-100 w-100 overflow-auto" id="trails">
                                {
                                    this.state.findallcommunication.map((item,index)=>{
                                        return(
                                            <>
                                                {
                                                    (localStorage.getItem("user_id") === item.luserid) ? (
                                                        <div className="mt-2 mb-2 d-flex flex-column align-items-end" >
                                                            <p className="m-0 p-1 px-3" style={{borderRadius:"5px",width:"fit-content",background:"#efefef"}}>{item.commessage}</p>
                                                            {(item.commfile == "") ? (<></>) : (<><p className="m-0 small"><b>Attached File : </b><a href={this.state.fileurlhost+((item.commfile).split(".")[0]+"_"+item.communication_id+"."+(item.commfile).split(".")[1])} target={"_blank"}>{item.commfile}</a></p></>)}
                                                            <p className="m-0" style={{fontSize: "10px",color:"#3c3c3c"}}>{item.created_at}</p>
                                                        </div>
                                                    ) : (
                                                        <div className="mt-2 mb-2">
                                                            <p className="m-0 p-1 px-3" style={{borderRadius:"5px",width:"fit-content",background:"#efefef"}}>{item.commessage}</p>
                                                            {(item.commfile == "") ? (<></>) : (<><p className="m-0 small"><b>Attached File : </b><a href={this.state.fileurlhost+((item.commfile).split(".")[0]+"_"+item.communication_id+"."+(item.commfile).split(".")[1])} target={"_blank"}>{item.commfile}</a></p></>)}
                                                            <p className="m-0" style={{fontSize: "10px",color:"#3c3c3c"}}>{item.created_at}</p>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="row p-2 m-0 mb-2" style={{backgroundColor:"#e0e0e0"}}>
                            <div className="col-sm-1 d-flex justify-content-center align-items-center p-0"><box-icon name='paperclip' style={{transform:"rotate(45deg)",fill:"#797979"}} onClick={()=>{this.refs.uploadfile.click()}} ></box-icon><input type="file" ref="uploadfile" onChange={this.handleFileUpload} hidden/></div>
                            <div className="col-sm-10 p-0"><input type="text" className="form-control" placeholder="Enter Message" name="commessage" value={this.state.commessage} onChange={this.handleChange} /></div>
                            <div className="col-sm-1 p-0"><button className="btn btn-primary" onClick={this.handleSubmit} >Send</button></div>
                        </div>
                    </div>
                        {/* <div className="row p-0 m-0"> */}
                            {/* <div className="col-sm-12"> */}
                            {/* </div> */}
                        {/* </div> */}

                    {/* <div className="row p-2 m-0" style={{marginLeft:"-5px"}}>
                        <div className="col-sm-12 d-flex" style={{alignItems:"center",backgroundColor:"#e0e0e0"}}>
                            {
                                (localStorage.getItem("user") == "Patient") ? (<>
                                    <box-icon type='solid' style={{fill:"#797979"}} name='user-circle'></box-icon><p className="m-0">Doctor and Staff</p>
                                </>) : (<>
                                    <img src={"https://clinic.dnyanasha.com/ws/"+this.state.findpdetail.profile_picture} style={{height:"50px",width:"50px",borderRadius:"50%"}} />
                                    &nbsp;&nbsp;&nbsp;<p className="m-0">{this.state.findpdetail.patient_name}</p>
                                </>)
                            }
                        </div>
                        <div className="col-sm-12">
                            <p>Messages</p>
                        </div>
                        <div className="col-sm-12">
                            <div className="row" style={{backgroundColor:"#e0e0e0"}}>
                                <div className="col-sm-1 d-flex justify-content-center align-items-center p-0"><box-icon name='paperclip' style={{transform:"rotate(45deg)",fill:"#797979"}} onClick={()=>{this.refs.uploadfile.click()}} ></box-icon><input type="file" ref="uploadfile" onChange={this.handleFileUpload} hidden/></div>
                                <div className="col-sm-10 p-0"><input type="text" className="form-control" placeholder="Enter Message" name="commessage" onChange={this.handleChange} /></div>
                                <div className="col-sm-1 p-0"><button className="btn btn-primary" onClick={this.handleSubmit} >Send</button></div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </>
        )
    }
}