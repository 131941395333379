import axios from "axios";
import React, { Component } from "react";
import Headerpd from "./header";
import Sidebarpd from "./sidebar";
import Modal from 'react-modal';
import DataTable from 'react-data-table-component'
import swal from "sweetalert";
import Printheader from '../assets/printheader.png';
import Printfooter from '../assets/printfooter.png';

export default class Billingpd extends Component{

    constructor(props){
        super(props);

        this.state = {
            findbillrec:[],
            totalinvoice:0,
            monthname : ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            findpid:[],
        }

        this.fetchbillingdata()
    } 

    fetchbillingdata = () =>{
        const billdata = {
            fetchbills : "yes",
            puid : this.props.match.params.puid,
        }
        axios.post('https://clinic.dnyanasha.com/ws/invoice.php',billdata).then((res)=>{
            console.log(res.data);
            this.setState({findbillrec:res.data.findbillrec})
            this.setState({findinnvoicetotal:res.data.findinnvoicetotal})
            this.setState({totalinvoice:res.data.findinnvoicetotal['totalinvoice']})
            this.setState({findpid : res.data.findpid})
            this.setState({pname : res.data.findpid['patient_name']})
            this.setState({email : res.data.findpid['email']})
            this.setState({pid : res.data.findpid['unique_pid']})
            this.setState({age : res.data.findpid['dob']})
        })
    }

    printform = () =>{
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = `
                                    <style>
                                        .hideonprint {
                                            display:none;
                                        }
                                        .header, .header-space {
                                            height: 100px;
                                        }
                                        .footer, .footer-space {
                                            height: 70px;
                                        }
                                        .header {
                                            position: fixed;
                                            top: 0;
                                            width:100%;
                                            display:flex;
                                        }
                                        .header img{
                                            width: 70%;
                                            height: 100px;
                                            margin:auto;
                                        }
                                        .footer {
                                            position: fixed;
                                            bottom: 0;
                                            width: 100%;
                                        }
                                        .footer img {
                                            width: 100%;
                                        }
                                        table {
                                            width:100%;
                                        }
                                        .watermark {
                                            position: fixed;
                                            top: 0;
                                            left: 0;
                                            height: 100%;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            z-index: -1;
                                        }
                                        .watermark p {
                                            font-size: 8rem;
                                            color: #bababa;
                                            transform: rotate(-45deg);
                                        }
                                        .printback {
                                            height: 100%;
                                            width: 100%;
                                            position: fixed;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                        }
                                        </style>
                                        <table>
                                        <thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead>
                                        <tbody><tr><td>
                                        <div class="content">
                                            ${printContents}
                                        </div>
                                        </td></tr></tbody>
                                        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                                        </table>
                                        <div class="header"><img src='${Printheader}' /></div>
                                        <div class="footer"><img src='${Printfooter}' /> </div>
                                        <div class="watermark"><p>CoreConcept</p></div>
                                        `;
        setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
        }, 500)
    }

    render(){
        var balance = this.state.totalinvoice;
        return(
            <>
                <Headerpd puid={this.props.match.params.puid} />
                <Sidebarpd puid={this.props.match.params.puid} />
                <div style={{marginLeft:"220px",padding:"5px"}}>
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary btn-sm my-2 mx-3" onClick={this.printform} >Print</button>
                </div>
                {/* <hr /> */}
                <div id="printablediv">
                    <hr  className="m-0" />
                        <div className="d-flex">
                            <div className="" style={{width:"65%"}}>
                                <p className="m-0">
                                    Patient Name : <b>{this.state.findpid['patient_name']} &nbsp; 
                                    { (this.state.findpid['gender'] == 'm') ? ("M") : ("F")  } 
                                    {(this.state.findpid['dob'] === "") ? (<></>) : (
                                        <>/{ new Date().getFullYear() - new Date(this.state.findpid['dob']).getFullYear() } Yrs</>
                                    )}
                                </b>
                                </p></div>
                            <div className="" style={{width:"35%"}}><p className="m-0">Patient ID : <b>{this.state.findpid['unique_pid']}</b></p></div>
                        </div>
                        <div className="d-flex">
                            <div className="" style={{width:"65%"}} ><p className="m-0">Email : <b>{this.state.findpid['email']}</b></p></div>
                            <div className="" style={{width:"35%"}} ><p className="m-0">Mob. No. : <b>{this.state.findpid['mobile_number']}</b></p></div>
                        </div>
                        <hr  className="m-0" />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table w-100">
                                    <thead>
                                        <tr style={{fontSize:"13px"}}>
                                            <th>Date</th>
                                            <th>Receipt No.</th>
                                            <th>Mode of Payment</th>
                                            <th>Amount</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.findbillrec.length === 0 ) ? (<></>) : (
                                            this.state.findbillrec.map((item,index)=>{
                                                var date = new Date(item.payment_date).getDate()+" "+this.state.monthname[new Date(item.payment_date).getMonth()]+" "+new Date(item.payment_date).getFullYear();
                                                const prevAmount = index === 0 ? 0 : this.state.findbillrec[index - 1].amount;
                                                const prevBalance = index === 0 ? balance : this.state.findbillrec[index - 1].balance;
                                                const newBalance = prevBalance - item.amount;
                                                item.balance = newBalance;
                                                balance = prevAmount === 0 ? balance - item.amount : prevBalance;
                                                return(
                                                        <tr style={{fontSize:"13px"}}>
                                                            <td>{date}</td>
                                                            <td>{item.recept_no}</td>
                                                            <td>{item.modeofpay}</td>
                                                            <td>{item.amount}</td>
                                                            <td>{newBalance}</td>
                                                        </tr>
                                                )
                                            })
                                        ) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}